import _ from 'lodash';
import { useState, useEffect } from 'react';
import axiosInstance from "../interceptor/axiosInstance";
import { toast } from "react-toastify";

import { Link } from 'react-router-dom';
const API_URL = process.env.REACT_APP_API_URL;


const DashboardFavourite = () => {
  const [FavProperties, setFavProperties] = useState([]);

  const getLeadData = () => {

    axiosInstance
      .get(`${API_URL}/properties/user/dashboard/count/favourite/`)
      .then((response) => {
        if (response.data) {
          setFavProperties(response.data);

        } else {


        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {


          toast.error(error.response?.data?.detail);
        } else {

          toast.error(' Please Contact Administrator');
        }
      });
  };
  useEffect(() => {
    getLeadData();
  }, []);



  return (
    <div className="card mb-5">
      <div className="card-body  align-items-center px-6 py-3 " style={{ overflowX: 'auto', overflowY: 'auto', maxHeight: '397px' }}>
        <div className="row">
          <div className="col-md-6">
            <h2 className="fs-18">Favourite Properties</h2>
          </div>
          <div className="col-md-6 text-right">
            <h2 className="fs-18">Count</h2>
          </div>
        </div>

        {/* Add a check for FavProperties before mapping */}
        {Array.isArray(FavProperties) && FavProperties.length > 0 ? (
          FavProperties
            .filter(item => item?.count_favourite > 0)
            .map((item, index) => (
              <div className="section-content-admin d-flex px-2 py-2 mb-1" key={index}>
                {/* <div className='px-2' style={{ width: '100px' }}>
          <img style={{ height: '55px' }} className="rounded-circle" src={item?.upload_featured_image} alt="property" />
        </div> */}

                <div className='property-content px-2 mt-1 w-100'>
                  <Link to={`/property/details/${item?.slug}`}>
                    <h2 className='fs-16'>{item?.property_name}</h2>
                    <span className='text-blue font-weight-bold'>
                      {item?.prop_id}
                    </span>
                  </Link>
                </div>

                <span className='badge mt-2 badge-blue justify-content-center px-2' style={{ height: 'fit-content' }}>
                  {item?.count_favourite}
                </span>
              </div>
            ))
        ) : (
          <p>No  properties favourited.</p>
        )}

      </div>
    </div>
  );
}

export default DashboardFavourite;
