import React, { useEffect } from "react";
import Footer from "../footer/Footer";
import Header from "../header/Header";

const Policy = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
    },[]);
    return (<>

        <Header />
        <main id="content " className="  mt-100">

            <section className="bg-patten-03 bg-gray-01 py-5 " data-animated-id="3" >
                <div className="container ">
                    <div className="card border-0 mt-5 z-index-3 mb-12">
                        <div className="card-body p-6 px-lg-14 py-lg-10">
                            <h2 className="text-heading mb-4 fs-20  text-center lh-22 px-6">PRIVACY POLICY
                            </h2>
                            <p className="text-center text-black fs-16 fm-nunito"> </p>


                            <body className="c7 doc-content">
                                <p className="c1"><span className="h4">PRIVACY POLICY</span></p>
                                <p className="c1"><span className="c2">Dirhamperfoot (DPF) and its related corporate bodies operate the following websites
                                    across desktop, mobile, tablet and apps (including any subdomains) collectively known as the
                                </span><span className="h4">Websites</span><span className="c0"> in this Privacy Policy.</span></p>
                                <p className="c1"><span className="c0">Dirhamperfoot and its related bodies corporate are together known as
                                    we, our and us in this Privacy Policy (unless the context
                                    requires), except in relation to the Credit Reporting Policy where  we,
                                    our and us refer only to Dirhamperfoot. Where we refer to the data practices of
                                    a specific business in this Privacy Policy, we, our or us refers to the
                                    operating entity of that business, as specified above.</span></p>
                                <p className="c1"><span className="c0">This Privacy Policy sets out the manner in which we collect, use, disclose and manage
                                    personal information. By using the Websites, subscribing to our services or entering into an agreement with
                                    us in relation to the Websites, you are taken to have read, and agreed to the collection, use, disclosure
                                    and handling of your personal information in accordance with this Privacy Policy.</span></p>
                                <p className="c1"><span className="c0">We may modify this Privacy Policy at any time. You should review this Privacy Policy
                                    periodically so that you are updated on any changes. If you are a customer and the modification would result
                                    in a material change to either party&rsquo;s rights or obligations and, for that reason, may cause you
                                    material detriment, we will give you at least 30 days&rsquo; written notice via email before the
                                    modification is to take effect and you may terminate your agreement with us during this period if you
                                    consider that the modification will cause you material detriment. For non-material changes we make that
                                    would not cause material detriment to a customer, we will give customers at least 30 days&rsquo; written
                                    notice before the modification is to take effect, but customers may not terminate as a result of this
                                    modificationduring this period.</span></p>
                                <p className="c1"><span className="h4">RESPECTING INFORMATION PRIVACY</span></p>
                                <p className="c1"><span className="c0">We recognise the importance of protecting personal information. We do not, without
                                    your consent, collect sensitive information such as racial or ethnicity information, political opinions or
                                    associations, criminal records or health information.</span></p>
                                <p className="c1"><span className="h4">THE PERSONAL INFORMATION WE COLLECT ABOUT YOU</span></p>
                                <p className="c1"><span className="c0">We may collect personal information about you, including, but not limited to, your
                                    name, address, phone number, email, gender, occupation, personal interests and any other information
                                    provided.</span></p>
                                <p className="c1"><span className="c0">If you access any of our services through a social network site or login to our
                                    Websites using your social network credentials such as Facebook, we will collect limited information
                                    provided to us by that social network site, such as user name, site ID, profile photo and email
                                    address.</span></p>
                                <p className="c1"><span className="c0">If you are a shareholder of Dirhamperfoot we may collect information about your
                                    shareholdings, banking details and tax file numbers for payment of dividends and other amounts. If you
                                    subscribe to alert services, we may collect your name and email address.</span></p>
                                <p className="c1"><span className="c0">If you submit personal information to us in relation to an employment opportunity, we
                                    may also collect information about your employment and academic history.</span></p>
                                <p className="c1"><span className="c0">We may also collect non-personal information about you including, but not limited to,
                                    data relating to your activities on the Websites and Dirhamperfoot Website (including IP addresses) via
                                    tracking technologies such as cookies, web beacons and measurement software or data relating to survey
                                    responses.</span></p>
                                <p className="c1"><span className="c0">You acknowledge that the personal information you provide us and which we collect
                                    from you, is your own information or information which you have been authorised to provide to us.</span></p>
                                <p className="c1"><span className="c0">For some services and products, we may also collect your personal information to
                                    enable verification of your identity, including information from your passport, drivers licence and
                                    health care and concession cards.</span></p>
                                <p className="c1"><span className="h4">HOW WE COLLECT YOUR PERSONAL INFORMATION</span></p>
                                <p className="c1"><span className="c0">We may collect personal information about you from a variety of sources including,
                                    but not limited to:</span></p>
                                <p className="c1"><span className="c0"> (a) registering to use the Websites or parts of them through a
                                    Dirhamperfoot account;</span></p>
                                <p className="c1"><span className="c2"> (b) logging in to use our Websites via your social networking site
                                    (</span><span className="h4">SNS</span><span className="c0">) account;</span></p>
                                <p className="c1"><span className="c0"> (c) subscribing to receive Alerts/e-brochures and filling in forms,
                                    applications (e.g. online rental applications), surveys or research, participating in promotions and
                                    competitions on the Websites or websites of our service providers;</span></p>
                                <p className="c1"><span className="c0"> (d) contacting us or our service providers for any reason including, but
                                    not limited to, reporting a problem with the Websites, requesting further services or seeking our
                                    assistance;</span></p>
                                <p className="c1"><span className="c0"> (e) posting or contributing material on our Websites;</span></p>
                                <p className="c1"><span className="c0"> (f) using our services and any credit provided by us in connection with
                                    those services (see our Credit Reporting Policy below for more detail); and</span></p>
                                <p className="c1"><span className="c0"> (g) applying for an employment opportunity with us directly, via an SNS
                                    site (eg. LinkedIn) or through your nominated referees.</span></p>
                                <p className="c1"><span className="c0">Dirhamperfoot may collect personal information about you if you:</span></p>
                                <p className="c1"><span className="c0"> (a) are a shareholder of Dirhamperfoot pty Ltd, then Dirhamperfoot may
                                    collect personal information about you from third parties (including, for example, collection of information
                                    from its share or share plan registrar); and</span></p>
                                <p className="c1"><span className="c0"> (b) subscribe to receive email alerts, ASX announcements, media releases
                                    and other news relating to Dirhamperfoot through the Dirhamperfoot Website.</span></p>
                                <p className="c1"><span className="c0">We may also collect your personal information through our related bodies corporate,
                                    our service providers and third parties so that we may provide a better or more relevant service or product
                                    to you.</span></p>
                                <p className="c1"><span className="c0">If you apply to us for credit or enquire about a mortgage broker or apply for a home
                                    loan, we may also obtain personal information about you from credit reporting bodies (see our Credit
                                    Reporting Policy below for more details), other credit providers, existing or previous suppliers of goods or
                                    services, your bank, finance brokers, accountants, lawyers and certain other businesses for the purposes of
                                    assessing your application. We will collect information directly from you where possible.</span></p>
                                <p className="c1"><span className="h4">HOW WE USE YOUR PERSONAL INFORMATION</span></p>
                                <p className="c1"><span className="c0">We use the personal information we have collected largely for the purpose of
                                    providing you with products and services that you have requested, responding to your enquiries, creating and
                                    maintaining your account and ensuring you comply and adhere to our website terms of use.</span></p>
                                <p className="c1"><span className="c0">More specifically, we may use personal information which we have collected to:</span>
                                </p>
                                <p className="c1"><span className="c0"> (a) ensure that content from the Websites is presented in the most
                                    effective manner for you and for your computer;</span></p>
                                <p className="c1"><span className="c0"> (b) provide a better or more relevant service or product to you, for
                                    instance by automatically populating forms on the Websites when you make enquiries of selected estate
                                    agents;</span></p>
                                <p className="c1"><span className="c0"> (c) combine your personal information with information that we have
                                    collected from our service providers, third parties, cookies or web beacons in order to provide you with a
                                    better or more relevant and personalised experience and to improve the quality of our services and the
                                    services of third parties. For example, we may combine behavioural data we have collected about you through
                                    the use of cookies or web beacons and combine it with your personal information from requests you send to
                                    third parties through our Websites;</span></p>
                                <p className="c1"><span className="c0"> (d) personalise and customise your services, experience, advertising and
                                    content that you view and engage with on the Websites or the websites of our service providers and business
                                    partners</span></p>
                                <p className="c1"><span className="c0"> (e) respond to or provide you with services, products, information and
                                    assistance that you request from us;</span></p>
                                <p className="c1"><span className="c0"> (f) contact you to conduct surveys, research and feedback about our
                                    products, services or the Websites;</span></p>
                                <p className="c1"><span className="c0"> (g) verify your identity when you register or log into our Websites via
                                    your SNS account and remind you of your password and username;</span></p>
                                <p className="c1"><span className="c0"> (h) allow you to participate in interactive features of our service,
                                    when you choose to do so;</span></p>
                                <p className="c1"><span className="c0"> (i) help carry out our obligations arising from any contracts entered
                                    into between you and us;</span></p>
                                <p className="c1"><span className="c0"> (j) to assess your application for credit as a business in connection
                                    with our services;</span></p>
                                <p className="c1"><span className="c0"> (k) by disclosing the information to debt collection agencies to recover
                                    any amounts you owe us;</span></p>
                                <p className="c1"><span className="c0"> (l) notify you about changes to our products and services; and</span>
                                </p>
                                <p className="c1"><span className="c0">(m) enhancing your security when you visit our Website.</span></p>
                                <p className="c1"><span className="c0">If all or part of this information is not provided, we may not be able to provide
                                    these services.</span></p>
                                <p className="c1"><span className="c0">If you are a shareholder of Dirhamperfoot, Dirhamperfoot may also use your personal
                                    information to:</span></p>
                                <p className="c1"><span className="c0"> (a) communicate with, and comply with Dirhamperfoot&rsquo;s legal
                                    obligations to, its shareholders, and to process payments to them; and</span></p>
                                <p className="c1"><span className="c0"> (b) enable its service providers to provide it with services relating to
                                    Dirhamperfoot&rsquo;s share register and group employee share plan.</span></p>
                                <p className="c1"><span className="c0">If you submit personal information to us in relation to an employment opportunity
                                    with us, we may also use your personal information to:</span></p>
                                <p className="c1"><span className="c0"> (a) consider you for the position for which you have submitted your
                                    personal information to us or any other positions that are or become available in the future;</span></p>
                                <p className="c1"><span className="c0"> (b) respond to you in relation to any future application you make for an
                                    employment opportunity with us; and</span></p>
                                <p className="c1"><span className="c0"> (c) contact your referees in order to collect the information you have
                                    consented to us collecting about you in order to consider you for employment opportunities with us.</span>
                                </p>
                                <p className="c1"><span className="h4">USING AND DISCLOSING YOUR PERSONAL INFORMATION FOR MARKETING PURPOSES</span></p>
                                <p className="c1"><span className="c0">We may:</span></p>
                                <p className="c1"><span className="c0"> (a) use your personal information to provide you with information about
                                    offers, promotions, goods or services, which we believe may be of interest to you; and</span></p>
                                <p className="c1"><span className="c0"> (b) share your information with our service providers and other third
                                    parties so that they can provide you with products or services on our behalf or help us to provide you with
                                    the requested products or services including contacting you in relation to the products or services.</span>
                                </p>
                                <p className="c1"><span className="c0">If we do contact you by using your personal information in accordance with either of
                                    the above, we will give you the opportunity to request that your information not be used for further direct
                                    marketing in the future.</span></p>
                                <p className="c1"><span className="c0">We may also, if you request us to by opting-in through your Dirhamperfoot.ae Connect
                                    or online rental applications, share your information with third parties so they may contact you directly
                                    about their offers, promotions, goods or services.</span></p>
                                <p className="c1"><span className="h4">HOW WE DISCLOSE YOUR PERSONAL INFORMATION</span></p>
                                <p className="c1"><span className="c0">We may disclose personal information to our related bodies corporate, service
                                    providers or business partners.</span></p>
                                <p className="c1"><span className="c0">We may also disclose your personal information:</span></p>
                                <p className="c1"><span className="c0"> (a) to real estate agents or agencies, property developers, builders,
                                    retirement community operators and landlords that have listings or advertising on our Websites and which you
                                    have requested information from by submitting an enquiry;</span></p>
                                <p className="c1"><span className="c0"> (b) to our service providers, so that they can provide you with products
                                    or services on our behalf or products or services that you have requested directly from them or to help us
                                    to provide you with our products or services (including if you are a shareholder, to share registrar and
                                    share plan management service providers). If you have subscribed to a service through the operating system
                                    on your mobile device (including, but not limited to Google&rsquo;s Google Now service), we
                                    will disclose information such as your search history to Google. You can opt out of us providing such
                                    information to Google through the operating system on your mobile device;</span></p>
                                <p className="c1"><span className="c0"> (c) to third parties where you have requested information, services or
                                    products from them (eg through using Dirhamperfoot.ae Connect or an online rental application);</span></p>
                                <p className="c1"><span className="c0"> (d) in conjunction with a sale or similar transfer of a business;</span>
                                </p>
                                <p className="c1"><span className="c0"> (e) if you apply to us for credit, to:</span></p>
                                <p className="c1"><span className="c0"> (i) credit reporting bodies
                                    (for the purpose of obtaining credit reporting information about you and other permitted purposes &ndash;
                                    see our Credit Reporting Policy below for more details); and</span></p>
                                <p className="c1"><span className="c0"> (ii) other credit providers,
                                    existing or previous suppliers of goods or services, your bank and certain other businesses for the purposes
                                    of assessing your application;</span></p>
                                <p className="c1"><span className="c0"> (f) relevant public, government or regulatory authorities, our legal
                                    representatives or other concerned parties, in special situations where we have reason to believe that
                                    disclosing your personal information is necessary to help identify, contact or bring legal action against
                                    anyone damaging, injuring, or interfering (intentionally or unintentionally) with our rights or property,
                                    users or anyone else who could be harmed by such activities; and</span></p>
                                <p className="c1"><span className="c0"> (g) where we are otherwise authorised or required by law to do
                                    so.</span></p>
                                <p className="c1"><span className="c0">If you request information from any organisation through our Websites, you will need
                                    to check their privacy policy to find out how they handle your personal information. We are not responsible
                                    for the way these organisations collect, use, disclose or handle personal information you provide to them
                                    through our Websites.</span></p>
                                <p className="c1"><span className="c11 h4">DISCLOSURE AND STORAGE OF PERSONAL INFORMATION </span></p>
                                <p className="c1"><span className="c0">Personal information submitted by our customers and visitors to our Websites may be
                                    held on servers located in the UAE or in the data centres of our outsourced data processors with data
                                    centres in the United States of America, Europe and Asia. We transfer data outside of UAE to our service
                                    providers in order to obtain secure storage, back-up and data retrieval services and to provide services. We
                                    have implemented policies and procedures to safeguard the secure storage and processing of data with our
                                    related companies and have verified the security arrangements of our outsourced data processors. When you
                                    provide us with your personal information you give us your consent to store your personal information
                                    outside of Australia for the purposes described above.</span></p>
                                <p className="c1"><span className="c0">Where we have obtained your consent to do so, we may also share your personal
                                    information with our service providers and other third parties located outside Australia so that they can
                                    provide you with any products or services requested by you, including contacting you in relation to the
                                    products or services. As these entities are located outside of Australia, they are not required to comply
                                    with the Privacy Act and you will not be able to seek any redress under the Privacy Act in connection with
                                    the use of your information.</span></p>
                                <p className="c1"><span className="h4">SECURITY</span></p>
                                <p className="c1"><span className="c0">We strive to ensure the security, integrity and privacy of personal information we
                                    collect. We have established safeguards and use reasonable security measures to protect your personal
                                    information from unauthorised access, modification and disclosure. Our employees, contractors, agents and
                                    service providers who provide services related to our information systems, are obliged to respect the
                                    confidentiality of any personal information held by us. We review and update our security measures in light
                                    of current technologies. Unfortunately, no data transmission over the internet can be guaranteed to be
                                    totally secure.</span></p>
                                <p className="c1"><span className="h4">CORRECTION AND ACCESS</span></p>
                                <p className="c1"><span className="c2">We will endeavour to take all reasonable steps to keep accurate and up to date, any
                                    information which we hold about you. If, at any time, you discover that information held about you is
                                    incorrect or you would like to review and confirm the accuracy of your personal information, you
                                    can</span><span className="c6">contact us</span><span className="c0">.</span></p>
                                <p className="c1"><span className="c2">You can also gain access to the personal information we hold about you, subject to
                                    certain exceptions provided for by law. To request access to your personal information,
                                    please</span><span className="c6">contact us</span><span className="c0">.</span></p>
                                <p className="c1"><span className="h4">COMPLAINTS RESOLUTION</span></p>
                                <p className="c1"><span className="c0">We are committed to providing our customers with a fair and responsible system for
                                    the handling of complaints.</span></p>
                                <p className="c1"><span className="c2">If at any time you have any concerns, complaints or questions in relation to your
                                    privacy or the operation of any of our businesses, please contact our Privacy Officer at</span><span
                                        className="c6"><a className="c8"
                                            href="mailto:privacyofficer@dollarperfoot.com.au">privacyofficer@dirhamperfoot.a</a></span><span
                                                className="c0">eso that we may resolve your concerns.</span></p>
                                <p className="c1"><span className="c0">Our Privacy Officer will consider your query and endeavour to respond to you
                                    promptly.</span></p>
                                <p className="c1"><span className="h4">OPTING OUT OF PERSONALISED ADS</span></p>
                                <p className="c1"><span className="c0">We aim to give you a highly relevant experience on our sites and apps. This
                                    involves us collecting and using information about the content you engage with, such as images, articles,
                                    calculators and ads, along with any information you provide to us via your dirhamperfoot.ae account (if you
                                    have one). We then show you ads that we believe will be engaging. You can opt out of us
                                    personalising your advertising experience by going to the opt out page.</span></p>
                                <p className="c1"><span className="h4">COOKIES AND WEB BEACONS</span></p>
                                <p className="c1"><span className="c2 c5">Apple iOS App users</span><span className="c0">Our Apple iOS Apps have been
                                    tailored to meet Apple&rsquo;s App Tracking Transparency requirements. Unlike our other Websites, our iOS
                                    Apps do not track our iOS users for the purposes of personalised or targeted advertising. In particular,
                                    this means that our iOS Apps do not link user or device data collected from our iOS Apps with user or device
                                    data collected from third party apps, websites or offline properties for targeted advertising or advertising
                                    measurement purposes. User and device data from our iOS Apps is not shared with data brokers. Other than the
                                    iOS App-specific cookie restrictions and limitations set out in this paragraph, this Cookies and Web Beacons
                                    section below summarises how our iOS Apps and Websites obtain and use cookies.</span></p>
                                <p className="c1"><span className="c2 c5">General</span><span className="c0">We use cookies, web beacons and measurement
                                    software and tools on the Websites and so do our services providers and third parties such as our analytics,
                                    advertising or ad serving partners. We use and disclose the information collected through the use of
                                    cookies, web beacons and measurement software and tools in accordance with this Privacy Policy. This
                                    includes using the information to report statistics, analyse trends, administer our services, diagnose
                                    problems and target and improve the quality of our products and services. We may allow other third parties
                                    to use their own cookies and web beacons to collect information about your visits to the Websites.</span>
                                </p>
                                <p className="c1"><span className="c0">We may combine our cookies, information collected through the cookies and web beacons
                                    on the Websites with other information (including information collected by third parties using their own
                                    cookies and web beacons and providing our cookies and information to third parties)and use analytics
                                    services such as Firebase Analytics &ndash; to provide better or more relevant services and advertising to
                                    you on the Websites and third party websites. Our service providers and other third parties may do the same
                                    in order to provide more relevant services and advertising to you through other websites that you may
                                    visit.</span></p>
                                <p className="c1"><span className="h4">COOKIE CHOICES</span></p>
                                <p className="c1"><span className="c0">If you do not want information collected through the use of cookies, web beacons or
                                    measurement software and tools, you may be able to delete or reject Cookies or some of the measurement
                                    software features through your browser or the settings section of your mobile or tablet device. Disabling
                                    these features may cause some of the functions on the Websites, or products and services not to work
                                    properly.</span></p>
                                <p className="c1"><span className="c2">To learn more about how we use Cookies and similar technologies, and how you can
                                    manage your preferences, visit our</span><span className="c6"><a className="c8"
                                        href="https://www.google.com/url?q=https://about.realestate.com.au/cookies/&amp;sa=D&amp;source=editors&amp;ust=1694073012082692&amp;usg=AOvVaw32XhchNzI122cd8WJC5Av5">Cookie
                                        Policy</a></span><span className="c0">.</span></p>
                                <p className="c1"><span className="h4">APPLICATIONS, WIDGETS OR LINKS TO OTHER WEBSITES</span></p>
                                <p className="c1"><span className="c0">We provide links to websites outside of the Websites, as well as to third party
                                    websites. We also allow some third parties to display widgets and applications on our Websites that allow
                                    you to interact and share content including social media buttons such as Facebook share and like, Twitter,
                                    Pinterest and Google+. These linked sites, applications and widgets are not under our control, and we cannot
                                    accept responsibility for the conduct of companies linked to the Websites, or their collection of
                                    information through these third party applications or widgets. Before disclosing your personal information
                                    on any other website, or using these applications or widgets we advise you to examine the terms and
                                    conditions of using that website and the relevant third party&rsquo;s data collection practices and controls
                                    in their privacy policy.</span></p>
                                <p className="c1"><span className="h4">VIRTUAL NUMBERS</span></p>
                                <p className="c1"><span className="c0">We use virtual phone numbers on the Websites to track the origin, time and duration
                                    of phone enquiries to our customers. We do not record calls which you make to agents. Our third
                                    party provider sends this information to the agent you called so that they can follow up on missed calls
                                    relating to Dirhamperfoot.ae listings. We also use this data in aggregated form, to report statistics,
                                    analyse trends, administer our services, diagnose problems and target and improve the quality of our
                                    products and services. If you do not want us to collect information regarding your call, you can
                                    contact an agent by email or by telephone using the number listed on the agent&rsquo;s website.</span></p>
                                <p className="c1"><span className="h4">Dirhamperfoot.com HOME LOANS FACEBOOK ADS</span></p>
                                <p className="c1"><span className="c0">If you submit an enquiry about Dirhamperfoot.ae Home Loans on Facebook, your
                                    information will be passed to the team at the Dirhamperfoot.ae Home Loans contact centre so they can help
                                    you with your enquiry. The information you provide will not be used for any other purpose unless you
                                    consent.</span></p>
                                <p className="c1"><span className="h4">USERS BASED IN THE EUROPEAN ECONOMIC AREA</span></p>
                                <p className="c1"><span className="c2">If you access our Websites from the European Economic Area (</span><span
                                    className="h4">EEA</span><span className="c2">), you may have additional rights under the General Data Protection
                                        Regulation, being Regulation 2016/679 (</span><span className="h4">GDPR</span><span className="c0">) in relation to
                                            the handling of your Personal Data (as defined in Article 4 of the GDPR).</span></p>
                                <p className="c1"><span className="c0">In providing our services to you, we may monitor your activity on the Websites using
                                    automated processes, including for the purposes of counting usage of our Websites and creating audience
                                    profiles. We do this to provide you with more tailored and relevant services, including to show you
                                    more relevant properties.</span></p>
                                <p className="c1"><span className="c0">In addition to other rights set out in this Privacy Policy, in certain circumstances
                                    you may:</span></p>
                                <ul className="c13 lst-kix_list_1-0 start">
                                    <li className="c12 li-bullet-0"><span className="c0">request access to Personal Data that we hold about you;</span></li>
                                    <li className="c9 li-bullet-0"><span className="c0">request a copy of Personal Data that that you have provided to us,
                                        in a structured electronic format;</span></li>
                                    <li className="c9 li-bullet-0"><span className="c0">request that we update any inaccurate Personal Data that we hold
                                        about you;</span></li>
                                    <li className="c9 li-bullet-0"><span className="c0">request that we restrict the processing of Personal Data that we
                                        hold about you. This enables you to ask us to suspend the processing of Personal Data, for example if
                                        you want us to establish its accuracy or the reason for processing it;</span></li>
                                    <li className="c9 li-bullet-0"><span className="c0">object to our processing of your Personal Data;</span></li>
                                    <li className="c9 li-bullet-0"><span className="c0">request that we delete any Personal Data that we hold about you,
                                        subject to any legal obligations we have to retain your Personal Data; and</span></li>
                                    <li className="c10 li-bullet-0"><span className="c0">withdraw your consent to the processing of the Personal Data that
                                        we hold about you. Once you have withdrawn your consent, we will no longer process your Personal Data
                                        for the purpose(s) you originally agreed to, unless we are required to do so by law. This will not
                                        affect the lawfulness of any processing of Personal Data based on your consent before its
                                        withdrawal.</span></li>
                                </ul>
                                <p className="c1"><span className="c0">Where your Personal Data is transferred outside of the EEA, it will only be
                                    transferred to countries that have been identified as providing adequate protection for Personal Data, or to
                                    a third party where we have approved transfer mechanisms in place to protect your Personal Data &ndash;
                                    i.e., by entering into the European Commission&rsquo;s Standard Contractual Clauses, or by ensuring the
                                    entity is Privacy Shield certified (for transfers to US-based third parties).</span></p>
                                <p className="c1"><span className="c2">For further information or to exercise any of your rights as a EEA data subject under
                                    the GDPR, please contact</span><span className="c6"><a className="c8"
                                        href="mailto:privacyofficer@dollarperfoot.com.au">privacyofficer@Dirhamperfoot.ae</a></span></p>
                                <p className="c1"><span className="h4">CREDIT REPORTING POLICY</span></p>
                                <p className="c1"><span className="h4">Credit applications by businesses for our services</span></p>
                                <p className="c1"><span className="c0">REA provides &lsquo;commercial credit&rsquo; for the purposes of the Privacy Act to
                                    businesses (such as real estate agents, developers and business brokers) who apply for credit in relation to
                                    our services.We do not provide any consumer credit.</span></p>
                                <p className="c1"><span className="c0">References to you and your in this credit reporting
                                    policy are references to individuals in relation to whom we hold credit information or credit eligibility
                                    information for the purposes of the Privacy Act in connection with such credit. This policy provides details
                                    about our management of such information.</span></p>
                                <p className="c1"><span className="h4">How we collect and hold &lsquo;credit information&rsquo; and &lsquo;credit
                                    eligibility information&rsquo; about you</span></p>
                                <p className="c1"><span className="c2"></span><span className="h4">Credit information</span><span
                                    className="c2">relates mainly to your credit-related dealings with us and comprises various types
                                    of information that can be collected by credit reporting bodies (</span><span
                                        className="h4">CRBs</span><span className="c0">) that report on consumer credit worthiness. We may collect or
                                            generate various categories of &lsquo;credit information&rsquo; about you.</span></p>
                                <p className="c1"><span className="c2">We may also collect </span><span className="h4">credit eligibility
                                    information</span><span className="c0"> about you, which is mostly informationprovided byCRBs
                                        relating to your dealings with other credit providers (for example, financial institutions that provide you
                                        with loans or other businesses that provide you with credit in connection with their products or
                                        services).</span></p>
                                <p className="c1"><span className="c0">Credit information and credit eligibility information may
                                    include:</span></p>
                                <p className="c1"><span className="c0"> (a) identification information: such as your name, address, date of
                                    birth or employer;</span></p>
                                <p className="c1"><span className="c0"> (b) consumer credit liability information: being information about
                                    consumer credit accounts you hold with other credit providers;</span></p>
                                <p className="c1"><span className="c0"> (c) details about information requests made to CRBs: such as the fact
                                    that we or another credit provider have requested credit reporting information about you from a CRB to
                                    assess a credit application and various details about the credit you have applied for;</span></p>
                                <p className="c1"><span className="c0"> (d) default information: being information about overdue payments owed
                                    by you in connection with consumer credit which have been disclosed to a CRB by other credit
                                    providers;</span></p>
                                <p className="c1"><span className="c0"> (e) payment information: being information that an overdue payment has
                                    been repaid;</span></p>
                                <p className="c1"><span className="c0"> (f) information about consumer credit-related serious credit
                                    infringements;</span></p>
                                <p className="c1"><span className="c0"> (g) new arrangement information: being information about certain
                                    credit-related arrangements you may have entered with another credit provider in connection with a consumer
                                    credit default or serious credit infringement;</span></p>
                                <p className="c1"><span className="c0"> (h) court proceedings information: being information about certain
                                    credit-related judgments;</span></p>
                                <p className="c1"><span className="c0"> (i) personal insolvency information: being information recorded in the
                                    National Personal Insolvency Index about bankruptcy or various other insolvency-related matters;</span></p>
                                <p className="c1"><span className="c0"> (j) publicly available information about activities relating to credit
                                    worthiness.</span></p>
                                <p className="c1"><span className="c0">Credit eligibility also includes credit worthiness information that we may derive
                                    from data we receive from a CRB, such as a credit risk score.</span></p>
                                <p className="c1"><span className="c0">We may collect credit information about you in any of the circumstances described
                                    above under the heading How we collect your personal information above. We collect credit
                                    eligibility information from CRBs but may collect it from other third parties where permitted by the Privacy
                                    Act (such as from other credit providers with your consent).</span></p>
                                <p className="c1"><span className="c0">We store and safeguard your credit information and credit eligibility information in
                                    the manner described under the headings Disclosure and storage of personal information outside
                                    Australia and Security above.</span></p>
                                <p className="c1"><span className="h4">How we use and when we disclose your credit information and credit eligibility
                                    information</span></p>
                                <p className="c1"><span className="c0">We may disclose your credit information to CRBs. Those CRBs may then include that
                                    information in credit reporting information that they provide to other credit providers to assist them to
                                    assess your credit worthiness. For example, we may disclose to a CRB the type of commercial credit, and the
                                    amount of credit, sought in your application for credit with us. We may also use and disclose your credit
                                    information for other purposes and in other circumstances as described under the headings How we use
                                    your personal information and How we disclose your personal information above when
                                    permitted to do so by the Privacy Act.</span></p>
                                <p className="c1"><span className="c2">Our use and disclosure of credit eligibility information is regulated by provisions
                                    of Part IIIA of the Privacy Act and the Privacy (Credit Reporting) Code 2014 (</span><span className="h4">Credit
                                        Reporting Privacy Code</span><span className="c0">). We will only use or disclose such information for purposes
                                            permitted by these provisions, such as:</span></p>
                                <p className="c1"><span className="c0"> (a) to process an application for credit with us in relation to our
                                    services;</span></p>
                                <p className="c1"><span className="c0"> (b) to manage the commercial credit we provide and collect overdue
                                    payments; or</span></p>
                                <p className="c1"><span className="c0"> (c) where a disclosure is required or authorised under an Australian law
                                    or a court/tribunal order.</span></p>
                                <p className="c1"><span className="c0">In some cases, the people to whom we may disclose your credit information or credit
                                    eligibility information may be based outside Australia &ndash; see the heading Disclosure and storage
                                    of personal information outside Australia above for more details.</span></p>
                                <p className="c1"><span className="h4">Correction of and access to credit information and credit eligibility
                                    information</span></p>
                                <p className="c1"><span className="c0">To request correction of, or access to, your credit eligibility information please
                                    see the heading Correction and access above</span></p>

                            </body>





                        </div>
                    </div>
                    
                </div>
            </section>


        </main>
        <Footer />
    </>)
}
export default Policy;