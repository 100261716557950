import React, { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import axiosInstance from "../../interceptor/axiosInstance";
import { toast } from "react-toastify";
import moment from "moment";
import { Modal } from "react-bootstrap";
import Loader from "../../Loader";
import { Button } from "react-bootstrap";
const API_URL = process.env.REACT_APP_API_URL;
const Savesearch = () => {
  const [ResData, setResData] = useState([]);
  moment.locale('en');
  const [searchId, setsearchId] = useState();


  /**********************Delete  Save Search */

  // Handle Modal
  const handleClose = () => setShow(false);
  const [loading, setLoading] = useState(false)
  const handleShow = () => setShow(true);
  const [show, setShow] = useState(false);

  const handleDelete = async () => {
    try {
      // setLoading(true);
      const response = await axiosInstance.delete(
        `${API_URL}/track/advanced/${searchId}/`
      );

      if (response.status === 204) {
        // setLoading(false);
      } else {
        
        
      }
      getSearchData();
    } catch (error) {
      // Handle network errors or exceptions
      toast.error(' Please Contact Administrator');
    }
    setsearchId("");

    toast.success("SaveSearch Removed Successfully");
  };

  /************ Get Save Search Data */
  const getSearchData = () => {
    setLoading(true);
    axiosInstance
      .get(`${API_URL}/track/user/advanced/`)
      .then((response) => {
        if (response.data) {
          setResData(response.data);
          console.log(response.data,"response.data");
          setLoading(false);
        } else {
          
          setLoading(false);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
         
          toast.error(error.response?.data?.detail);
        } else {
          
        }
      });
  };


  /************ GO to propertylist */
   const navigate = useNavigate();

  const gosearch = (item) => {
    const searchitem = item;
    console.log(searchitem,"this is searchitem")

    // Navigate to '/propertylist' and send data
    navigate('/advance-search', { state: { searchitem } });
    // navigate(`/advance-search?searchitem=${item}`);
  };
  useEffect(() => {
    getSearchData();
  }, []);

  return (
    <>
    {loading ? <Loader /> : null}
    
    <main id="content" className="bg-gray-01">
      <div className="px-3 px-lg-6 px-xxl-13 py-5 py-lg-10">
        <div className="d-flex flex-wrap flex-md-nowrap mb-6">
          <div className="mr-0 mr-md-auto">
            <h2 className="mb-0 text-heading fs-22 lh-15">
              My Saved Searches
              {ResData?.length > 0 ? (
                <>
                  <span className="badge badge-white badge-pill text-blue fs-18 font-weight-bold ml-2">
                    {ResData?.length}
                  </span>
                </>
              ) : (
                <>
                  <span className="badge badge-white badge-pill text-blue fs-18 font-weight-bold ml-2">
                    0
                  </span>
                </>
              )}
            </h2>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table table-hover bg-white border rounded-lg">
            <thead className="thead-sm thead-black bg-blue text-white fs-18">
              <tr>
                <th scope="col" className="border-top-0 px-6 pt-6 pb-3">
                  Search
                </th>
                <th scope="col" className="border-top-0 pt-6 pb-3">
                  Property Label
                </th>
                <th scope="col" className="border-top-0 pt-6 pb-3">
                  Building Type
                </th>
                <th scope="col" className="border-top-0 pt-6 pb-3">
                  Category Type
                </th>
                <th scope="col" className="border-top-0 pt-6 pb-3">
                 Date
                </th>
                <th scope="col" className="border-top-0 pt-6 pb-3">
                  Action
                </th>
              </tr>
            </thead>
            {ResData?.length > 0 ? (
              <>
                {ResData?.map((item, index) => {
                  return (
                    <>
                      <tbody>
                        <tr className="shadow-hover-xs-2 bg-hover-white">
                          <td className="text-heading align-middle p-6 fs-16 font-weight-500 lh-1625 hover-saffron-text"
                           onClick={() => gosearch(item)}
                           >
                            {/* <Link
                          
                             className="text-heading fs-16 font-weight-500 lh-1625 hover-saffron-text">
                               </Link> */}
                              {item?.name}
                           
                          </td>
                          <td className="align-middle">
                            {item?.property_label_name}
                          </td>
                          <td className="align-middle">
                            {item?.building_type_name}
                          </td>
                          <td className="align-middle">
                            {item?.property_type_name}
                          </td>
                          <td className="align-middle">
                            {moment(item?.create_at).format("DD-MM-YYYY")}
                          </td>
                          <td className="align-middle">
                            <a className="d-inline-block fs-18 text-muted hover-saffron-text">
                              <i
                                className="fal fa-trash-alt"
                                onClick={() => {
                                  setsearchId(item?.id);
                                  handleShow();
                                }}
                              ></i>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </>
                  );
                })}
              </>
            ) : null}
          </table>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>SaveSearch Delete</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are You Sure You Want to Delete This SaveSearch
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button
                variant="danger"
                onClick={() => {
                  handleClose();
                  handleDelete();
                }}
              >
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </main>
    </>
  );
};

export default Savesearch;
