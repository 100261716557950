import React, { useState } from 'react'
import { Link, useNavigate ,redirect, useLocation} from 'react-router-dom'
import AuthService from './AuthService';
import { isExpired, decodeToken } from "react-jwt";
import axios from 'axios';
import Loader from '../Loader';
import { toast } from "react-toastify";
import axiosInstance from '../interceptor/axiosInstance';


function EmailVerification() {
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL
  const [loading, setLoading] = useState('')
  const senderData = useLocation()
  
  const email = senderData.state.Data.email

  

  const handleSubmit = async (e) => {
    e.preventDefault();
    
      try {
        const response = await axios.post(`${API_URL}/auth/users/resend_activation/`,{'email':email});
        if (response.status === 204 ) {
          toast.success(
            "Please Check Your Email Address ! Click to Vefify"
          );
        }
       
        // You can redirect the user to the login page or take other actions here.
      } catch (error) {
        
         toast.error("Your Email With Account Already Verified !!", error.response.data.message);
         navigate('/login');

      }
    } 



  return (<>
    {loading ? <Loader /> : null}
    <div className="form-body">
      <div className="website-logo">
        <Link to="/">
          <div className="logo">
            <img
              className="logo-size"
              src="../../images/dpf-logo-ae.png"
              style={{ width: "150px" }}
            />
          </div>
        </Link>
      </div>
      <div className="img-holder">
        <div
          className="bg"
          style={{ backgroundImage: "url(images/skyline-2.png)" }}
        ></div>
      </div>
      <div className="form-holder">
        <div className="form-content fixed-in-mobile">
          <div className="form-items">
            <h3 className="ti-fs">
              Singup Email Verification     {" "}
              <a href="/">
                <span className="pl-name">Dirham Per Foot.</span>
              </a>
            </h3>
           
            
            <div className="d-flex mb-4">
              
            </div>
            <div className="Submit-button-section-login">
              <button
                onClick={handleSubmit}
                className="btn text-white bg-blue hover-saffron btn-lg btn-block"
              >
                Resend Email
              </button>
            </div>
            <div className="w-100 mt-5">
              <div style={{ textAlign: "-webkit-center" }}>
                {/* <Googlelogin /> */}
              </div>
            </div>
            <p className="lg-bo">
              Don't have an account?{" "}
              <a href="/login" className="text-blue hover-saffron-text">
                <u>Login</u>
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}
export default EmailVerification
