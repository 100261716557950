import React, { useEffect, useState } from "react";
import BackImage from "../images/about-shape.png";
import { BsInstagram, BsTwitter, BsFacebook } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from "../interceptor/axiosInstance";
import Loader from "../Loader";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import CurrencyFormat from "react-currency-format";
const API_URL = process.env.REACT_APP_API_URL;

const MyCompany = () => {
  const [loading, setLoading] = useState("");
  const [isEdit, setIsEdit] = useState("true");
  const [mycompanydata, setmycompanydata] = useState();
  const { register, handleSubmit } = useForm();
  const [leadInput, setLeadInput] = useState([]);

  const navigate = useNavigate();
  const getcompanydata = () => {
    setLoading(true);

    axiosInstance
      .get(`${API_URL}/company/get/`)
      .then((response) => {
        if (response.data) {
          const profileinfo = response.data;
          setmycompanydata(profileinfo);
          setLoading(false);
        } else {
          toast.error(' Please Contact Administrator');
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          // Handle 401 Unauthorized error
          
          
          toast.error(error.response?.data?.detail);
          // navigate("/login");
        } else {
          toast.error(' Please Contact Administrator');
        }
      });
  };

  useEffect(() => {
    getcompanydata();
  }, []);

  /* ****************lead handler ************* */

  const handleLead = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setLeadInput({ ...leadInput, [name]: value, company: mycompanydata?.id });
  };

  const onSubmit = async () => {
    try {
      const response = await axiosInstance.post(
        `${API_URL}/company/contact/post/`,
        leadInput
      );
      const myresponse = response.data;
     
      toast.success("Lead Send successfully");
    } catch (error) {
      toast.error("Something Went Wrong");
    }
  };
  return (
    <>
      {loading ? <Loader /> : null}
      <div className="main-content">
        <main id="content ">
          <div
            className="bg-gray-01 pb-13"
            style={{ backgroundImage: `url(${BackImage})` }}
          >
            <section className="pb-8">
              <div className="container">
                <div className="row h-100 ">
                  <div className="col-lg-12 mt-100">
                    <div className="card border-0 px-6 pt-6 pb-10 add-pb-full">
                      <div className="row h-100">
                        <div className="col-sm-6 mb-6 mb-sm-0 py-5">
                          {mycompanydata?.img ? (
                            <>
                              <img
                                src={mycompanydata?.img}
                                alt="Company Image"
                                className="rounded-lg  card-img set-height-image-dprofilep"
                              />
                            </>
                          ) : (
                            <>
                              <img
                                className="rounded-lg  card-img set-height-image-dprofilep"
                                src="../../images/dpf-logo-ae.png"
                                alt="user-profile"
                              />
                            </>
                          )}
                        </div>
                        <div className="col-sm-6 py-5">
                          <div className="card-body p-0">
                            <div className="row d-flex">
                              {mycompanydata?.company_name ? (
                                <>
                                  <div className="col-lg-9">
                                    <h2 className="card-title fs-26 lh-15 mb-1 text-dark">
                                      {mycompanydata?.company_name}
                                    </h2>
                                  </div>
                                </>
                              ) : null}

                              <div className="col-lg-3">
                                <Link
                                  to={`/company/edit/${mycompanydata?.id}`}
                                  state={{ isEdit: isEdit }}
                                  className="btn bg-blue text-white hover-saffron"
                                >
                                  Edit
                                </Link>
                              </div>
                            </div>

                            <p className="card-text mb-2 text-blue">
                              {mycompanydata?.business_profile}
                            </p>
                            {mycompanydata?.reviewlength ? (
                              <>
                                <ul className="list-inline mb-5">
                                  <li className="list-inline-item fs-13 text-heading font-weight-500">
                                    4
                                  </li>

                                  <li className="list-inline-item fs-13 text-heading font-weight-500 mr-1">
                                    <ul className="list-inline mb-0">
                                      <li className="list-inline-item mr-0">
                                        <span className="text-warning fs-12 lh-2">
                                          <i className="fas fa-star"></i>
                                        </span>
                                      </li>
                                      <li className="list-inline-item mr-0">
                                        <span className="text-warning fs-12 lh-2">
                                          <i className="fas fa-star"></i>
                                        </span>
                                      </li>
                                      <li className="list-inline-item mr-0">
                                        <span className="text-warning fs-12 lh-2">
                                          <i className="fas fa-star"></i>
                                        </span>
                                      </li>
                                      <li className="list-inline-item mr-0">
                                        <span className="text-warning fs-12 lh-2">
                                          <i className="fas fa-star"></i>
                                        </span>
                                      </li>
                                      <li className="list-inline-item mr-0">
                                        <span className="text-warning fs-12 lh-2">
                                          <i className="fas fa-star"></i>
                                        </span>
                                      </li>
                                    </ul>
                                  </li>
                                  <li className="list-inline-item fs-13 text-gray-light">
                                    9
                                  </li>
                                </ul>
                              </>
                            ) : null}

                            
                            <ul className="list-group list-group-no-border">
                              {mycompanydata?.company_name ? (
                                <>
                                  <li className="list-group-item d-flex align-items-sm-center lh-214 row  p-0 mb-2">
                                    <span className="col-sm-5 px-1 fw-600 fs-14 text-heading margin-bottom-auto">
                                      Company Name:
                                    </span>
                                    <span className="col-sm-7 px-1 text-heading">
                                      <span className="text-uppercase fs-16">
                                        {mycompanydata?.company_name}
                                      </span>
                                    </span>
                                  </li>
                                </>
                              ) : null}
                              {mycompanydata?.office_number ? (
                                <>
                                  <li className="list-group-item d-flex align-items-sm-center lh-214 row  p-0 mb-2">
                                    <span className="col-sm-5 px-1 fw-600 fs-14 text-heading">
                                      Office Number:
                                    </span>
                                    <span className="col-sm-7 px-1 text-heading">
                                    <CurrencyFormat displayType={'text'} value={mycompanydata?.office_number} format="+(###) #-###-####" mask="_" />
                                      {/* {mycompanydata?.office_number} */}
                                    </span>
                                  </li>
                                </>
                              ) : null}

                              {mycompanydata?.mobile_number ? (
                                <>
                                  <li className="list-group-item d-flex align-items-sm-center lh-214 row  p-0 mb-2">
                                    <span className="col-sm-5 px-1 fw-600 fs-14 text-heading">
                                      Mobile Number:
                                    </span>
                                    <span className="col-sm-7 px-1 text-heading">
                                    <CurrencyFormat displayType={'text'} value={mycompanydata?.mobile_number} format="+(###) #-###-####" mask="_" />
                                      {/* {mycompanydata?.mobile_number} */}
                                    </span>
                                  </li>
                                </>
                              ) : null}

                              {mycompanydata?.dld_permit_number ? (
                                <>
                                  <li className="list-group-item d-flex align-items-sm-center lh-214 row  p-0 mb-2">
                                    <span className="col-sm-5 px-1 fw-600 fs-14 text-heading">
                                      DLD Number:
                                    </span>
                                    <span className="col-sm-7 px-1 text-heading">
                                      {mycompanydata?.dld_permit_number}
                                    </span>
                                  </li>
                                </>
                              ) : null}
                              {mycompanydata?.orn_number ? (
                                <>
                                  <li className="list-group-item d-flex align-items-sm-center lh-214 row  p-0 mb-2">
                                    <span className="col-sm-5 px-1 fw-600 fs-14 text-heading">
                                      ORN Number:
                                    </span>
                                    <span className="col-sm-7 px-1 text-heading">
                                      {mycompanydata?.orn_number}
                                    </span>
                                  </li>
                                </>
                              ) : null}

                              {mycompanydata?.company_website ? (
                                <>
                                  <li className="list-group-item d-flex align-items-sm-center lh-214 row  p-0 mb-2">
                                    <span className="col-sm-5 px-1 fw-600 fs-14 text-heading">
                                      Web Link:
                                    </span>
                                    <span className="col-sm-7 px-1 text-heading">
                                      <a
                                        className=" create-button-website"
                                        target="_blank"
                                        href={mycompanydata?.company_website}
                                      >
                                        Click Here
                                      </a>
                                    </span>
                                  </li>
                                </>
                              ) : null}

                              {mycompanydata?.street_address ? (
                                <>
                                  <li className="list-group-item d-flex align-items-sm-center lh-214 row  p-0 mb-2">
                                    <span className="col-sm-5 px-1 fw-600 fs-14 text-heading margin-bottom-auto">
                                      Address:
                                    </span>
                                    <span className="col-sm-7 px-1 text-heading line-height-1-7">
                                      {mycompanydata?.street_address}
                                    </span>
                                  </li>
                                </>
                              ) : null}

                              

                              {mycompanydata?.social ? (
                                <>
                              <li className="list-group-item d-flex align-items-sm-center lh-214 row mb-0 mt-3  p-0 ">
                                <ul className="col-sm-10 list-inline text-gray-lighter m-0 px-1 text-align-end">
                                  <li className="list-inline-item m-0">
                                    <Link
                                      className="w-32px h-32 rounded hover-saffron bg-blue  text-white  d-flex align-items-center justify-content-center border "
                                      to=""
                                    >
                                      <BsTwitter />
                                    </Link>
                                  </li>
                                  <li className="list-inline-item mr-0 ml-2">
                                    <Link
                                      className="w-32px h-32 rounded hover-saffron bg-blue  text-white  d-flex align-items-center justify-content-center border "
                                      to=""
                                    >
                                      <BsFacebook />
                                    </Link>
                                  </li>
                                  <li className="list-inline-item mr-0 ml-2">
                                    <Link
                                      className="w-32px h-32 rounded hover-saffron bg-blue  text-white  d-flex align-items-center justify-content-center border "
                                      to=""
                                    >
                                      <BsInstagram />
                                    </Link>
                                  </li>
                                </ul>
                              </li> </>
                              ) : null}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-lg-4 mt-20">
                    <div className="card border-0">
                      <div className="card-body p-6">
                      <div className=" d-block text-center  ">
                      <Link className="py-3 bg-blue text-white px-1">
                        I am Interested
                      </Link>
                    </div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                          <div className="form-group mb-2 position-add-validate-symbol">
                            <input
                              type="text"
                              className="form-control form-control-lg border-0"
                              placeholder="Your Name"
                              name="name"
                              {...register("name", { required: true })}
                              onChange={handleLead}
                            />
                            <span className="asterisk_input"></span>
                          </div>
                          <div className="form-group mb-2 position-add-validate-symbol">
                            <input
                              type="email"
                              className="form-control form-control-lg border-0"
                              placeholder="Your Email"
                              name="email"
                              {...register("email", { required: true })}
                              onChange={handleLead}
                            />
                            <span className="asterisk_input"></span>
                          </div>
                          <div className="form-group mb-2">
                            <input
                              type="text"
                              className="form-control form-control-lg border-0"
                              placeholder="Your Mobile Number"
                              name="mobile"
                              {...register("mobile")}
                              onChange={handleLead}
                              maxLength={10}
                            />
                          </div>
                          <div className="form-group mb-4">
                            <textarea
                              className="form-control form-control-lg border-0"
                              rows="7"
                              placeholder="Message"
                              name="message"
                              {...register("message")}
                              onChange={handleLead}
                            ></textarea>
                          </div>
                          <button
                            type="submit"
                            className="btn bg-blue text-white hover-saffron btn-lg btn-block"
                          >
                            Send Message
                          </button>
                        </form>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </section>
            
          </div>
        </main>
      </div>
    </>
  );
};

export default MyCompany;
