import React from 'react';
import { Navigate, Route } from 'react-router-dom';

const PrivateRoute = ({ element }) => {

  const isAuthenticated = localStorage.getItem('is_authenticated');
 
  return isAuthenticated === "false" ? (
    element
  ) : (
    <Navigate to="/login" />
  );
};
export default PrivateRoute;
