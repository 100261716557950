// src/actions.js
import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL;

export const fetchPostsRequest = () => ({
  type: 'FETCH_POSTS_REQUEST',
});

export const fetchPostsSuccess = (posts) => ({
  type: 'FETCH_POSTS_SUCCESS',
  payload: posts,
});

export const fetchPostsFailure = (error) => ({
  type: 'FETCH_POSTS_FAILURE',
  payload: error,
});

export const fetchPosts = () => {
  return async (dispatch) => {
    dispatch(fetchPostsRequest());
    try {
      const response = await axios.get(`${API_URL}/company/get/all/`);
      console.log(response.data,"dssadaed")
      dispatch(fetchPostsSuccess(response.data.filter(item=>item?.is_featured===true)));
    } catch (error) {
      dispatch(fetchPostsFailure(error.message));
    }
  };
};
