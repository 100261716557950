import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ChatBot from 'react-simple-chatbot';
import CurrencyFormat from "react-currency-format";
import Slider from "react-slick";
import { Modal } from 'react-bootstrap';
import GetProperty from './getProperty';
import LeadForm from './LeadForm';

const DihramChat = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [allCity, setAllCity] = useState([]);
  const [buildingType, setBuildingType] = useState([]);
  const [propertyLabel, setPropertyLabel] = useState([]);
  const [residentailPropertyType, setResidentialPropertyType] = useState([]);
  const [commericalPropertyType, setCommericalPropertyType] = useState([]);

  const [selectedCity, setSelectedCity] = useState('');
  const [selectedProjectType, setSelectedProjectType] = useState('');
  
  const [selectedBuildingType, setSelectedBuildingType] = useState('');
  const [selectedPropertyType, setSelectedPropertyType] = useState('');
  const [selectedPropertyLabel, setSelectedPropertyLabel] = useState('');
  const [selectedMinPriceRange, setSelectedMinPriceRange] = useState('');
  const [selectedMaxPriceRange, setSelectedMaxPriceRange] = useState('');
  const [startFilter, setStartFilter] = useState(false);
  const [show, setShow] = useState(false);
  const [propertyData, setPropertyData] = useState([]);
  console.log(selectedCity,selectedProjectType,selectedBuildingType,selectedPropertyType,selectedPropertyLabel,"DATA");
  
/* handle functions */
const handleCitySelect = (value) => {
  setSelectedCity(value.value);
  return '9';
};


 
// const handleResult = () => {
//   setShow(true);}
const handleClose = () => {
  setShow(false);}

const handleProjectType = (value) => {
  setSelectedProjectType(value.value);
  return '11';
};

const handleBuildingType = (value) => {
  setSelectedBuildingType(value.value);
  return value.value === "569c3700-61ab-42ad-b792-8019d1a3a994" ? '13' : '15';
};

const handlePropertyType = (value) => {
  setSelectedPropertyType(value.value);
  return '16';
};

const handlePropertyLabel = (value) => {
  setSelectedPropertyLabel(value.value);
  setStartFilter(true);
  return '19';
};

// const handlePriceRange = (value) => {
//   setSelectedMinPriceRange(value.value?.min_range);
//   setSelectedMaxPriceRange(value.value?.max_range);
  
//   return '21';
// };

/* API calls */
const fetchData = async (url, setter) => {
  try {
    const response = await axios.get(url);
    if (response.status === 200) {
      setter(response.data);
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

useEffect(() => {
  fetchData(`${API_URL}/properties/state/get/`, setAllCity);
  fetchData(`${API_URL}/properties/building/types/`, setBuildingType);
  fetchData(`${API_URL}/properties/property/label/`, setPropertyLabel);
  fetchData(`${API_URL}/properties/property/types/?building_type=569c3700-61ab-42ad-b792-8019d1a3a994`, setCommericalPropertyType);
  fetchData(`${API_URL}/properties/property/types/?building_type=00eb5d1b-1d34-4ebb-84d8-aa6d0a843419`, setResidentialPropertyType);
}, []);


useEffect(() => {
  if (startFilter === true) {
    fetchData(`${API_URL}/properties/advance/filter/?state=${selectedCity}&building_type=${selectedBuildingType}&property_type=${selectedPropertyType}&property_label=${selectedPropertyLabel}&is_new_property=${selectedProjectType}`,
      setPropertyData);
  }
}, [startFilter]);
const [propertyData2, setPropertyData2] = useState([])

useEffect(() => {
  fetchData(`${API_URL}/properties/advance/filter/?state=${selectedCity}&building_type=${selectedBuildingType}&property_label=${selectedPropertyLabel}`,
    setPropertyData2);

}, [selectedCity,selectedBuildingType,selectedPropertyLabel]);


const steps = [

  { id: '1', message: 'Welcome to Dirham Chat! How can I assist you today?', trigger: '4' },
  { id: '2', message: 'Are you from Dubai ?', trigger: '3' },
  { id: '3', options: [{ value: 1, label: 'yes', trigger: '4' }, { value: 0, label: 'No', trigger: '6' }] },
  { id: '4', message: 'Please select the city you are interested in:', trigger: '5' },
  { id: '5', options: allCity.map((city) => ({ value: city, label: city, trigger: handleCitySelect })) },
  { id: '9', message: 'Choose Your Project Type', trigger: '10' },
  {
    id: '10', options: [{ value: "true", label: 'New Project', trigger: handleProjectType },
    { value: "false", label: 'Ready To Move', trigger: handleProjectType }]
  },
  { id: '11', message: 'Looking For Commercial or Residential ?', trigger: '12' },
  { id: '12', options: buildingType.map((item) => ({ value: item?.id, label: item?.name, trigger: handleBuildingType })) },
  { id: '13', message: 'Choose Your Commercial Property Type.', trigger: '14' },
  { id: '14', options: commericalPropertyType.map((item) => ({ value: item?.id, label: item?.name, trigger: handlePropertyType })) },
  { id: '15', message: 'Choose Your Residential Property Type.', trigger: '17' },
  { id: '17', options: residentailPropertyType.map((item) => ({ value: item?.id, label: item?.name, trigger: handlePropertyType })) },
  { id: '16', message: 'Looking for Sale or Rent ?', trigger: "18" },
  { id: '18', options: propertyLabel.map((item) => ({ value: item?.id, label: item?.name, trigger: handlePropertyLabel })) },
  // { id: '19', message: 'Please select your budget', trigger: '20' },
  // {
  //   id: '20', options: [
  //     { value: { min_range: 1000, max_range: 5000 }, label: '1000-5,000', trigger: handlePriceRange },
  //     { value: { min_range: 5000, max_range: 10000 }, label: '5,000-10,000', trigger: handlePriceRange },
  //     { value: { min_range: 10000, max_range: 50000 }, label: '10,000-50,000', trigger: handlePriceRange },
  //     { value: { min_range: 50000, max_range: 100000 }, label: '50,000-1,00,000', trigger: handlePriceRange },
  //     { value: { min_range: 100000, max_range: 200000 }, label: '1,00,000-2,00,000', trigger: handlePriceRange },
  //     { value: { min_range: 200000, max_range: 300000 }, label: '2,00,000-3,00,000', trigger: handlePriceRange },
  //     { value: { min_range: 300000, max_range: 500000 }, label: '3,00,000-5,00,000', trigger: handlePriceRange },
  //     { value: { min_range: 500000, max_range: 1000000 }, label: '5,00,000-10,00,000', trigger: handlePriceRange },
  //   ]
  // },
  { id: '19', message: 'Submit details below while I showup matching property for you...!', trigger:"21"},
  {
        id: '21',
        component: (
          <div style={{ width: "350px" }}>
  <LeadForm  
             setShow={setShow} 
             show={show} 
             selectedCity={selectedCity}
             selectedProjectType={selectedProjectType} 
             selectedBuildingType={selectedBuildingType}
             selectedPropertyType={selectedPropertyType}
             selectedPropertyLabel={selectedPropertyLabel}
             />
           </div>
        ),
        end: true
      },

  { id: '6', message: 'Are you Relocated to Dubai?', trigger: '7' },
  { id: '7', options: [{ value: 1, label: 'yes', trigger: '4' }, { value: 0, label: 'No', trigger: '8' }] },
  

    { id: '23', message: 'Thanks for your Valuable Time !', end: true },
    { id: '8', message: 'Thanks for your Valuable Time !', end: true },
  ]
  const key = `${allCity?.length}-${buildingType?.length}-${commericalPropertyType?.length}-${residentailPropertyType?.length}-${propertyLabel?.length}`;
  const dirhamBot = "https://dollarperfoot.s3.amazonaws.com/home/image/loadlogo_ZE9aUG2.png"
  const headerTitle = "I'm Era. Your Personalized AI Property Search Assistant!"

  return (
    <>
    
    <ChatBot
      key={key}
      headerTitle={headerTitle}
      botAvatar={dirhamBot}
      steps={steps}
      floating={true}

    />
     <div className='display_none'> 
            <LeadForm  
             setShow={setShow} 
             show={show} 
             selectedCity={selectedCity}
             selectedProjectType={selectedProjectType} 
             selectedBuildingType={selectedBuildingType}
             selectedPropertyType={selectedPropertyType}
             selectedPropertyLabel={selectedPropertyLabel}
             /> 
      </div>
    <Modal id="getData"show={show} onHide={handleClose}>
       
    <Modal.Body>
      {propertyData?.length > 0 ? (<>
        <h4 className="fs-18 mb-5 lh-1.5">Fetching properties based on your selections...</h4>
      <GetProperty data={propertyData}/>
      </>):(<>
        <h4 className="fs-18 mb-5 lh-1.5">No direct match found check out other related properties...</h4>
      <GetProperty data={propertyData2}/>
      </>)}
      
    </Modal.Body>
   
  </Modal>
  </>
  );
};

export default DihramChat;
// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import ChatBot from 'react-simple-chatbot';

// import { ThemeProvider } from 'styled-components';
// import GetProperty from './getProperty';
// import LeadForm from './LeadForm';
// import { Modal } from 'react-bootstrap';

// const DihramChat = () => {
//   const API_URL = process.env.REACT_APP_API_URL; // Ensure this is correctly set in your .env

  // Consolidated state for filters
  // const [propertyData, setPropertyData] = useState([])
  // console.log(propertyData, "propertyData");

  // const [propertyData2, setPropertyData2] = useState([])
  // const [filters, setFilters] = useState({
  //   selectedCity: '',
  //   selectedProjectType: '',
  //   selectedBuildingType: '',
  //   selectedPropertyType: '',
  // });

  // console.log(filters, "filters");
  // State to store dynamic data for the ChatBot
  // const [data, setData] = useState({
  //   allCity: [],
  //   buildingType: [],
  //   propertyLabel: [],
  //   residentialPropertyType: [],
  //   commercialPropertyType: [],
  // });
  // const fetchData = async (url, setter) => {
  //     try {
  //       const response = await axios.get(url);
  //       if (response.status === 200) {
  //         setter(response.data);
  //       }
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //     }
  //   };
  // console.log(data, "ALL CITY");
  // Fetch initial data for ChatBot options
  // useEffect(() => {
  //   const fetchData = async () => {
  //     const requests = [
  //       `${API_URL}/properties/state/get/`,
  //       `${API_URL}/properties/building/types/`,
  //       `${API_URL}/properties/property/label/`,
  //       `${API_URL}/properties/property/types/?building_type=569c3700-61ab-42ad-b792-8019d1a3a994`,
  //       `${API_URL}/properties/property/types/?building_type=00eb5d1b-1d34-4ebb-84d8-aa6d0a843419`,
  //     ].map(endpoint => axios.get(endpoint));

  //     try {
  //       const responses = await Promise.all(requests);
  //       setData({
  //         allCity: responses[0].data,
  //         buildingType: responses[1].data,
  //         propertyLabel: responses[2].data,
  //         commercialPropertyType: responses[3].data,
  //         residentialPropertyType: responses[4].data,
  //       });
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };

  //   fetchData();
  // }, [API_URL]);

  // Function to handle filter changes
  // const handleFilterChange = (newFilters) => {
  //   setFilters(prevFilters => ({
  //     ...prevFilters,
  //     ...newFilters,
  //   }));
  // };
  // useEffect(() => {
  //   // Check if all filters have non-empty values before fetching data
  //   if (filters.selectedCity && filters.selectedBuildingType && filters.selectedPropertyType && filters.selectedProjectType !== '') {
  //     fetchData(`${API_URL}/properties/advance/filter/?state=${filters.selectedCity}&building_type=${filters.selectedBuildingType}&property_type=${filters.selectedPropertyType}&is_new_property=${filters.selectedProjectType}`,
  //       setPropertyData);
  //   }
  // }, [filters.selectedCity, filters.selectedBuildingType, filters.selectedPropertyType, filters.selectedProjectType]);

  // useEffect(() => {
  //   fetchData(`${API_URL}/properties/advance/filter/?state=${selectedCity}&building_type=${selectedBuildingType}&property_label=${selectedPropertyLabel}`,
  //     setPropertyData2);

  // }, [filters]);
  // const bannerSlider = {
  //       pauseOnHover: false,
  //       infinite: true,
  //       speed: 1000,
  //       slidesToShow: 1,
  //       slidesToScroll: 1,
  //       arrows: true,
  //       autoplay: false,

  //       autoplaySpeed: 5000,

  //     };



  // const [show, setShow] = useState(false);
 
  // const handleResult = () => {
  //   setShow(true);}
  // const handleClose = () => {
  //   setShow(false);}



  // const steps = [
  //   {
  //     id: '1',
  //     message: 'Welcome to Dirham Chat! How can I assist you today?',
  //     trigger: '2',
  //   },
  //   {
  //     id: '2',
  //     options: [
  //       { value: 'start', label: 'Start property search', trigger: 'citySelect' },
  //     ],
  //   },
  //   {
  //     id: 'citySelect',
  //     message: 'Please select the city you are interested in:',
  //     trigger: 'cityOptions',
  //   },
  //   {
  //     id: 'cityOptions',
  //     options: data.allCity.map(city => ({
  //       value: city,
  //       label: city,
  //       trigger: 'projectTypeSelect',
  //     })),
  //   },
  //   {
  //     id: 'projectTypeSelect',
  //     message: 'Choose your project type:',
  //     trigger: 'projectTypeOptions',
  //   },
  //   {
  //     id: 'projectTypeOptions',
  //     options: [
  //       { value: 'true', label: 'New Project', trigger: 'buildingTypeSelect' },
  //       { value: 'false', label: 'Ready to Move', trigger: 'buildingTypeSelect' },
  //     ],
  //   },
  //   {
  //     id: 'buildingTypeSelect',
  //     message: 'Are you looking for Commercial or Residential properties?',
  //     trigger: 'buildingTypeOptions',
  //   },
  //   {
  //     id: 'buildingTypeOptions',
  //     options: data.buildingType.map(type => ({
  //       value: type.id,
  //       label: type.name,
  //       trigger: 'propertyTypeSelect',
  //     })),
  //   },
  //   {
  //     id: 'propertyTypeSelect',
  //     message: 'What type of property are you interested in?',
  //     trigger: ({ previousValue, steps }) => {
  //       const buildingType = steps.buildingTypeOptions.value;
  //       return buildingType === '569c3700-61ab-42ad-b792-8019d1a3a994' ? 'commercialPropertyTypeOptions' : 'residentialPropertyTypeOptions';
  //     },
  //   },
  //   {
  //     id: 'commercialPropertyTypeOptions',
  //     options: data.commercialPropertyType.map(item => ({
  //       value: item.id,
  //       label: item.name,
  //       trigger: 'showResults',
  //     })),
  //   },
  //   {
  //     id: 'residentialPropertyTypeOptions',
  //     options: data.residentialPropertyType.map(item => ({
  //       value: item.id,
  //       label: item.name,
  //       trigger: 'showResults',
  //     })),
  //   },
  //   {
  //     id: 'showResults',
  //     message: "Thanks for Showing Interset, we need more Info...!",

  //     trigger: ({ previousValue, steps }) => {
  //       const selectedPropertyType = previousValue || (steps.commercialPropertyTypeOptions ? steps.commercialPropertyTypeOptions.value : steps.residentialPropertyTypeOptions.value);
  //       handleFilterChange({
  //         selectedCity: steps.cityOptions.value,
  //         selectedProjectType: steps.projectTypeOptions.value === 'true',
  //         selectedBuildingType: steps.buildingTypeOptions.value,
  //         selectedPropertyType,
  //       });
  //       return 'displayResults';
  //     },
  //   },

  //   {
  //     id: 'displayResults',
  //     component: (
  //       <div style={{ width: "350px" }}>

  //         <LeadForm  />
         
  //       </div>
  //     ),
  //     trigger:handleResult
  //   },
  //   // {
  //   //   id: 'propertyResult',
     
  //   //   end:true
  //   // },

  // ];

  // const fetchData = async (url, setter) => {
  //   try {
  //     const response = await axios.get(url);
  //     if (response.status === 200) {
  //       setter(response.data);
  //     }
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   }
  // };
  // useEffect(() => {
  //   if (filters.selectedCity && filters.selectedBuildingType && filters.selectedPropertyType && filters.selectedProjectType !== '') {
  //     fetchData(`${API_URL}/properties/advance/filter/?state=${filters.selectedCity}&building_type=${filters.selectedBuildingType}&property_type=${filters.selectedPropertyType}&is_new_property=${filters.selectedProjectType}`,
  //       setPropertyData);
  //   }
  // }, [filters]);

  // const dirhamlogo = "https://dollarperfoot.s3.amazonaws.com/home/image/loadlogo_ZE9aUG2.png"
  
  // return (<>
  //   <ThemeProvider theme={{
  //     background: '#f5f8fb',
  //     fontFamily: 'Helvetica Neue',
  //     headerBgColor: '#EF6C00',
  //     headerFontColor: '#fff',
  //     headerFontSize: '15px',
  //     botBubbleColor: '#EF6C00',
  //     botFontColor: '#fff',
  //     userBubbleColor: '#fff',
  //     userFontColor: '#4a4a4a',
  //   }}>
  //     <ChatBot
  //       steps={steps}
  //       botAvatar={dirhamlogo}
  //       key={[data?.allCity, data?.commercialPropertyType, data?.residentialPropertyType]}

  //     />
     {/*  <div className='display_none'> */}
      {/* <LeadForm data={propertyData} /> */}
      {/* <GetProperty data={propertyData}/> */}
    {/*   </div> */}
//     </ThemeProvider>
//     <Modal id="getData"show={show} onHide={handleClose}>
       
//         <Modal.Body>
//           <h4 className="fs-18 mb-5 lh-1.5">Fetching properties based on your selections...</h4>
//           <GetProperty data={propertyData}/>
//         </Modal.Body>
       
//       </Modal>
//     </>
//   );
// };

// export default DihramChat;




// Assuming handleFilterChange and data are properly set up as described previously

// const chatBotSteps = [
//   {
//     id: '1',
//     message: 'Welcome to Dirham Chat! How can I assist you today?',
//     trigger: '2',
//   },
//   {
//     id: '2',
//     options: [
//       { value: 'start', label: 'Start property search', trigger: 'citySelect' },
//     ],
//   },
//   {
//     id: 'citySelect',
//     message: 'Please select the city you are interested in:',
//     trigger: 'cityOptions',
//   },
//   {
//     id: 'cityOptions',
//     options: data.allCity.map(city => ({
//       value: city.id,
//       label: city.name,
//       trigger: 'projectTypeSelect',
//     })),
//   },
//   {
//     id: 'projectTypeSelect',
//     message: 'Choose your project type:',
//     trigger: 'projectTypeOptions',
//   },
//   {
//     id: 'projectTypeOptions',
//     options: [
//       { value: 'true', label: 'New Project', trigger: 'buildingTypeSelect' },
//       { value: 'false', label: 'Ready to Move', trigger: 'buildingTypeSelect' },
//     ],
//   },
//   {
//     id: 'buildingTypeSelect',
//     message: 'Are you looking for Commercial or Residential properties?',
//     trigger: 'buildingTypeOptions',
//   },
//   {
//     id: 'buildingTypeOptions',
//     options: data.buildingType.map(type => ({
//       value: type.id,
//       label: type.name,
//       trigger: 'propertyTypeSelect',
//     })),
//   },
//   {
//     id: 'propertyTypeSelect',
//     message: 'What type of property are you interested in?',
//     trigger: ({ previousValue, steps }) => {
//       // Example of dynamically deciding which property types to show based on the building type selection
//       const buildingType = steps.buildingTypeOptions.value;
//       return buildingType === 'commercial' ? 'commercialPropertyTypeOptions' : 'residentialPropertyTypeOptions';
//     },
//   },
//   {
//     id: 'commercialPropertyTypeOptions',
//     options: data.commercialPropertyType.map(type => ({
//       value: type.id,
//       label: type.name,
//       trigger: 'showResults',
//     })),
//   },
//   {
//     id: 'residentialPropertyTypeOptions',
//     options: data.residentialPropertyType.map(type => ({
//       value: type.id,
//       label: type.name,
//       trigger: 'showResults',
//     })),
//   },
//   {
//     id: 'showResults',
//     message: 'Fetching properties based on your selections...',
//     trigger: ({ value, steps }) => {
//       // Example of how to use handleFilterChange based on selections
//       // You would call handleFilterChange for each filter as selections are made, not just at the end
//       handleFilterChange({
//         selectedCity: steps.cityOptions.value,
//         selectedProjectType: steps.projectTypeOptions.value === 'true',
//         selectedBuildingType: steps.buildingTypeOptions.value,
//         selectedPropertyType: value, // Assuming this step is for selecting property type
//       });
//       return 'displayResults'; // Proceed to show the results or another step
//     },
//   },
//   {
//     id: 'displayResults',
//     // This is a placeholder. You would implement a custom component to show filtered results
//     message: 'Here are the properties that match your criteria:',
//     end: true,
//   },
// ];

// // Include these steps in your ChatBot component
