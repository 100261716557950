import React, { useEffect, useState } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import ReactWhatsapp from "react-whatsapp";
import Tooltip from "react-bootstrap/Tooltip";
import axios from "axios";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import PhoneInput from "react-phone-number-input";
import logo from "../../src/images/dpf-logo.png";
import "react-phone-number-input/style.css";
import { Link } from "react-router-dom";
const API_URL = process.env.REACT_APP_API_URL;
const CallActionButton = (props) => {
  const [refId, setRefId] = useState("");
  const [refPropertyName, setRefProperty] = useState("");
  const [message, setMessage] = useState("");
  const [queryName, setQueryName] = useState("");
  const [queryPhone, setQueryPhone] = useState("");

  const [queryEmail, setQueryEmail] = useState("");
  const [queryPropertyId, setQueryPropertyId] = useState("");

  const [show, setShow] = useState(false);
  const { item } = props;

  const handleClose = () => {
    setShow(false);
    setRefId("");
    setRefProperty("");
    setMessage("");
    setQueryPropertyId("");
    setQueryPhone("");
    setQueryName("");
    setQueryEmail("");
  };
  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };
  const handleShow = (e) => {
    setShow(true);
    setRefId(e?.ref);
    setRefProperty(e?.name);

    setQueryPropertyId(e?.id);
  };

  /* ***********lead submit ***************/
  const handleLeadSubmit = async () => {
    const data = {};
    data.name = queryName;
    data.email = queryEmail;
    data.mobile = queryPhone;
    data.message = message;
    data.properties = queryPropertyId;
    data.prop_id = refId;


    try {
      const response = await axios.post(
        `${API_URL}/properties/user/contact/post/`,
        data
      );

      if (response.status === 201) {

        toast.success("Query submitted successfully");
        handleClose();


      } else {

        toast.error("Something went wrong");
        // You can handle errors here
      }
    } catch (error) {
        toast.error(' Please Contact Administrator');
    }
  };

  /* ***********call action ***************/
  const handleCallAction = async (e) => {
    const data = {};
    data.prop_id = e?.ref;
    data.properties = e?.id;
    data.phone = e?.phone;

    try {
      const response = await axios.post(
        `${API_URL}/properties/phone/click/post/`,
        data
      );

      if (response.status === 201) {

        // toast.success("We'll connect you soon...");
      } else {

      }
    } catch (error) {
        toast.error(' Please Contact Administrator');
    }
  };
  /* ***********whatsapp action ***************/
  const handleWhatAppAction = async (e) => {
    const data = {};
    data.prop_id = e?.ref;
    data.properties = e?.id;
    data.whatsapp = e?.phone;

    try {
      const response = await axios.post(
        `${API_URL}/properties/whats/click/post/`,
        data
      );

      if (response.status === 201) {

        toast.success("We'll connect you soon...");
      } else {

      }
    } catch (error) {

    }
  };
  useEffect(() => {
    setMessage(
      `Hi, I found your property with ref: ${refId} on Dirham Per Foot. Please contact me. Thank you.`
    );
  }, [refId]);

  return (
    <>
      <div className="mb-1">
        {item?.get_user_mobile ? (
          <>
            <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-disabled">{item?.get_user_mobile}</Tooltip>
              }
            >
              <Link
                to={`tel:${item?.get_user_mobile}`}
                className="btn btn-primary mr-1 py-1 px-4 width-50"
                onClick={() =>
                  handleCallAction({
                    ref: item?.prop_id,
                    phone: item?.get_user_mobile,
                    id: item?.id,
                  })
                }
              >
                <i className="bi bi-telephone margin-native"></i>
              </Link>
            </OverlayTrigger>
          </>
        ) : null}
        <Link
          onClick={(e) =>
            handleShow({
              ref: item?.prop_id,
              name: item?.property_name,
              id: item?.id,
            })
          }
          className=" mr-1   width-50  bg-saffron text-white "
          style={{ borderRadius: "3px",  padding: "7px 21px 9px 24px"}}
        >
          <i class="bi bi-envelope margin-native " style={{fontSize:"13.7px"}}></i>
        </Link>
        {item?.get_user_whatsapp ? (
          <>
            <ReactWhatsapp
              className="btn btn-success mr-1 py-1 width-50 px-4"
              number={item?.get_user_whatsapp}
              onClick={() =>
                handleWhatAppAction({
                  ref: item?.prop_id,
                  phone: item?.get_user_whatsapp,
                  id: item?.id,
                })
              }
              message="Hi"
            >
              <i class="bi bi-whatsapp margin-native"></i>
            </ReactWhatsapp>
  
          </>
        ) : null}
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="fs-16">
            <img
              src={logo}
              alt="HomeID"
              className="normal-logo main-logo-header"
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className="fs-18 mb-5 lh-1.5">{refPropertyName}</h4>
          <div className="form-group">
            <input
              onChange={(e) => setQueryName(e?.target?.value)}
              value={queryName}
              type="text"
              className="form-control form-control-lg border-1 mb-2"
              placeholder="Name"
            />
            <input
              onChange={(e) => setQueryEmail(e?.target?.value)}
              value={queryEmail}
              type="email"
              className="form-control form-control-lg border-1 mb-2"
              placeholder="Email"
            />

            <PhoneInput
              className="form-control form-control-lg border-1 mb-2 d-flex"
              defaultCountry="AE"
              value={queryPhone}
              onChange={setQueryPhone}
            />
            <textarea
              row="10"
              value={message}
              onChange={handleMessageChange}
              name="message"
              id="message"
              className="form-control form-control-lg border-1"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="px-5 py-1 round-5 bg-blue border-0 text-white hover-saffron"
            onClick={handleLeadSubmit}
          >
            Send
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default CallActionButton;
