import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate ,redirect, useLocation} from 'react-router-dom'
import AuthService from './AuthService';
import { isExpired, decodeToken } from "react-jwt";
import axios from 'axios';
import Loader from '../Loader';
import { toast } from "react-toastify";
import axiosInstance from '../interceptor/axiosInstance';

const ForgetPassword = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [value , setValue] = useState({
    email:"",
  })


  const navigate = useNavigate()
  // setValue(response.data)
  const handleSubmit = () => {
    axios.post(`${API_URL}/auth/users/reset_password/`, value)
      .then(response => {
        if (response.status === 204) {
          // Display a custom success message for status 204
          toast.success("Password reset request succeeded.");
        } else {
          // Display the response data in a success message
          toast.success("User", response.data.message);
        }
      })
      .catch(error => {
      
        if (error.response.data.password) {
              toast.error(`${error.response.data.password}`)
            } else if (error.response.data.email) {
              toast.error(`${error.response.data.email}`)
            }
        // // Display an error message
        // toast.error("User Already Exist", error.response.data.message);
      });
  }
  return (
    <section className="py-13 add-cstm-mt modal-opacity-none">
      <div className="container">
        <div className="row">
          <div className="col-lg-7 mx-auto">
            <div className="card border-0 shadow-xxs-2 login-register">
              <div className="card-body p-6">
                <h2 className="card-title fs-30 font-weight-600 text-dark lh-16 mb-2">
                  Forgot your password?
                </h2>

                <form className="form">
                  <div className="form-group">
                    <label htmlFor="email" className="text-heading">
                      Enter your email address
                    </label>
                    <input
                      type="email"
                      name="email"
                      className="form-control form-control-lg border-0"
                      placeholder="Enter your email address"
                      onChange={e => setValue({...value, email:e.target.value})}
                    />
                  </div>
                  <button
                    type="button" onClick={handleSubmit} 
                    className="btn bg-blue text-white hover-saffron btn-lg rounded"
                    // onClick={EmailSubmit}
                  >
                    Get new password
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForgetPassword;
