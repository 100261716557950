import React, { useEffect, useState } from "react";
import Loader from "../../Loader";
import moment from "moment";
import Header from "../../header/Header";
import Footer from "../../footer/Footer";
import BlogSidebar from "./BlogSidebar";
import { Link, useLocation, useParams } from "react-router-dom";

import axios from "axios";
import { toast } from "react-toastify";
const API_URL = process.env.REACT_APP_API_URL;

const BlogCategory = () => {
  moment.locale('en');
  const categoryname = useParams()?.slug;
  const location = useLocation();
  const category_slug = location?.state?.name;
  const [blogData, setblogData] = useState([]);
  const [category, setCategory] = useState([]);
  const [blogCategoryData, setblogCategoryData] = useState([]);
  const [loading, setLoading] = useState(false);
  const GetBlog = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_URL}/blogs/get/`);
      if ((response.status = 200)) {
        const responseData = response.data;
        setblogData(responseData);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(' Please Contact Administrator');
      }
    } catch (err) {
      setLoading(false);
      toast.error(' Please Contact Administrator');
    }
  };
  const GetCategoryBlog = async () => {

    try {
      const response = await axios.get(`${API_URL}/blogs/category/${categoryname}/`);
      if ((response.status = 200)) {
        const responseData = response.data;
        setblogCategoryData(responseData);

      } else {

        toast.error(' Please Contact Administrator');
      }
    } catch (err) {

      toast.error(' Please Contact Administrator');
    }
  };


  const GetCategory = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_URL}/blogs/category/`);
      if ((response.status = 200)) {
        const responseData = response.data;
        setCategory(responseData);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(' Please Contact Administrator');
      }
    } catch (err) {
      setLoading(false);
      toast.error(' Please Contact Administrator');
    }
  };

  useEffect(() => {
    GetBlog();
    GetCategory();
    GetCategoryBlog();
  }, [categoryname]);


  // const filteredBlogData = blogData.filter(
  //   (item) => item.category_name === category_slug
  // );


  return (
    <>
      {/* {loading ? <Loader /> : null} */}
      <Header />
      <main id="content" className="mt-100">
        {/* <section
        className="h-100vh d-flex flex-column"
        style={{
          backgroundImage: `url(${BannerImage})`,
          backgroundSize: "cover",
        }}
      >
        <div
          className="d-flex align-items-center mr-bm-sr text-center"
          style={{ height: "300px" }}
        >
          <div className="container-fluid px-20">
            <h1 className="text-white mb-5 lh-2 fs-md-14 fs-lg-32">
              Our Blogs
             
            </h1>
          </div>
        </div>
      </section> */}
        <section className="pt-8 pb-8">
          <div className="container-fluid px-20">
            <div className="row  ">
              <p className="fs-18 text-dark font-weight-600  ">
                {category.map((item) => (
                  <Link to={`/blog/${item?.slug}`} key={item.id} state={{ name: item?.name }}>
                    <span
                      className={`btn blog-category border-c  hover-saffron  round-10 ml-2 fs-16 ${item.name === category_slug ? "bg-saffron text-white" : "bg-blue text-white"
                        }`}

                    >
                      {item?.name}
                    </span>
                  </Link>
                ))}
              </p>
              <div className="col-lg-9 mt-3 border-right">
                <div className="row">
                  {blogCategoryData.map((item) => (
                    <div className="col-lg-4 mb-6">
                      <div className="card border-0 shadow-xxs-3">
                        <div className="position-relative d-flex align-items-end card-img-top hover-shine">
                          <Link
                            to={{
                              pathname: `/blog/${categoryname}/${item?.slug}`,
                            }}
                            state={{ blogData: blogData }}
                            className="text-heading "
                          >
                            <img
                              src={item?.image || "images/favicon-icon.png"}
                              alt={item?.title}
                            />
                            <div className="card-img-overlay p-2 d-flex flex-column">
                              <div>
                                <span className="badge mr-2 badge-orange">
                                  {item?.category_name}
                                </span>
                              </div>
                            </div>
                          </Link>
                        </div>
                        <div className="card-body px-5 pt-3 pb-5">
                          <p className="mb-1 fs-13">
                            {moment(item?.create_at).format("DD-MM-YYYY")}
                          </p>
                          <h3 className="fs-18 text-heading lh-194 mb-1">
                            <Link
                              to={`/blog/${item?.category_slug}/${item?.slug}`}
                              state={{ blogData: blogData }}
                              className="text-heading "
                            >
                              {item?.title}
                            </Link>
                          </h3>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item?.txt.substring(0, 250),
                            }}
                          />

                          <Link
                            to={`/blog/${item?.category_slug}/${item?.slug}`}
                            state={{ blogData: blogData }}
                            className="text-heading "
                          >
                            Learn more
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <BlogSidebar />
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};
export default BlogCategory;