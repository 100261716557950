import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment/moment";
import axios from "axios";
import Loader from "../../Loader";
import { toast } from "react-toastify";
const API_URL = process.env.REACT_APP_API_URL;

const BlogSidebar = (props) => {
  const [blogData, setblogData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState();
  moment.locale('en');


  const GetBlog = async () => {
    setLoading(true);
   try {
     const response = await axios.get(`${API_URL}/blogs/get/`);
     if ((response.status = 200)) {
       const responseData = response.data;
       setblogData(responseData);
        setLoading(false);
     } else {
        setLoading(false);
        toast.error(' Please Contact Administrator');
     }
   } catch (err) {
      setLoading(false);
      toast.error(' Please Contact Administrator');
   }
 };
 useEffect(() => {
  GetBlog();
}, []);
  const filteredBlogs = blogData.filter((item) =>
    item.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
      <div
        className="col-lg-3  primary-sidebar sidebar-sticky mt-5"
        id="sidebar"
      >
        <div className="position-relative w-100">
          <i className="far fa-search text-dark fs-18 position-absolute pl-4 pos-fixed-left-center"></i>
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="rounded w-100 pl-8 py-2 bg-gray border-c fs-13 font-weight-500 text-gray-light lh-17"
            placeholder="Search..."
          />
        </div>
        <div className="primary-sidebar-inner">
          <div className="mb-4">
            <div className="card-body px-6 pt-5 pb-6">
              <h4 className="card-title fs-16 lh-2 text-dark">Update</h4>
              <span className="heading-divider divider-bottom"></span>
              <ul className="list-group list-group-flush mt-3">
                {filteredBlogs.slice(0, 10).map((item) => (
                  <li
                    className="list-group-item px-0 pt-0 pb-3 mb-4"
                    key={item.id}
                  >
                    <div className="media">
                      <div className="position-relative mr-3">
                        <img
                          className="d-block w-100px rounded bg-img-cover-center"
                          src={item?.image}
                          alt="Title"
                        />
                      </div>
                      <div className="media-body">
                        <h4 className="fs-14 lh-186 mb-1">
                          <Link
                            to={`/blog/${item?.category_slug}/${item?.slug}`}
                            className="text-dark hover-saffron-text"
                            state={{ blogData: blogData }}
                          >
                            {item?.title}
                          </Link>
                        </h4>
                        <div className="text-gray-light">
                          {moment(item?.create_at).format("DD-MM-YYYY")}
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogSidebar;
