import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import axiosInstance from '../../interceptor/axiosInstance';
import { toast } from 'react-toastify';
const API_URL = process.env.REACT_APP_API_URL;
const Searchmodal = ({ saveshow, handleshowClose,searchCriteria,lat,lng }) => {
   
    const [saveSearchName, setSaveSearchName] = useState('');
    

  const SubmitSave = async () => {
    try {
      const response = await axiosInstance.post(`${API_URL}/track/advanced/post/`, {
       
        name:saveSearchName,
        building_type:searchCriteria?.selectedBuildingTypeId,
        property_type:searchCriteria?.propertyTypeId,
        construction_status:searchCriteria?.constructionStatusId,
        property_label:searchCriteria?.propertyLabelId,
        user:localStorage.getItem("currentUser_id"),
        street_address:searchCriteria?.place?.formatted_address,
        lat:lat,
        long:lng
      });

      // Handle successful response
      if (response.status === 201) {
       
        toast.success("Save Search Done")
      } else {
        // Handle error response
        toast.error(' Please Contact Administrator');
      }
      handleshowClose(true)
    } catch (error) {
      // Handle Axios error
      toast.error(' Please Contact Administrator');
    }
  };
    
  return (
    <Modal show={saveshow} onHide={handleshowClose}>
      <div className="modal-content">
          <div className="modal-header border-0 p-0">

            <div className="px-8  mt-5">
              <h4>Save Search</h4>
            </div>

            <div className=" f-right" >
              <button onClick={() => handleshowClose(true)} type="button" className="close m-0 fs-23 ">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

          </div>
          <div className="modal-body p-4 py-sm-7 px-sm-8">


            <p>Be notified when there are new listings matching your search criteria.</p>

            <div className="">
              <div className="form-group">
                <label htmlFor="category" className="text-heading margin-b-0">
                  Name
                  <span className="star-mandatoryfield"></span>
                </label>
                <input
                  type="text"
                  className="form-control form-control-lg border-0"
                  id="save search"
                  name="save search"
                  value={saveSearchName}
                  onChange={(e) => setSaveSearchName(e?.target?.value)}
               
                />
              </div>
            </div>
          
            <button className="btn bg-blue text-white hover-saffron"
              onClick={SubmitSave}
             >Submit</button>
          </div>
        </div>
    </Modal>
  );
};

export default Searchmodal;
