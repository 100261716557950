import React, { useEffect, useState } from "react";
import Header from "../header/Header";
import SearchLocationInput from "../pages/advancedSearch/SearchLocationInput";
import Slider from "react-slick";
import Footer from "../footer/Footer";
import axiosInstance from "../interceptor/axiosInstance";
import { Link } from "react-router-dom";
import axios from "axios";
import Loader from "../Loader";
import CurrencyFormat from "react-currency-format";
import CallActionButton from "../callActionButton/CallActionButton";
import _ from "lodash";
import FavActionButton from "../favActionButton/FavActionButton";
import MyModal from "./MyModal";
import { Nav, Navbar } from "react-bootstrap";
import { fetchPosts } from "../redux/actions";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const API_URL = process.env.REACT_APP_API_URL;

const LandingPage = ({ posts, fetchPosts }) => {

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [allimages, setallimages] = useState([]);

  const [buildingType, setBuildingType] = useState([]);
  const [commercialId, setCommercialId] = useState([]);
  const [residentialId, setResidentialId] = useState([]);

  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [loading, setLoading] = useState(false);
  const [favproperty, setfavproperty] = useState([]);
  const [Image, setImage] = useState("");
  const [propertyLabel, setPropertyLabel] = useState([]);
  const [groupedCity, setgroupedCity] = useState([]);
  const [groupedState, setGroupedState] = useState([]);
  const [groupedcity, setGroupedCity] = useState([]);
  console.log(groupedCity,"grouped City");
  const [commericalProperty, setCommericalProperty] = useState([]);
  const [residentialProperty, setResidentialProperty] = useState([]);
  const [newState, setNewState] = useState("Dubai");
  const is_auth = localStorage.getItem("is_authenticated");

  // Api For Getting All Images
  const getLocationType = async () => {
    // setLoading(true);
    try {
      let localParam =
        latitude !== null
          ? `/track/properties/get/?longitude=${longitude}&latitude=${latitude}`
          : `/track/properties/get/`;
      const response = await axios.get(`${API_URL}${localParam}`);
      if (response.status === 200) {
        const responseData = response.data;

        setallimages(responseData);
        // setLoading(false);
      } else {
        // setLoading(false);
      }
    } catch (err) {
      // setLoading(false);
    }
  };
  /* **********************Api For Getting All commercial property************************************ */
  const getCommercialProperty = async () => {

    try {
      let localParam =
        latitude !== null
          ? `/track/new/?building_type=569c3700-61ab-42ad-b792-8019d1a3a994&longitude=${longitude}&latitude=${latitude}&is_new_property=true`
          : `/track/new/?building_type=569c3700-61ab-42ad-b792-8019d1a3a994&is_new_property=true`;
      const response = await axios.get(`${API_URL}${localParam}`);
      if (response.status === 200) {
        const responseData = response.data;
        setCommericalProperty(responseData);
      }
    } catch (err) {
      toast.error("error to fetch commerical properties")
    }
  };
  /* ***************Api For Getting All Residential property********* */
  const getResidentailProperty = async () => {

    try {
      let localParam =
        latitude !== null
          ? `/track/new/?building_type=00eb5d1b-1d34-4ebb-84d8-aa6d0a843419&longitude=${longitude}&latitude=${latitude}&is_new_property=true`
          : `/track/new/?building_type=00eb5d1b-1d34-4ebb-84d8-aa6d0a843419&is_new_property=true`;
      const response = await axios.get(`${API_URL}${localParam}`);
      if (response.status === 200) {
        const responseData = response.data;
        setResidentialProperty(responseData);
      }
    } catch (err) {
      toast.error("error to fetch residential properties")
    }
  };
  /* *********Api TO Get Image *********/
  const getImage = async () => {
    try {
      const response = await axios.get(`${API_URL}/track/home/page/image/`);
      if (response.status === 200) {
        const responseData = response.data;
        setImage(responseData);
      } else {

      }
    } catch (err) {

    }
  };

  /* *********Api TO Get Favourite property *********/
  const getfavourite = async () => {
    try {
      const response = await axiosInstance.get(
        `${API_URL}/properties/user/get/favourite/`
      );
      if (response.status === 200) {
        const responseData = response.data;
        setfavproperty(responseData);
      } else {
        toast.error(" Please Contact Administrator");
      }
    } catch (error) {
      toast.error(" Please Contact Administrator");
    }
  };

/* ****************api for property label ************* */

  const getPropertyLabel = async () => {
    try {
      const response = await axios.get(`${API_URL}/properties/property/label/`);
      if ((response.status = 200)) {
        const responseData = response?.data;
        setPropertyLabel(responseData);
      } else {
        toast.error(" Please Contact Administrator");
      }
    } catch (err) {
      toast.error(" Please Contact Administrator");
    }
  };

  // Api to Send the coordinates and Get all images

  const getCoordinates = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        (error) => {

        }
      );
    } else {

    }
  };

  /* ****************api for building type ************* */

  const getBuildType = async () => {
    try {
      const response = await axios.get(`${API_URL}/properties/building/types/`);
      if (response.status === 200) {
        const responseData = response.data;
        setBuildingType(responseData);
      } else {
        toast.error(" Please Contact Administrator");
      }
    } catch (err) {
      toast.error(" Please Contact Administrator");

      // throw err;
    }
  };
  useEffect(() => {
    getLocationType();
  }, [latitude, longitude]);

  // useEffect(() => {

  // }, [allimages]);

  useEffect(() => {
    setGroupedState(_.groupBy(allimages, "state"));
    setgroupedCity(_.groupBy(allimages, "city"));

  }, [allimages]);

  useEffect(() => {
    if (newState) {
      const cityData = _.groupBy(groupedState[newState], "city");
      setGroupedCity(cityData);
    }
  }, [newState, groupedState]);

  useEffect(() => {
    if (Array.isArray(buildingType) && Array.isArray(allimages)) {
      const building_commercial = buildingType.find(
        (item) => item?.name === "Commercial"
      );
      if (building_commercial) {
        const foundCommercial = allimages.filter(
          (item) => item?.building_type_name === building_commercial?.name
        );

        setCommercialId(foundCommercial);
      }

      const building_residential = buildingType.find(
        (item) => item?.name === "Residential"
      );
      if (building_residential) {
        const foundResidential = allimages.filter(
          (item) => item?.building_type_name === building_residential?.name
        );
        setResidentialId(foundResidential);
      }
    }
  }, [buildingType, allimages]);

  useEffect(() => {
    window?.scrollTo(0, 0);
    getCoordinates();
    getImage();
    getBuildType();
    getPropertyLabel();
    getResidentailProperty();
    getCommercialProperty();
  }, []);

  useEffect(() => {
    if (is_auth === "false") {
      getfavourite();
    }
  }, [is_auth]);

  useEffect(() => {
    fetchPosts();
  }, [fetchPosts]);

  var citysettings = {
    dots: false,
    speed: 500,
    initialSlide: 0,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    arrows: true,
    adaptiveHeight: true,
    accessibility: true,

    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
          autoplay: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
          autoplay: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
          autoplay: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
          autoplay: true,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
          autoplay: true,
        },
      },
    ],
  };
  var settings = {
    dots: true,
    speed: 500,
    initialSlide: 0,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  const bannerSlider = {
    pauseOnHover: false,
    infinite: true,
    speed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    fade: true,
    autoplaySpeed: 8000,

  };


  /* ***************end******************* */


  return (
    <>
      {loading ? <Loader /> : null}

      <main id="content-landing">
        <Header />
        <section
          className="h-100vh d-flex flex-column header_part land-slider-section"

        >

          <div className="d-flex align-items-center header_part_1  marginTop-100px">
            <div className="container-fluid p-0 m-0 header_part_2" >
              <div className="margin-top-0 header_part_3 ">
                <div className="header_part_4">
                  <Slider {...bannerSlider}>
                    {/* *********first Slide */}
                    <div className="position-relative">
                      <div
                        className="dark-overlay"
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%', // Adjust the width of the dark overlay
                          height: '100%',
                          filterRight: 'blur(30px)',
                          background: 'linear-gradient(to right, #000 0%, transparent 100%)',
                          zIndex: 1,
                        }}
                      ></div>
                      <img
                        src="../images/topBanner-1.png"
                        // alt={`Banner ${index + 1}`}
                        style={{ width: '100%', height: '850px', objectFit: 'cover' }}
                      />
                      <div className="row px-8" style={{
                        position: 'absolute',
                        top: "30%",
                        width: '100%', // Adjust the width of the dark overlay
                        height: '100%',
                        zIndex: 2,
                      }}>
                        <div className="col-lg-6">
                          <h2 className="py-5  px-5 text-white fs-48 lh-15 font-weight-600 fs-slide display-re-none" >
                          {/* Only Platform For <br /> Your Residential & Commercial <br />Real Estate, Powered by AI */}
                          Real Estate Elevated.<br /> Endorsed by developers,<br /> Tailored for You!
                          {/*   Discover <br />your Dream Property with <br />our Real Estate Data Intelligence */}</h2>
                          <SearchLocationInput allimages={allimages} />
                        </div>
                        <div className="col-lg-6 display-re-none top-110" >
                          <div className="row ">
                            <div className="col-lg-3 px-2">

                              <img className="land-slide-img" style={{ borderRadius: "15px", zIndex: "1", objectFit: "fill" }} src="../images/mobileApp.png" alt="dirham-mobile-app" />
                            </div>
                            <div className="col-lg-9">
                              <img className="land-slide-img" style={{ borderRadius: "15px", zIndex: "0", objectFit: "fill" }} src="../images/adv.png" alt="dirham-web-app" />
                            </div>
                          </div>
                        </div>
                      </div>


                    </div>
                    {/* *********second Slide */}
                    <div className="position-relative">
                      <div
                        className="dark-overlay"
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%', // Adjust the width of the dark overlay
                          height: '100%',
                          filterRight: 'blur(30px)',
                          background: 'linear-gradient(to right, #000 0%, transparent 100%)',
                          zIndex: 1,
                        }}
                      ></div>
                      <img
                        src="../images/top-banner-4.jpg"
                        // alt={`Banner ${index + 1}`}
                        style={{ width: '100%', height: '850px', objectFit: 'cover' }}
                      />
                      <div className="row px-8" style={{
                        position: 'absolute',
                        top: "30%",
                        width: '100%', // Adjust the width of the dark overlay
                        height: '100%',
                        zIndex: 2,
                      }}>
                        <div className="col-lg-6">
                          <h2 className="py-5  px-5 text-white fs-48 lh-15 font-weight-600 fs-slide display-re-none" >

                          Personalize  <br />Property Search <br /> Powered By AI
                          {/*   Discover <br />your Dream Property with <br />our Real Estate Data Intelligence */}</h2>
                          <SearchLocationInput allimages={allimages} />
                        </div>
                        <div className="col-lg-6 display-re-none top-110" >
                          <div className="row ">
                            <div className="col-lg-3 px-2">

                              <img className="land-slide-img" style={{ borderRadius: "15px", zIndex: "1", objectFit: "fill" }} src="../images/openai.gif" alt="dirham-mobile-app" />
                            </div>
                            <div className="col-lg-9">
                              <img className="land-slide-img" style={{ borderRadius: "15px", zIndex: "0", objectFit: "fill" }} src="../images/openia.png" alt="dirham-web-app" />
                            </div>
                          </div>
                        </div>
                      </div>


                    </div>
                    {/* *********third Slide */}
                    {/* <div className="position-relative">
                      <div
                        className="dark-overlay"
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%', // Adjust the width of the dark overlay
                          height: '100%',
                          filterRight: 'blur(30px)',
                          background: 'linear-gradient(to right, #000 0%, transparent 100%)',
                          zIndex: 1,
                        }}
                      ></div>
                      <img
                        src="../images/top-banner-3.jpg"
                        // alt={`Banner ${index + 1}`}
                        style={{ width: '100%', height: '850px', objectFit: 'cover' }}
                      />
                      <div className="row px-8" style={{
                        position: 'absolute',
                        top: "30%",
                        width: '100%', // Adjust the width of the dark overlay
                        height: '100%',
                        zIndex: 2,
                      }}>
                        <div className="col-lg-6  ">
                          <h2 className="py-5 px-5 text-white" style={{
                            fontSize: "48px",
                            fontWeight: "600",
                            lineHeight: "1.5",
                            wordSpacing: "2px"
                          }}>Property <br />Score Using <br />Location Intelligence</h2>
                          <SearchLocationInput allimages={allimages} />
                        </div>
                        <div className="col-lg-6" style={{ position: "relative" }}>
                          <img style={{ width: "350px", borderRadius: "10px", position: "absolute", zIndex: "1", margin: "45px 0 0 0" }} src="../images/scoreCard.png" alt="dirham-mobile-app" />
                          <img style={{ width: "700px", borderRadius: "20px", position: "absolute", zIndex: "0", margin: "-96px 0 0 160px" }} src="../images/nearPlace.png" alt="dirham-web-app" />
                        </div>
                      </div>


                    </div> */}
                    {/* *********fourth Slide */}
                    <div className="position-relative">
                      <div
                        className="dark-overlay"
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%', // Adjust the width of the dark overlay
                          height: '100%',
                          filterRight: 'blur(30px)',
                          background: 'linear-gradient(to right, #000 0%, transparent 100%)',
                          zIndex: 1,
                        }}
                      ></div>
                      <img
                        src="../images/top-banner-2.jpg"
                        // alt={`Banner ${index + 1}`}
                        style={{ width: '100%', height: '850px', objectFit: 'cover' }}
                      />
                      <div className="row px-8" style={{
                        position: 'absolute',
                        top: "30%",
                        width: '100%', // Adjust the width of the dark overlay
                        height: '100%',
                        zIndex: 2,
                      }}>
                        <div className="col-sm-12 col-lg-6">
                          <h2 className="py-5  px-5 text-white fs-48 fs-slide lh-15 font-weight-600  display-re-none" >Machine Learning <br />Based Property<br /> Price Recommendations</h2>
                          <SearchLocationInput allimages={allimages} />
                        </div>
                        <div className="col-lg-6 display-re-none top-110">
                          <div className="row">
                            <div className="col-lg-3 px-2">
                              <img className="land-slide-img" style={{ borderRadius: "15px", zIndex: "1", objectFit: "fill" }} src="../images/priceTrend.png" alt="dirham-mobile-app" />
                            </div>
                            <div className="col-lg-9">
                              <img className="land-slide-img" style={{ borderRadius: "15px", zIndex: "0", objectFit: "fill" }} src="../images/proIntell.png" alt="dirham-web-app" />
                            </div>
                          </div>


                        </div>
                      </div>


                    </div>
                    {/* end slider */}
                  </Slider>



                  {/* <form className="d-flex">
                      <div className="in-fr">
                        <SearchLocationInput allimages={allimages} />
                      </div>
                    </form> */}
                </div>
              </div>





            </div>
          </div>
        </section>

        <section>
          <div className="bg-gray-02  pt-11 pb-6 padding-top-bottom-50">
            <div className="container container-xxl">
              <div className="row">
                <div className=" col-md-4">
                  <h2 className="text-heading fs-32 fw-600">
                    Explore Properties By Cities
                  </h2>
                  <span className="heading-divider divider-bottom"></span>
                </div>
                <div className="col-md-8">
                  <Navbar
                    collapseOnSelect
                    expand="lg"
                    className="bg-body-tertiary ml-auto"
                  >
                    <Navbar.Toggle
                      aria-controls="responsive-navbar-nav"
                      className="ml-auto"
                    />
                    <Navbar.Collapse id="responsive-navbar-nav ml-auto">
                      <Nav className="ml-auto ">
                        {[
                          ...Object.entries(groupedState)
                            .filter(([state, stateData]) => state === "Dubai")
                            .map(([state, stateData], index) => (
                              <Nav.Link
                                key={index}
                                onClick={(e) => setNewState(state)}
                                className={`mb-1 round-5 mr-1 py-1 px-2 text-white fs-md-14
                                ${newState === state
                                    ? "active bg-saffron"
                                    : " bg-blue"
                                  }`}
                              >
                                {state}
                              </Nav.Link>
                            )),
                          ...Object.entries(groupedState)
                            .filter(([state, stateData]) => state !== "Dubai")
                            .map(([state, stateData], index) => (
                              <Nav.Link
                                key={index}
                                onClick={(e) => setNewState(state)}
                                className={`mb-1 round-5 mr-1 py-1 px-2 text-white fs-md-14 ${newState === state
                                  ? "active bg-saffron"
                                  : " bg-blue"
                                  }`}
                              >
                                {state}
                              </Nav.Link>
                            )),
                        ]}
                      </Nav>
                    </Navbar.Collapse>
                  </Navbar>
                </div>
              </div>

              <div className="slick-slider-city slick-dots-mt-0 custom-arrow-spacing-30 opacity-1">
                <Slider {...citysettings} className="commercial-set ">
                  {Object.entries(groupedcity).map(([city, cityData], index) =>
                    city ? (
                      <div className="box " key={index}>
                        <div className="card  align-items-center round-10  text-gray   text-decoration-none height-city"
                        style={{height:"275px"}}
                        >
                          <Link to="/advance-search" state={{ newCity: city }}>
                            <img
                              src={cityData[0]?.upload_featured_image}
                              className="px-2 pt-2 round-5 height-city-footer"
                              alt="Apartment"
                              style={{ width: "220px", height: "207px" }}
                            />
                            <div className="card-body px-0 py-3 pb-0 px-2">
                              <h4 className="card-title fs-lg-14 fs-md-12 fs-sm-12 lh-12 fw-600 text-dark mb-0">

                                {city}({cityData.length})
                              </h4>
                            </div>
                          </Link>
                        </div>
                      </div>
                    ) : null
                  )}
                </Slider>
              </div>


            </div>
          </div>
        </section>
        {/* Featured  Residential Homes For Sale */}
        <div id="commercial">
          <section className="bg-gray-02  pb-lg-11 py-5 padding-top-bottom-50">
            <div className="container container-xxl">
              <div className="row">
                <div className="col-md-6">
                  <h2 className="text-heading fs-32 fw-600">
                    Featured Residential Properties
                  </h2>
                  <span className="heading-divider divider-bottom"></span>
                </div>
                <div className="col-md-6 text-md-right">
                  <Link
                    className="btn fs-14 bg-blue text-white hover-saffron py-3 lh-15 px-7 mb-6 mb-lg-0 py-md-2 px-md-4"
                    to="/advance-search"
                    state={{ building_type: buildingType[1]?.id }}
                  >
                    See all properties
                    <i className="far fa-long-arrow-right ml-1"></i>
                  </Link>
                </div>
              </div>
              <div className="slick-dots-mt-0 custom-arrow-spacing-30 opacity-1">
                <Slider {...settings} className="commercial-set">
                  {residentialProperty.slice(0, 10).map((item) => (
                    <div className="box box pb-7" data-animate="fadeInUp">
                      <div className="card shadow-hover-2 rounded-15" key={item?.id}>
                        <Link to={`/property/details/${item?.slug}`}>
                          <div className="  card-img-top">
                            <div className="  hover-change-image bg-hover-overlay px-3 pt-3">
                              <img
                                src={item?.upload_featured_image}
                                alt="Home in Metric Way"
                                className="prelaunch-property-image round-10 "
                              />
                            </div>
                            <div className="card-img-overlay p-2 d-flex flex-row justify-content-between">
                              <div>
                                <span className="badge ml-3 mt-3 badge-orange">
                                  {item?.property_label_name}
                                </span>
                              </div>
                              <div className="text-right">
                                 {item?.under_offer === true ? (<span className="badge mr-3 mt-3 bg-blue">under offer</span>) :null}
                              </div>
                            </div>
                          </div>
                          <div
                            className="card-body "
                            style={{ height: "210px" }}
                          >
                            <h2 className="card-title fs-20 fw-600 lh-12 mb-1 ">
                              <Link className="text-dark">{item?.property_name}</Link>
                            </h2>
                            <p className="card-text font-weight-500 text-gray mb-2 lh-1">
                              {item?.city}{item?.city ? (<>,{" "}</>) : (<></>)}
                              {item?.state}{item?.state ? (<>,{" "}</>) : (<></>)}
                              {item?.country}
                            </p>
                            <ul className="list-inline d-flex mb-0 flex-wrap mr-n5">
                              <li
                                className="list-inline-item text-gray font-weight-500 fs-13 d-flex align-items-center mr-5"
                                data-toggle="tooltip"
                                title="Size"
                              >
                                <img
                                  src="images/area.png"
                                  className="icon icon-bedroom fs-18 text-heading mr-1 width-32"
                                  alt="area"
                                />
                                {item?.property_size}/sqft

                              </li>
                            </ul>
                            <h2 className="card-title fs-16 lh-2 fw-600 mb-0">
                              {item?.property_label_name === "Sale" ? (
                                <Link className="text-dark">
                                  Price:{" "}
                                  <CurrencyFormat
                                    value={item?.property_price}
                                    displayType="text"
                                    thousandSeparator={true}
                                    suffix=".00 AED"
                                  />
                                </Link>
                              ) : (
                                <Link className="text-dark">
                                  Price:{" "}
                                  <CurrencyFormat
                                    value={item?.lease_price_per_mo}
                                    displayType="text"
                                    thousandSeparator={true}
                                    suffix=".00 AED/mo"
                                  />
                                </Link>
                              )}
                            </h2>
                          </div>
                        </Link>
                        <div className="card-footer bg-transparent d-flex justify-content-between align-items-center py-3 px-1366-1"
                        style={{ zIndex: "1111" }}
                        >
                          <div className="mr-auto">
                            <CallActionButton key={item?.id} item={item} />
                          </div>
                          <ul className="list-inline mb-0">
                            <li className="list-inline-item">
                              <FavActionButton
                                setShow={setShow}
                                itemId={item?.id}
                                favproperty={favproperty}
                                setfavproperty={setfavproperty}
                              // setLoading={setLoading}
                              />
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </section>
        </div>
        {/* Featured  commerical Homes For Sale */}

        <div id="commercial">
          <section className="bg-gray-02  pb-lg-11  padding-top-bottom-50">
            <div className="container container-xxl">
              <div className="row">
                <div className="col-lg-6 ">
                  <h2 className="text-heading font-size-24 fs-32  fw-600">
                    Featured Commercial Properties
                  </h2>
                  <span className="heading-divider divider-bottom"></span>
                </div>
                <div className="col-md-6 text-md-right">
                  <Link
                    className="btn fs-14 bg-blue text-white hover-saffron py-3 lh-15 px-7 py-md-2 px-md-4 mb-6 mb-lg-0"
                    to="/advance-search"
                    state={{ building_type: buildingType[0]?.id }}
                  >
                    See all properties
                    <i className="far fa-long-arrow-right ml-1"></i>
                  </Link>
                </div>
              </div>
              <div className="slick-dots-mt-0 custom-arrow-spacing-30 opacity-1">
                <Slider {...settings} className="commercial-set">
                  {commericalProperty.slice(0, 10).map((item) => (
                    <div className="box box pb-7" data-animate="fadeInUp">
                      <div className="card shadow-hover-2 rounded-15" key={item?.id}>
                        <Link to={`/property/details/${item?.slug}`}>
                          <div className="  card-img-top">
                            <div className="  hover-change-image bg-hover-overlay px-3 pt-3">
                              <img
                                src={item?.upload_featured_image}
                                alt="Home in Metric Way"
                                className="prelaunch-property-image round-10 "
                              />
                            </div>
                            <div className="card-img-overlay p-2 d-flex flex-row justify-content-between">
                              <div>
                                <span className="badge ml-3 mt-3 badge-orange">
                                  {item?.property_label_name}
                                </span>
                              </div>
                              <div className="text-right">
                                 {item?.under_offer === true ? (<span className="badge mr-3 mt-3 bg-blue">under offer</span>) :null}
                              </div>
                            </div>
                          </div>
                          <div
                            className="card-body "
                            style={{ height: "210px" }}
                          >
                            <h2 className="card-title fs-20 fw-600 lh-12 mb-1 ">
                              <Link className="text-dark">{item?.property_name}</Link>
                            </h2>
                            <p className="card-text font-weight-500 text-gray mb-2 lh-1">
                              {item?.city}{item?.city ? (<>,{" "}</>) : (<></>)}
                              {item?.state}{item?.state ? (<>,{" "}</>) : (<></>)}
                              {item?.country}
                            </p>
                            <ul className="list-inline d-flex mb-0 flex-wrap mr-n5">
                              <li
                                className="list-inline-item text-gray font-weight-500 fs-13 d-flex align-items-center mr-5"
                                data-toggle="tooltip"
                                title="Size"
                              >
                                <img
                                  src="images/area.png"
                                  className="icon icon-bedroom fs-18 text-heading mr-1 width-32"
                                  alt="area"
                                />
                                {item?.property_size}/sqft
                              </li>
                            </ul>
                            <h2 className="card-title fs-16 lh-2 fw-600 mb-0">
                              {item?.property_label_name === "Sale" ? (
                                <Link className="text-dark">
                                  Price:{" "}
                                  <CurrencyFormat
                                    value={item?.property_price}
                                    displayType="text"
                                    thousandSeparator={true}
                                    suffix=".00 AED"
                                  />
                                </Link>
                              ) : (
                                <Link className="text-dark">
                                  Price:{" "}
                                  <CurrencyFormat
                                    value={item?.lease_price_per_mo}
                                    displayType="text"
                                    thousandSeparator={true}
                                    suffix=".00 AED/mo"
                                  />
                                </Link>
                              )}
                            </h2>
                          </div>
                        </Link>
                        <div className="card-footer bg-transparent d-flex justify-content-between align-items-center py-3 px-1366-1"
                          style={{ zIndex: "1111" }}
                        >
                          <div className="mr-auto">
                            <CallActionButton key={item?.id} item={item} />
                          </div>
                          <ul className="list-inline mb-0">
                            <li className="list-inline-item">
                              <FavActionButton
                                setShow={setShow}
                                itemId={item?.id}
                                favproperty={favproperty}
                                setfavproperty={setfavproperty}
                              // setLoading={setLoading}
                              />
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </section>
        </div>

        {/* Featured  company Data  */}
        <section className="bg-gray-02  pb-lg-10 padding-top-bottom-50">
          <div className="container container-xxl">
            <div className="row">
              <div className="col-md-6">
                <h2 className="text-heading  fs-32 fw-600">
                  Our Top Developers
                </h2>
                <span className="heading-divider divider-bottom"></span>
              </div>
            </div>
            <Slider {...settings} className="commercial-set">
              {posts.map((item) => (
                <div className="box box pb-7 " data-animate="fadeInUp">
                  <div className="card shadow-hover-2 rounded-15" key={item?.id}>
                    <Link to={`/company/details/${item?.id}`}>
                      <div className="px-3 pt-3">
                        <img
                          src={item?.img}
                          alt="Home in Metric Way"
                          className="prelaunch-property-image round-10 "
                        />
                      </div>
                      <div
                        className="card-body pt-3 "
                        style={{ height: "100px" }}
                      >
                        <h2 className="card-title fs-20 fw-600 lh-12 mb-0">
                          <Link to={`/company/details/${item?.id}`} className="text-dark">
                            {item?.company_name}
                          </Link>
                        </h2>
                        <p className="card-text font-weight-500 text-gray-light mb-1">
                          {item?.city}{item?.city ? (<>,{" "}</>) : (<></>)}
                          {item?.state}{item?.state && item?.country ? (<>,{" "}</>) : (<></>)}
                          {item?.country}
                        </p>

                        {/* <h2 className="card-title fs-16 lh-2 mb-0">
                          <Link
                            target="blank"
                            to={item?.company_website}
                            className="text-dark"
                          >
                            Weblink
                          </Link>
                        </h2> */}
                      </div>
                    </Link>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </section>
      </main>

      <Footer />
      <MyModal show={show} handleClose={handleClose} />
    </>
  );
};
const mapStateToProps = (state) => ({
  posts: state.posts,
  loading: state.loading,
  error: state.error,
});

const mapDispatchToProps = {
  fetchPosts,
};

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);
