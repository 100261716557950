import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../images/dpf-logo-new.png";
import axios from "axios";
import Loader from "../Loader";
import { Logout } from "../auth/Logout";
import { Dropdown, DropdownButton } from "react-bootstrap";
import MyModal from "../landingPage/MyModal";
import axiosInstance from "../interceptor/axiosInstance";
import { toast } from "react-toastify";
const API_URL = process.env.REACT_APP_API_URL;

const Header = () => {
  const isTrue = localStorage.getItem("token");
  const [propertyLabel, setPropertyLabel] = useState([]);

  const [buildingType, setBuildingType] = useState([]);
  const [loading, setLoading] = useState(false);
  const [allProperties, setAllProperties] = useState([]);

  const [commercialProperties, setCommercialProperties] = useState([]);
  const [saleProperties, setSaleProperty] = useState([]);
  const [rentProperties, setRentProperty] = useState([]);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const is_auth = localStorage.getItem("is_authenticated");

  const navigate = useNavigate();
  const [profiledata, setprofiledata] = useState([]);


  const user_Id = localStorage.getItem("currentUser_id")

  const getprofiledata = () => {
    // setLoading(true);
    if (user_Id) {
      try {
        axiosInstance
          .get(
            `${API_URL}/account/user/profile/${user_Id}/patch/`
          )
          .then((response) => {
            const profileinfo = response.data;

            setprofiledata(profileinfo);
            // setLoading(false);
          })
          .catch((error) => {

            // setLoading(false);
            // Handle the error as needed
          });
      } catch (error) {
        // toast.error(' Please Contact Administrator');
        // setLoading(false);
        // Handle the error as needed
      }
    }
  };
  useEffect(() => {
    getprofiledata();
  }, [user_Id]);
/* sale/rent function */

  const saleFunction = async (id) => {
    try {
      const my = await getAllProperties(id); // Wait for the properties to be fetched

      navigate("/advance-search", { state: { my,property_label: propertyLabel[0]?.id,building_type:"" } });
    } catch (error) {
      // Handle errors here, e.g., display an error message or log the error
      console.error("Error fetching properties:", error);
    }
  };


  const rentFunction = async (id) => {
    try {
      const my = await getAllProperties(id); // Wait for the properties to be fetched

      navigate("/advance-search", { state: { my,property_label: propertyLabel[1]?.id,building_type: "" } });
    } catch (error) {
      // Handle errors here, e.g., display an error message or log the error
      console.error("Error fetching properties:", error);
    }
  };

  const getAllProperties = async (id) => {
    setLoading(true);
    try {
      let apiUrl = `${API_URL}/properties/advance/filter/?property_label=${id}&is_new_property=true`;
      const response = await axios.get(apiUrl);
      if (response.status === 200) {
        const responseData = response.data;
        // setAllProperties(responseData);
        setLoading(false);
        return responseData
      } else {
        // Handle unexpected status codes, if needed
      }
    } catch (error) {
      // Handle network errors or other exceptions
      console.error("Error fetching properties:", error);
      throw error; // Re-throw the error to be caught by the caller
    }
  };
  // /* commerical function */


  const commericalFunction = async (id) => {
    try {
      const allcommerical = await getCommericalProperties(id); // Wait for the properties to be fetched

      navigate("/advance-search", { state: { allcommerical,building_type: buildingType[0]?.id ,property_label: ""} });
    } catch (error) {
      // Handle errors here, e.g., display an error message or log the error
      console.error("Error fetching properties:", error);
    }
  };

  const getCommericalProperties = async (id) => {
    setLoading(true);
    try {
      let apiUrl = `${API_URL}/properties/advance/filter/?building_type=${id}&is_new_property=true`;
      const response = await axios.get(apiUrl);
      if (response.status === 200) {
        const responseData = response.data;
        // setAllProperties(responseData);
        setLoading(false);
        return responseData
      } else {
        // Handle unexpected status codes, if needed
      }
    } catch (error) {
      // Handle network errors or other exceptions
      console.error("Error fetching properties:", error);
      throw error; // Re-throw the error to be caught by the caller
    }
  };
 /* new commerical function */


 const newcommericalFunction = async (id) => {
  try {
    const my = await getNewCommericalProperties(id); // Wait for the properties to be fetched

    navigate("/advance-search/new-project", { state: {my, bludingName:id}  });
  } catch (error) {
    // Handle errors here, e.g., display an error message or log the error
    console.error("Error fetching properties:", error);
  }
};

const getNewCommericalProperties = async (id) => {
  setLoading(true);
  try {
    let apiUrl = `${API_URL}/properties/advance/filter/?building_type=${id}&is_property=true&property_label=9c9cd2a0-ef4f-4151-b7ab-17496bd3d6c0`;
    const response = await axios.get(apiUrl);
    if (response.status === 200) {
      const responseData = response.data;
      // setAllProperties(responseData);
      setLoading(false);
      return responseData
    } else {
      // Handle unexpected status codes, if needed
    }
  } catch (error) {
    // Handle network errors or other exceptions
    console.error("Error fetching properties:", error);
    throw error; // Re-throw the error to be caught by the caller
  }
};



  /* ************check login*********** */
  const OnSave = async () => {
    if (is_auth === "false") {
      navigate("/plan");
    } else {
      setShow(true);
    }
  };
  /* ****************api for property label ************* */
  const getPropertyLabel = async () => {
    try {
      const response = await axios.get(`${API_URL}/properties/property/label/`);
      if ((response.status = 200)) {
        const responseData = response?.data;
        setPropertyLabel(responseData);
      } else {

      }
    } catch (err) {

    }
  };

  /* ****************api for building type ************* */

  const getBuildType = async () => {
    try {
      const response = await axios.get(`${API_URL}/properties/building/types/`);
      if ((response.status = 200)) {
        const responseData = response.data;
        setBuildingType(responseData);
      } else {

      }
    } catch (err) {

    }
  };

  useEffect(() => {
    // getAllProperties();
    getBuildType();
    getPropertyLabel();
  }, []);
  // useEffect(() => {
  //   if (Array.isArray(buildingType) && Array.isArray(allProperties)) {
  //     const building_commercial = buildingType.find(
  //       (item) => item?.name === "Commercial"
  //     );
  //     if (building_commercial) {
  //       const foundCommercial = allProperties.filter(
  //         (item) => item?.building_type_name === building_commercial?.name
  //       );
  //       setCommercialProperties(foundCommercial);
  //     }

  //     const building_residential = propertyLabel.find(
  //       (item) => item?.name === "Sale"
  //     );
  //     if (building_residential) {
  //       const foundResidential = allProperties.filter(
  //         (item) => item?.property_label_name === building_residential?.name
  //       );
  //       setSaleProperty(foundResidential);
  //     }

  //     const building_rent = propertyLabel.find((item) => item?.name === "Rent");
  //     if (building_rent) {
  //       const foundResidential = allProperties.filter(
  //         (item) => item?.property_label_name === building_rent?.name
  //       );
  //       setRentProperty(foundResidential);
  //     }
  //   }
  // }, [buildingType, allProperties, propertyLabel]);

  /* *******************logout handler */
  function handleLogout() {
    Logout();
  }
  function handleHomePage() {
    window.location.reload();
    window.location.href = "/";
  }

  return (
    <>
      {loading ? <Loader /> : null}
      <header className="main-header  fixed-top m-0 navbar-dark header-sticky header-sticky-smart header-mobile-xl blog-main">
        <div className="sticky-area stick-area">
          <div className="container-fluid px-8">
            <div className="d-flex align-items-center pos-relative">
              <nav className="navbar navbar-expand-xl bg-transparent px-0 w-100 w-xl-auto wdth-100per">
                <a
                  className="navbar-brand mr-7 mr-none"
                  onClick={handleHomePage}
                >
                  <img
                    src={logo}
                    alt="HomeID"
                    className="normal-logo main-logo-header"
                  />
                  <img src={logo} alt="HomeID" className="sticky-logo" />
                </a>

                <button
                  className="navbar-toggler border-0 px-0"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#primaryMenu02"
                  aria-controls="primaryMenu02"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="text-white fs-24 font-size-50">
                    <i className="fal fa-bars"></i>
                  </span>
                </button>
                <div
                  className="collapse navbar-collapse mt-3 mt-xl-0"
                  id="primaryMenu02"
                >
                  <ul className="navbar-nav hover-menu main-menu px-0 mx-xl-n4">
                    {/* <li
                      id="navbar-item-home"
                      aria-haspopup="true"
                      aria-expanded="false"
                      className="nav-item dropdown py-xl-5 py-2 nav-list-item px-0 px-xl-2 padding-nav"
                    >
                      <a
                        className="nav-link dropdown-toggle p-0 nav-bar-link clr-white-mobile"
                        href="/"
                      >
                        Home
                        <span className="caret"></span>
                      </a>
                    </li> */}

                    <li
                      id="navbar-item-dashboard"
                      aria-haspopup="true"
                      aria-expanded="false"
                      className="nav-item dropdown py-xl-5 py-2 nav-list-item px-0 px-xl-2 padding-nav"
                    >
                      <Link
                        className="nav-link dropdown-toggle p-0 nav-bar-link clr-white-mobile"
                        // to="/advance-search"
                        onClick={()=>saleFunction(propertyLabel[0]?.id)}
                        // state={{ property_label: propertyLabel[0]?.id }}
                      >
                        Sale
                        <span className="caret"></span>
                      </Link>
                    </li>
                    <li
                      id="navbar-item-dashboard"
                      aria-haspopup="true"
                      aria-expanded="false"
                      className="nav-item dropdown py-xl-5 py-2 nav-list-item px-0 px-xl-1 padding-nav"
                    >
                      <Link
                        className="nav-link dropdown-toggle p-0 nav-bar-link clr-white-mobile"
                        // to="/advance-search"
                        onClick={()=>rentFunction(propertyLabel[1]?.id)}
                        // state={{ property_label: propertyLabel[1]?.id }}
                      >
                        Rent
                        <span className="caret"></span>
                      </Link>
                    </li>

                    <li
                      id="navbar-item-dashboard"
                      aria-haspopup="true"
                      aria-expanded="false"
                      className="nav-item dropdown py-xl-5 py-2 nav-list-item px-0 px-xl-1 padding-nav"
                    >
                      <Link
                        className="nav-link dropdown-toggle p-0 nav-bar-link clr-white-mobile"
                        to="/businessfinder"
                      >
                        Business Finder
                        <span className="caret"></span>
                      </Link>
                    </li>
                    <li
                      id="navbar-item-dashboard"
                      aria-haspopup="true"
                      aria-expanded="false"
                      className="nav-item dropdown py-xl-5 py-2 nav-list-item px-0 px-xl-1 padding-nav"
                    >
                      <Link
                        className="nav-link dropdown-toggle p-0 nav-bar-link clr-white-mobile"
                        // to="/advance-search"
                        // state={{ building_type: buildingType[0]?.id }}
                        onClick={()=>commericalFunction(buildingType[0]?.id)}
                      >
                        Commercial
                        <span className="caret"></span>
                      </Link>
                    </li>
                    <li
                      id="navbar-item-pages"
                      aria-haspopup="true"
                      aria-expanded="false"
                      className="nav-item dropdown py-xl-5 py-2 nav-list-item px-0 px-xl-1 padding-nav"
                    >
                      <Link
                        className="nav-link dropdown-toggle p-0 nav-bar-link clr-white-mobile"
                        to="/blog"
                      >
                        Blog
                        <span className="caret"></span>
                      </Link>
                    </li>
                    {/* <li
                    id="navbar-item-dashboard"
                    aria-haspopup="true"
                    aria-expanded="false"
                    className="nav-item dropdown py-xl-5 py-2 nav-list-item px-0 px-xl-1 padding-nav "
                  >
                    <Link
                      className="round-5  py-2 px-2 clr-white-mobile  text-white bg-orange "
                      to="/advance-search"
                      state={{ is_property: true }}
                    >
                      New Projects
                      <span className="caret"></span>
                    </Link>
                  </li> */}

                    <DropdownButton
                      id="dropdown-basic"
                      title="Off-Plan"
                      className="dropydowny"
                    >
                      <Dropdown.Item className="">
                        <Link
                          // to="/advance-search/new-project"
                          // state={{

                          //   buildingname: buildingType[0]?.id,
                          // }}
                          onClick={()=>newcommericalFunction(buildingType[0]?.id)}
                        >
                          Commercial
                        </Link>
                      </Dropdown.Item>

                      <Dropdown.Item>
                        <Link
                          // to="/advance-search/new-project"
                          // state={{

                          //   buildingname: buildingType[1]?.id,
                          // }}
                          onClick={()=>newcommericalFunction(buildingType[1]?.id)}
                        >
                          Residential
                        </Link>
                      </Dropdown.Item>
                    </DropdownButton>

                    <li
                      id="navbar-item-pages"
                      aria-haspopup="true"
                      aria-expanded="false"
                      className="nav-item dropdown py-xl-5 py-2 nav-list-item px-0 px-xl-1 padding-nav"
                    >
                      <Link
                        className="nav-link dropdown-toggle p-0 nav-bar-link clr-white-mobile"
                        to="/contact"
                      >
                        Contact
                        <span className="caret"></span>
                      </Link>
                    </li>
                    <DropdownButton
                      id="dropdown-basic"
                      title="RE Intelligence "
                      className="dropydowny"
                    >
                      <Dropdown.Item href="/intellegence">
                        Price Intelligence
                      </Dropdown.Item>
                      <Dropdown.Item href="/propertyscore">
                        Property Score
                      </Dropdown.Item>
                    </DropdownButton>
                    {/* <li
                    id="navbar-item-dashboard"
                    aria-haspopup="true"
                    aria-expanded="false"
                    className="nav-item dropdown py-xl-5 py-2 nav-list-item px-0 px-xl-1 padding-nav "
                  >
                    <Link
                      className="round-5  py-2 px-2 clr-white-mobile  text-white bg-orange "
                      to="/intellegence"

                    >
                      RE Intelligence
                      <span className="caret"></span>
                    </Link>
                  </li> */}
                  </ul>
                  {isTrue !== null ? (
                    <>
                      <ul className="d-r-inline navbar-nav main-menu hover-menu flex-row ml-auto align-items-center justify-content-lg-end flex-wrap  add-positioning-styles">
                      <li className="nav-item dropdown nav-list-item px-0 px-xl-1 padding-nav">
                      <Link
                        to={profiledata?.is_paid ? "/property/kind/" : "/plan"}
                        onClick={() => {
                          if (!profiledata?.is_paid) {
                            toast.error("Please Subscribe Plan First");
                          }
                        }}
                             style={{ cursor: "pointer" }}
                            className="listing-a  fs-16 text-white dashboard-link-header-mob round-5"
                          >
                            Add Listing
                          </Link>
                        </li>


                        <li
                          id="navbar-item-pages"
                          aria-haspopup="true"
                          aria-expanded="false"
                          className="nav-item dropdown nav-list-item px-0 px-xl-1 padding-nav"
                        >
                          <Link
                            className="nav-link dropdown-toggle p-0 nav-bar-link dashboard-link-header-mob"
                            to={"/dashboard"}
                          >
                            Dashboard
                          </Link>
                        </li>
                        <li className="nav-item dropdown nav-list-item px-0 px-xl-1 padding-nav">
                          <a
                            onClick={handleLogout}
                            className="nav-link dropdown-toggle p-0 nav-bar-link dashboard-link-header-mob"
                          >
                            Logout
                          </a>
                        </li>

                        {/* <li className="subscibe-button-main-header">
                          <a onClick={() => OnSave()} classname="py-2 text-white" style={{ color: "#fff", cursor: "pointer" }}>
                            Plans
                          </a>
                        </li> */}
                        <li className="nav-item dropdown nav-list-item px-0 px-xl-1 padding-nav">
                          <a
                           onClick={() => OnSave()}
                           style={{ cursor: "pointer" }}
                            className="listing-a   fs-16 text-white dashboard-link-header-mob round-5"
                          >
                           Plans
                          </a>
                        </li>
                      </ul>
                    </>
                  ) : (
                    <>
                      <ul className="d-r-inline navbar-nav main-menu hover-menu flex-row ml-auto align-items-center justify-content-lg-end flex-wrap  add-positioning-styles">
                        <li className="nav-item dropdown nav-list-item px-0 px-xl-1 padding-nav">
                          <Link
                            to="/login"
                            className="nav-link dropdown-toggle p-0 nav-bar-link dashboard-link-header-mob"
                          >
                            Sign In
                          </Link>
                        </li>

                        <li className="subscibe-button-main-header">
                          <a
                            onClick={() => OnSave()}
                            classname="py-2 text-white"
                            style={{ color: "#fff", cursor: "pointer" }}
                          >
                            Plans
                          </a>
                        </li>
                      </ul>
                    </>
                  )}

                  {/* </div> */}
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>
      <MyModal show={show} handleClose={handleClose} />
    </>
  );
};
export default Header;
