// import { render } from "@testing-library/react";
import React, { useEffect, useState } from "react";
import { ImCross } from "react-icons/im";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router-dom";








const ImageUploader = (props) => {
  const togglePopup = () => {
    setIsOpen(!isOpen);
  };



  const { id } = useParams();
  const [deleteImg, setDeleteImg] = useState();
  const [Loaded, setLoaded] = useState(true);
  const [newUpload, setNewUpload] = useState([]);
  const removeItem = () => localStorage.removeItem("propertyId");

  const { state } = useLocation();


  const setImageData = props.setImageData;
  const setVideoData = props.setVideoData;
  const imageData = props.imageData;
  const setSelectedImage = props.setSelectedImage;
  const selectedImage = props.selectedImage;

  const selectedVideo = props.selectedVideo;
  const SetSelectedVideo = props.SetSelectedVideo;
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  // const [selectedImage, setSelectedImage] = useState([]);



  // const [selectedVideo, SetSelectedVideo] = useState("");

  const [videoPreview, setVideoPreview] = useState();


  const [mediaType, setMediaType] = useState();
  const [getDropwonData, setGetDropdownData] = useState();
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const OnhideModal = () => setShow(false);

  const ImageHandler = (e) => {
    const size = parseFloat(e?.target?.files[0]?.size / (1024 * 1024)).toFixed(
      2
    );
    if (size > 10) {
      toast.error("Please select image size less than 10 MB");
      return;
    }
    if (e?.target?.files) {
      const fileArray = Array.from(e?.target?.files).map((file) =>
        URL.createObjectURL(file)
      );
      const fileImage = Array.from(e?.target?.files).map((file) => file);


      if (fileArray?.length > 20) {
        toast.error("You Can Maximum 20 Images Send");
        return;
      }

      setSelectedImage((prevImages) => prevImages.concat(fileArray));
      setImageData((prevImages) => prevImages.concat(fileImage));
      Array.from(e?.target?.files).map((file) => URL.revokeObjectURL(file));
    }
  };
  const VideoHandler = (e) => {
    const videoSize = parseFloat(
      e?.target?.files[0]?.size / (1024 * 1024)
    ).toFixed(20);
    if (videoSize > 50) {
      toast.error("Please select Video size less than 50 MB");
      return;
    }
    SetSelectedVideo(URL.createObjectURL(e?.target?.files[0]));
    setVideoData(e?.target?.files[0]);
    setVideoPreview(e?.target?.files[0]);
  };
  const renderPhotos = (source) => {
    return source.map((photo) => {
      return (
        <>
          <div className="previewimgmuploader">
            <img src={photo} key={photo} />

            <span
              className="services-add-preview"
              onClick={() => onRemove(photo)}
            >
              <ImCross className="cross-icon-service" />
            </span>
          </div>
        </>
      );
    });
  };
const renderVideo = (source) => {
    
      return (
        <>
          <div className="previewimgmuploader">
                <iframe
                  src={source}
                  style={{ width: "250px", height: "200px" }}
                ></iframe>
                <span
                  className="services-add-preview"
                  onClick={OnVideoRemove}
                >
                  <ImCross className="cross-icon-service" />
                </span>
              </div>
        </>
      );
    
  };
  const onRemove = (val) => {
    let arr = [...selectedImage];

    const Index = arr.indexOf(val);
    if (Index > -1) {
      arr.splice(Index, 1);
    }
    setSelectedImage(arr);
  };

  const OnVideoRemove = () => {
    SetSelectedVideo();
    setVideoData();
  };
  // images post API
  const ImageSubmit = (PropTypeId) => {
    setLoading(true);
    imageData?.map((item) => {



    });
  };

  return (
    <>
      <div className="agent-row margin-re-top">
        <div className="container">
          <h1
            style={{
              width: "100%",
              margin: "0 auto",
              textAlign: "center",
              marginBottom: "20px",
            }}
          >
            Upload Property Images
          </h1>
          <div className="col-lg-12">

          </div>

          <div className="col-lg-12">
            <div className="card mb-6 header-r-logout">
              <div className="col-sm-4">
                <h2 className="upload-h2-re">upload images</h2>
                <div className="edit-prfl btn-sub">
                  Upload Images
                  <input
                    type="file"
                    className="input-file-profile"
                    multiple
                    accept="image/*"
                    onChange={ImageHandler}
                  ></input>
                </div>
                <p>Photos must be JPEG or PNG format and Max Size 2MB</p>
              </div>
            </div>
            <div className="card mb-6 header-r-logout">
              <div className="col-sm-4 mt-2">
                <h2 className="upload-h2-re">upload video</h2>
                <div className="edit-prfl btn-sub">
                  Upload Video
                  <input
                    type="file"
                    name="name"
                    className="input-file-profile"
                    accept="video/*"
                    onChange={VideoHandler}
                    onClick={togglePopup}
                  ></input>
                </div>

                <p>Video must be mp4 format and Max Size 20MB</p>
              </div>
            </div>

            <div className="card mb-6 header-user-icon">
              <div className="card-body p-6">
                <div className="row">
                  <div class="col-lg-6">

                    <div class="card mb-6">
                      <div class="card-body p-6">
                        <h3 class="card-title mb-0 text-heading fs-22 lh-15">
                          Upload Images for your property
                        </h3>
                        <div class="dropzone upload-file text-center py-5">
                          <div class="dz-default dz-message">
                            <span class="upload-icon lh-1 d-inline-block mb-4">
                              <i class="fal fa-cloud-upload-alt"></i>
                            </span>
                            <p class="text-heading fs-22 lh-15 mb-4">
                              Browse Property Gallery Images
                            </p>
                            <div className="edit-prfl btn-sub">
                              Upload Images
                              <input
                                type="file"
                                className="input-file-profile"
                                multiple
                                accept="image/*"
                                onChange={ImageHandler}
                              ></input>
                            </div>
                            <p>
                              Photos must be JPEG or PNG format and Max Size 2MB
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="card mb-6">
                      <div class="card-body p-6">
                        <h3 class="card-title mb-0 text-heading fs-22 lh-15">
                          Upload Video for your property
                        </h3>

                        <div class="dropzone upload-file text-center py-5">
                          <div class="dz-default dz-message">
                            <span class="upload-icon lh-1 d-inline-block mb-4">
                              <i class="fal fa-cloud-upload-alt"></i>
                            </span>
                            <p class="text-heading fs-22 lh-15 mb-4">
                              Browse Your Video Content
                            </p>
                            <div className="edit-prfl btn-sub">
                              Upload Video
                              <input
                                type="file"
                                name="name"
                                className="input-file-profile"
                                accept="video/*"
                                onChange={VideoHandler}
                                onClick={togglePopup}
                              ></input>
                            </div>

                            <p>Video must be mp4 format and Max Size 20MB</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="finalresult">
            {selectedImage ? renderPhotos(selectedImage) : null}
          </div>
          <div className="finalresult">
            {selectedVideo?.length > 0 ? renderVideo(selectedVideo) : null}
          </div>


        </div>
      </div>

    </>
  );
};
export default ImageUploader;
