import React, { useEffect, useState } from "react";
import AutoComplete from "react-google-autocomplete";
import { useForm } from "react-hook-form";
import { useLocation, useParams } from "react-router-dom";
import dummycompanyimage from "../images/dpf-logo.png";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../interceptor/axiosInstance";
import { toast } from "react-toastify";
import Loader from "../Loader";
import PhoneInput from "react-phone-number-input";
const AddEditCompany = () => {
  const locatedData = useLocation();
  const isEdit = locatedData?.state?.isEdit;


  const user_id = localStorage.getItem("currentUser_id");
  const API_URL = process.env.REACT_APP_API_URL;
  const my_id = useParams();

  const [place, setPlace] = useState({});
  const [UpdateData, setUpdate] = useState({});

  const [country, setCountry] = useState();
  const [postcode, setPostCode] = useState();
  const [state, setState] = useState();
  const [city, setCity] = useState();
  const [area, setArea] = useState();
  const [streetName, setStreetName] = useState();
  const [companyInfo, setCompanyInfo] = useState();
  const [companyimage, setCompanyImage] = useState("");

  const [imgFile, setImageFile] = useState("");
  const [loading, setLoading] = useState("");
  const [myPhone, setMyphone] = useState('');
  const { register, handleSubmit, setValue } = useForm();
  const [myMobile, setMyMobile] = useState('');
  const handleInput = (e) => {
    const name = e?.target?.name;
    const value = e?.target?.value;

    setUpdate({
      ...UpdateData,
      [name]: value,
      street_address: streetName,
      country: country,
      city: city,
      post_code: postcode,
      state: state,
      user: user_id,

    });
  };
  const handlephone = (e) => {
    setMyphone(e)
    setUpdate({
      ...UpdateData,
      office_number: e,
    });
  }
  const handleMobile = (e) => {
    setMyMobile(e)
    setUpdate({
      ...UpdateData,
      mobile_number: e,
    });
  }
  useEffect(() => {
    async function propCountry() {
      if (place?.formatted_address) {
        setValue("street_address", place?.formatted_address);
        setStreetName(place?.formatted_address);
        setUpdate({ ...UpdateData, street_address: place?.formatted_address });
      }
      if (place) {
        setValue("lat", place?.geometry?.location.lat());
        setValue("lng", place?.geometry?.location.lng());
      }

      const res = await place?.address_components?.map((item) => {
        if (item?.types[0] === "country") {
          setValue("country", item["long_name"]);
          setCountry(item["long_name"]);
          setUpdate({ ...UpdateData, country: item["long_name"] });
        }
        if (item?.types[0] === "postal_code") {
          setValue("post_code", item["long_name"]);
          setPostCode(item["long_name"]);
          setUpdate({ ...UpdateData, post_code: item["long_name"] });
        }
        if (item?.types[0] === "administrative_area_level_1") {
          setValue("state", item["long_name"]);
          setState(item["long_name"]);
          setUpdate({ ...UpdateData, state: item["long_name"] });
        }
        if (item?.types[0] === "administrative_area_level_3") {
          setValue("city", item["long_name"]);
          setCity(item["long_name"]);
          setUpdate({ ...UpdateData, city: item["long_name"] });
        }
        if (item?.types[0] === "locality") {
          setValue("area", item["long_name"]);
          setArea(item["long_name"]);
          setUpdate({ ...UpdateData, area: item["long_name"] });
        }
      });
    }
    propCountry();
  }, [place, setValue]);

  // Update Company Data
  const [compdata, setCompData] = useState("");
  const getData = async () => {
    setLoading(true)
    axiosInstance
      .get(`${API_URL}/company/${my_id.id}/patch/`)
      .then((response) => {
        const data = response.data;
        setCompData(data);
        const fieldsToSet = [
          "company_name",
          "company_website",
          "company_description",
          "orn_number",
          "dld_permit_number",
          "office_number",
          "mobile_number",
          "street_address",
          "country",
          "state",
          "city",
          "post_code",
          "img",

        ];
        fieldsToSet.forEach((fieldName) => {
          setValue(fieldName, data[fieldName]);
        });
        setLoading(false)
        setMyphone(response.data?.office_number);
        setMyMobile(response.data?.mobile_number);
      })
      .catch((error) => {

        setLoading(false)
        toast.error(' Please Contact Administrator');
      });
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (isEdit) {
      getData();
    }
  }, [isEdit]);

  const onSubmit = async () => {
    if (imgFile) {
      UpdateData["img"] = imgFile;
    }
    UpdateData["street_address"] = streetName;
    UpdateData["country"] = country;
    UpdateData["state"] = state;
    UpdateData["city"] = city;
    UpdateData["post_code"] = postcode;
    UpdateData["area"] = area;
    UpdateData["lat"] = place?.geometry?.location.lat();
    UpdateData["lng"] = place?.geometry?.location.lng();

    setLoading(true)
    try {
      if (isEdit) {
        const response = await axiosInstance.put(
          `${API_URL}/company/${my_id.id}/update/`,
          UpdateData
        );

        toast.success("Data Updated Successfully");
        setLoading(false);
        navigate('/mycompany');
      } else {
        const response = await axiosInstance.post(`${API_URL}/company/post/`, UpdateData);


        toast.success("Data Updated Successfully");
        setLoading(false);
        navigate('/mycompany');
      }

    } catch (error) {

      setLoading(false);
      toast.error(' Please Contact Administrator');
    }
  };

  const PorfileImgHandler = (e) => {
    const size = parseFloat(e?.target?.files[0]?.size / (1024 * 1024)).toFixed(20);
    if (size > 20) {
      toast.error("Please select an image size less than 20 MB");
      return;
    }
    setCompanyImage(URL.createObjectURL(e?.target?.files[0]));
    setImageFile(e?.target?.files[0]);
    setUpdate({ ...UpdateData, img: e?.target?.files[0] });
  };


  return (
    <>
      {loading ? <Loader /> : null}
      <main id="content" className="bg-gray-01">
        <div className="px-3 px-lg-6 px-xxl-13 py-5 py-lg-10">
          <div className="mb-6">
            <h1 className="mb-0 text-heading fs-22 lh-15">My Company</h1>
          </div>

          <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data" key={user_id}>
            <div className="row mb-4">
              <div className="col-lg-12">
                <div className="card mb-3">
                  <div className="card-body px-4 pt-4 pb-3">
                    <h3 className="card-title mb-3 text-heading fs-22 lh-15">
                      Company Details
                    </h3>
                    <div className="row">
                      <div className="col-lg-4 mb-6">
                        <h3 className="card-title mb-0 text-heading fs-22 lh-15">
                          Photo
                        </h3>
                        <p className="card-text">Upload your Company photo.</p>

                        {companyimage ? (
                          <img
                            src={companyimage}
                            name="img"
                            alt="image"
                            className="w-100 add-border-other"
                          />
                        ) : compdata.img ? (
                          <img
                            src={compdata.img}
                            alt="image"
                            className="w-100 add-border-other"
                          />
                        ) : (
                          <img
                            src={dummycompanyimage}
                            alt="image"
                            className="w-100 add-border-other"
                          />
                        )}
                        <div className="custom-file mt-4 h-auto">
                          <input
                            type="file"
                            className="custom-file-input add-pstion-abs"
                            id="customFile"
                            name="img"
                            accept="image/*"
                            onChange={PorfileImgHandler}
                          />
                          <label
                            className="btn bg-blue text-white hover-saffron btn-lg btn-block add-pd-label-uploader mt-3"
                            htmlFor="customFile"
                          >
                            <span className="d-inline-block">
                              <i className="fal fa-cloud-upload"></i>
                            </span>
                            Upload company image
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="form-row mx-n4">
                      <div className="form-group col-md-6 px-4">
                        <label htmlFor="companyName" className="text-heading margin-b-0">
                          Company Name <span className="star-mandatoryfield">*</span>
                        </label>
                        <input
                          type="text"
                          id="company_name"
                          name="company_name"
                          className="form-control form-control-lg border-0"
                          value={companyInfo}
                          {...register("company_name")}
                          onChange={handleInput}
                        />
                      </div>
                      <div className="form-group col-md-6 px-4">
                        <label htmlFor="twitter" className="text-heading margin-b-0 mt-2">
                          Search Address
                        </label>
                        <AutoComplete
                          apiKey={"AIzaSyDZSiGDJcQdFDZqQU5ONXF7dGkoKQePszo"}
                          onPlaceSelected={(place) => {
                            setPlace(place);
                          }}
                          className="form-control form-control-lg border-0"
                          placeholder="Search Address..."
                          onKeyDown={(event) => {
                            if (event?.keyCode === 13) {
                              event?.preventDefault();
                            }
                          }}
                          options={{
                            fields: [
                              "address_components",
                              "geometry",
                              "place_id",
                              "formatted_address",
                            ],
                            types: ["establishment"],
                            libraries: ["places"],
                          }}
                        />
                      </div>
                      <div className="form-group col-md-6 px-4">
                        <label htmlFor="website" className="text-heading">
                          Website
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg border-0"
                          id="company_website"
                          name="company_website"
                          placeholder="https://google.com"
                          {...register("company_website")}
                          onChange={handleInput}
                        />
                      </div>
                      <div className="form-group col-md-6 px-4">
                        <label htmlFor="country" className="text-heading">
                          Country
                        </label>
                        <input
                          className="form-control form-control-lg border-0"
                          id="country"
                          type="text"
                          name="country"
                          {...register("country")}
                          onChange={handleInput}
                        />
                      </div>
                      <div className="form-group col-md-6 px-4">
                        <label htmlFor="phone" className="text-heading margin-b-0">
                          Office Number <span className="star-mandatoryfield">*</span>
                        </label>
                        {/* <input
                          type="text"
                          className="form-control form-control-lg border-0"
                          id="office_number"
                          name="office_number"
                          placeholder="Enter 10 Digit Office Number"
                          format="+971 5# ###-####"

                          {...register("office_number")}
                          onChange={handleInput}
                        /> */}
                         <PhoneInput

className="form-control form-control-lg border-0 mb-2 d-flex"
international

defaultCountry="AE"
value={myPhone}
onChange={handlephone}

/>
                      </div>
                      <div className="form-group col-md-6 px-4">
                        <label htmlFor="street_address" className="text-heading">
                          Street Address
                        </label>
                        <input
                          className="form-control form-control-lg border-0"
                          id="street_address"
                          type="text"
                          name="street_address"
                          {...register("street_address")}
                          onChange={handleInput}
                        />
                      </div>
                      <div className="form-group col-md-6 px-4">
                        <label htmlFor="phone" className="text-heading margin-b-0">
                          Mobile Number <span className="star-mandatoryfield">*</span>
                        </label>
                        <PhoneInput

className="form-control form-control-lg border-0 mb-2 d-flex"
international

defaultCountry="AE"
value={myMobile}
onChange={handleMobile}

/>
                        {/* <input
                        type="text"
                        className="form-control form-control-lg border-0"
                        id="mobile_number"
                        name="mobile_number"
                        
                        placeholder="Enter 10 Digit Mobile Number"
                        format="+971 5# ###-####"
                        {...register("mobile_number")}
                        onChange={handleInput}
                      /> */}
                      </div>
                      <div className="form-group col-md-6 px-4">
                        <label htmlFor="city" className="text-heading">
                          City
                        </label>
                        <input
                          className="form-control form-control-lg border-0"
                          id="city"
                          type="text"
                          name="city"
                          {...register("city")}
                          onChange={handleInput}
                        />
                      </div>
                      <div className="form-group col-md-6 px-4">
                        <label htmlFor="twitter" className="text-heading">
                          ORN Number
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg border-0"
                          id="orn_number"
                          name="orn_number"
                          {...register("orn_number")}
                          onChange={handleInput}
                        />
                      </div>
                      <div className="form-group col-md-6 px-4">
                        <label htmlFor="state" className="text-heading">
                          State
                        </label>
                        <input
                          className="form-control form-control-lg border-0"
                          id="state"
                          type="text"
                          name="state"
                          {...register("state")}
                          onChange={handleInput}
                        />
                      </div>
                      <div className="form-group col-md-6 px-4">
                        <label htmlFor="dldnumber" className="text-heading">
                          DLD Permit Number
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg border-0"
                          id="dld_permit_number"
                          name="dld_permit_number"
                          {...register("dld_permit_number")}
                          onChange={handleInput}
                        />
                      </div>
                      <div className="form-group col-md-6 px-4">
                        <label htmlFor="postcode" className="text-heading">
                          Post Code
                        </label>
                        <input
                          className="form-control form-control-lg border-0"
                          id="post_code"
                          type="text"
                          name="post_code"
                          {...register("post_code")}
                          onChange={handleInput}
                        />
                      </div>

                    </div>
                  </div>
                  <div className=" mb-6">
                    <div className=" p-6">
                      <h3 className="card-title mb-0 text-heading fs-22 lh-15">
                        Description
                      </h3>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label htmlFor="category" className="text-heading margin-b-0">
                              Describe Details
                              <span className="star-mandatoryfield"></span>
                            </label>
                            <textarea
                              rows="10"
                              name="company_description"
                              id="company_description"
                              className="form-control form-control-lg border-0"
                              {...register("company_description")}
                              onChange={handleInput}
                            // value={multipleInput?.description}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end flex-wrap">
              <button
                type="submit"
                className="btn btn-lg bg-blue text-white hover-saffron ml-4 mb-3"
              >
                Update Company
              </button>
            </div>
          </form>
        </div>
      </main>
    </>
  );
};

export default AddEditCompany;
