import React, { useEffect, useState } from "react";
import Header from "../../header/Header";
import Footer from "../../footer/Footer";
import FSImage from "../../images/bg-title-3.jpg";
import BackImage from "../../images/about-shape.png";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import _ from 'lodash';
import { AnimationOnScroll } from "react-animation-on-scroll";

import axios from "axios";
import Loader from "../../Loader";
import { Link } from "react-router-dom";
import CallActionUser from "./CallActionUser";
import { toast } from "react-toastify";
const API_URL = process.env.REACT_APP_API_URL;
const Businessfinder = () => {
  const [businessType, setBusinessType] = useState("Select Business Type");
  const [showNationality, setShowNationality] = useState(true);
  const [nationalityData, setnationalityData] = useState([]);
  const [selectednationality, setSelectednationality] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [showme,setshowme] = useState(false);

  const [loading, setLoading] = useState(false);
  const [UserData, setUserData] = useState([]);

  const [agentdata, setagentdata] = useState([]);
// const nationalityuser =( _.uniqBy(agentdata, "get_nationlity_name"));

  const filteredUserData = selectednationality
    ? agentdata.filter((user) => user.nationality === selectednationality)
    : agentdata;

  const filteredUsers = filteredUserData.filter((item) =>
    item.first_name.toLowerCase().includes(searchInput.toLowerCase())
  );
  const filteredCompanies = UserData.slice(0, 20).filter((item) =>
    item.company_name.toLowerCase().includes(searchInput.toLowerCase())
  );

  // Function to handle Business Type change
  const handleBusinessTypeChange = (e) => {
    const selectedBusinessType = e.target.value;

    setBusinessType(selectedBusinessType);

    // Update the visibility of the Nationality field
    if (selectedBusinessType === "Agents") {
      setShowNationality(true);
      setshowme(false)
    } else if (selectedBusinessType === "Developers") {
      setShowNationality(false);
      setshowme(false);
    }
    else{
      setshowme(true);
      setShowNationality(false);
    }
  };

  // Api For Getting Nationality
  const getnationality = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_URL}/account/only/user/nationality/`);
      if (response.status === 200) {
        const responseData = response.data;
        setnationalityData(responseData);
        setLoading(false);
      } else {
        toast.error(" Please Contact Administrator");
      }
    } catch (err) {
      toast.error(" Please Contact Administrator");
    }
  };
  /* ****************api for Company list ************* */

  const getAllCompany = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_URL}/company/get/all/`);
      if ((response.status = 200)) {
        const responseData = response.data;
        setUserData(responseData);
        setLoading(false);
      } else {
        toast.error(" Please Contact Administrator");
      }
    } catch (err) {
      toast.error(" Please Contact Administrator");
    }
  };
  /* ****************api for User list ************* */

  const getAllUser = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_URL}/account/get/filter/user/?is_agent=true`);
      if (response.status === 200) {
        const responseData = response.data;
       
        setagentdata(responseData);
        
        setLoading(false);
      } else {
        toast.error(" Please Contact Administrator");
      }
    } catch (err) {
      toast.error(" Please Contact Administrator");
    }
  };

  // Otherv User
  const [OtherUserRole, setOtherUserRole] = useState([]);
  const getOtheruser = () => {
    axios
      .get(`${API_URL}/account/get/other/user/permission/`)
      .then((response) => {
        // Handle the data
        
        if (response.data) {
          setOtherUserRole(response.data);
        }
      })
      .catch((error) => {
        // Handle any errors
       
      });
  };

  useEffect(() => {
    getnationality();
    getAllUser();
    getAllCompany();
    getOtheruser();
  }, [selectednationality, searchInput]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      {loading ? <Loader /> : null}
      <Header />
      <main id="content">
        <div style={{ backgroundImage: `url(${FSImage})` }}>
          <section className="op-8 bg-business position-relative pb-15 pt-2 page-title  ">
            <div className="container mt-5">
              <AnimationOnScroll animateIn="animate__zoomIn">
                <h1
                  className="fs-32 mb-0 text-white font-weight-600 text-center pt-11 mb-5 lh-17  mt-set-finder-s-banner-heading"
                  data-animate="fadeInDown"
                >
                  Find Developers & Agents & other Businesses that are
                  transforming Real Estate.
                </h1>
                <p className="fs-18 text-white text-center fsfinder-text">
                  (Builders/Developers, Agents/Brokers, Architects, Interior
                  Designers, Lawyers, Property Management Companies,
                  Construction Contractors, Planners, Surveyors, Banks/financial
                  institutions/Mortgage brokers/Private lenders, Professional
                  Photograhers)
                </p>
              </AnimationOnScroll>
            </div>
          </section>
        </div>

        <section>
          <div className="container">
            <div className="shadow-sm mt-n8 bg-white px-6 py-3 shadow-sm-2 rounded-lg form-search-02 position-relative z-index-3">
              <form className=" row d-md-flex flex-wrap align-items-center">
                <div className="col-12">
                  <div className="row">
                    <div className="col-10">
                      <div className="row">
                        <div className="s-m form-group mb-3 mb-md-0 col-3 mx-w-inherit">
                          <label
                            htmlFor="Type"
                            className="mb-0 lh-1 text-uppercase fs-14 letter-spacing-093"
                          >
                            Business Type
                          </label>
                          <select
                            className="form-control border-0 shadow-none form-control-lg selectpicker add-adjustable-heights bg-transparent-to-color"
                            name="typeof"
                            id="typeof"
                            data-style="bg-white pl-0 text-dark rounded-0"
                            onChange={handleBusinessTypeChange}
                            defaultValue=""
                          >
                            <option>Select Business Type</option>
                            {OtherUserRole.map((item) => (
                              <option
                                key={item?.permission}
                                value={item?.permission}
                              >
                                {item?.permission}
                              </option>
                            ))}
                            {/* <option value={"company"}>Company</option> */}
                          </select>
                        </div>
                        {showNationality && (
                          <div className="s-m form-group mb-3 mb-md-0 col-3 mx-w-inherit">
                            <label
                              htmlFor="region"
                              className="mb-0 lh-1 text-uppercase fs-14 letter-spacing-093"
                            >
                              Nationality
                            </label>
                            <OverlayTrigger
                              overlay={
                                <Tooltip id="tooltip-disabled">
                                  Select Business Type First
                                </Tooltip>
                              }
                            >
                              <select
                                className="form-control border-0 shadow-none form-control-lg selectpicker add-adjustable-heights"
                                title="Select"
                                data-style="btn-lg py-2 h-52"
                                classNamePrefix="select-inner"
                                onChange={(e) =>
                                  setSelectednationality(e.target.value)
                                }
                                disabled={
                                  !businessType ||
                                  businessType === "Select Business Type"
                                }
                              >
                                <option disabled selected>
                                  Select Nationality
                                </option>
                                {nationalityData.map((item, index) => (
                                  <option key={item.id} value={item.id}>
                                    {item.national}
                                  </option>
                                ))}
                              </select>
                            </OverlayTrigger>
                          </div>
                        )}
                        <div className="s-m form-group mb-3 mb-md-0 col-6 mx-w-inherit">
                          <label
                            htmlFor="search"
                            className="mb-0 lh-1 text-uppercase fs-14 letter-spacing-093"
                          >
                            Search
                          </label>
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="tooltip-disabled">
                                Select Business Type First
                              </Tooltip>
                            }
                          >
                            <input
                              type="text"
                              id="search"
                              className="form-control form-control-lg border-2 bg-gray"
                              name="search"
                              placeholder="Enter Name"
                              value={searchInput}
                              onChange={(e) => setSearchInput(e.target.value)}
                              disabled={
                                !businessType ||
                                businessType === "Select Business Type"
                              }
                            />
                          </OverlayTrigger>
                        </div>
                      </div>
                    </div>
                    <div className="s-m form-group mb-3 mb-md-0 col-2 mx-w-inherit">
                      <button
                        type="button"
                        style={{ marginTop: "27px" }}
                        className="btn mr-1 bg-blue text-white hover-grey width-re-10"
                      >
                        <span className="fs-18  rounded-0">
                          <i className="fal fa-search"></i>
                        </span>
                      </button>
                      <a
                        href="/businessfinder"
                        style={{ marginTop: "27px", padding: "8px 15px" }}
                        className="btn advanced-search bg-saffron text-white hover-grey width-re-10"
                      >
                        <img src="images/reload.png" alt="Reload" />
                      </a>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
        <section
          className="pt-12 pb-13"
          style={{ backgroundImage: `url(${BackImage})` }}
        > {!showme ?
          <div className="container">
            {showNationality && (
              <div className="mb-10">
                {filteredUsers.length > 0 ? (
                  <>
                    <h2 className="text-heading mb-5">Featured Agents</h2>

                    <div className="row">
                      {filteredUsers.map((item) => (
                        <div
                          key={item.id}
                          className="col-lg-6 mb-2 "
                          data-animate="fadeInUp"
                        >
                          <div className="" data-animate="fadeInUp">
                            <div style={{ cursor: "pointer" }}>
                              <div className="">
                                <div
                                  className="row border-c  round-5 mb-5  "
                                  style={{
                                    margin: "0px",
                                    backgroundColor: "#F7F7F7",
                                    borderColor: "#D3D3D3",
                                  }}
                                >
                                  <div
                                    className="col-lg-5 col-md-4  "
                                    style={{ padding: "0px" }}
                                  >
                                    <Link to={`/profile/details/${item?.id}`}>
                                      <div className=" ">
                                        <img
                                          style={{
                                            height: "250px",
                                            width: "100%",
                                            borderRadius: " 5px 0 0 5px",
                                          }}
                                          src={item?.profile}
                                          alt="Profile"
                                        />
                                      </div>
                                    </Link>
                                  </div>

                                  <div
                                    className="col-lg-7 col-md-6 px-2"
                                    style={{ padding: "0px" }}
                                  >
                                    <h2 className="mt-2">
                                      <Link
                                        className="fs-20 text-dark"
                                        to={`/profile/details/${item?.id}`}
                                      >
                                        {item?.first_name.toUpperCase()}{" "}
                                        {item?.last_name.toUpperCase()}
                                      </Link>
                                    </h2>
                                    <Link to={`/profile/details/${item?.id}`}>
                                      {item.user_role ? (
                                        <span className="badge badge-orange fs-14">
                                          {item?.user_role}
                                          <i class="bi bi-star-fill"></i>
                                        </span>
                                      ) : null}
                                      {item.company_name ? (
                                        <p className="text-dark fs-15 m-0 lh-1.5">
                                          <span className="fs-14 font-weight-600">
                                            {" "}
                                            Company:{" "}
                                          </span>
                                          {item?.company_name}
                                        </p>
                                      ) : null}
                                      {item.count_property ? (
                                        <p className="text-blue fs-15 m-0 lh-1.5">
                                          <span className="fs-14 font-weight-600">
                                            Listed Properties(
                                            {item?.count_property}){" "}
                                          </span>
                                        </p>
                                      ) : null}

                                      {item.get_language_name.length > 0 ? (
                                        <p className="text-dark fs-15 m-0">
                                          <span className="fs-14 font-weight-600">
                                            {" "}
                                            Language:{" "}
                                          </span>
                                          {item.get_language_name.join(", ")}
                                        </p>
                                      ) : null}
                                      {item.get_nationlity_name ? (
                                        <p className="text-dark  fs-15 m-0 ">
                                          <span className="fs-14 font-weight-600">
                                            {" "}
                                            Nationality:{" "}
                                          </span>
                                          {item?.get_nationlity_name}
                                        </p>
                                      ) : null}
                                    </Link>
                                    <CallActionUser item={item} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                ) : (
                  <h2>No Data Found</h2>
                )}
              </div>
            )}
            {!showNationality && (
              <div className="mb-10">
                {filteredCompanies.length > 0 ? (
                  <>
                    <h2 className="text-heading mb-5">Featured Company</h2>

                    <div className="row">
                      {filteredCompanies.map((item) => (
                        <div
                          key={item.id}
                          className="col-lg-6 mb-2 "
                          data-animate="fadeInUp"
                        >
                          <div className="" data-animate="fadeInUp">
                            <div style={{ cursor: "pointer" }}>
                              <div className="">
                                <div
                                  className="row border-c  round-5 mb-5  "
                                  style={{
                                    margin: "0px",
                                    backgroundColor: "#F7F7F7",
                                    borderColor: "#D3D3D3",
                                  }}
                                >
                                  <div
                                    className="col-lg-5 col-md-4 "
                                    style={{ padding: "0px" }}
                                  >
                                    <Link to={`/company/details/${item?.slug}`}>
                                      <div className="">
                                        <img
                                          style={{
                                            height: "250px",
                                            borderRadius: " 5px 0 0 5px",
                                          }}
                                          src={item?.img}
                                          alt="Company"
                                        />
                                      </div>
                                    </Link>
                                  </div>
                                  <div
                                    className="col-lg-7 col-md-6 px-2"
                                    style={{ padding: "0px" }}
                                  >
                                    <Link to={`/company/details/${item?.slug}`}>
                                      {item.company_name ? (
                                        <h2 className="mt-2">
                                          <Link
                                            to={`/company/details/${item?.slug}`}
                                            className="fs-18 text-dark"
                                          >
                                            {item?.company_name}
                                          </Link>
                                        </h2>
                                      ) : null}

                                      {item?.street_address ? (
                                        <p className="text-blue  fs-15">
                                          <img
                                            src="images/locations.png"
                                            className="icon icon-bedroom fs-15 width-32 mr-2"
                                            alt="Location"
                                          />
                                          {item?.street_address}
                                        </p>
                                      ) : null}
                                      {item.count_property ? (
                                        <p className="text-saffron fs-15 m-0 lh-1.5">
                                          <span className="fs-14 font-weight-600">
                                            Listed Properties(
                                            {item?.count_property}){" "}
                                          </span>
                                        </p>
                                      ) : null}
                                      {item?.company_total_user_count ? (
                                        <p className=" text-dark  fs-15">
                                          Agents(
                                          {item?.company_total_user_count})
                                        </p>
                                      ) : null}
                                    </Link>
                                    <CallActionUser item={item} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                ) : (
                  <h2>No Data Found</h2>
                )}
              </div>
            )}
          </div>:<div className="container"><h2>No Matches Found</h2></div>}
        </section>
      </main>
      <Footer />
    </>
  );
};
export default Businessfinder;