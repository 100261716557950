import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  Paper,
  Checkbox,
  IconButton,
} from "@mui/material/";
import { Button, Dropdown, DropdownButton, Modal } from 'react-bootstrap';

import MailIcon from '@mui/icons-material/Mail';
import FilterListIcon from "@mui/icons-material/FilterList";
import axiosInstance from "../interceptor/axiosInstance";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const API_URL = process.env.REACT_APP_API_URL;

const headCells = [
  { id: "firstname", label: "Firstname" },
  { id: "lastname", label: "Lastname" },
  { id: "email", label: "Email" },
  { id: "phone", label: "Phone" },
  { id: "country", label: "Country" },
];

const EnhancedTableToolbar = ({ numSelected, handleOptionClick, selectedOption }) => (
  <Toolbar>
    {numSelected > 0 &&
    <>
      <div>
        <DropdownButton
          title="Actions"
          className=" bg-blue text-white hover-saffron"
        >
          <Dropdown.Item onClick={() => handleOptionClick("Update")}>Upload</Dropdown.Item>
          <Dropdown.Item onClick={() => handleOptionClick("Download")}>Download</Dropdown.Item>
          {/* <Dropdown.Item >Send</Dropdown.Item> */}
        </DropdownButton>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
  <IconButton onClick={() => handleOptionClick("Send")}>
    <span className="btn bg-blue text-white hover-saffron mr-2" style={{ display: 'flex', alignItems: 'center' }}>
      <p style={{ margin: 0, marginRight: '0.5rem' }}>Send</p>
      <MailIcon />
    </span>
  </IconButton>
</div>

    </>
    }
  </Toolbar>
);

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  handleOptionClick: PropTypes.func.isRequired,
  selectedOption: PropTypes.string,
};

export default function ContactTable({ myData }) {
  console.log(myData,"this is my csv Data");

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedOption, setSelectedOption] = useState(null);
  const [show, setShow] = useState(false);
  const [sendShow, setSendShow] = useState(false);
  const [editorData, setEditorData] = useState('');

  const [dataEmail, setDataEmail] = useState('');

  const [selected, setSelected] = useState([]);
  const [formData, setFormData] = useState({
    Subject: '',
    EditorData: '',    
  });

  console.log(selected, "selected Data Has Been printed !!");

  useEffect(() => {
    setDataEmail(selected);
  }, [selected, dataEmail]);

  const jsonData = JSON.stringify(selected);


  const OnEmailSend = () => {
  
    axiosInstance
      .post(`${API_URL}/track/send/email/`, {
        subject: formData.Subject,
        body: formData.EditorData,
        emailData: jsonData,
      })
      .then((response) => {
        toast.success("Your Contact Details Have Been Successfully Sent!");
      })
      .catch((error) => {
        // Handle error
      });
  };
  


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleSendClose = () => setSendShow(false);
  const handleSendShow = () => setSendShow(true);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    if (option === 'Download') {
      downloadCSV(myData?.email_data);
    }
    if (option === 'Update') {
      setShow(true);
    }
    if (option === 'Send') {
      setSendShow(true);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
        const jsonData = e.target.result;
        console.log('Selected file:', jsonData);
        editData(myData?.id, jsonData); 
    };
    reader.readAsText(file);
  };

  const handleSelectClick = (event, row) => {
   
    const selectedIndex = selected.findIndex(selectedRow => selectedRow === row);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = [...selected, row];
    } else {
      newSelected = selected.filter((item) => item !== row);
    }

    setSelected(newSelected);
    setFormData({ ...formData, selected: newSelected });
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = [...myData?.email_data];
      setSelected(newSelected);
      setFormData({ ...formData, selected: newSelected });
    } else {
      setSelected([]);
    }
  };


console.log(selected,"this is selected")  

  const isSelected = (row) => selected.indexOf(row) !== -1;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, myData?.email_data.length - page * rowsPerPage);

  const convertToCSV = (data) => {
    const csvRows = [];
    const headers = Object.keys(data[0]);
    csvRows.push(headers.join(','));

    for (const row of data) {
      const values = headers.map(header => {
        const escaped = ('' + row[header]).replace(/"/g, '\\"');
        return `"${escaped}"`;
      });
      csvRows.push(values.join(','));
    }

    return csvRows.join('\n');
  };

  const downloadCSV = (data) => {
    const csv = convertToCSV(data);
    const csvBlob = new Blob([csv], { type: 'text/csv' });
    const url = window.URL.createObjectURL(csvBlob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'mydata.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  const editData = (id, jsonData) => {
    console.log(id, "this is edit id");

    // Send a PUT request to the API using Axios
    axiosInstance.put(`${API_URL}/track/update/${id}/email/`, { email_data: jsonData })
        .then(response => {
            console.log('PUT request succeeded with response:', response.data);
            // Handle the response data as needed
        })
        .catch(error => {
            console.error('Error making PUT request:', error);
            // Handle errors
        });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setFormData({
      ...formData,
      EditorData: data
    });
  };

  return (
    <Paper sx={{ width: "100%", mb: 2 }}>
      <EnhancedTableToolbar
        numSelected={selected.length}
        handleOptionClick={handleOptionClick}
        selectedOption={selectedOption}
      />
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size="medium"
        >
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  indeterminate={selected.length > 0 && selected.length < myData?.email_data.length}
                  checked={myData?.email_data.length > 0 && selected.length === myData?.email_data.length}
                  onChange={handleSelectAllClick}
                  inputProps={{
                    "aria-label": "select all contacts",
                  }}
                />
              </TableCell>
              {headCells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  align="left"
                  padding="normal"
                >
                  {headCell.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {myData?.email_data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                const isItemSelected = isSelected(row);
                const labelId = `contact-table-checkbox-${row}`;

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleSelectClick(event, row)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row?.firstname}
                    selected={isItemSelected}
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{ "aria-labelledby": labelId }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="normal"
                    >
                      {row.firstname}
                    </TableCell>
                    <TableCell>{row.lastname}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.phone}</TableCell>
                    <TableCell>{row.country}</TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={myData?.email_data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        
      />
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Upload CSV</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="file-input">
            <label htmlFor="file-upload">
              <Button>Choose File</Button>
            </label>
            <input
              id="file-upload"
              type="file"
              name="file"
              className="input-file-profile"
              accept=".csv"
              onChange={handleFileChange}
            />
          </div>
          <p className="fs-12">File must be CSV</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="success"
            onClick={() => {
              handleClose();
              editData(myData?.id);
            }}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={sendShow} onHide={handleSendClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Send Mail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        
          <input
            type="text"
            className="form-control form-control-lg border-1 mb-2"
            id="Subject"
            name="Subject"
            placeholder="Subject"
            onChange={handleInputChange}
            required
          />
          <CKEditor
            editor={ClassicEditor}
            data={editorData}
            onChange={handleEditorChange}

          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleSendClose}>
            Close
          </Button>
          <Button
            variant="success"
            onClick={() => {
              handleSendClose();
              OnEmailSend();
              // Here you can use editorData for further processing
            }}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </Paper>
  );
}

ContactTable.propTypes = {
  myData: PropTypes.object.isRequired,
};