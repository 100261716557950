import React, { useEffect, useState } from "react";
import Header from "../../header/Header";
import Loader from "../../Loader";
import Footer from "../../footer/Footer";
import axios from "axios";
import Table from "react-bootstrap/Table";
import axiosInstance from "../../interceptor/axiosInstance";
import CurrencyFormat from "react-currency-format";
import { toast } from "react-toastify";
import Form from 'react-bootstrap/Form';
const API_URL = process.env.REACT_APP_API_URL;

const Plan = () => {


  const [loading, setLoading] = useState(false);
  const [standardTable, setstandardTable] = useState(false);
  const [professionalTable, setprofessionalTable] = useState(true);
  const [premiumTable, setpremiumTable] = useState(false);
  const [plan, setplan] = useState([]);

  const monthlyplan = plan.filter((item) => item.types === "monthly");
  const yearlyplan = plan.filter((item) => item.types === "yearly");

  const devplan = yearlyplan.filter((item) => item.is_developer === true);
  const monthdevplan = monthlyplan.filter((item) => item.is_developer === true);

  const agentplan = yearlyplan.filter((item) => item.is_agent === true);
  const monthagentplan = monthlyplan.filter((item) => item.is_agent === true);

  const otherplan = yearlyplan.filter(
    (item) => item.is_other_business === true
  );
  const monthotherplan = monthlyplan.filter(
    (item) => item.is_other_business === true
  );
  const handleStandardClick = () => {
    // Toggle the state to show/hide the table
    setprofessionalTable(false);
    setpremiumTable(false);
    setstandardTable(!standardTable);
  };
  const handleprofessionalClick = () => {
    // Toggle the state to show/hide the table
    setstandardTable(false);
    setpremiumTable(false);
    setprofessionalTable(!professionalTable);
  };
  const handlepremiumClick = () => {
    setstandardTable(false);
    setprofessionalTable(false);
    setpremiumTable(!premiumTable);
  };

  /************** Subscribe  ***************** */

  const subscribe = async (id, price, title) => {
    try {
      const response = await axiosInstance.post(
        `${API_URL}/payment/stripe/gateway/`,
        {
          id: id,
          quantity: 1,
          price: price,
          name: title,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          mode: "cors",
        }
      );

      const data = response.data;

      if (data) {
        localStorage.setItem("checkoutId", data.CheckOut?.id);
      }

      window.location = data.CheckOut.url;
    } catch (error) {
      toast.error(" Please Contact Administrator");
    }
  };

  ///// Api for Getting Plans Details
  const getPlan = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_URL}/payment/subscription/`);
      if (response.status === 200) {
        const responseData = response.data;
        setplan(responseData);
      } else {
        // throw new Error("Request failed with a non-success status");
      }
      setLoading(false);
    } catch (err) {
      toast.error(" Please Contact Administrator");
      // throw err;
    }
  };

  useEffect(() => {
    window?.scrollTo(0, 0);
    getPlan();
  }, []);

  const [showYear, setshowYear] = useState(false);

  const toggleSlider = () => {
    setshowYear(!showYear);
  };

  return (
    <>
      {loading ? <Loader /> : null}
      <Header />
      <main id="content">
        <div className="primary-content bg-f3f3f3 mt-100 mt-r-0">
          <div className="container ">
            <section className="pt-10 pb-5 ">
              <div className="col-lg-12 mb-bt-3">
              <h2 className="text-heading font-size-24 fs-md-20">
                    Choose Your Plan
                  </h2>
                  <span className="heading-divider divider-bottom"></span>
                  <p className="fs-18 text-dark">Elevate Your Real Estate Experience with Dirham Per Foot Subscription Plan! </p>
                <div className="slider-container text-md-right">
                  <Form className="toggle-form">
                    <Form.Check // prettier-ignore
                      type="switch"
                      id="custom-switch"
                      label="Check yearly Plan"
                      onClick={toggleSlider}


                    />

                  </Form>
                  {/* <a
                    onClick={toggleSlider}
                    className={`slider-btn fs-14 text-white common-border py-3 lh-15 px-7 mb-6 mb-lg-0 ${!showYear ? "active bg-saffron" : "bg-g"
                      }`}
                  >
                    Monthly
                  </a>
                  <a
                    onClick={toggleSlider}
                    className={`slider-btn fs-14 text-white rounded py-3 lh-15 px-7 mb-6 mb-lg-0 ${showYear ? "active bg-saffron" : "bg-g"
                      }`}
                  >
                    Yearly
                  </a>
                  <div
                    className={`slider ${showYear ? "left" : "right"}`}
                  ></div> */}
                </div>
              </div>

              {showYear && (
                <div className="collapse-tabs mb-10 ">
                  <ul
                    className="nav nav-tabs text-uppercase d-md-inline-flex agent-details-tabs"
                    role="tablist"
                  >
                    <li className="nav-item">
                      <a
                        href="#agent"
                        className=" text-blue nav-link active  fs-16 padding-r-5"
                        data-bs-toggle="tab"
                        role="tab"
                      >
                        Agent
                      </a>
                    </li>
                    <>
                      <li className="nav-item ml-0">
                        <a
                          href="#developer"
                          className="nav-link text-blue fs-16 padding-r-5"
                          data-bs-toggle="tab"
                          role="tab"
                        >
                          Developer
                        </a>
                      </li>

                      <li className="nav-item ml-0">
                        <a
                          href="#other"
                          className="nav-link text-blue fs-16 padding-r-5"
                          data-bs-toggle="tab"
                          role="tab"
                        >
                          Other
                        </a>
                      </li>
                    </>
                  </ul>
                  <div className="tab-content shadow-none  bg-white">
                    {/* **********agent********** */}

                    <div
                      className="tab-pane tab-pane-parent fade show active"
                      id="agent"
                      role="tabpanel"
                    >
                      <div className="col-lg-12">
                        <div className="row">
                          {agentplan.map((item, index) => (
                           <div className="col-lg-4 col-md-4 text-center mb-3">
                           <div className="border-1"
                           style={{borderRadius:"10px",

                         }}
                           >
                           <div
                             className=" p-2 fs-18 text-white bg-orange font-weight-600"
                             style={{
                               borderTopLeftRadius:"10px",
                               borderTopRightRadius:"10px",
                               boxShadow:"0px -1px 5px -1px inset"
                             }}
                           >
                             {" "}
                             {item?.title.toUpperCase()}
                           </div>
                           <div className=" bg-blue pb-3"
                            style={{
                             borderBottomLeftRadius:"10px",
                             borderBottomRightRadius:"10px"
                           }}
                           >
                             <div className="py-5 px-3 ">
                               <h2 className="mb-2 text-white ">
                                 <CurrencyFormat
                                   value={item?.pricing}
                                   displayType="text"
                                   thousandSeparator={true}
                                   suffix=".00"
                                 />{" "}
                                 AED<span className="fs-16">/yr</span>
                               </h2>
                               <p className="text-white">Discount{" "}{((item?.old_pricing-item.pricing)/item?.old_pricing)*100}%</p>
                               <h2 className="mb-0 text-white fs-18 px-10  ">
                                 <CurrencyFormat
                                   value={item?.old_pricing}
                                   displayType="text"
                                   thousandSeparator={true}
                                   className="strikethrough"
                                   suffix=".00"
                                 />{" "}
                                 AED<span className="fs-16">/yr</span>
                               </h2>
                               {/* <div
                                 dangerouslySetInnerHTML={{
                                   __html: item.details,
                                 }}
                               /> */}
                             </div>

                             {/* // <p>{item?.services}</p> */}
                             <button
                               className="rounded border-0 fs-14 bg-f3f3f3 text-dark  py-2 lh-15 px-5 mb-3 "
                               onClick={() =>
                                 subscribe(
                                   item.id,
                                   item?.pricing,
                                   item?.title
                                 )
                               }
                             >
                               Subscribe
                             </button>

                           </div>
                           </div>

                         </div>
                          ))}
                        </div>
                        <h2 className="text-center text-uppercase fs-18 text-dark mt-5 mb-5">
                          Plans
                        </h2>
                        <Table className="fs-16 table-responsive-sm table-responsive-md" bordered hover>
                          <tbody>
                            <tr className="bg-gray-01 text-dark text-uppercase font-weight-600 fs-18">
                              <td className="">Features </td>
                              <td className="text-center">Silver</td>
                              <td className="text-center">Gold</td>
                              <td className="text-center">Platinum</td>
                            </tr>
                            <tr>
                              <td>Listings Per Month </td>
                              <td className="text-center">45</td>
                              <td className="text-center">90</td>
                              <td className="text-center">120</td>
                            </tr>
                            <tr>
                              <td>Leads Per Month </td>
                              <td className="text-center">200</td>
                              <td className="text-center">500</td>
                              <td className="text-center">Unlimited</td>
                            </tr>
                            <tr>
                              <td>Placement in search results </td>
                              <td className="text-center">
                                {" "}
                                <i
                                  style={{ color: "#1A63C6" }}
                                  className="bi bi-check-circle-fill "
                                ></i>
                              </td>
                              <td className="text-center">
                                {" "}
                                <i
                                  style={{ color: "#1A63C6" }}
                                  className="bi bi-check-circle-fill "
                                ></i>
                              </td>
                              <td className="text-center">
                                {" "}
                                <i
                                  style={{ color: "#1A63C6" }}
                                  className="bi bi-check-circle-fill "
                                ></i>
                              </td>
                            </tr>
                            <tr>
                              <td>Dashboard for Leads Management</td>
                              <td className="text-center">
                                {" "}
                                <i
                                  style={{ color: "#1A63C6" }}
                                  className="bi bi-check-circle-fill "
                                ></i>
                              </td>
                              <td className="text-center">
                                {" "}
                                <i
                                  style={{ color: "#1A63C6" }}
                                  className="bi bi-check-circle-fill "
                                ></i>
                              </td>
                              <td className="text-center">
                                {" "}
                                <i
                                  style={{ color: "#1A63C6" }}
                                  className="bi bi-check-circle-fill "
                                ></i>
                              </td>
                            </tr>
                            <tr>
                              <td>Featured Listing on Homepage</td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#FE5900" }}
                                  className="bi bi-x-circle-fill"
                                ></i>
                              </td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#1A63C6" }}
                                  className="bi bi-check-circle-fill "
                                ></i>
                              </td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#1A63C6" }}
                                  className="bi bi-check-circle-fill "
                                ></i>
                              </td>
                            </tr>
                            <tr>
                              <td>Featured Agent on Homepage</td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#FE5900" }}
                                  className="bi bi-x-circle-fill"
                                ></i>
                              </td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#FE5900" }}
                                  className="bi bi-x-circle-fill"
                                ></i>
                              </td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#1A63C6" }}
                                  className="bi bi-check-circle-fill "
                                ></i>
                              </td>
                            </tr>

                            <tr>
                              <td>Homepage Banner Ad </td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#FE5900" }}
                                  className="bi bi-x-circle-fill"
                                ></i>
                              </td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#FE5900" }}
                                  className="bi bi-x-circle-fill"
                                ></i>
                              </td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#1A63C6" }}
                                  className="bi bi-check-circle-fill "
                                ></i>
                              </td>
                            </tr>
                            <tr>
                              <td>Ad Campaign Management </td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#FE5900" }}
                                  className="bi bi-x-circle-fill"
                                ></i>
                              </td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#FE5900" }}
                                  className="bi bi-x-circle-fill"
                                ></i>
                              </td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#1A63C6" }}
                                  className="bi bi-check-circle-fill"
                                ></i>
                              </td>
                            </tr>

                          </tbody>
                        </Table>
                      </div>
                    </div>

                    {/* **********dev********** */}

                    <div
                      className="tab-pane tab-pane-parent fade show "
                      id="developer"
                      role="tabpanel"
                    >
                      <div className="col-lg-12">
                        <div className="row">
                          {devplan.map((item, index) => (
                          <div className="col-lg-4 col-md-4 text-center mb-3">
                          <div className="border-1"
                          style={{borderRadius:"10px",

                        }}
                          >
                          <div
                            className=" p-2 fs-18 text-white bg-orange font-weight-600"
                            style={{
                              borderTopLeftRadius:"10px",
                              borderTopRightRadius:"10px",
                              boxShadow:"0px -1px 5px -1px inset"
                            }}
                          >
                            {" "}
                            {item?.title.toUpperCase()}
                          </div>
                          <div className=" bg-blue pb-3"
                           style={{
                            borderBottomLeftRadius:"10px",
                            borderBottomRightRadius:"10px"
                          }}
                          >
                            <div className="py-5 px-3 ">
                              <h2 className="mb-2 text-white ">
                                <CurrencyFormat
                                  value={item?.pricing}
                                  displayType="text"
                                  thousandSeparator={true}
                                  suffix=".00"
                                />{" "}
                                AED<span className="fs-16">/yr</span>
                              </h2>
                              <p className="text-white">Discount{" "}{((item?.old_pricing-item.pricing)/item?.old_pricing)*100}%</p>
                              <h2 className="mb-0 text-white fs-18 px-10  ">
                                <CurrencyFormat
                                  value={item?.old_pricing}
                                  displayType="text"
                                  thousandSeparator={true}
                                  className="strikethrough"
                                  suffix=".00"
                                />{" "}
                                AED<span className="fs-16">/yr</span>
                              </h2>
                              {/* <div
                                dangerouslySetInnerHTML={{
                                  __html: item.details,
                                }}
                              /> */}
                            </div>

                            {/* // <p>{item?.services}</p> */}
                            <button
                              className="rounded border-0 fs-14 bg-f3f3f3 text-dark  py-2 lh-15 px-5 mb-3 "
                              onClick={() =>
                                subscribe(
                                  item.id,
                                  item?.pricing,
                                  item?.title
                                )
                              }
                            >
                              Subscribe
                            </button>

                          </div>
                          </div>

                        </div>
                          ))}
                        </div>
                        <h2 className="text-center text-uppercase fs-18 text-dark mt-5 mb-5">
                          Plans
                        </h2>
                        <Table className="fs-16 table-responsive-sm table-responsive-md" bordered hover>
                          <tbody>
                            <tr className="bg-gray-01 text-dark text-uppercase font-weight-600 fs-18">
                              <td>Features </td>
                              <td className="text-center">Silver</td>
                              <td className="text-center">Gold</td>
                              <td className="text-center">Platinum</td>
                            </tr>
                            <tr>
                              <td>Listings Per Month </td>
                              <td className="text-center">30</td>
                              <td className="text-center">60</td>
                              <td className="text-center">90</td>
                            </tr>
                            <tr>
                              <td>Leads Per Month </td>
                              <td className="text-center">120</td>
                              <td className="text-center">300</td>
                              <td className="text-center">Unlimited</td>
                            </tr>
                            <tr>
                              <td>Placement in search results </td>
                              <td className="text-center">
                                {" "}
                                <i
                                  style={{ color: "#1A63C6" }}
                                  className="bi bi-check-circle-fill "
                                ></i>
                              </td>
                              <td className="text-center">
                                {" "}
                                <i
                                  style={{ color: "#1A63C6" }}
                                  className="bi bi-check-circle-fill "
                                ></i>
                              </td>
                              <td className="text-center">
                                {" "}
                                <i
                                  style={{ color: "#1A63C6" }}
                                  className="bi bi-check-circle-fill "
                                ></i>
                              </td>
                            </tr>
                            <tr>
                              <td>Dashboard for Leads Management</td>
                              <td className="text-center">
                                {" "}
                                <i
                                  style={{ color: "#1A63C6" }}
                                  className="bi bi-check-circle-fill "
                                ></i>
                              </td>
                              <td className="text-center">
                                {" "}
                                <i
                                  style={{ color: "#1A63C6" }}
                                  className="bi bi-check-circle-fill "
                                ></i>
                              </td>
                              <td className="text-center">
                                {" "}
                                <i
                                  style={{ color: "#1A63C6" }}
                                  className="bi bi-check-circle-fill "
                                ></i>
                              </td>
                            </tr>
                            <tr>
                              <td>Featured Listing on Homepage</td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#FE5900" }}
                                  className="bi bi-x-circle-fill"
                                ></i>
                              </td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#1A63C6" }}
                                  className="bi bi-check-circle-fill "
                                ></i>
                              </td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#1A63C6" }}
                                  className="bi bi-check-circle-fill "
                                ></i>
                              </td>
                            </tr>
                            <tr>
                              <td>Featured Developer on Homepage</td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#FE5900" }}
                                  className="bi bi-x-circle-fill"
                                ></i>
                              </td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#FE5900" }}
                                  className="bi bi-x-circle-fill"
                                ></i>
                              </td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#1A63C6" }}
                                  className="bi bi-check-circle-fill "
                                ></i>
                              </td>
                            </tr>
                            <tr>
                              <td>Notifications on Matched User Searches</td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#FE5900" }}
                                  className="bi bi-x-circle-fill"
                                ></i>
                              </td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#1A63C6" }}
                                  className="bi bi-check-circle-fill "
                                ></i>
                              </td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#1A63C6" }}
                                  className="bi bi-check-circle-fill "
                                ></i>
                              </td>
                            </tr>
                            <tr>
                              <td>Homepage Banner Ad </td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#FE5900" }}
                                  className="bi bi-x-circle-fill"
                                ></i>
                              </td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#FE5900" }}
                                  className="bi bi-x-circle-fill"
                                ></i>
                              </td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#1A63C6" }}
                                  className="bi bi-check-circle-fill "
                                ></i>
                              </td>
                            </tr>
                            <tr>
                              <td>Ad Campaign Management </td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#FE5900" }}
                                  className="bi bi-x-circle-fill"
                                ></i>
                              </td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#FE5900" }}
                                  className="bi bi-x-circle-fill"
                                ></i>
                              </td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#1A63C6" }}
                                  className="bi bi-check-circle-fill"
                                ></i>
                              </td>
                            </tr>

                          </tbody>
                        </Table>
                      </div>
                    </div>

                    {/* **********other********** */}

                    <div
                      className="tab-pane tab-pane-parent fade show "
                      id="other"
                      role="tabpanel"
                    >
                      <div className="col-lg-12">
                        <div className="row">
                          {otherplan.map((item, index) => (
                             <div className="col-lg-4 col-md-4 text-center mb-3">
                             <div className="border-1"
                             style={{borderRadius:"10px",

                           }}
                             >
                             <div
                               className=" p-2 fs-18 text-white bg-orange font-weight-600"
                               style={{
                                 borderTopLeftRadius:"10px",
                                 borderTopRightRadius:"10px",
                                 boxShadow:"0px -1px 5px -1px inset"
                               }}
                             >
                               {" "}
                               {item?.title.toUpperCase()}
                             </div>
                             <div className=" bg-blue pb-3"
                              style={{
                               borderBottomLeftRadius:"10px",
                               borderBottomRightRadius:"10px"
                             }}
                             >
                               <div className="py-5 px-3 ">
                                 <h2 className="mb-2 text-white ">
                                   <CurrencyFormat
                                     value={item?.pricing}
                                     displayType="text"
                                     thousandSeparator={true}
                                     suffix=".00"
                                   />{" "}
                                   AED<span className="fs-16">/yr</span>
                                 </h2>
                                 <p className="text-white">Discount{" "}{((item?.old_pricing-item.pricing)/item?.old_pricing)*100}%</p>
                                 <h2 className="mb-0 text-white fs-18 px-10  ">
                                   <CurrencyFormat
                                     value={item?.old_pricing}
                                     displayType="text"
                                     thousandSeparator={true}
                                     className="strikethrough"
                                     suffix=".00"
                                   />{" "}
                                   AED<span className="fs-16">/yr</span>
                                 </h2>
                                 {/* <div
                                   dangerouslySetInnerHTML={{
                                     __html: item.details,
                                   }}
                                 /> */}
                               </div>

                               {/* // <p>{item?.services}</p> */}
                               <button
                                 className="rounded border-0 fs-14 bg-f3f3f3 text-dark  py-2 lh-15 px-5 mb-3 "
                                 onClick={() =>
                                   subscribe(
                                     item.id,
                                     item?.pricing,
                                     item?.title
                                   )
                                 }
                               >
                                 Subscribe
                               </button>

                             </div>
                             </div>

                           </div>

                          ))}
                        </div>
                        <h2 className="text-center text-uppercase fs-18 text-dark mt-5 mb-5">
                          Plans
                        </h2>
                        <Table className="fs-16 table-responsive-sm table-responsive-md" bordered hover>
                          <tbody>
                            <tr className="bg-gray-01 text-dark text-uppercase font-weight-600 fs-18">
                              <td>Features </td>
                              <td className="text-center">Silver</td>
                              <td className="text-center">Gold</td>
                              <td className="text-center">Platinum</td>
                            </tr>
                            <tr>
                              <td>Listings Per Month </td>
                              <td className="text-center">20</td>
                              <td className="text-center">30</td>
                              <td className="text-center">60</td>
                            </tr>
                            <tr>
                              <td>Leads Per Month </td>
                              <td className="text-center">60</td>
                              <td className="text-center">90</td>
                              <td className="text-center">Unlimited</td>
                            </tr>
                            <tr>
                              <td>Personalized Portfolio </td>
                              <td className="text-center">
                                {" "}
                                <i
                                  style={{ color: "#1A63C6" }}
                                  className="bi bi-check-circle-fill "
                                ></i>
                              </td>
                              <td className="text-center">
                                {" "}
                                <i
                                  style={{ color: "#1A63C6" }}
                                  className="bi bi-check-circle-fill "
                                ></i>
                              </td>
                              <td className="text-center">
                                {" "}
                                <i
                                  style={{ color: "#1A63C6" }}
                                  className="bi bi-check-circle-fill "
                                ></i>
                              </td>
                            </tr>
                            <tr>
                              <td>Dashboard for Leads Management</td>
                              <td className="text-center">
                                {" "}
                                <i
                                  style={{ color: "#1A63C6" }}
                                  className="bi bi-check-circle-fill "
                                ></i>
                              </td>
                              <td className="text-center">
                                {" "}
                                <i
                                  style={{ color: "#1A63C6" }}
                                  className="bi bi-check-circle-fill "
                                ></i>
                              </td>
                              <td className="text-center">
                                {" "}
                                <i
                                  style={{ color: "#1A63C6" }}
                                  className="bi bi-check-circle-fill "
                                ></i>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Featured Business in Finder Service search
                                results
                              </td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#FE5900" }}
                                  className="bi bi-x-circle-fill"
                                ></i>
                              </td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#1A63C6" }}
                                  className="bi bi-check-circle-fill "
                                ></i>
                              </td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#1A63C6" }}
                                  className="bi bi-check-circle-fill "
                                ></i>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Featured Professional in Finder Service search
                                results
                              </td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#FE5900" }}
                                  className="bi bi-x-circle-fill"
                                ></i>
                              </td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#1A63C6" }}
                                  className="bi bi-check-circle-fill "
                                ></i>
                              </td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#1A63C6" }}
                                  className="bi bi-check-circle-fill "
                                ></i>
                              </td>
                            </tr>

                            <tr>
                              <td>Notifications on Matched User Searches </td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#FE5900" }}
                                  className="bi bi-x-circle-fill"
                                ></i>
                              </td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#1A63C6" }}
                                  className="bi bi-check-circle-fill "
                                ></i>
                              </td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#1A63C6" }}
                                  className="bi bi-check-circle-fill "
                                ></i>
                              </td>
                            </tr>
                            <tr>
                              <td>Ad Campaign Management </td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#FE5900" }}
                                  className="bi bi-x-circle-fill"
                                ></i>
                              </td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#FE5900" }}
                                  className="bi bi-x-circle-fill"
                                ></i>
                              </td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#1A63C6" }}
                                  className="bi bi-check-circle-fill"
                                ></i>
                              </td>
                            </tr>


                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {!showYear && (
                <div className="collapse-tabs mb-10">
                  <ul
                    className="nav nav-tabs text-uppercase d-md-inline-flex agent-details-tabs"
                    role="tablist"
                  >
                    <li className="nav-item">
                      <a
                        href="#agent"
                        className=" text-blue nav-link active  fs-16 padding-r-5"
                        data-bs-toggle="tab"
                        role="tab"
                      >
                        Agent
                      </a>
                    </li>
                    <>
                      <li className="nav-item ml-0">
                        <a
                          href="#developer"
                          className="nav-link text-blue fs-16 padding-r-5"
                          data-bs-toggle="tab"
                          role="tab"
                        >
                          Developer
                        </a>
                      </li>

                      <li className="nav-item ml-0">
                        <a
                          href="#other"
                          className="nav-link text-blue fs-16 padding-r-5"
                          data-bs-toggle="tab"
                          role="tab"
                        >
                          Other
                        </a>
                      </li>
                    </>
                  </ul>
                  <div className="tab-content shadow-none  bg-white">
                    <div
                      className="tab-pane tab-pane-parent fade show active"
                      id="agent"
                      role="tabpanel"
                    >
                      <div className="col-lg-12">
                        <div className="row ">
                          {agentplan.map((item, index) => (
                            <div className="col-lg-4 col-md-4 text-center mb-3">
                              <div className="border-1"
                              style={{borderRadius:"10px",

                            }}
                              >
                              <div
                                className=" p-2 fs-18 text-white bg-orange font-weight-600"
                                style={{
                                  borderTopLeftRadius:"10px",
                                  borderTopRightRadius:"10px",
                                  boxShadow:"0px -1px 5px -1px inset"
                                }}
                              >
                                {" "}
                                {item?.title.toUpperCase()}
                              </div>
                              <div className=" bg-blue pb-3"
                               style={{
                                borderBottomLeftRadius:"10px",
                                borderBottomRightRadius:"10px"
                              }}
                              >
                                <div className="py-5 px-3 ">
                                  <h2 className="mb-0 text-white ">
                                    <CurrencyFormat
                                      value={item?.pricing / 12}
                                      displayType="text"
                                      thousandSeparator={true}
                                      suffix=".00"
                                    />{" "}
                                    AED<span className="fs-16">/mo</span>
                                  </h2>
                                  {/* <div
                                    dangerouslySetInnerHTML={{
                                      __html: item.details,
                                    }}
                                  /> */}
                                </div>

                                {/* // <p>{item?.services}</p> */}
                                <button
                                  className="rounded border-0 fs-14 bg-f3f3f3 text-dark  py-2 lh-15 px-5 mb-2 "
                                  onClick={() =>
                                    subscribe(
                                      item.id,
                                      item?.pricing / 12,
                                      item?.title
                                    )
                                  }
                                >
                                  Subscribe
                                </button>
                                <p className="text-white fs-12">Billed Quarterly</p>
                              </div>
                              </div>

                            </div>
                          ))}
                        </div>
                        <h2 className="text-center text-uppercase fs-18 text-dark mt-5 mb-5">
                          Plans
                        </h2>
                        <Table className="fs-16 table-responsive-sm table-responsive-md" bordered hover>
                          <tbody>
                            <tr className="bg-gray-01 text-dark text-uppercase font-weight-600 fs-18">
                              <td className="">Features </td>
                              <td className="text-center">Silver</td>
                              <td className="text-center">Gold</td>
                              <td className="text-center">Platinum</td>
                            </tr>
                            <tr>
                              <td>Listings Per Week </td>
                              <td className="text-center">15</td>
                              <td className="text-center">30</td>
                              <td className="text-center">90</td>
                            </tr>
                            <tr>
                              <td>Leads Per Week </td>
                              <td className="text-center">20</td>
                              <td className="text-center">50</td>
                              <td className="text-center">Unlimited</td>
                            </tr>
                            <tr>
                              <td>Placement in search results </td>
                              <td className="text-center">
                                {" "}
                                <i
                                  style={{ color: "#1A63C6" }}
                                  className="bi bi-check-circle-fill "
                                ></i>
                              </td>
                              <td className="text-center">
                                {" "}
                                <i
                                  style={{ color: "#1A63C6" }}
                                  className="bi bi-check-circle-fill "
                                ></i>
                              </td>
                              <td className="text-center">
                                {" "}
                                <i
                                  style={{ color: "#1A63C6" }}
                                  className="bi bi-check-circle-fill "
                                ></i>
                              </td>
                            </tr>
                            <tr>
                              <td>Dashboard for Leads Management</td>
                              <td className="text-center">
                                {" "}
                                <i
                                  style={{ color: "#1A63C6" }}
                                  className="bi bi-check-circle-fill "
                                ></i>
                              </td>
                              <td className="text-center">
                                {" "}
                                <i
                                  style={{ color: "#1A63C6" }}
                                  className="bi bi-check-circle-fill "
                                ></i>
                              </td>
                              <td className="text-center">
                                {" "}
                                <i
                                  style={{ color: "#1A63C6" }}
                                  className="bi bi-check-circle-fill "
                                ></i>
                              </td>
                            </tr>
                            <tr>
                              <td>Featured Listing on Homepage</td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#FE5900" }}
                                  className="bi bi-x-circle-fill"
                                ></i>
                              </td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#1A63C6" }}
                                  className="bi bi-check-circle-fill "
                                ></i>
                              </td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#1A63C6" }}
                                  className="bi bi-check-circle-fill "
                                ></i>
                              </td>
                            </tr>
                            <tr>
                              <td>Featured Agent on Homepage</td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#FE5900" }}
                                  className="bi bi-x-circle-fill"
                                ></i>
                              </td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#FE5900" }}
                                  className="bi bi-x-circle-fill"
                                ></i>
                              </td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#1A63C6" }}
                                  className="bi bi-check-circle-fill "
                                ></i>
                              </td>
                            </tr>

                            <tr>
                              <td>Homepage Banner Ad </td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#FE5900" }}
                                  className="bi bi-x-circle-fill"
                                ></i>
                              </td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#FE5900" }}
                                  className="bi bi-x-circle-fill"
                                ></i>
                              </td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#1A63C6" }}
                                  className="bi bi-check-circle-fill "
                                ></i>
                              </td>
                            </tr>
                            <tr>
                              <td>Ad Campaign Management </td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#FE5900" }}
                                  className="bi bi-x-circle-fill"
                                ></i>
                              </td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#FE5900" }}
                                  className="bi bi-x-circle-fill"
                                ></i>
                              </td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#1A63C6" }}
                                  className="bi bi-check-circle-fill"
                                ></i>
                              </td>
                            </tr>

                          </tbody>
                        </Table>
                      </div>
                    </div>
                    <div
                      className="tab-pane tab-pane-parent fade show"
                      id="developer"
                      role="tabpanel"
                    >
                      <div className="col-lg-12">
                        <div className="row">
                          {devplan.map((item, index) => (
                              <div className="col-lg-4 col-md-4 text-center mb-3">
                              <div className="border-1"
                              style={{borderRadius:"10px",

                            }}
                              >
                              <div
                                className=" p-2 fs-18 text-white bg-orange font-weight-600"
                                style={{
                                  borderTopLeftRadius:"10px",
                                  borderTopRightRadius:"10px",
                                  boxShadow:"0px -1px 5px -1px inset"
                                }}
                              >
                                {" "}
                                {item?.title.toUpperCase()}
                              </div>
                              <div className=" bg-blue pb-3 "
                               style={{
                                borderBottomLeftRadius:"10px",
                                borderBottomRightRadius:"10px"
                              }}
                              >
                                <div className="py-5 px-3 ">
                                  <h2 className="mb-0 text-white ">
                                    <CurrencyFormat
                                      value={item?.pricing / 12}
                                      displayType="text"
                                      thousandSeparator={true}
                                      suffix=".00"
                                    />{" "}
                                    AED<span className="fs-16">/mo</span>
                                  </h2>
                                  {/* <div
                                    dangerouslySetInnerHTML={{
                                      __html: item.details,
                                    }}
                                  /> */}
                                </div>

                                {/* // <p>{item?.services}</p> */}
                                <button
                                  className="rounded border-0 fs-14 bg-f3f3f3 text-dark  py-2 lh-15 px-5 mb-3 "
                                  onClick={() =>
                                    subscribe(
                                      item.id,
                                      item?.pricing / 12,
                                      item?.title
                                    )
                                  }
                                >
                                  Subscribe
                                </button>
                                <p className="text-white">Billed Quarterly</p>
                              </div>
                              </div>

                            </div>
                          ))}
                        </div>
                        <h2 className="text-center text-uppercase fs-18 text-dark mt-5 mb-5">
                          Plans
                        </h2>
                        <Table className="fs-16 table-responsive-sm table-responsive-md" bordered hover>
                          <tbody>
                            <tr className="bg-gray-01 text-dark text-uppercase font-weight-600 fs-18">
                              <td>Features </td>
                              <td className="text-center">Silver</td>
                              <td className="text-center">Gold</td>
                              <td className="text-center">Platinum</td>
                            </tr>
                            <tr>
                              <td>Listings Per Week </td>
                              <td className="text-center">10</td>
                              <td className="text-center">20</td>
                              <td className="text-center">30</td>
                            </tr>
                            <tr>
                              <td>Leads Per Week </td>
                              <td className="text-center">20</td>
                              <td className="text-center">40</td>
                              <td className="text-center">Unlimited</td>
                            </tr>
                            <tr>
                              <td>Placement in search results </td>
                              <td className="text-center">
                                {" "}
                                <i
                                  style={{ color: "#1A63C6" }}
                                  className="bi bi-check-circle-fill "
                                ></i>
                              </td>
                              <td className="text-center">
                                {" "}
                                <i
                                  style={{ color: "#1A63C6" }}
                                  className="bi bi-check-circle-fill "
                                ></i>
                              </td>
                              <td className="text-center">
                                {" "}
                                <i
                                  style={{ color: "#1A63C6" }}
                                  className="bi bi-check-circle-fill "
                                ></i>
                              </td>
                            </tr>
                            <tr>
                              <td>Dashboard for Leads Management</td>
                              <td className="text-center">
                                {" "}
                                <i
                                  style={{ color: "#1A63C6" }}
                                  className="bi bi-check-circle-fill "
                                ></i>
                              </td>
                              <td className="text-center">
                                {" "}
                                <i
                                  style={{ color: "#1A63C6" }}
                                  className="bi bi-check-circle-fill "
                                ></i>
                              </td>
                              <td className="text-center">
                                {" "}
                                <i
                                  style={{ color: "#1A63C6" }}
                                  className="bi bi-check-circle-fill "
                                ></i>
                              </td>
                            </tr>
                            <tr>
                              <td>Featured Listing on Homepage</td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#FE5900" }}
                                  className="bi bi-x-circle-fill"
                                ></i>
                              </td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#1A63C6" }}
                                  className="bi bi-check-circle-fill "
                                ></i>
                              </td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#1A63C6" }}
                                  className="bi bi-check-circle-fill "
                                ></i>
                              </td>
                            </tr>
                            <tr>
                              <td>Featured Developer on Homepage</td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#FE5900" }}
                                  className="bi bi-x-circle-fill"
                                ></i>
                              </td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#FE5900" }}
                                  className="bi bi-x-circle-fill"
                                ></i>
                              </td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#1A63C6" }}
                                  className="bi bi-check-circle-fill "
                                ></i>
                              </td>
                            </tr>
                            <tr>
                              <td>Notifications on Matched User Searches</td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#FE5900" }}
                                  className="bi bi-x-circle-fill"
                                ></i>
                              </td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#1A63C6" }}
                                  className="bi bi-check-circle-fill "
                                ></i>
                              </td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#1A63C6" }}
                                  className="bi bi-check-circle-fill "
                                ></i>
                              </td>
                            </tr>
                            <tr>
                              <td>Homepage Banner Ad </td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#FE5900" }}
                                  className="bi bi-x-circle-fill"
                                ></i>
                              </td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#FE5900" }}
                                  className="bi bi-x-circle-fill"
                                ></i>
                              </td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#1A63C6" }}
                                  className="bi bi-check-circle-fill "
                                ></i>
                              </td>
                            </tr>
                            <tr>
                              <td>Ad Campaign Management </td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#FE5900" }}
                                  className="bi bi-x-circle-fill"
                                ></i>
                              </td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#FE5900" }}
                                  className="bi bi-x-circle-fill"
                                ></i>
                              </td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#1A63C6" }}
                                  className="bi bi-check-circle-fill"
                                ></i>
                              </td>
                            </tr>

                          </tbody>
                        </Table>
                      </div>
                    </div>
                    <div
                      className="tab-pane tab-pane-parent fade show"
                      id="other"
                      role="tabpanel"
                    >
                      <div className="col-lg-12">
                        <div className="row">
                          {otherplan.map((item, index) => (
                             <div className="col-lg-4 col-md-4 text-center mb-3">
                             <div className="border-1"
                             style={{borderRadius:"10px",

                           }}
                             >
                             <div
                               className=" p-2 fs-18 text-white bg-orange font-weight-600"
                               style={{
                                 borderTopLeftRadius:"10px",
                                 borderTopRightRadius:"10px",
                                 boxShadow:"0px -1px 5px -1px inset"
                               }}
                             >
                               {" "}
                               {item?.title.toUpperCase()}
                             </div>
                             <div className=" bg-blue pb-3"
                              style={{
                               borderBottomLeftRadius:"10px",
                               borderBottomRightRadius:"10px"
                             }}
                             >
                               <div className="py-5 px-3 ">
                                 <h2 className="mb-0 text-white ">
                                   <CurrencyFormat
                                     value={item?.pricing / 12}
                                     displayType="text"
                                     thousandSeparator={true}
                                     suffix=".00"
                                   />{" "}
                                   AED<span className="fs-16">/mo</span>
                                 </h2>
                                 {/* <div
                                   dangerouslySetInnerHTML={{
                                     __html: item.details,
                                   }}
                                 /> */}
                               </div>

                               {/* // <p>{item?.services}</p> */}
                               <button
                                 className="rounded border-0 fs-14 bg-f3f3f3 text-dark  py-2 lh-15 px-5 mb-3 "
                                 onClick={() =>
                                   subscribe(
                                     item.id,
                                     item?.pricing / 12,
                                     item?.title
                                   )
                                 }
                               >
                                 Subscribe
                               </button>
                               <p className="text-white">Billed Quarterly</p>
                             </div>
                             </div>

                           </div>
                          ))}
                        </div>
                        <h2 className="text-center text-uppercase fs-18 text-dark mt-5 mb-5">
                          Plans
                        </h2>
                        <Table className="fs-16 table-responsive-sm table-responsive-md" bordered hover>
                          <tbody>
                            <tr className="bg-gray-01 text-dark text-uppercase font-weight-600 fs-18">
                              <td>Features </td>
                              <td className="text-center">Silver</td>
                              <td className="text-center">Gold</td>
                              <td className="text-center">Platinum</td>
                            </tr>
                            <tr>
                              <td>Listings Per Week </td>
                              <td className="text-center">10</td>
                              <td className="text-center">20</td>
                              <td className="text-center">30</td>
                            </tr>
                            <tr>
                              <td>Leads Per Week </td>
                              <td className="text-center">25</td>
                              <td className="text-center">50</td>
                              <td className="text-center">Unlimited</td>
                            </tr>
                            <tr>
                              <td>Placement in search results </td>
                              <td className="text-center">
                                {" "}
                                <i
                                  style={{ color: "#1A63C6" }}
                                  className="bi bi-check-circle-fill "
                                ></i>
                              </td>
                              <td className="text-center">
                                {" "}
                                <i
                                  style={{ color: "#1A63C6" }}
                                  className="bi bi-check-circle-fill "
                                ></i>
                              </td>
                              <td className="text-center">
                                {" "}
                                <i
                                  style={{ color: "#1A63C6" }}
                                  className="bi bi-check-circle-fill "
                                ></i>
                              </td>
                            </tr>
                            <tr>
                              <td>Dashboard for Leads Management</td>
                              <td className="text-center">
                                {" "}
                                <i
                                  style={{ color: "#1A63C6" }}
                                  className="bi bi-check-circle-fill "
                                ></i>
                              </td>
                              <td className="text-center">
                                {" "}
                                <i
                                  style={{ color: "#1A63C6" }}
                                  className="bi bi-check-circle-fill "
                                ></i>
                              </td>
                              <td className="text-center">
                                {" "}
                                <i
                                  style={{ color: "#1A63C6" }}
                                  className="bi bi-check-circle-fill "
                                ></i>
                              </td>
                            </tr>
                            <tr>
                              <td>Featured Listing on Homepage</td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#FE5900" }}
                                  className="bi bi-x-circle-fill"
                                ></i>
                              </td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#1A63C6" }}
                                  className="bi bi-check-circle-fill "
                                ></i>
                              </td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#1A63C6" }}
                                  className="bi bi-check-circle-fill "
                                ></i>
                              </td>
                            </tr>
                            <tr>
                              <td>Featured Developer on Homepage</td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#FE5900" }}
                                  className="bi bi-x-circle-fill"
                                ></i>
                              </td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#FE5900" }}
                                  className="bi bi-x-circle-fill"
                                ></i>
                              </td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#1A63C6" }}
                                  className="bi bi-check-circle-fill "
                                ></i>
                              </td>
                            </tr>
                            <tr>
                              <td>Notifications on Matched User Searches</td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#FE5900" }}
                                  className="bi bi-x-circle-fill"
                                ></i>
                              </td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#1A63C6" }}
                                  className="bi bi-check-circle-fill "
                                ></i>
                              </td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#1A63C6" }}
                                  className="bi bi-check-circle-fill "
                                ></i>
                              </td>
                            </tr>
                            <tr>
                              <td>Homepage Banner Ad </td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#FE5900" }}
                                  className="bi bi-x-circle-fill"
                                ></i>
                              </td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#FE5900" }}
                                  className="bi bi-x-circle-fill"
                                ></i>
                              </td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#1A63C6" }}
                                  className="bi bi-check-circle-fill "
                                ></i>
                              </td>
                            </tr>
                            <tr>
                              <td>Ad Campaign Management </td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#FE5900" }}
                                  className="bi bi-x-circle-fill"
                                ></i>
                              </td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#FE5900" }}
                                  className="bi bi-x-circle-fill"
                                ></i>
                              </td>
                              <td className="text-center">
                                <i
                                  style={{ color: "#1A63C6" }}
                                  className="bi bi-check-circle-fill"
                                ></i>
                              </td>
                            </tr>

                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </section>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Plan;
