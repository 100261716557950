// src/reducers.js
const initialState = {
    posts: [],
    loading: false,
    error: null,
  };
  
  const rootReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'FETCH_POSTS_REQUEST':
        return { ...state, loading: true, error: null };
      case 'FETCH_POSTS_SUCCESS':
        return { ...state, loading: false, posts: action.payload, error: null };
      case 'FETCH_POSTS_FAILURE':
        return { ...state, loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export default rootReducer;