import React, { useEffect, useState } from 'react'
import CurrencyFormat from "react-currency-format";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from "react-accessible-accordion";
import AutoComplete from "react-google-autocomplete";
import ImageUploader from './component/ImageUploader';
import axiosInstance from '../../../interceptor/axiosInstance';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import moment from 'moment';
import 'moment/locale/fr';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { Link, useLocation, useParams } from 'react-router-dom';
import Loader from '../../../Loader';
import { FifteenMp } from '@mui/icons-material';
import { Button, Modal } from 'react-bootstrap';



const API_URL = process.env.REACT_APP_API_URL
const AddProperty = () => {

    const navigate = useNavigate();
    const locatedData = useLocation();
    console.log(locatedData, "locatedData");
    const isEdit = locatedData?.state?.isEdit;
    console.log(isEdit, "isEdit");
    const previewData = locatedData?.state?.propData;
    const lat = locatedData?.state?.lat;
    const lng = locatedData?.state?.lng;
    const IsNew = locatedData?.state?.isProperty;
    const searchParams = new URLSearchParams(locatedData.search)
    const keyValue = searchParams.get('key')
    console.log(keyValue, "keyValue");
    const [isProperty, setIsProperty] = useState("False");
    console.log(isProperty, "isProperty");
    const [isNewProperty, setIsNewProperty] = useState("True");
    const Propid = useParams();


    const [place, setPlace] = useState([]);
    const [streetAddress, setStreetAddress] = useState("");
    const [country, setCountry] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [area, setArea] = useState("");
    const [description, setDescription] = useState("");
    const [propertyName, setPropertyName] = useState("");
    const [dldNumber, setDLDNumber] = useState("");
    const [propertyBuildingType, setPropertyBuildingType] = useState("");
    const [propertyPropType, setPropertyPropType] = useState("");
    const [propertyPropLabel, setPropertyPropLabel] = useState("");
    const [salePrice, setSalePrice] = useState("");
    const [leasePrice, setLeasePrice] = useState("");
    const [propertyConstStatus, setPropertyConstStatus] = useState("");
    const [bhkCount, setBhkCount] = useState(1);
    console.log(bhkCount, "setBhkCount");
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    // const [propertyOwnership, setPropertyOwnership] = useState("");
    const [propertySize, setPropertySize] = useState("");
    const [paymentPlan, setPaymentPlan] = useState("");
    const [paymentPlanUrl, setPaymentPlanUrl] = useState("");

    const [bedroomNumber, setBedroomNumber] = useState("");
    const [bathroomNumber, setBathroomNumber] = useState("");
    const [constructionStartDate, setConstructionStartDate] = useState("");
    const [estimateDate, setEstimateDate] = useState("");
    const [launchDate, setLaunchDate] = useState("");
    const [propertyWebsite, setPropertyWebsite] = useState("");
    const [status, setStatus] = useState("");
    const [numberBuilding, setNumberBuilding] = useState("");
    const [dataCheckInput, setDataCheckInput] = useState();
    const [inputList, setInputList] = useState([])
    const [buildingType, setBuildingType] = useState([])
    const [propertyType, setPropertyType] = useState([])
    const [propertyLabel, setPropertyLabel] = useState([])
    const [ownerShip, setownerShip] = useState([])
    const [imgFile, setImageFile] = useState();
    const [isMyProfile, setIsMyProfile] = useState("");
    const [myProfile, setProfileImage] = useState("");
    const [avaFile, setAVAFile] = useState();
    console.log(inputList, "inputList");
    const [isMyAVA, setIsMyAVA] = useState("");
    const [myAVA, setMyAVA] = useState("");
    const [brochureFile, setBrochureFile] = useState();
    const [isBrochure, setIsBrochure] = useState("");
    const [myBrochure, setMyBrochure] = useState("");
    const [allAmenities, setAllAmenities] = useState();
    const [Ammenities, setAmmenities] = useState([])
    const [imageData, setImageData] = useState([]);
    const [selectedImage, setSelectedImage] = useState([]);
    const [selectedVideo, SetSelectedVideo] = useState([]);
    const [videoData, setVideoData] = useState();
    const [loading, setLoading] = useState('')
    const [constructionStatus, setConstructionStatus] = useState([])
    const [propertyStatus, setPropertyStatus] = useState([])
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [show, setShow] = useState(false);
    const [bhkDetails, setBhkDetails] = useState([{ name: '', size: '', file: null }]);
    console.log(bhkDetails, "bhkDetails");
    /* handle function for number floor */
    const HandleInputChange = (e, index) => {
        setDataCheckInput((e?.target?.value))
        const { name, value } = e?.target
        let list = Array.from(inputList);
        list[index] = {
            ...list[index],
            [name]: value
        };
        setInputList(list);
    }
    const AddBHKInput = () => {
        setBhkDetails([{ name: '', size: '', file: null }]);
    }
    const HandleSelect = (e) => {
        const selectedValue = e?.target?.value;
        const numberBuildingValue = selectedValue ? Number(selectedValue) : null;
        setNumberBuilding(numberBuildingValue);
        const finalArray = Array(numberBuildingValue).fill({ name: '', no_of_unit: '', floor_pdf: '' });
        setInputList(finalArray);
    }
    const floorPdfHandler = (e, index) => {
        const file = e?.target?.files[0];
        const file_Url = URL.createObjectURL(e?.target?.files[0]);
        console.log(file_Url, "file");
        const size = parseFloat(file?.size / (1024 * 1024)).toFixed(2);
        if (size > 50) {
            toast.error("Please select an image with a size less than 50 MB");
            return;
        }
        setInputList(prevInputList => {
            const newInputList = [...prevInputList];
            newInputList[index] = {
                ...newInputList[index],
                floor_pdf: file,
                pdf_url: file_Url
            };
            return newInputList;
        });
    };
    const paymentPdfHandler = (e) => {
        const file = e?.target?.files[0];
        const file_Url = URL.createObjectURL(e?.target?.files[0]);

        const size = parseFloat(file?.size / (1024 * 1024)).toFixed(2);
        if (size > 50) {
            toast.error("Please select Payment Plan with a size less than 50 MB");
            return;
        }
        setPaymentPlan(file);
        setPaymentPlanUrl(file_Url);
    };

    /* ************construction start date handler */
    const handleConstStartDate = (selectedDate) => {
        setConstructionStartDate(moment(selectedDate).format('YYYY-MM-DD'));

    }

    /* ************Estimate date handler */
    const handleEstiamateDate = (selectedDate) => {
        setEstimateDate(moment(selectedDate).format('YYYY-MM-DD'));

    }
    /* ************launch_date  handler */
    const handleLaunchDate = (selectedDate) => {
        setLaunchDate(moment(selectedDate).format('YYYY-MM-DD'));

    }

    /* handle function for multiimages */
    const PorfileImgHandler = (e) => {
        const size = parseFloat(e?.target?.files[0]?.size / (1024 * 1024)).toFixed(
            2
        );
        if (size > 10) {
            toast.error("Please select image size less than 10 MB");
            return;
        }
        setIsMyProfile(URL.createObjectURL(e?.target?.files[0]));
        setImageFile(e?.target?.files[0]);

    };

    /* handle function for availablity plan */
    const AVAHandler = (e) => {
        const size = parseFloat(e?.target?.files[0]?.size / (1024 * 1024)).toFixed(2);
        if (size > 50) {
            toast.error("Please select image size less than 50 MB");
            return;
        }
        setIsMyAVA(URL.createObjectURL(e?.target?.files[0]));
        setAVAFile(e?.target?.files[0]);

    };
    /* HANDLE FUNCTION FOR BROUCHURE */

    const brochureHandler = (e) => {
        const size = parseFloat(e?.target?.files[0]?.size / (1024 * 1024)).toFixed(
            2
        );
        if (size > 50) {
            toast.error("Please select image size less than 50 MB");
            return;
        }
        setIsBrochure(URL.createObjectURL(e?.target?.files[0]));
        setBrochureFile(e?.target?.files[0]);

    };
    /* HANDLE FUNCTION FOR AMMENITIES */
    const amenities_value = Ammenities?.map((i) => {
        return { amenities: i.id };
    })
    const amenities = [...new Set(Ammenities?.map(item => item.id))];
    const onAmenities = (item) => {
        let arr = [...Ammenities]
        const findIndex = Ammenities?.findIndex(i => i?.id === item?.id)
        const findItem = Ammenities?.find(i => i?.id === item?.id)


        if (findItem) {
            if (findIndex > -1) {
                arr?.splice(findIndex, 1)
                setAmmenities(arr)
            }
        } else {
            setAmmenities([...Ammenities, { id: item['id'] }])
        }
    }


    /* ************building type handler */
    const handleBuildingTypeChange = (event) => {
        const selectedBuildingTypeId = event.target.value;
        getPropertyType(selectedBuildingTypeId);
        setPropertyBuildingType(selectedBuildingTypeId)

    }



    /* ****************api for property ownership************* */

    const getownership = async () => {
        setLoading(true)
        try {
            const response = await axiosInstance.get(`${API_URL}/properties/ownership/get/`);
            if (response.status = 200) {
                const responseData = response.data;
                setownerShip(responseData)
                setLoading(false)
            } else {

            }
        } catch (err) {
            toast.error(' Please Contact Administrator');
        }
    }
    /* ****************api for getting amenities ************* */

    const getAmemitiesType = async () => {
        setLoading(true)
        try {
            const response = await axiosInstance.get(`${API_URL}/properties/amenities/get/`);
            if (response.status = 200) {
                const responseData = response.data;
                setAllAmenities(responseData)
                setLoading(false)
            } else {

            }
        } catch (err) {
            toast.error(' Please Contact Administrator');
            // throw err;
        }
    }

    /* ****************api for building type ************* */

    const getBuildType = async () => {
        setLoading(true)
        try {
            const response = await axiosInstance.get(`${API_URL}/properties/building/types/`);
            if (response.status = 200) {
                const responseData = response.data;
                setBuildingType(responseData)
                setLoading(false)
            } else {

            }
        } catch (err) {
            toast.error(' Please Contact Administrator');

        }
    }
    /* ****************api for property type ************* */

    const getPropertyType = async (selectedBuildingTypeId) => {
        setLoading(true)
        try {
            const response = await axiosInstance.get(`${API_URL}/properties/property/types/?building_type=${selectedBuildingTypeId}`);
            if (response.status = 200) {
                const responseData = response.data;
                setPropertyType(responseData)
                setLoading(false)
            } else {


            }
        } catch (err) {
            toast.error(' Please Contact Administrator');

        }
    }
    /* ****************api for property label ************* */

    const getPropertyLabel = async () => {
        setLoading(true)
        try {
            const response = await axiosInstance.get(`${API_URL}/properties/property/label/`);
            if (response.status = 200) {
                const responseData = response.data;
                setPropertyLabel(responseData)
                setLoading(false)
            } else {


            }
        } catch (err) {
            toast.error(' Please Contact Administrator');

        }
    }
    /* ****************api for property contruction status ************* */

    const getConstructionstatus = async () => {
        setLoading(true)
        try {
            const response = await axiosInstance.get(`${API_URL}/properties/construction/status/`);
            if (response.status = 200) {
                const responseData = response.data;
                setConstructionStatus(responseData)
                setLoading(false)
            } else {


            }
        } catch (err) {
            toast.error(' Please Contact Administrator');

        }
    }
    /* ****************api for property status ************* */

    const getPropertyStatus = async () => {
        setLoading(true)
        try {
            const response = await axiosInstance.get(`${API_URL}/properties/property/status/`);
            if (response.status = 200) {
                const responseData = response.data;
                setPropertyStatus(responseData)
                setLoading(false)
            } else {


            }
        } catch (err) {
            toast.error(' Please Contact Administrator');

        }
    }

    /* HANDLE VALIDATION */
    const Validation = () => {
        if (!streetAddress) {
            toast.error("Please Enter Location First")
            return false;
        }
        if (!propertyBuildingType) {
            toast.error("is Property Commerical/Residential?, Select Building Type")
            return false;
        }
        if (!propertyPropLabel) {
            toast.error("is Property For Sale/Rent?, Select Property Label")
            return false;
        }
        if (!salePrice && !leasePrice) {
            toast.error("Please Enter Property Price")
            return false;
        }
        if (!propertySize) {
            toast.error("Please Enter Property Size")
            return false;
        }
        if (numberBuilding && (!inputList || !inputList.every(item => item?.name && item?.no_of_unit && item?.floor_pdf))) {
            toast.error("Please Enter Floor Plan Details for all floors");
            return false;
        }
        if (!imgFile) {
            toast.error("Please Upload Feature Image")
            return false;
        }
        if (imageData?.length < 4) {
            toast.error("Property Image Should be at least Four")
            return false;
        }
        return true; // Return true if place is not null or undefined
    }



    useEffect(() => {
        if (keyValue !== undefined && keyValue !== false && keyValue !== null) {
            const isTrue = keyValue.charAt(0).toUpperCase() + keyValue.slice(1);
            setIsProperty(isTrue);

        } else if (IsNew) {
            setIsProperty(IsNew);
        }
        else {
            setIsProperty("False");
        }
    }, [])
    useEffect(() => {
        getBuildType();
        getAmemitiesType();
        getPropertyStatus();
        getPropertyLabel();
        getConstructionstatus();
        getownership();
        HandleSelect()
    }, []);

    useEffect(() => {
        async function propCountry() {
            if (place?.formatted_address) {

                setStreetAddress(place?.formatted_address)
            }
            const res = await place?.address_components?.map((item) => {
                if (item?.types[0] === "country") {
                    setCountry(item["long_name"]);
                }
                if (item?.types[0] === "administrative_area_level_1") {
                    setState(item["long_name"]);
                }
                if (item?.types[0] === "route") {
                    setCity(item["long_name"]);
                }
                if (item?.types[0] === "route") {
                    setArea(item["long_name"]);
                }
            });
        }
        propCountry();
    }, [place]);

    useEffect(() => {
        if (typeof salePrice === 'string') {
            setSalePrice(salePrice.replace(/,/g, ""))
        }
    }, [salePrice])
    useEffect(() => {
        if (typeof leasePrice === 'string') {
            setLeasePrice(leasePrice.replace(/,/g, ""))
        }
    }, [leasePrice])


    const propData = {
        isEdit: isEdit,
        isProperty: isProperty,
        streetAddress: streetAddress,
        amenities: amenities,
        Ammenities: Ammenities,
        country: country,
        city: city,
        state: state,
        area: area,
        description: description,
        propertyName: propertyName,
        propertyBuildingType: propertyBuildingType,
        propertyPropType: propertyPropType,
        propertyPropLabel: propertyPropLabel,
        salePrice: salePrice,
        leasePrice: leasePrice,
        propertyConstStatus: propertyConstStatus,
        bhkCount: bhkCount,
        // propertyOwnership: propertyOwnership,
        propertySize: propertySize,
        bedroomNumber: bedroomNumber,
        bathroomNumber: bathroomNumber,
        constructionStartDate: constructionStartDate,
        estimateDate: estimateDate,
        launchDate: launchDate,
        propertyWebsite: propertyWebsite,
        status: status,
        numberBuilding: numberBuilding,
        inputList: inputList,
        bhkDetails: bhkDetails,
        buildingType: buildingType,
        propertyType: propertyType,
        propertyLabel: propertyLabel,
        ownerShip: ownerShip,
        imgFile: imgFile,
        paymentPlan: paymentPlan,
        paymentPlanUrl: paymentPlanUrl,
        isMyProfile: isMyProfile,
        avaFile: avaFile,
        isMyAVA: isMyAVA,
        myAVA: myAVA,
        brochureFile: brochureFile,
        isBrochure: isBrochure,
        myBrochure: myBrochure,
        isNewProperty: isNewProperty,
        imageData: imageData,
        videoData: videoData,
        dldNumber: dldNumber
    }

    /* HANDLE SUBMIT QUERY */

    const handleSubmit = () => {
        if (!Validation()) return;
        navigate('/preview', { state: { inputData: propData, lat: place?.geometry?.location.lat() || lat, lng: place?.geometry?.location.lng() || lng } })
    }


    /* END */



    // Function to handle adding more BHK types
    const handleAddMoreType = () => {
        setBhkDetails(prevState => [...prevState, { name: '', size: '', file: null }]);
        setBhkCount(prevCount => prevCount + 1);
    };

    // Function to handle changes in BHK details
    const handleBhkDetailChange = (index, event) => {
        const { name, value } = event?.target;
        const newBhkDetails = [...bhkDetails];
        newBhkDetails[index][name] = value;
        setBhkDetails(newBhkDetails);
    };

    // Function to handle file input change
    const handleFileChange = (index, event) => {
        const file = event.target.files[0];
        const file_Url = URL.createObjectURL(event?.target?.files[0]);
        const newBhkDetails = [...bhkDetails];
        newBhkDetails[index].file = file;
        newBhkDetails[index].file_url = file_Url;
        setBhkDetails(newBhkDetails);
    };

    /* after preview data */
    useEffect(() => {
        setStreetAddress(previewData?.streetAddress)
        setCountry(previewData?.country)
        setCity(previewData?.city)
        setState(previewData?.state)
        setArea(previewData?.area)
        setDescription(previewData?.description)
        setPropertyName(previewData?.propertyName)
        setDLDNumber(previewData?.dldNumber)
        setPropertyBuildingType(previewData?.propertyBuildingType)
        setPropertyPropType(previewData?.propertyPropType)
        setPropertyPropLabel(previewData?.propertyPropLabel)
        setSalePrice(previewData?.salePrice)
        setLeasePrice(previewData?.leasePrice)
        setPropertyConstStatus(previewData?.propertyConstStatus)

        // setPropertyOwnership(previewData?.propertyOwnership)
        setPropertySize(previewData?.propertySize)
        setBedroomNumber(previewData?.bedroomNumber)
        setBathroomNumber(previewData?.bathroomNumber)
        setConstructionStartDate(previewData?.constructionStartDate)
        setEstimateDate(previewData?.estimateDate)
        setLaunchDate(previewData?.launchDate)
        setPropertyWebsite(previewData?.propertyWebsite)
        setStatus(previewData?.status)
        setNumberBuilding(previewData?.numberBuilding)

        setInputList(previewData?.inputList)
        setBhkDetails(previewData?.bhkDetails)

        setImageFile(previewData?.imgFile)
        setIsMyProfile(previewData?.isMyProfile)
        setPaymentPlan(previewData?.paymentPlan)
        setPaymentPlanUrl(previewData?.paymentPlanUrl)
        setAVAFile(previewData?.avaFile)
        // setBhkCount(previewData?.bhkCount)
        setIsMyAVA(previewData?.isMyAVA)
        setMyAVA(previewData?.myAVA)
        setBrochureFile(previewData?.brochureFile)
        setIsBrochure(previewData?.isBrochure)
        setMyBrochure(previewData?.myBrochure)

        // setAmmenities(previewData?.Ammenities)
        // setImageData(previewData?.imageData)

    }, [previewData])



    const getPropertyData = async () => {
        setLoading(true)
        axiosInstance
            .put(`${API_URL}/properties/${Propid.id}/update/`)
            .then((response) => {
                const data = response.data;
                console.log(data, "DATA")
                setStreetAddress(data?.street_address)
                setCountry(data?.country)
                setCity(data?.city)
                setState(data?.state)
                setArea(data?.area)
                setDescription(data?.description)
                setPropertyName(data?.property_name)
                setDLDNumber(data?.dld_number)
                setPropertyBuildingType(data?.building_type)
                setPropertyPropType(data?.property_type)
                setPropertyPropLabel(data?.property_label)
                setSalePrice(data?.property_price)
                setLeasePrice(data?.lease_price_per_mo)
                setPropertyConstStatus(data?.construction_status)
                setBhkCount(data?.bhk_count)
                // setPropertyOwnership(data?.ownership)
                setPropertySize(data?.property_size)
                setBedroomNumber(data?.bedroom)
                setBathroomNumber(data?.bathroom)
                setConstructionStartDate(data?.construction_start_date)
                setEstimateDate(data?.estimated_completion)
                setLaunchDate(data?.launch_date)
                setPropertyWebsite(data?.project_website)
                setStatus(data?.property_status)
                setLatitude(data?.lat)
                setLongitude(data?.lng)
                setPaymentPlan(data?.paymentPlan)


            })
            .catch((error) => {
                toast.error(' Please Contact Administrator');
            });
    };


    const handleDeleteRow = (index) => {
        // Create a copy of bhkDetails array
        const updatedBhkDetails = [...bhkDetails];
        // Remove the row at the specified index
        updatedBhkDetails.splice(index, 1);
        // Update state with the modified array
        setBhkDetails(updatedBhkDetails);
        setBhkCount(prevCount => prevCount - 1);
    };



    useEffect(() => {
        if (isEdit === "true") {
            getPropertyData();
        }
    }, [isEdit]);

    const handleUpdate = async () => {
        const formData = new FormData();
        formData.append("amenities", JSON.stringify(amenities) || "");
        formData.append("street_address", streetAddress || "");
        formData.append("property_name", propertyName || "");
        formData.append("dld_number", dldNumber || "");
        formData.append("lat", place?.geometry?.location.lat() || latitude);
        formData.append("lng", place?.geometry?.location.lng() || longitude);
        formData.append("building_type", propertyBuildingType || "");
        formData.append("property_type", propertyPropType || "");
        formData.append("property_size", propertySize || "");
        formData.append("project_website", propertyWebsite || "");
        formData.append("country", country || "");
        formData.append("state", state || "");
        formData.append("city", city || "");
        formData.append("area", area || "");
        formData.append("description", description || "");
        formData.append("bathroom", bathroomNumber || "");
        formData.append("bedroom", bedroomNumber || "");
        formData.append("construction_status", propertyConstStatus || "");
        formData.append("launch_date", launchDate || "");
        formData.append("construction_start_date", constructionStartDate || "");
        formData.append("estimated_completion", estimateDate || "");
        formData.append("property_label", propertyPropLabel || "");
        formData.append("lease_price_per_mo", leasePrice || 0);
        formData.append("property_price", salePrice || 0);
        // formData.append("ownership", propertyOwnership || "");
        formData.append("property_status", status || "");

        formData.append("is_new_property", isNewProperty || "");
        try {
            const response = await axiosInstance.put(`${API_URL}/properties/${Propid.id}/update/`, formData);
            if (response.status === 200) {
                const responseData = response.data;
                toast.success("Property Updated")
                navigate('/property');
                setLoading(false);

            }

        } catch (error) {
            setLoading(false);
            toast.error("Something went wrong")

        }
    }



    return (
        <>
            {loading ? <Loader /> : null}
            <main id="content" className="bg-gray-01">
                <div className="px-3 px-lg-6 px-xxl-13 py-5 py-lg-10 my-profile">
                    <div className="mb-6 margin-re-top">
                        <h2 className="mb-0 text-heading fs-22 lh-15">Add new property</h2>
                    </div>

                    <div className="card bg-transparent border-0">
                        <div className="card-body py-4 py-md-0 px-0">
                            <div className="card mb-6">
                                <div className="card-body p-6">
                                    <h3 className="card-title mb-0 text-heading fs-22 lh-15">
                                        Property Type & Address
                                    </h3>
                                    <div className="form-group mt-5">
                                        <label
                                            htmlFor="title"
                                            className="text-heading margin-b-0"
                                        >
                                            Search Property Address
                                            <span className="star-mandatoryfield">*</span>
                                        </label>
                                        <AutoComplete
                                            language={"en"}
                                            apiKey={"AIzaSyDZSiGDJcQdFDZqQU5ONXF7dGkoKQePszo"}
                                            onPlaceSelected={(place) => {
                                                setPlace(place);

                                            }}
                                            className="mt-fourteen form-control form-control-lg border-0 radius-left-tb shadow-none pr-8 mb-5"
                                            placeholder="Search Property Location..."
                                            onKeyDown={(event) => {
                                                if (event?.keyCode === 13) {
                                                    event?.preventDefault();
                                                }
                                            }}
                                            options={{
                                                fields: [
                                                    "address_components",
                                                    "geometry",
                                                    "place_id",
                                                    "formatted_address"

                                                ],
                                                types: ["route"],
                                                componentRestrictions: { country: 'ae' },
                                                libraries: ["places"],
                                            }}
                                        />
                                    </div>
                                    <div className="row">

                                        <div className="col-lg-4">
                                            <div className="form-group">
                                                <label htmlFor="category" className="text-heading margin-b-0">
                                                    Address
                                                    <span className="star-mandatoryfield">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control form-control-lg border-0"
                                                    id="street_address"
                                                    name="street_address"
                                                    onChange={(e) => setStreetAddress(e?.target?.value)}
                                                    value={streetAddress}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="form-group">
                                                <label htmlFor="category" className="text-heading margin-b-0">
                                                    Country
                                                    <span className="star-mandatoryfield">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control form-control-lg border-0"
                                                    id="country"
                                                    name="country"
                                                    onChange={(e) => setCountry(e?.target?.value)}
                                                    value={country}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-4">
                                            <div className="form-group">
                                                <label htmlFor="category" className="text-heading margin-b-0">
                                                    City
                                                    {/* <span className="star-mandatoryfield">*</span> */}
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control form-control-lg border-0"
                                                    id="state"
                                                    name="state"
                                                    onChange={(e) => setState(e?.target?.value)}
                                                    value={state}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-4">
                                            <div className="form-group">
                                                <label htmlFor="category" className="text-heading margin-b-0">
                                                    Area
                                                    {/*  <span className="star-mandatoryfield">*</span> */}
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control form-control-lg border-0"
                                                    id="city"
                                                    name="city"
                                                    onChange={(e) => setCity(e?.target?.value)}
                                                    value={city}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-4">
                                            <div className="form-group">
                                                <label htmlFor="category" className="text-heading margin-b-0">
                                                    Street
                                                    {/*    */}
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control form-control-lg border-0"
                                                    id="area"
                                                    name="area"
                                                    onChange={(e) => setArea(e?.target?.value)}
                                                    value={area}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card-body py-4 py-md-0 px-0">
                            <div className="card mb-6">
                                <div className="card-body p-6">
                                    <h3 className="card-title mb-0 text-heading fs-22 lh-15">
                                        Description
                                    </h3>
                                    <div className="row">

                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <label htmlFor="category" className="text-heading margin-b-0">
                                                    Describe Details
                                                    <span className="star-mandatoryfield"></span>
                                                </label>
                                                <textarea
                                                    rows="15"
                                                    name="description"
                                                    id="description"
                                                    className="form-control form-control-lg border-0"
                                                    onChange={(e) => setDescription(e?.target?.value)}
                                                    value={description}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body py-4 py-md-0 px-0">
                            <div className="card mb-6">
                                <div className="card-body p-6">
                                    <h3 className="card-title mb-0 text-heading fs-22 lh-15">
                                        Select Property Type
                                    </h3>
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <div className="form-group">
                                                <label htmlFor="category" className="text-heading margin-b-0">
                                                    Property Name
                                                    <span className="star-mandatoryfield">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control form-control-lg border-0"
                                                    id="property_name"
                                                    name="property_name"
                                                    onChange={(e) => setPropertyName(e?.target?.value)}
                                                    value={propertyName}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="form-group">
                                                <label htmlFor="category" className="text-heading margin-b-0">
                                                    DLD Permit Number
                                                    <span className="star-mandatoryfield">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control form-control-lg border-0"
                                                    id="dld_number"
                                                    name="dld_number"
                                                    onChange={(e) => setDLDNumber(e?.target?.value)}
                                                    value={dldNumber}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="form-group">
                                                <label
                                                    htmlFor="title"
                                                    className="text-heading margin-b-0"
                                                >
                                                    Building Type{" "}
                                                    <span className="star-mandatoryfield">*</span>
                                                </label>
                                                <select
                                                    name="building_type"
                                                    id="building_type"
                                                    className="form-control form-control-lg border-0"
                                                    onChange={handleBuildingTypeChange}
                                                    value={propertyBuildingType}
                                                >
                                                    <option >Select</option>
                                                    {
                                                        buildingType?.map((item) => {
                                                            return (
                                                                <option value={item?.id}>{item?.name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>

                                        <div className="col-lg-4">
                                            <div className="form-group">
                                                <label htmlFor="category" className="text-heading margin-b-0">
                                                    Property Type
                                                    <span className="star-mandatoryfield"></span>
                                                </label>

                                                <select
                                                    id="property_type"
                                                    name="property_type"
                                                    onChange={(e) => setPropertyPropType(e?.target?.value)}
                                                    className="form-control form-control-lg border-0"
                                                    value={propertyPropType}
                                                >
                                                    <option >Select</option>
                                                    {
                                                        propertyType?.map((item) => {
                                                            return (
                                                                <option value={item?.id}>{item?.name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="form-group">
                                                <label
                                                    htmlFor="title"
                                                    className="text-heading margin-b-0"
                                                >
                                                    Property Label{" "}
                                                    <span className="star-mandatoryfield"></span>
                                                </label>
                                                <select
                                                    name="property_label"
                                                    id="property_label"
                                                    onChange={(e) => setPropertyPropLabel(e?.target?.value)}
                                                    className="form-control form-control-lg border-0"
                                                    value={propertyPropLabel}
                                                >
                                                    <option >Select</option>
                                                    {
                                                        propertyLabel.map((item) => {
                                                            return (
                                                                <>
                                                                    <option value={item?.id}>{item?.name}</option>
                                                                </>
                                                            )
                                                        })
                                                    }

                                                </select>
                                            </div>
                                        </div>
                                        {propertyPropLabel === "9c9cd2a0-ef4f-4151-b7ab-17496bd3d6c0" ? (<>
                                            <div className="col-lg-4">
                                                <div className="form-group">
                                                    <label htmlFor="category" className="text-heading margin-b-0">
                                                        Starting Price (AED)
                                                        <span className="star-mandatoryfield"></span>
                                                    </label>
                                                    <CurrencyFormat
                                                        thousandSeparator={true}
                                                        type="text"
                                                        placeholder='Total Price'
                                                        className="form-control form-control-lg border-0"
                                                        id="property_price"
                                                        name="property_price"
                                                        onChange={(e) => setSalePrice(e?.target?.value)}
                                                        value={salePrice}
                                                    />
                                                </div>
                                            </div>
                                        </>) : propertyPropLabel === "a93e1231-f698-4860-8181-ff0591ad7d9f" ? (<>
                                            <div className="col-lg-4">
                                                <div className="form-group">
                                                    <label htmlFor="category" className="text-heading margin-b-0">
                                                        Starting Price
                                                        <span className="star-mandatoryfield"></span>
                                                    </label>
                                                    <CurrencyFormat
                                                        thousandSeparator={true}
                                                        type="text"
                                                        placeholder='price/month'
                                                        className="form-control form-control-lg border-0"
                                                        id="lease_price_per_mo"
                                                        name="lease_price_per_mo"
                                                        onChange={(e) => setLeasePrice(e?.target?.value)}
                                                        value={leasePrice}
                                                    />
                                                </div>
                                            </div>
                                        </>) : null}


                                        <div className="col-lg-4">
                                            <div className="form-group">
                                                <label
                                                    htmlFor="title"
                                                    className="text-heading margin-b-0"
                                                >
                                                    Construction Status {" "}
                                                    <span className="star-mandatoryfield"></span>
                                                </label>
                                                <select
                                                    name="construction_status"
                                                    id="construction_status"
                                                    className="form-control form-control-lg border-0"

                                                    onChange={(e) => setPropertyConstStatus(e?.target?.value)}
                                                    value={propertyConstStatus}
                                                >
                                                    <option >Select</option>
                                                    {
                                                        constructionStatus?.map((item) => {
                                                            return (
                                                                <option value={item?.id}>{item?.name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        {/* <div className="col-lg-4">
                                            <div className="form-group">
                                                <label
                                                    htmlFor="title"
                                                    className="text-heading margin-b-0"
                                                >
                                                    Ownership Status {" "}
                                                    <span className="star-mandatoryfield"></span>
                                                </label>
                                                <select
                                                    name="ownership"
                                                    id="ownership"
                                                    className="form-control form-control-lg border-0"

                                                    onChange={(e) => setPropertyOwnership(e?.target?.value)}
                                                    value={propertyOwnership}
                                                >
                                                    <option >Select</option>
                                                    {
                                                        ownerShip?.map((item) => {
                                                            return (
                                                                <option value={item?.id}>{item?.name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div> */}
                                        <div className="col-lg-4">
                                            <div className="form-group">
                                                <label htmlFor="category" className="text-heading margin-b-0">
                                                    Property Size(sqft)
                                                    <span className="star-mandatoryfield"></span>
                                                </label>
                                                <input
                                                    type="number"
                                                    className="form-control form-control-lg border-0"
                                                    id="property_size"
                                                    name="property_size"
                                                    onChange={(e) => setPropertySize(e?.target?.value)}
                                                    value={propertySize}
                                                />
                                            </div>
                                        </div>
                                        {/* {propertyBuildingType === "00eb5d1b-1d34-4ebb-84d8-aa6d0a843419" ? (<>
                                            <div className="col-lg-4">
                                                <div className="form-group">
                                                    <label htmlFor="category" className="text-heading margin-b-0">
                                                        Bedroom
                                                        <span className="star-mandatoryfield">*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control form-control-lg border-0"
                                                        id="bedroom"
                                                        name="bedroom"

                                                        onChange={(e) => setBedroomNumber(e?.target?.value)}
                                                        value={bedroomNumber}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-group">
                                                    <label htmlFor="category" className="text-heading margin-b-0">
                                                        Bathroom
                                                        <span className="star-mandatoryfield">*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control form-control-lg border-0"
                                                        id="bathroom"
                                                        name="bathroom"
                                                        onChange={(e) => setBathroomNumber(e?.target?.value)}
                                                        value={bathroomNumber}
                                                    />
                                                </div>
                                            </div>

                                        </>) : null} */}
                                        {propertyConstStatus === "44e5019c-aca1-4c52-a576-8d2f6cd111ce" ? (<>
                                            <div className="col-lg-4">
                                                <div className="form-group">
                                                    <label htmlFor="category" className="text-heading margin-b-0">
                                                        Construction Start Date
                                                        <span className="star-mandatoryfield"></span>
                                                    </label>
                                                    <DatePicker
                                                        className="form-control form-control-lg border-0"
                                                        onChange={handleConstStartDate}
                                                        value={constructionStartDate}
                                                        locale="ae"
                                                        dateFormat="YYYY-MM-DDTHH:mm:ss.SSSSSS+HH:mm"
                                                        timeFormat={true} />
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-group">
                                                    <label htmlFor="category" className="text-heading margin-b-0">
                                                        Estimated completion Date
                                                        <span className="star-mandatoryfield"></span>
                                                    </label>
                                                    <DatePicker
                                                        className="form-control form-control-lg border-0"
                                                        onChange={handleEstiamateDate}
                                                        value={estimateDate} />
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-group">
                                                    <label htmlFor="category" className="text-heading margin-b-0">
                                                        Launch Date
                                                        <span className="star-mandatoryfield"></span>
                                                    </label>
                                                    <DatePicker
                                                        className="form-control form-control-lg border-0"
                                                        onChange={handleLaunchDate}
                                                        value={launchDate} />
                                                </div>
                                            </div>
                                        </>) : null}

                                        {/* <div className="col-lg-4">
                                                <div className="form-group">
                                                    <label htmlFor="category" className="text-heading margin-b-0">
                                                        Inspection Time
                                                        <span className="star-mandatoryfield"></span>
                                                    </label>

                                                    <TimeRangePicker
                                                        amPmAriaLabel="Select AM/PM"
                                                        disableClock="false"
                                                        hourPlaceholder="hh"
                                                        minutePlaceholder="mm"
                                                        className="form-control form-control-lg border-0"
                                                        onChange={handleInspection}
                                                        value={[inspectionFromTime, inspectionToTime]}
                                                    />
                                                </div>
                                            </div> */}
                                        <div className="col-lg-4">
                                            <div className="form-group">
                                                <label htmlFor="category" className="text-heading margin-b-0">
                                                    Project Website
                                                    <span className="star-mandatoryfield"></span>
                                                </label>
                                                <input
                                                    type="url"
                                                    className="form-control form-control-lg border-0"
                                                    id="project_website"
                                                    name="project_website"
                                                    onChange={(e) => setPropertyWebsite(e?.target?.value)}
                                                    value={propertyWebsite}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="form-group">
                                                <label
                                                    htmlFor="title"
                                                    className="text-heading margin-b-0"
                                                >
                                                    Property Status{" "}
                                                    <span className="star-mandatoryfield"></span>
                                                </label>
                                                <select
                                                    name="property_status"
                                                    id="property_status"
                                                    className="form-control form-control-lg border-0"
                                                    onChange={(e) => setStatus(e?.target?.value)}
                                                    value={status}
                                                >
                                                    <option >Select</option>
                                                    {
                                                        propertyStatus.map((item) => {
                                                            return (
                                                                <>
                                                                    <option value={item?.id}>{item?.name}</option>
                                                                </>
                                                            )
                                                        })
                                                    }

                                                </select>
                                            </div>
                                        </div>
                                        {propertyBuildingType === "00eb5d1b-1d34-4ebb-84d8-aa6d0a843419" ? (<>
                                            <div className="col-lg-4 mt-6">
                                                <div className="form-group">
                                                    <a onClick={() => {
                                                        handleShow();
                                                        AddBHKInput();
                                                    }}
                                                        className=" btn bg-blue text-white hover-saffron btn-lg btn-block add-pd-label-uploader">Add BHK Details</a>
                                                </div>
                                            </div>
                                        </>) : null}
                                        <div className="col-lg-4 mt-6">
                                            <div className="form-group">
                                                {/* <button className='btn bg-blue text-white' onChange={floorPdfHanler}>Upload Floor Plan</button> */}
                                                <input
                                                    type="file"
                                                    className="custom-file-input add-pstion-abs"
                                                    id="paymentFile"
                                                    name="profile"
                                                    accept="application/pdf"
                                                    onChange={(e) => paymentPdfHandler(e)}
                                                />

                                                <label
                                                    className="btn bg-blue text-white hover-saffron btn-lg btn-block add-pd-label-uploader"
                                                    htmlFor="paymentFile"
                                                >
                                                    <span className="d-inline-block mr-1">
                                                        <i className="fal fa-cloud-upload"></i>
                                                    </span>
                                                    Upload Payment Plan
                                                </label>
                                            </div>
                                        </div>

                                        {isEdit !== 'true' && (<>
                                            <div className="col-lg-4">
                                                <div className="form-group">
                                                    <label htmlFor="category"
                                                        className="text-heading">No. of Floors</label>
                                                    <input type="number" name="no_of_floor"
                                                        className="form-control form-control-lg border-0"
                                                        placeholder="Enter units"
                                                        defaultValue={numberBuilding}
                                                        onChange={HandleSelect} />
                                                </div>
                                            </div>

                                            {
                                                inputList?.map((x, i) => {
                                                    return (


                                                        <div className="row">
                                                            <h3 className="card-title mb-2 mt-2 text-heading fs-18 lh-15">Add Details For Floor {i + 1}</h3>
                                                            <div className="col-lg-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="category"
                                                                        className="text-heading">Floor Name</label>
                                                                    <input type="text" name="name"
                                                                        value={x?.name}
                                                                        className="form-control form-control-lg border-0"
                                                                        placeholder="Enter Floor Name"
                                                                        onChange={(e) => HandleInputChange(e, i)} />

                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="category"
                                                                        className="text-heading">No. Of Units</label>
                                                                    <input type="number" name="no_of_unit"
                                                                        className="form-control form-control-lg border-0"
                                                                        placeholder="Enter units"
                                                                        value={x?.no_of_unit}
                                                                        onChange={(e) => HandleInputChange(e, i)} />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 mt-6">
                                                                <div className="form-group">
                                                                    {/* <button className='btn bg-blue text-white' onChange={floorPdfHanler}>Upload Floor Plan</button> */}
                                                                    <input
                                                                        type="file"
                                                                        className="custom-file-input add-pstion-abs"
                                                                        id="customFile"
                                                                        name="profile"
                                                                        accept="application/pdf"
                                                                        // onChange={floorPdfHandler}
                                                                        onChange={(e) => floorPdfHandler(e, i)} />

                                                                    <label
                                                                        className="btn bg-blue text-white hover-saffron btn-lg btn-block add-pd-label-uploader"
                                                                        htmlFor="customFile"
                                                                    >
                                                                        <span className="d-inline-block mr-1">
                                                                            <i className="fal fa-cloud-upload"></i>
                                                                        </span>
                                                                        Upload Floor Plan
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    )
                                                })
                                            }
                                        </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {bhkDetails?.length > 0 ? (
                            <div className="card-body py-4 py-md-0 px-0">
                                <div className="card mb-6">
                                    <div className="card-body p-6">
                                        <h3 className="card-title mb-0 text-heading fs-22 lh-15">
                                            BHK Details
                                        </h3>
                                        <div className="col-lg-12">

                                            <div className="py-3 border-bottom">
                                                <Accordion allowOneExpanded preExpanded={['item-0']}>
                                                    {bhkDetails.map((bhk, index) => (
                                                        <AccordionItem key={index} uuid={`item-${index}`}>
                                                            <AccordionItemHeading>
                                                                <AccordionItemButton>
                                                                    <div className="floor-title" >
                                                                        <div className="d-flex justify-content-between">
                                                                            <div className="">
                                                                                <h4 className="card-title fs-16  mb-1 ">
                                                                                    {bhk.name}{" "} with {" "}{bhk.size} Size.
                                                                                </h4>
                                                                            </div>
                                                                            <div className='mr-2'>
                                                                                <a className="delete-icon-container" onClick={() => handleDeleteRow(index)}>
                                                                                    <i className="fa fa-trash" aria-hidden="true"></i>
                                                                                    <span className="delete-info">Delete</span> {/* Additional info */}
                                                                                </a>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </AccordionItemButton>
                                                            </AccordionItemHeading>
                                                            <AccordionItemPanel>
                                                                <div className="floor-plan-sr">
                                                                    <div className="card border-0 shadow-xxs-2">
                                                                        <div>
                                                                            <div className="card-body mb-3">
                                                                                <div className="">
                                                                                    <iframe
                                                                                        // ref={iframeRefs.current[index]}
                                                                                        src={bhk.file_url}
                                                                                        style={{ width: "100%", height: "500px" }}
                                                                                        title="Floor Plan"
                                                                                    ></iframe>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </AccordionItemPanel>
                                                        </AccordionItem>
                                                    ))}
                                                </Accordion>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                        {paymentPlan ? (
                            <div className="card-body py-4 py-md-0 px-0">
                                <div className="card mb-6">
                                    <div className="card-body p-6">
                                        <h3 className="card-title mb-0 text-heading fs-22 lh-15">
                                            Payment Plan
                                        </h3>
                                        <div className="col-lg-12">

                                            <div className="py-3 border-bottom">

                                                <iframe
                                                    // ref={iframeRefs.current[index]}
                                                    src={paymentPlanUrl}
                                                    style={{ width: "100%", height: "500px" }}

                                                ></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                        {/* floor plan Details */}
                        {isEdit !== 'true' && (<>
                            {inputList?.length > 0 ? (
                                <div className="card-body py-4 py-md-0 px-0">
                                    <div className="card mb-6">
                                        <div className="card-body p-6">
                                            <h3 className="card-title mb-0 text-heading fs-22 lh-15">
                                                Floor Plan Details
                                            </h3>
                                            <div className="col-lg-12">

                                                <div className="py-3 border-bottom">
                                                    <Accordion allowOneExpanded preExpanded={['item-0']}>
                                                        {inputList.map((floors, index) => (
                                                            <AccordionItem key={index} uuid={`item-${index}`}>
                                                                <AccordionItemHeading>
                                                                    <AccordionItemButton>
                                                                        <div className="floor-title" >
                                                                            <div className="d-flex justify-content-between">
                                                                                <div className="">
                                                                                    <h4 className="card-title fs-16  mb-1 ">
                                                                                        {floors.name}{" "} with {" "}{floors.no_of_unit} units.
                                                                                    </h4>
                                                                                </div>
                                                                            </div>


                                                                        </div>
                                                                    </AccordionItemButton>
                                                                </AccordionItemHeading>
                                                                <AccordionItemPanel>
                                                                    <div className="floor-plan-sr">
                                                                        <div className="card border-0 shadow-xxs-2">
                                                                            <div>
                                                                                <div className="card-body mb-3">
                                                                                    <div className="">
                                                                                        <iframe
                                                                                            // ref={iframeRefs.current[index]}
                                                                                            src={floors.pdf_url}
                                                                                            style={{ width: "100%", height: "500px" }}
                                                                                            title="Floor Plan"
                                                                                        ></iframe>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </AccordionItemPanel>
                                                            </AccordionItem>
                                                        ))}
                                                    </Accordion>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                        </>
                        )}
                        {/* end */}
                        {isEdit !== 'true' && (<>
                            <div className="card-body py-4 py-md-0 px-0">
                                <div className="card mb-6">
                                    <div className="card-body p-6">
                                        <h3 className="card-title mb-0 text-heading fs-22 lh-15">
                                            Media
                                        </h3>
                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                {/* upload featured image */}
                                                <div className="row">
                                                    <div className='col-lg-4'>
                                                        <div className=" img-uploader-cnt-inf">
                                                            {isMyProfile ? (
                                                                <img
                                                                    src={isMyProfile}
                                                                    alt="image"
                                                                    className="w-100 add-border-other"
                                                                />
                                                            ) : myProfile ? (
                                                                <>
                                                                    <img
                                                                        src={myProfile}
                                                                        alt="image"
                                                                        className="w-100 add-border-other"
                                                                    />
                                                                </>
                                                            ) : (<>
                                                                <img
                                                                    src="../../../images/demo-property.jpg"
                                                                    alt="image"
                                                                    className="w-100 add-border-other"
                                                                />
                                                            </>)}

                                                        </div>
                                                        <div className=" mb-6">
                                                            <div className="custom-file mt-4 h-auto">
                                                                <input
                                                                    type="file"
                                                                    className="custom-file-input add-pstion-abs"
                                                                    id="customFile"
                                                                    name="profile"
                                                                    accept="image/*"
                                                                    onChange={PorfileImgHandler}
                                                                />
                                                                <label
                                                                    className="btn bg-blue text-white hover-saffron btn-lg btn-block add-pd-label-uploader"
                                                                    htmlFor="customFile"
                                                                >
                                                                    <span className="d-inline-block mr-1">
                                                                        <i className="fal fa-cloud-upload"></i>
                                                                    </span>
                                                                    Upload Featured Image
                                                                    <span className="star-mandatoryfield" >*</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='col-lg-4'>
                                                        <div className=" img-uploader-cnt-inf">
                                                            {isMyAVA ? (
                                                                <iframe src={isMyAVA} alt="image" className="add-border-other" />
                                                            ) : myAVA ? (
                                                                <>
                                                                    <img
                                                                        src={myAVA}
                                                                        alt="image"
                                                                        className="w-100 add-border-other"
                                                                    />
                                                                </>
                                                            )
                                                                : (<>
                                                                    <img
                                                                        src="../../../images/dummy-ava-plan.jpg"
                                                                        alt="image"
                                                                        className="w-100 add-border-other"
                                                                    />
                                                                </>)}


                                                        </div>
                                                        <div className=" mb-6">
                                                            <div className="custom-file mt-4 h-auto">
                                                                <input
                                                                    type="file"
                                                                    className="custom-file-input add-pstion-abs"
                                                                    id="customFile"
                                                                    name="profile"
                                                                    accept="application/pdf"
                                                                    onChange={AVAHandler}
                                                                />
                                                                <label
                                                                    className="btn bg-blue text-white hover-saffron btn-lg btn-block add-pd-label-uploader"
                                                                    htmlFor="customFile"
                                                                >
                                                                    <span className="d-inline-block mr-1">
                                                                        <i className="fal fa-cloud-upload"></i>
                                                                    </span>
                                                                    Upload Availability Plan
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='col-lg-4'>
                                                        <div className=" img-uploader-cnt-inf">
                                                            {isBrochure ? (
                                                                <iframe src={isBrochure} alt="image" className="add-border-other" />
                                                            ) : myBrochure ? (

                                                                <iframe src={myBrochure} alt="image" className="add-border-other" />

                                                            ) : (
                                                                <img
                                                                    src="../../../images/upload-pdf-dummy.jpg"
                                                                    alt="image"
                                                                    className="w-100 h-500 add-border-other"
                                                                />
                                                            )}

                                                        </div>
                                                        <div className=" mb-6">
                                                            <div className="custom-file mt-4 h-auto">
                                                                <input
                                                                    type="file"
                                                                    className="custom-file-input add-pstion-abs"
                                                                    id="customFile"
                                                                    name="profile"
                                                                    accept="application/pdf"
                                                                    onChange={brochureHandler}
                                                                />
                                                                <label
                                                                    className="btn bg-blue text-white hover-saffron btn-lg btn-block add-pd-label-uploader"
                                                                    htmlFor="customFile"
                                                                >
                                                                    <span className="d-inline-block mr-1">
                                                                        <i className="fal fa-cloud-upload"></i>
                                                                    </span>
                                                                    Upload Property Brochure
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> </>
                        )}



                        <div className="card-body py-4 py-md-0 px-0">
                            <div className="card mb-6">
                                <div className="card-body p-6">
                                    <h3 className="card-title mb-0 text-heading fs-22 lh-15">
                                        Property Features and Amenties
                                    </h3>
                                    <div className="card bg-transparent border-0">
                                        <div className="card-body py-4 py-md-0 px-0">
                                            <div className="card mb-6">
                                                <div className="card-body p-6">
                                                    <h3 className="card-title mb-2 text-heading fs-22 lh-15">
                                                        Amenities
                                                    </h3>
                                                    <div className="row">
                                                        {allAmenities?.map((item, index) => {
                                                            return (
                                                                <div className="col-lg-3">
                                                                    <div className="form-group mb-lg-0 py-1">
                                                                        <div className="custom-control custom-checkbox">
                                                                            <div>
                                                                                <input
                                                                                    type="checkbox"
                                                                                    id="Down"
                                                                                    className="custom-control-input"
                                                                                    name="features[]"
                                                                                    value={item?.name}
                                                                                    checked={
                                                                                        Ammenities?.find(
                                                                                            (i) => i?.id === item?.id
                                                                                        )
                                                                                            ? true
                                                                                            : false
                                                                                    }
                                                                                />
                                                                                <label
                                                                                    className="custom-control-label add-color-with-logo color-333"
                                                                                    htmlFor="Down"
                                                                                    value={item?.name}
                                                                                    id="amenities"
                                                                                    name="amenities"
                                                                                    // {...register("amenities")}
                                                                                    onClick={() => onAmenities(item)}
                                                                                // onChange={handleInput}
                                                                                >
                                                                                    {item?.name}
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        {isEdit !== 'true' && (<>
                            <ImageUploader
                                setImageData={setImageData}
                                setVideoData={setVideoData}
                                selectedImage={selectedImage}
                                setSelectedImage={setSelectedImage}
                                selectedVideo={selectedVideo}
                                SetSelectedVideo={SetSelectedVideo}
                            />
                        </>
                        )}
                        <div className="center-button mb-5">
                            {isEdit === "true" ? (<>
                                <button
                                    onClick={handleUpdate}
                                    className="btn btn-lg bg-blue text-white hover-saffron next-button mb-10 c-padding f-right">
                                    Update & Continue

                                    <span className="d-inline-block ml-2 fs-16">
                                        <i className="fal fa-long-arrow-right"></i>
                                    </span>


                                </button>
                            </>) : (<>
                                <button
                                    onClick={handleSubmit}
                                    className="btn btn-lg bg-blue text-white hover-saffron next-button mb-10 c-padding f-right">
                                    Save & Continue

                                    <span className="d-inline-block ml-2 fs-16">
                                        <i className="fal fa-long-arrow-right"></i>
                                    </span>


                                </button>
                            </>)}


                        </div>
                    </div >


                </div >
            </main >
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Add BHK Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {bhkDetails?.map((bhk, index) => (
                        <div className="row" key={index}>
                            <div className="col-lg-4">
                                <div className="form-group">
                                    <input
                                        type="text"
                                        name="name"
                                        value={bhk?.name}
                                        onChange={e => handleBhkDetailChange(index, e)}
                                        className="form-control form-control-lg border-0"
                                        placeholder="Enter BHK Type"
                                    />
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="form-group">
                                    <input
                                        type="number"
                                        name="size"
                                        value={bhk?.size}
                                        onChange={e => handleBhkDetailChange(index, e)}
                                        className="form-control form-control-lg border-0"
                                        placeholder="Enter BHK Size"
                                    />
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="form-group">
                                    <input
                                        type="file"
                                        className="custom-file-input add-pstion-abs"
                                        id={`customFile${index}`}
                                        name="profile"
                                        accept="application/pdf"
                                        onChange={e => handleFileChange(index, e)}
                                    />
                                    <label
                                        className="btn bg-blue text-white hover-saffron"
                                        htmlFor={`customFile${index}`}
                                        style={{ padding: "14px 29px" }}
                                    >
                                        Upload Floor Plan
                                    </label>
                                </div>
                            </div>
                        </div>
                    ))}

                </Modal.Body>

                <Modal.Footer>
                    <div className="col-lg-3 ">
                        <div className="form-group">

                            <a style={{ padding: "7px 8px 7px 8px", margin: "17px 0 0 34px" }}
                                onClick={handleAddMoreType}
                                className="btn bg-blue text-white hover-saffron " >
                                + Add More Type
                            </a>
                        </div>
                    </div>
                    <Button
                        variant="success"
                        onClick={handleClose}

                    >
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default AddProperty