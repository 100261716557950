import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import Avatar from "react-avatar";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import axiosInstance from "../../../interceptor/axiosInstance";
import { toast } from "react-toastify";
import Loader from "../../../Loader";
const API_URL = process.env.REACT_APP_API_URL;

const BusinessLead = () => {
  moment.locale('en');
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const [userLeads, setUserLeads] = useState([]);

  const [loading, setLoading] = useState(false);
  const [LeadTotal, setLeadTotal] = useState();

  const getLeadData = () => {
    setLoading(true);
    axiosInstance
      .get(`${API_URL}/account/contact/get/`)
      .then((response) => {
        if (response.data) {
          setUserLeads(response.data);
          setLoading(false);
        } else {
          
          setLoading(false);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          
          setLoading(false);
          toast.error(error.response?.data?.detail);
        } else {
          setLoading(false);
          toast.error(' Please Contact Administrator');
        }
      });
  };

  useEffect(() => {
    getLeadData();
  }, []);
  return (
    <>
    {loading ? <Loader /> : null}
      <main id="content" className="bg-gray-01 margin-re-top">
        <div className="px-3 px-lg-6 px-xxl-13 py-5 py-lg-10">
          <div className="d-flex flex-wrap flex-md-nowrap mb-6">
            <div className="mr-0 mr-md-auto">
              <h2 className="mt-5 mb-0 text-heading fs-22 lh-15">
                Business Leads
                <span className="badge badge-white badge-pill text-blue fs-18 font-weight-bold ml-2">
                  {userLeads.length}
                </span>
              </h2>
            </div>
           
              <>
                <div>
                  <Link
                    to="property"
                    className="btn bg-blue text-white hover-saffron btn-lg"
                  >
                    <span>Property Leads</span>
                    <span className="d-inline-block ml-1 fs-20 lh-1"></span>
                  </Link>
                </div>
              </>
           
          </div>
          {userLeads.length > 0 ? (
            <>
              <div className="card border-0 mb-4 header-r-logout">

                {userLeads
                 .sort((a, b) => new Date(b.date_published) - new Date(a.date_published))
                .map((item, key) => {
                  return (
                    <Accordion
                      expanded={expanded === `panel${key}`}
                      onChange={handleChange(`panel${key}`)}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography sx={{ width: "25%", flexShrink: 0 }}>
                          <Avatar
                            name={item?.name}
                            round={true}
                            size="50"
                            color="#529BE8"
                          />
                        </Typography>
                        <Typography
                          sx={{
                            marginTop: "15px",
                            width: "75%",
                            color: "text.secondary",
                          }}
                        >
                          {item?.name}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography
                          className="
                            "
                        >
                          <span className="fw-bold fs-18">Published Date:</span>
                          {moment(item?.date_published).format("DD-MMM-YY")}
                          <br />
                          <span className="fw-bold fs-18">Message: </span>
                          {item?.message}
                          <br />
                          <span className="fw-bold fs-18">Email: </span>
                          <a
                            href={`mailto:${item?.email}`}
                            className="mb-0 text-heading border-dark hover-saffron-text lh-1 cl-text-hover"
                          >
                            {item?.email}
                          </a>
                          <br />
                          <span className="fw-bold fs-18">Phone Number:</span>
                          {item?.phone}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  );
                })}

                {/*  </div> */}
              </div>
              <div className="card border-0 mb-4 header-user-icon">
                <div className="card-body p-0">
                  {/* lead */}

                  {userLeads.map((item, key) => {
                    return (
                      <Accordion
                        expanded={expanded === `panel${key}`}
                        onChange={handleChange(`panel${key}`)}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <Typography sx={{ width: "10%", flexShrink: 0 }}>
                            <Avatar
                              name={item?.name}
                              round={true}
                              size="50"
                              color="#529BE8"
                            />
                          </Typography>
                          <Typography
                            sx={{
                              marginTop: "15px",
                              width: "25%",
                              color: "text.secondary",
                            }}
                          >
                            {item?.name}
                          </Typography>
                          <Typography
                            sx={{
                              marginTop: "15px",
                              width: "30%",
                              color: "text.secondary",
                            }}
                          >
                            <a
                              href={`mailto:${item?.email}`}
                              className="mb-0 text-heading border-dark hover-saffron-text lh-1 cl-text-hover"
                            >
                              {item?.email}
                            </a>
                          </Typography>
                          <Typography
                            sx={{
                              marginTop: "15px",
                              width: "30%",
                              color: "text.secondary",
                            }}
                          >
                            Phone No. {item?.mobile}
                          </Typography>
                          <Typography
                            sx={{
                              marginTop: "15px",
                              width: "15%",
                              color: "text.secondary",
                            }}
                          >
                            {moment(item?.create_at).format("DD-MMM-YYYY")}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography className="mx-10">
                            {item?.msg}
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
                </div>
              </div>
            </>
          ) : null}
        </div>
      </main>
    </>
  );
};
export default BusinessLead;
