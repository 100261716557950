import React, { useEffect, useState } from "react";
import Footer from "../footer/Footer";
import Header from "../header/Header";
import axios from "axios";

const Terms = () => {
    const[termsData,setTermsData]= useState('');
    const API_URL = process.env.REACT_APP_API_URL;
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, []);
    const getTerm = async () => {
       try {
        const response = await axios.get(
            `${API_URL}/track/terms`
          );
          if (response.status === 200) {
            const responseData = response.data.text;
    
            setTermsData(responseData);
           
          } 
        } catch (err) {
          
        }
      };
      useEffect(()=>{
        getTerm();
      },[])
    return (<>
        <Header />
        <main id="content " className="  mt-100">

            <section className="bg-patten-03 bg-gray-01 py-5 " data-animated-id="3" >
                <div className="container ">
                    <div className="card border-0 mt-5 z-index-3 mb-12">
                        <div className="card-body p-6 px-lg-14 py-lg-10">
                            <h2 className="text-heading mb-4 fs-20  text-center lh-22 px-6">Terms of Use </h2>
                            <div dangerouslySetInnerHTML={{ __html: termsData }}></div>

                        </div>
                    </div>
                </div>
            </section>
        </main>
        <Footer />
    </>)
}
export default Terms;