import React, { useEffect, useState } from "react";
import moment from "moment";
import Avatar from "react-avatar";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import axiosInstance from "../../../interceptor/axiosInstance";
import { toast } from "react-toastify";
import _ from 'lodash';

const API_URL = process.env.REACT_APP_API_URL;

function LeadCard() {
  moment.locale('en');
  const [leaddata, setLeadData] = useState([]);

  const [expanded, setExpanded] = useState({});

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded({ ...expanded, [panel]: isExpanded });
  };

  const groupedData = _.groupBy(leaddata, "properties_name");
 

  const getLeadData = () => {
    axiosInstance
      .get(`${API_URL}/properties/user/contact/get/`)
      .then((response) => {
        if (response.data) {
          setLeadData(response.data);
        } else {
          
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
        
          toast.error(error.response?.data?.detail);
        } else {
          toast.error(' Please Contact Administrator');
        }
      });
  };

  useEffect(() => {
    getLeadData();
  }, []);

  return (
    <>
      {Object.keys(groupedData).map((key) => {
        const items = groupedData[key];
        return (
          <Accordion
            key={key}
            expanded={expanded[key]}
            onChange={handleChange(key)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel-${key}-content`}
              id={`panel-${key}-header`}
            >
              <Typography
                sx={{
                  marginTop: "15px",
                  width: "52%",
                  
                }}
              >
                {items[0]?.properties_name}
              </Typography>

              <Typography
                sx={{
                  marginTop: "15px",
                  width: "18%",
                  
                }}
              >
                {items[0]?.building_type_name.toUpperCase()}
              </Typography>

              <Typography
                sx={{
                  marginTop: "15px",
                  width: "10%",
                  
                }}
              >
                {items.length}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="mx-10">
                <div className="card">
                  <div className="able  border rounded-lg  bg-grey-01 bg-blue text-white">
                    <div className="row ">
                      <div className="col-3 border-top-0 px-6 pt-5 pb-4">
                        Name
                      </div>
                      <div className="col-3 border-top-0  pt-5 pb-4">
                        Email
                      </div>
                      <div className="col-3 border-top-0 pt-5 pb-4">
                        Phone
                      </div>
                      <div className="col-2 border-top-0 pt-5 pb-4 ">Date</div>
                    </div>
                  </div>
                  {items.map((item, index) => (
                    <Accordion
                      key={index}
                      expanded={expanded[`message-${key}-${index}`]}
                      onChange={handleChange(`message-${key}-${index}`)}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`message-${key}-${index}-content`}
                        id={`message-${key}-${index}-header`}
                      >
                        <Typography
                          sx={{
                            marginTop: "15px",
                            width: "30%",
                           
                          }}
                        >
                          {item.name}
                        </Typography>
                        <Typography
                          sx={{
                            marginTop: "15px",
                            width: "35%",
                            
                          }}
                        >
                        
                            {item.email}
                         
                        </Typography>
                        <Typography
                          sx={{
                            marginTop: "15px",
                            width: "30%",
                            
                          }}
                        >
                          {item.mobile}
                        </Typography>
                        <Typography
                          sx={{
                            marginTop: "15px",
                            width: "30%",
                            
                          }}
                        >
                          {moment(item.create_at).format("DD-MMM-YYYY")}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails className="bg-gray-01">
                        <Typography className="mx-10">
                          <div className="ml-2">Message: {item?.message}</div>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </>
  );
}

export default LeadCard;
