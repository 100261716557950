import React from 'react'
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
// Create styles
const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
    
    },
   
  });
const MyDoc = () => {
  return (
    <Document>
    <Page size="A4" style={styles.page}>
      <View >
        <Text>Section #1</Text>
      </View>
      <View >
        <Text>Section #2</Text>
      </View>
    </Page>
  </Document>
  )
}

export default MyDoc