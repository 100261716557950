import React, { useEffect, useState } from "react";
import moment from "moment";
import Avatar from "react-avatar";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import axiosInstance from "../../../interceptor/axiosInstance";
import { toast } from "react-toastify";
import ReactStars from "react-rating-stars-component";
import _ from 'lodash';
import { Button, Modal } from "react-bootstrap";

const API_URL = process.env.REACT_APP_API_URL;

const PropertyReview = () => {
  const [reviewData, setreviewData] = useState([]);
  const [reviewId, setreviewId] = useState("");
  console.log(reviewId,"this is reviewId");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show, setShow] = useState(false);


 const handleInactiveShow = () => setshowinactive(true);
const handleInactiveClose = () => setshowinactive(false);
const [showinactive,setshowinactive] = useState(false);





  const [expanded, setExpanded] = useState({});
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded({ ...expanded, [panel]: isExpanded });
  };

  const groupedData = _.groupBy(reviewData, "property_name");
  console.log(groupedData,"this is grouped Data");
  

  const getReviewData = () => {
    axiosInstance
      .get(`${API_URL}/properties/review/get/`)
      .then((response) => {
        if (response.data) {
          setreviewData(response.data.filter(item=>item?.average_review>0));
        } else {
          
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
         
          toast.error(error.response?.data?.detail);
        } else {
          toast.error(' Please Contact Administrator');
        }
      });
  };

  useEffect(() => {
    getReviewData();
  }, []);
  const handleActive = async () => {
    try {
     
      const response = await axiosInstance.put(
        `${API_URL}/properties/user/review/${reviewId}/update/`,{is_active:true}
      );
      
console.log(response,"this is resposne")
      if (response.status === 200) {

        
     
        toast.success("Review  Successfully Activated");
        setreviewId("");
        getReviewData();

        
      } else {
        // Handle other status codes or errors as needed
       
      }
    } catch (error) {
      // Handle network errors or exceptions
      toast.error(' Please Contact Administrator');
    }
   
  };
  const handleInactive = async () => {
    try {
     
      const response = await axiosInstance.put(
        `${API_URL}/properties/user/review/${reviewId}/update/`,{is_active:false}
      );
      
console.log(response,"this is resposne")
      if (response.status === 200) {
        toast.success("Review  Successfully InActivated");
        setreviewId("");
    getReviewData();
 
    

        
      } else {
        // Handle other status codes or errors as needed
       
      }
    } catch (error) {
      // Handle network errors or exceptions
      toast.error(' Please Contact Administrator');
    }
    
    
  };

  return (
    <>
      {Object.keys(reviewData).map((key) => {
        const items = reviewData[key];
        return (
          <Accordion
            key={key}
            expanded={expanded[key]}
            onChange={handleChange(key)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel-${key}-content`}
              id={`panel-${key}-header`}
            >
              <Typography
                sx={{
                  marginTop: "15px",
                  width: "52%",
                  color: "text.secondary",
                }}
              >
                {items?.property_name}
              </Typography>

              <Typography
                sx={{
                  marginTop: "15px",
                  width: "18%",
                  color: "text.secondary",
                }}
              >
                <ReactStars
                      value={items?.average_review}
                      count={5}
                      edit={false}
                      size={26}
                    />
              </Typography>

              <Typography
                sx={{
                  marginTop: "15px",
                  width: "10%",
                  color: "text.secondary",
                }}
              >
                {items?.total_review}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="mx-10">
                <div className="card">
                  <div className="able  border rounded-lg  bg-blue text-white">
                    <div className="row ">
                      <div className="col-3 border-top-0 px-6 pt-5 pb-4">
                        Name
                      </div>
                      <div className="col-3 border-top-0  pt-5 pb-4">
                        Email
                      </div>
                      <div className="col-3 border-top-0 pt-5 pb-4">
                        Phone
                      </div>
                      <div className="col border-top-0 pt-5 pb-4 ">
                        Date
                        </div>
                        <div className="col border-top-0 pt-5 pb-4">
                        Status
                      </div>

                    </div>
                  </div>
                  {items?.total_property_review.map((item, index) => (
                    <Accordion
                      key={index}
                      expanded={expanded[`message-${key}-${index}`]}
                      onChange={handleChange(`message-${key}-${index}`)}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`message-${key}-${index}-content`}
                        id={`message-${key}-${index}-header`}
                      >
                        <Typography
                          sx={{
                            marginTop: "15px",
                            width: "25%",
                            color: "text.secondary",
                          }}
                        >
                          {item.user_name}
                        </Typography>
                        <Typography
                          sx={{
                            marginTop: "15px",
                            width: "25%",
                            color: "text.secondary",
                          }}
                        >
                          <a
                            href={`mailto:${item.user_email}`}
                            className="mb-0 text-heading border-dark hover-saffron-text lh-1 cl-text-hover"
                          >
                            {item.user_email}
                          </a>
                        </Typography>
                        <Typography
                          sx={{
                            marginTop: "15px",
                            width: "25%",
                            color: "text.secondary",
                          }}
                        >
                          {item.user_phone}
                        </Typography>
                        <Typography
                          sx={{
                            marginTop: "15px",
                            width: "20%",
                            color: "text.secondary",
                          }}
                        >
                          {moment(item.create_at).format("DD-MM-YYYY")}
                        </Typography>
                       { item?.is_active==false ? (<Typography
                          sx={{
                            marginTop: "15px",
                            width: "5%",
                            color: "text.secondary",
                          }}
                        >
                          <a
                              data-bs-toggle="tooltip"
                              title="Active"
                              className="d-inline-block fs-18  text-blue"
                              onClick={() => {
                                
                                 setreviewId(item?.id);
                                  handleShow();
                              }}
                            >
                    <i class="bi bi-clipboard-check-fill fa-lg"></i></a>
                        </Typography>):
                        <Typography
                        sx={{
                          marginTop: "15px",
                          width: "5%",
                          color: "text.secondary",
                        }}
                      >
                        <a
                            data-bs-toggle="tooltip"
                            title="InActive"
                            className="d-inline-block fs-18  text-orange"
                            onClick={() => {
                              
                               setreviewId(item?.id);
                                handleInactiveShow();
                            }}
                          >
                  <i class="bi bi-clipboard-check-fill fa-lg"></i></a>
                      </Typography> }
                      </AccordionSummary>
                      <AccordionDetails className="bg-gray-01">
                        <Typography className="mx-10">
                          <div className="ml-2">Message: {item?.description}</div>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>
        );
      })}
            <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Review Active</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Do You Want to Activate This Review
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button
                variant="success"
                onClick={() => {
                   handleClose();
                   handleActive();
                }}
              >
                Active
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal show={showinactive} onHide={handleInactiveClose}>
            <Modal.Header closeButton>
              <Modal.Title>Review Active</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Do You Want to Deactivate This Review
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button
                variant="danger"
                onClick={() => {
                   handleInactiveClose();
                   handleInactive();
                }}
              >
                InActive
              </Button>
            </Modal.Footer>
          </Modal>
    </>
  );
}

export default PropertyReview;
