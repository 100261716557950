import React from "react";
import { useState } from "react";
import Chart from "react-apexcharts";
import axiosInstance from "../../interceptor/axiosInstance";
import { useEffect } from "react";
import { Link } from "react-router-dom";
const API_URL = process.env.REACT_APP_API_URL;
const ContactGraph = (props) => {
  const [AllProperties, setAllProperties] = useState([]);
  useEffect(() => {
    setAllProperties(props?.AllProperties);
  }, [props.AllProperties]);

  const phone = AllProperties.filter(
    (item) => item?.id === props.selectedProperty?.id
  );

  let data = [1, 9, 6, 3, 8, 5, 0, 7, 1, 5, 7, 3];
  let calldata = [1, 2, 3, 4, 2, 3, 5, 2, 4, 6, 4, 1];
  if (phone.length > 0) {
    const mycall = phone[0]?.count_phone_call_month;
    const whatsapp = phone[0]?.count_whatsapp_call_month;

    if (whatsapp !== undefined) {
      // If whatsapp is defined, use its data
      data = whatsapp;
    } else {
      // If whatsapp is not defined, use some default data
      data = [1, 9, 6, 3, 8, 5, 0, 7, 1, 5, 7, 3];
    }

    if (mycall !== undefined) {
      // If mycall is defined, use its data
      calldata = mycall;
    } else {
      // If mycall is not defined, use some default data
      calldata = [1, 2, 3, 4, 2, 3, 5, 2, 4, 6, 4, 1];
    }
  } else {
  }

  const options = {
    series: [
      {
        name: "Whatsapp",
        data: data,
      },
      {
        name: "Call",
        data: calldata,
      },
    ],
    chart: {
      height: 350,
      type: "line",
      dropShadow: {
        enabled: true,
        color: "#000",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2,
      },
      toolbar: {
        show: true,
      },
    },
    colors: ["#1a63c6", "#fe5900"],
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: "smooth",
    },
    title: {
      text: "Stats By Intercation Count",
      align: "left",
    },
    grid: {
      borderColor: "#e7e7e7",
      row: {
        colors: ["#f3f3f3", "transparent"],
        opacity: 0.5,
        height: 40,
      },
    },
    markers: {
      size: 1,
    },
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ],
    xaxis: {
      type: "Month",
    },
    yaxis: {
      title: {
        text: "Contact Count",
      },
      min: 0,
      max: 10,
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
      floating: true,
      offsetY: -20,
      offsetX: -5,
    },
  };
  return (
    <div>
      <Link to="/stats">
        <Chart
          options={options}
          series={options.series}
          type="line"
          height={350}
        />
      </Link>
    </div>
  );
};

export default ContactGraph;
