
import React from "react";
import { useForm } from "react-hook-form";
import axios from "axios";

import { toast } from "react-toastify";
const ApiUrl = process.env.REACT_APP_API_URL


const SuperContactForm = () => {

    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = data =>

        ( axios.post(`${ApiUrl}/account/business/lead/`, data).then(response => {
            console.log(response.data)
            if (response.status == 201) {
                toast.success("Thanks to showing your interest")
            }

 }
        )

        .catch(err => {
            toast.error(" Unsuccessfull")
          })
    )

    return (<div className="" style={{ textAlign: 'center' }} >
        <div className="">
            <form className="" onSubmit={handleSubmit(onSubmit)} style={{ backgroundColor: "#f8f8f8", borderRadius: '5px', opacity: "0.9" }}>
                <div className="pt-3 " style={{ textAlign: 'center' }}><p className="fs-18">Stay tuned.<br /> <span style={{color:"#2eb1fe",textTransform:'uppercase',fontWeight:"600"}}><img style={{width:"40px"}}src="images/favicon-icon.png" />Dirham Per Foot</span> </p></div>

                <div className=" mx-3" >
                    <input type="text" className="form-control form-control-lg border-1" placeholder="Name" {...register("name", { required: true, maxLength: 80 })} />
                </div>

                <div className="py-1 mx-3 " >
                    <input type="text" className="form-control form-control-lg border-1" placeholder="Email" {...register("email", { required: true, pattern: /^\S+@\S+$/i })} />
                </div>
                <div className=" mx-3 " >
                    <input type="text" className="form-control form-control-lg border-1" placeholder="Mobile number" {...register("mobile")} />
                </div>
                <div className="py-1 mx-3 " >
                    <textarea className="form-control form-control-lg border-1" placeholder="Message" {...register("msg", {maxLength: 150 })} />
                </div>
                <div className="form-group mx-3  pb-3 " style={{ textAlign: 'center' }} >
                    <input type="submit" className="btn text-white hover-saffron bg-blue " />
                </div>


                {/*  <input type="text" placeholder="Last name" {...register("Last name", {required: true, maxLength: 100})} />
        <input type="text" placeholder="Email" {...register("Email", {required: true, pattern: /^\S+@\S+$/i})} />
        <input type="tel" placeholder="Mobile number" {...register("Mobile number", {required: true, minLength: 6, maxLength: 12})} /> */}


            </form>
        </div>
    </div>
    );
}
export default SuperContactForm;
