import React, { useEffect, useState } from "react";
import moment from "moment";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import axiosInstance from "../interceptor/axiosInstance";
import { toast } from "react-toastify";
import _ from "lodash";
import Loader from "../Loader";
import { Link, useLocation, useNavigate } from "react-router-dom";
const API_URL = process.env.REACT_APP_API_URL;
function MyStats() {
  const location = useLocation();
  const buildingName = location.state?.buildingname || "";
 
  moment.locale("en");
  const [leaddata, setLeadData] = useState([]);
  const [expanded, setExpanded] = useState({});
  const [loading, setLoading] = useState("");
  const [searchText, setSearchText] = useState("");
  useEffect(() => {
    if (buildingName) {
      setSearchText(buildingName);
    }
  }, [buildingName]);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded({ ...expanded, [panel]: isExpanded });
  };
  const groupedData = _.groupBy(leaddata, "get_property_name");
  const getStatData = () => {
    setLoading(true);
    axiosInstance
      .get(`${API_URL}/properties/user/dashboard/favourite/`)
      .then((response) => {
        if (response.data) {
          setLeadData(response.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response && error.response.status === 401) {
          toast.error(error.response?.data?.detail);
        } else {
          // Handle other errors
        }
      });
  };
  useEffect(() => {
    getStatData();
  }, []);
  const filteredLeads = leaddata.filter((item) =>
    item.get_property_name.toLowerCase().includes(searchText.toLowerCase())
  );
  const displayData = searchText ? filteredLeads : leaddata;
  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };
  const navigate = useNavigate();
  const [receivedState, setReceivedState] = useState();
  
  return (
    <>
      {loading ? <Loader /> : null}
      <main id="content" className="bg-gray-01 margin-re-top">
        <div className="px-3 px-lg-6 px-xxl-13 py-5 py-lg-10">
          <div className="d-flex flex-wrap flex-md-nowrap mb-6">
            <div className="mr-0 mr-md-auto">
              <h2 className="mt-5 mb-0 text-heading fs-24 lh-15">
                My Leads
              </h2>
              <p className="fs-18">
                Potential leads Who have interacted with Property listing
              </p>
            </div>
            <div className="p-2">
              <div className="input-group input-group-lg bg-white border">
                <div className="input-group-prepend">
                  <button
                    className=" btn pr-0 shadow-none border-0"
                    type="button"
                  >
                    <i className="far fa-search mr-3"></i>
                  </button>
                </div>
                <input
                  type="text"
                  className="form-control bg-transparent border-0 shadow-none text-body "
                  placeholder="Enter Property Name & Location"
                  value={searchText}
                  name="search"
                  onChange={handleSearch}
                />
              </div>
            </div>
            <div className="p-2">
              <Link
                to="/contactlead"
                className="btn bg-blue text-white hover-saffron btn-lg"
              >
                <span>Contact Leads</span>
                <span className="d-inline-block ml-1 fs-20 lh-1"></span>
              </Link>
            </div>
          </div>
          <div className="card">
            <div className="able  border rounded-lg  bg-blue text-white fs-18">
              <div className="row">
                <div className="col-6 border-top-0 fs-20 px-6 pt-5 pb-4">
                  Property Name
                </div>
                <div className="col-2 border-top-0 fs-20 pt-5 pb-4">
                  Property Type
                </div>
                <div className="col-2 border-top-0 fs-20  pt-5 pb-4 ">
               My Statistics
                </div>
              </div>
            </div>
            {Object.keys(_.groupBy(displayData, "get_property_name")).map(
              (key) => {
                const items = groupedData[key];
                return (
                  <Accordion
                    key={key}
                    expanded={expanded[key]}
                    onChange={handleChange(key)}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel-${key}-content`}
                      id={`panel-${key}-header`}
                    >
                      <Typography
                        sx={{
                          marginTop: "15px",
                          width: "52%",
                        }}
                      >
                        {items[0]?.get_property_name}
                      </Typography>
                      <Typography
                        sx={{
                          marginTop: "15px",
                          width: "18%",
                        }}
                      >
                        {items[0]?.get_property_building_type.toUpperCase()}
                      </Typography>
                      <Typography
                        sx={{
                          marginTop: "15px",
                          width: "10%",
                        }}
                      >
                        {items.length}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography className="mx-10">
                        <div className="card">
                          <div className="able border rounded-lg bg-grey-01 bg-blue text-white">
                            <div className="row ">
                              <div className="col-3 border-top-0 px-6 pt-5 pb-4">
                                Name
                              </div>
                              <div className="col-3 border-top-0 pt-5 pb-4">
                                Email
                              </div>
                              <div className="col-3 border-top-0 pt-5 pb-4">
                                Phone
                              </div>
                              <div className="col-2 border-top-0 pt-5 pb-4">
                                Date
                              </div>
                            </div>
                          </div>
                          {items.map((item, index) => (
                            <Accordion>
                            <AccordionSummary key="index">
                              <Typography
                                sx={{
                                  marginTop: "15px",
                                  width: "30%",
                                }}
                              >
                                {item.user_full_name}
                              </Typography>
                              <Typography
                                sx={{
                                  marginTop: "15px",
                                  width: "35%",
                                }}
                              >
                                {item.user_email}
                              </Typography>
                              <Typography
                                sx={{
                                  marginTop: "15px",
                                  width: "30%",
                                }}
                              >
                                {item.get_user_mobile}
                              </Typography>
                              <Typography
                                sx={{
                                  marginTop: "15px",
                                  width: "30%",
                                }}
                              >
                                {moment(item.create_at).format("DD-MM-YYYY")}
                              </Typography>
                            </AccordionSummary>
                            </Accordion>
                          ))}
                        </div>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                );
              }
            )}
          </div>
        </div>
      </main>
    </>
  );
}
export default MyStats;