import { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { uniqBy } from "lodash";
import Loader from "../Loader";
import { BsInstagram, BsLinkedin, BsYoutube, BsFacebook } from "react-icons/bs";
import logo from "../images/dpf-logo-new.png";
import axios from "axios";
import { toast } from "react-toastify";
import Chatbot from 'react-chatbot-kit';
import 'react-chatbot-kit/build/main.css';
import config from "../dashboard/pages/property/component/DirhamChatbot/config";
import ActionProvider from "../dashboard/pages/property/component/DirhamChatbot/ActionProvider";
import MessageParser from "../dashboard/pages/property/component/DirhamChatbot/MessageParser";
import DihramChat from "../dashboard/pages/property/component/DirhamChatbot/chatbot/ChatBot";

const API_URL = process.env.REACT_APP_API_URL;


const Footer = () => {
  const [allProperty, setAllProperty] = useState([]);
  
  // const chatbotRef = useRef(null);
  // const [showChatbot, setShowChatbot] = useState(false);

  // const toggleChatbot = () => {
  //   setShowChatbot(!showChatbot);
  // };
  // const handleClickOutside = (event) => {
  //   if (chatbotRef.current && !chatbotRef.current.contains(event.target)) {
  //     setShowChatbot(false);
  //   }
  // };

  // useEffect(() => {

  //   document.addEventListener("mousedown", handleClickOutside);

  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);
  /* *********Api TO Get Properties *********/
  const getProperty = async () => {

    try {
      const response = await axios.get(
        `${API_URL}/properties/city/filter/`
      );
      if (response.status === 200) {
        const responseData = response.data;
        setAllProperty(responseData);

      }
    } catch (err) {
      toast.error(' Please Contact Administrator');

    }
  };

  useEffect(() => {
    getProperty();
  }, []);

  /************ GO to propertylist */
  const navigate = useNavigate();
  const gosearch = (state, label) => {

    const footersearch = [
      { footercity: state },
      { footerlabel: label }
    ];

    navigate('/advance-search', { state: { footersearch } });
  };

  const statesearch = (statename) => {
    navigate('/advance-search/new-project', { state: { statename } });

  }

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const filterPropertiesByLabel = (label) => {
    return allProperty.filter((item) => item.property_label_name === label);
  };

  const saleProperty = filterPropertiesByLabel("Sale");
  const groupsale = uniqBy(saleProperty, "state"); // Use uniqBy to get unique states

  const rentProperty = filterPropertiesByLabel("Rent");
  const grouprent = uniqBy(rentProperty, "state");

  return (
    <>


      <div className="position-fixed pos-fixed-bottom-right p-6 z-index-10"  >
        {/* <Link onClick={handleScrollToTop} class="gtf-back-to-top text-white bg-blue hover-saffron sidebar-list-item bg shadow p-0 w-52px h-52 rounded-circle fs-20 d-flex align-items-center justify-content-center add-opacity-cstm" title="Back To Top">
          <i class="fal fa-arrow-up"></i>
        </Link> */}
        {/* {!showChatbot && (
          <img
            className="logo zooming"
            style={{ width: "50px" }}
            src="../../images/chaticon.png" 
            onClick={toggleChatbot}
            alt="Toggle Chatbot"
          />
        )} */}

        {/* {showChatbot && ( */}
          <div 
        //  className="logo zooming"
          >
             {/* Attach the ref to the chatbot container */}
             <p className="text-black fw-600 m-0 text-center" style={{width:"250px", padding:"3px", borderRadius:"5px", background:"#FAFAFA"}}> 
             I'm Era. Your Personalized AI Property Search Assistant!
          </p>
          <DihramChat className/>
          
            {/* <Chatbot
              config={config}
              messageParser={MessageParser}
              actionProvider={ActionProvider}
            /> */}
          </div>
        {/* )} */}

      </div>
      <footer className="bg-dark pt-8 pb-6 footer text-muted">
        <div className="container container-xxl">
          <div className="mb-6 mb-md-0 footer-icon">
            <Link className="d-block mb-2 logo-footer-add-other" href="#">
              <img src={logo} alt="HomeID" className="logo-footer" />
            </Link>
          </div>
          <div className="row footer-grid">
            <div className="col-md-3 col-lg-3 mb-6 mb-md-0 footer-subscribe">
              <h4 className="text-white fs-16 my-4 font-weight-500">
                Property For Sale
              </h4>
              <ul className="list-group list-group-flush list-group-no-border">
                {groupsale?.length > 0 && (
                  <>
                    {groupsale.map((item, index) => (

                      <li
                        key={item.state}
                        className="list-group-item bg-transparent p-0"
                        onClick={() => gosearch(item.state, item.property_label_name)}
                      >
                        <div
                          className="text-muted lh-26 hover-white font-weight-500 span-class-cursor">
                          Properties for Sale in {item.state}
                        </div>
                      </li>


                    ))}
                  </>
                )}
              </ul>
            </div>
            <div className="col-md-3 col-lg-3 mb-6 mb-md-0 footer-subscribe">
              <h4 className="text-white fs-16 my-4 font-weight-500">
                Property For Rent
              </h4>
              <ul className="list-group list-group-flush list-group-no-border">
                {grouprent?.length > 0 ? (
                  <>
                    {grouprent?.map((item) => {
                      return (


                        <li
                          key={item.state}
                          className="list-group-item bg-transparent p-0"
                          onClick={() => gosearch(item.state, item.property_label_name)}
                        >
                          <div
                            className="text-muted lh-26 hover-white font-weight-500 span-class-cursor">
                            Properties for Rent in {item?.state}
                          </div>
                        </li>

                        // <li className="list-group-item bg-transparent p-0" onClick={() => gosearch(item.state, item.property_label_name)}>
                        //   <div className="text-muted lh-26 hover-white font-weight-500 span-class-cursor">
                        //     Properties for Rent in {item?.state}
                        //   </div>
                        // </li>

                      );
                    })}
                  </>
                ) : null}
              </ul>
            </div>

            <div className="col-md-3 col-lg-3 mb-6 mb-md-0 footer-subscribe">
              <h4 className="text-white fs-16 my-4 font-weight-500">
                Off Plan Projects
              </h4>
              <ul className="list-group list-group-flush list-group-no-border">
                {grouprent?.length > 0 ? (
                  <>
                    {grouprent?.map((item) => {
                      return (
                        <li
                          key={item.state}
                          className="list-group-item bg-transparent p-0"
                          onClick={() => statesearch(item.state)}
                        >
                          <div className="text-muted lh-26 hover-white font-weight-500 span-class-cursor">
                            {item?.state}
                          </div>
                        </li>
                      );
                      // return (
                      // <a href={`/advance-search/${}`}>
                      // <li className="list-group-item bg-transparent p-0" onClick={() => statesearch(item.state)}>
                      //   <div
                      //     className="text-muted lh-26 hover-white font-weight-500 span-class-cursor">
                      //     {item?.state}
                      //   </div>
                      // </li>
                      // </a>
                      // );
                    })}
                  </>
                ) : null}
              </ul>
            </div>
            <div className="col-md-3 col-lg-3 mb-6 mb-md-0 footer-subscribe">
              <h4 className="text-white fs-16 my-4 font-weight-500">
                Get in touch for upcoming projects
              </h4>
              <p className="font-weight-500 text-muted lh-184">
                Never miss a real deal. Maximize your return with Dirham.
              </p>

              <ul className="list-inline mb-0">
                <li className="list-inline-item mr-0">
                  <Link

                    className="text-white opacity-3 fs-25 px-4 opacity-hover-10"
                  >
                    <BsFacebook />
                  </Link>
                </li>

                <li className="list-inline-item mr-0">
                  <Link

                    className="text-white opacity-3 fs-25 px-4 opacity-hover-10"
                  >
                    <BsInstagram />
                  </Link>
                </li>

                <li className="list-inline-item mr-0">
                  <Link

                    className="text-white opacity-3 fs-25 px-4 opacity-hover-10"
                  >
                    <BsLinkedin />
                  </Link>
                </li>

                <li className="list-inline-item mr-0">
                  <Link

                    className="text-white opacity-3 fs-25 px-4 opacity-hover-10"
                  >
                    <BsYoutube />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="mt-0 mt-md-10 row">
            <ul className="list-inline mb-0 col-md-6 mr-auto">
              <li className="list-inline-item mr-6">
                <Link to={"/termanduse"} className="text-muted lh-26 font-weight-500 hover-white">

                  Terms of Use
                  {" "}
                </Link>
              </li>
              <li className="list-inline-item">
                <Link to={"/policy"} className="text-muted lh-26 font-weight-500 hover-white">
                  Privacy Policy
                </Link>
              </li>
            </ul>
            <p className="col-md-auto mb-0 text-muted">
              © 2024 Dirham. All Rights Reserved
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};
export default Footer;
