import React, { useEffect, useState } from "react";
import Loader from "../../Loader";
import BannerImage from "../../images/area-score.jpg";
import Header from "../../header/Header";
import Footer from "../../footer/Footer";
import AutoComplete from "react-google-autocomplete";
import axios from "axios";
import ReactStoreIndicator from "react-score-indicator";
import CurrencyFormat from "react-currency-format";
import axiosInstance from "../../interceptor/axiosInstance";
import FavActionButton from "../../favActionButton/FavActionButton";
import CallActionButton from "../../callActionButton/CallActionButton";
import { Link } from "react-router-dom";
import MyModal from "../MyModal";
import { toast } from "react-toastify";
const API_URL = process.env.REACT_APP_API_URL;
const Areascore = () => {
  const is_auth = localStorage.getItem("is_authenticated");
  const [place, setPlace] = useState([]);
  const [favproperty, setfavproperty] = useState([]);

  const lat = place?.geometry?.location?.lat();
  const lng = place?.geometry?.location?.lng();
  const [loading, setLoading] = useState(false);
  const [coords, setCoords] = useState([]);
  const [nearPlaceCount, setNearPlaceCount] = useState([]);
  // const [PropertyWeightage, setPropertyWeightage] = useState({
  //     Groceries: 15,
  //     ShoppingMall: 10,
  //     Hospital: 40,
  //     Schools: 20,
  //     MetroStation: 15,
  // });
  // const [scoreData?.property_score, setPropData] = useState();
  const [scoreData, setScoreData] = useState();
  const [propName, setPropName] = useState("");
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [allProperties, setAllProperties] = useState([]);
  const [visibleItems, setVisibleItems] = useState(12);
  const itemsToLoad = 12;

  /* *********Api TO Get Favourite property *********/
  const getfavourite = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        `${API_URL}/properties/user/get/favourite/`
      );
      if ((response.status = 200)) {
        const responseData = response.data;
        setfavproperty(responseData);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };
  useEffect(() => {
    window?.scrollTo(0, 0);
    if (is_auth === "false") {
      getfavourite();
    }
  }, [is_auth]);
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      Search();
      // calculateTotalWeightedValue();
      // handleCalculatePropertyCount();
      Scorecheck();
    }
  };

  const Search = async () => {
    
    try {
      const response = await axios.get(
        `${API_URL}/properties/advance/filter/?latitude=${lat}&longitude=${lng}&distance=10000`
      );
      if (response.status === 200) {
        const responseData = response.data;

        setAllProperties(responseData);
        NearPlace();
        setNearPlaceCount([]);
      } else {
      }
    } catch (error) {
      // toast.error("Your Account is Non Business . Please Update Your Profile")
    }
   
  };

  useEffect(() => {
    if (lat || lng) {
      setCoords({ lat, lng });
    }
  }, [lat, lng]);
  const NearPlace = () => {
    if (window.google) {
      const placesService = new window.google.maps.places.PlacesService(
        document.createElement("div")
      );

      const location = new window.google.maps.LatLng(coords);

      // const setNearPlaceCount = props.setNearPlaceCount

      const NearPlaceList = [
        "Groceries",
        "ShoppingMall",
        "Hospital",
        "Schools",
        "MetroStation",
      ];

      const res = NearPlaceList?.map((item) => {
        const request = {
          location,
          keyword: item,
          radius: "3000", // Specify the radius in meters@
          type: "", // Specify the type of places you want to fetch
        };

        // Send the request to the PlacesService
        placesService?.nearbySearch(request, (results, status) => {
          if (status === window?.google?.maps?.places?.PlacesServiceStatus.OK) {
        
            setNearPlaceCount((prevItems) => [
              ...prevItems,
              { [item]: results.length },
            ]);
          }
        });
      });
    }
  };

  const Scorecheck = async () => {
    setLoading(true);
   
    try {
      const response = await axios.get(
        `${API_URL}/track/property/score/?latitude=${lat}&longitude=${lng}&radius=3000`
      );
      if (response.status === 200) {
        const responseData = response.data;
        setScoreData(responseData);
        
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
        setLoading(false);
        toast.error("Please Select Location to get Area Score ")
    }
    
  };

  useEffect(() => {
    if (scoreData?.property_score >= 75) {
      
      setPropName("Premium");
    } else if (scoreData?.property_score >= 45) {
      

      setPropName("Good");
    } else if(scoreData?.property_score <45) {
      
      setPropName("Average");
    }
  }, [scoreData]);

  return (
    <>
      {loading ? <Loader /> : null}
      <Header />

      <main id="areascore">
        <section
          className="h-100vh d-flex flex-column"
          style={{
            backgroundImage: `url(${BannerImage})`,
            backgroundSize: "cover",
            // height: "400px"
            // backgroundColor: "#000",
            // opacity: "0.8",
          }}
        >
          <div
            className="d-flex align-items-center mr-bm-sr text-center"
            style={{ height: "400px" }}
          >
            <div className="container-fluid px-20">
              <h1 className="text-white mb-5 lh-2 fs-md-14 fs-lg-32 display-re-none">
                Smart Location Selection
                <br /> A Weighted Approach to Optimize Urban Living
              </h1>

              <div className="d-flex justify-content-center margin-re-top-100">
                <div className="position-relative w-50">
                  <i className="far fa-search text-dark fs-18 position-absolute pl-4 pos-fixed-left-center"></i>
                  <AutoComplete
                    apiKey={"AIzaSyDZSiGDJcQdFDZqQU5ONXF7dGkoKQePszo"}
                    onPlaceSelected={(place) => {
                      setPlace(place);
                    }}
                    className="rounded-bottom-right-lg rounded-top-right-ra w-100 pl-8 py-4   bg-white border-0 fs-16 font-weight-500 text-dark rounded-0 lh-17"
                    placeholder="Enter Location..."
                    defaultValue="Dubai Hills Mall - Al Khail Road - Dubai - Dubai - United Arab Emirates"
                    onKeyDown={(event) => {
                      if (event?.keyCode === 18) {
                        event?.preventDefault();
                      }
                    }}
                    onKeyPress={handleKeyPress}
                    options={{
                      types: ["route"],
                      componentRestrictions: { country: "ae" },
                      // types: ["(regions)"],
                    }}
                  />
                </div>
                <button
                  type="button"
                  onClick={() => {
                    Search();
                    Scorecheck();
                    // handleCalculatePropertyCount();
                  }}
                  className="btn text-white bg-blue hover-saffron fs-18 font-weight-600 rounded-left-0 "
                >
                  Search
                </button>
              </div>
            </div>
          </div>
        </section>
        <div className="primary-content bg-f3f3f3">
          <div className="container-fluid px-15">
            <div className="row pt-5">
              <article className="col-lg-9 col-md-7 border-right">
                <section className="border-bottom">
                  <div className="mt-5">
                    <h2>Property Score By Area</h2>
                    <span className="heading-divider divider-bottom"></span>
                    <p className="mt-3 fs-17">
                      In the realm of urban planning and real estate, choosing
                      the right location is a critical decision that can
                      significantly impact various aspects of daily life. To
                      streamline this process, we introduce a method that
                      incorporates weighted place counts within a 3km radius to
                      determine the optimal location based on specific criteria.
                      This approach considers various factors, assigning
                      weightages to different types of places, such as
                      groceries, shopping centers, hospitals, schools, and metro
                      stations.
                      <br />
                      <br />
                      This innovative method provides a quantitative and
                      objective approach to selecting an optimal location based
                      on specific criteria. By assigning weightages to different
                      types of places and considering their counts within a 3km
                      radius, individuals can make informed decisions that align
                      with their priorities and preferences. This approach not
                      only enhances the decision-making process but also
                      contributes to more efficient urban planning and real
                      estate development.
                    </p>
                  </div>
                </section>
                <div className="col-lg-12 pt-7 p-0 order-2">
                  {
                    allProperties?.length > 0 ? (
                      <div className="">
                        <p className="fs-18 text-dark font-weight-600 ">
                          Related Properties in same area
                        </p>
                        {allProperties
                          ?.slice(0, visibleItems)
                          .map((item, index) => {
                            return (
                              <div className="py-5  border-bottom round-5  ">
                                <div className="row hover-change-image">
                                  <div className="col-lg-4 col-md-6">
                                    <Link to={`/property/details/${item?.slug}`}>
                                      <div className="bg-overlay-gradient-1 bg-hover-overlay-gradient-3 rounded-lg card-img">
                                        <img
                                          className="propertylist-img"
                                          style={{ height: "200px" }}
                                          src={item?.upload_featured_image}
                                        />
                                        <div className="card-img-overlay d-flex flex-column justify-content-between ">
                                          <div>
                                            <span className="badge badge-orange mr-2">
                                              {item?.property_label_name}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </Link>
                                  </div>

                                  <div className="col-lg-8 col-md-6 px-5">
                                    <div className="d-flex justify-content-between">
                                      <Link
                                        to={`/property/details/${item?.slug}`}
                                      >
                                        <p
                                          className="mt-3 fs-20 font-weight-bold text-heading mb-0 "
                                          style={{ lineHeight: "1.3" }}
                                        >
                                          {item?.property_price ? (
                                            <>
                                              <span className="text-black font-weight-500 fs-16">
                                                AED{" "}
                                              </span>
                                              <CurrencyFormat
                                                value={item?.property_price}
                                                displayType="text"
                                                thousandSeparator={true}
                                                suffix=".00"
                                              />
                                              {/* <span className="text-gray-light font-weight-500 fs-14"> /Mo</span> */}
                                            </>
                                          ) : (
                                            <>
                                              <span className="text-black font-weight-500 fs-16">
                                                AED{" "}
                                              </span>
                                              <CurrencyFormat
                                                value={item?.lease_price_per_mo}
                                                displayType="text"
                                                thousandSeparator={true}
                                                suffix=".00"
                                              />
                                              <span className="text-gray-light font-weight-500 fs-14">
                                                {" "}
                                                /Mo
                                              </span>
                                            </>
                                          )}
                                        </p>
                                      </Link>
                                      <div className="d-flex">
                                        {item?.is_property === true ? (
                                          <div>
                                            <span className="badge badge-orange mt-2 mr-2">
                                              New
                                            </span>
                                          </div>
                                        ) : null}
                                        <div className="mt-2 mr-2">
                                          <FavActionButton
                                            setShow={setShow}
                                            itemId={item?.id}
                                            favproperty={favproperty}
                                            setfavproperty={setfavproperty}
                                            setLoading={setLoading}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <Link to={`/property/details/${item?.slug}`}>
                                      <div
                                        className="list-inline-item text-black font-weight-500 fs-15 d-flex align-items-center mr-5 mb-0"
                                        data-toggle="tooltip"
                                        title="Size"
                                      >
                                        {item?.building_type_name}
                                        <span className="text-gray-light ml-2 mr-2">
                                          |
                                        </span>
                                        Area: {item?.property_size}Sq.Ft
                                        {item?.building_type_name ===
                                        "Residential" ? (
                                          <>
                                            {item?.bedroom !== null &&
                                            item?.bathroom !== null ? (
                                              <>
                                                <span className="text-gray-light ml-2 mr-2">
                                                  |
                                                </span>
                                                {item?.bedroom}{" "}
                                                <img
                                                  src="images/bedd.png"
                                                  className="icon icon-bedroom fs-18  mr-1 width-32 ml-1 mr-2"
                                                />
                                                {item?.bathroom}{" "}
                                                <img
                                                  src="images/bathtubb.png"
                                                  className="icon icon-bedroom fs-18 mr-1 mr-1 width-32"
                                                />
                                              </>
                                            ) : null}
                                          </>
                                        ) : null}
                                      </div>
                                      <h2
                                        className="mt-1"
                                        style={{ lineHeight: "0.5" }}
                                      >
                                        <a className="fs-16 text-dark">
                                          {item?.property_name}
                                        </a>
                                      </h2>

                                      <p
                                        className="text-blue  mb-2 "
                                        style={{ lineHeight: "1.7" }}
                                      >
                                        <img
                                          src="images/locations.png"
                                          className="icon icon-bedroom fs-18  mr-1 width-32 "
                                        />
                                        {item?.street_address}
                                      </p>
                                    </Link>
                                    <CallActionButton
                                      key={item?.id}
                                      item={item}
                                    />
                                  </div>

                                </div>
                              </div>
                            );
                          })}
                        {allProperties?.length > visibleItems && (
                          <div className="d-flex justify-content-center">
                            <button
                              className="btn bg-blue hover-saffron text-white "
                              onClick={() =>
                                setVisibleItems((prev) => prev + itemsToLoad)
                              }
                            >
                              Load More
                            </button>
                          </div>
                        )}
                      </div>
                    ) : null
                    /*  <h3>No Properties</h3> */
                  }
                </div>
              </article>
              <aside
                className="col-lg-3 col-md-5 primary-sidebar sidebar-sticky"
                id="sidebar"
              >
                <div className="primary-sidebar-inner">
                  <div className="card border-0 widget-request-tour mt-5">
                    <div className=" d-block text-center  ">
                      <a className="py-2 bg-blue text-white px-3">
                        Property Score
                      </a>
                    </div>
                    <div class="card-body pb-5 pt-0  mt-5">
                      {!scoreData?.property_score ? (
                        <>
                          <ReactStoreIndicator
                            value={75}
                            maxValue={100}
                            lineWidth="25"
                            fadedOpacity="25"
                          />
                        </>
                      ) : (
                        <>
                          <ReactStoreIndicator
                            value={scoreData?.property_score}
                            maxValue={100}
                            lineWidth="25"
                            fadedOpacity="25"
                          />
                        </>
                      )}

                      <div className="fs-18 text-center font-weight-500 mb-2">
                        {!scoreData?.property_score ? (
                          <>Premium</>
                        ) : (
                          <> {propName}</>
                        )}{" "}
                        Location
                      </div>

                      {!scoreData?.property_score>0 ? (
                        <>
                          <div className="d-flex align-items-center justify-content-center mb-2 bg-blue text-white">
                              <div className="font-weight-500 text-uppercase mr-1">
                                Hospitals
                              </div>
                              <div className="font-weight-bold fs-16">12</div>
                            </div>
                          <div className="d-flex align-items-center justify-content-center mb-2 bg-green text-white">
                            <div className="font-weight-500 text-uppercase mr-2">
                              Groceries
                            </div>
                            <div className="font-weight-bold fs-16">13</div>
                          </div>
                          {/* <div className="d-flex align-items-center justify-content-center mb-2 bg-orange text-white">
                            <div className="font-weight-500 text-uppercase mr-2">
                              Schools
                            </div>
                            <div className="font-weight-bold fs-16">20</div>
                          </div> */}
                          <div className="d-flex align-items-center justify-content-center mb-2 bg-success text-white">
                            <div className="font-weight-500 text-uppercase mr-1">
                              Shopping Mall
                            </div>
                            <div className="font-weight-bold fs-16">12</div>
                          </div>
                          {/* <div className="d-flex align-items-center justify-content-center mb-2 bg-danger text-white">
                            <div className="font-weight-500 text-uppercase mr-6">
                              Metro
                            </div>
                            <div className="font-weight-bold fs-16">1</div>
                          </div> */}
                        </>
                      ) : (
                        <>
                          {/* ****1 */}
                          {scoreData?.total_hospital > 0 ? (
                            <div className="d-flex align-items-center justify-content-center mb-2 bg-blue text-white">
                              <div className="font-weight-500 text-uppercase mr-1">
                                Hospitals
                              </div>
                              <span className="font-weight-bold fs-16 ">
                                {scoreData?.total_hospital}
                              </span>
                            </div>
                          ) : null}
                          {/* ****2 */}
                          {scoreData?.total_grocery > 0 ? (
                            <div className="d-flex align-items-center justify-content-center mb-2 bg-green text-white">
                              <div className="font-weight-500 text-uppercase mr-2">
                                Groceries
                              </div>
                              <div className="font-weight-bold fs-16">
                                {scoreData?.total_grocery}
                              </div>
                            </div>
                          ) : null}
                          {/* ****3 */}
                        
                          {/* ****4 */}
                          {scoreData?.total_malls > 0 ? (
                            <div className="d-flex align-items-center justify-content-center mb-2 bg-success text-white">
                              <div className="font-weight-500 text-uppercase mr-1">
                                Shopping Mall
                              </div>
                              <div className="font-weight-bold fs-16">
                                {scoreData?.total_malls}
                              </div>
                            </div>
                          ) : null}
                          {/* ****5 */}
                          {scoreData?.total_metro > 0 ? (
                            <div className="d-flex align-items-center justify-content-center mb-2 bg-danger text-white">
                              <div className="font-weight-500 text-uppercase mr-6">
                                Metro
                              </div>
                              <div className="font-weight-bold fs-16">
                                {scoreData?.total_metro}
                              </div>
                            </div>
                          ) : null}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </aside>
            </div>
          </div>
        </div>
        <MyModal show={show} handleClose={handleClose} />
      </main>
      <Footer />
    </>
  );
};

export default Areascore;
