import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { toast } from "react-toastify";
import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;
const Chartgraph = (props) => {
  const [tdata, setTdata] = useState([]);
  const propertytype = props?.propertytypedata;
  const label = props?.buildinglabelData;
  const area = props?.buildingArea;
  const getData = async () => {
    try {
      let locationParams = `/dubailand/transactions/average/price/monthly/?usage=${propertytype}&area=${area}&transaction_type=Sales`;
      if(label==="Rent"){
         locationParams = `/dubailand/rent/average/price/monthly/?usage=${propertytype}&area=${area}`;
      }
      
      const response = await axios.get(`${API_URL}${locationParams}`);
      if (response.status === 200) {
        const responseData = response.data;
        setTdata(responseData?.average_price_per_month);
      } else {
      }
    } catch (err) {
      toast.error("No data, try more filters");
    } finally {
    }
  };
  useEffect(() => {
    if (propertytype) {
      getData();
    }
  }, [propertytype])
  const options = {
    series: [
      {
        name: label,
        data: tdata?.map(item => item?.avg_price_per_meter_square?.toFixed(2))
      },
    ],
    chart: {
      type: "area",
      dropShadow: {
        enabled: true,
        color: "#000",
        top: 18,
        left: 7,
      },
      toolbar: {
        show: true,
      },
    },
    colors: ["#1A63C6", "#FE5900"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    title: {
      text:"Property Pricing in this Area",
      style:{
fontSize:'22px',
      },
      aligin:'left',
    },
    grid: {
      borderColor: "#E7E7E7",
      row: {
        colors: ["#F3F3F3", "transparent"],
        opacity: 0.5,
        height: 40,
      },
    },
    markers: {
      size: 8,
    },
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ],
    xaxis: {

      type: "Month",
      title: "Months",
      style: {
        fontSize: '12px',
      },
    },
    yaxis: {
      title: {
        text: "Average Pricing(AED)",
        style: {
          fontSize: '20px',
        },
      },
      labels: {
        formatter: function (value) {
          if(label==="Rent"){
            return `AED/Mo ${value.toFixed(2)}`;
          }else{
            return `AED ${value.toFixed(2)}`;
          }
          
        },
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
      floating: true,
      offsetY: -20,
      offsetX: -10,
    },
  };
  return (<>
    {tdata && tdata.length > 0 ? (
    <section className="mt-6">
    <div id="chart" className="card">
      
  <Chart
    options={options}
    series={tdata.map(item => item?.avg_price_per_meter_square?.toFixed(2))}
    type="area"
    height={400}
  />


    </div>
    </section>
  
    ) :null}  </>
  );
};
export default Chartgraph;