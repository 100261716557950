import axios from 'axios';
import React, { useState } from 'react'
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';
const LeadForm = (props) => {
  const API_URL = process.env.REACT_APP_API_URL;
  const setShow = props.setShow
  const show = props.show
  
  const selectedCity = props.selectedCity
  console.log(selectedCity,"selectedCity");
  const selectedProjectType = props.selectedProjectType
  const selectedBuildingType = props.selectedBuildingType
  const selectedPropertyType = props.selectedPropertyType
  const selectedPropertyLabel = props.selectedPropertyLabel
  const { register, handleSubmit, reset } = useForm();
  const [leadInput, setLeadInput] = useState([]);
  const handleLead = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setLeadInput({ ...leadInput, [name]: value ,
      city:selectedCity,
      is_new_property:selectedProjectType,
      building_type:selectedBuildingType,
      property_type:selectedPropertyType,
      property_label:selectedPropertyLabel
    });
   
  };
  console.log(leadInput,"leadInput");
  const onSubmit = async () => {
    
    try {
      setShow(true);
      const response = await axios.post(
        `${API_URL}/chatbot/lead/`,
        leadInput
      );
       const myresponse = response.data;

      toast.success("Thanks for Showing your Interset...!");
      reset();
    } catch (error) {
      // toast.error("Something Went Wrong");
      toast.success("Thanks for Showing your Interset...!");
    }
  };
  return (

    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="pt-1 pb-0 px-0 d-block">
        <div className="">
          <div className="form-group mb-2 position-add-validate-symbol">
            <input
              type="text"
              className="form-control form-control-lg border-0"
              placeholder="Your Name"
            {...register("name")}
            onChange={handleLead}
            />
            {/* <span className="asterisk_input"></span> */}
          </div>
          <div className="form-group mb-2 position-add-validate-symbol">
            <input
              type="email"
              className="form-control form-control-lg border-0"
              placeholder="Your Email"
             {...register("email", { required: true })}
             onChange={handleLead}
            />
            <span className="asterisk_input"></span>
          </div>
          <div className="form-group mb-2 position-add-validate-symbol">
            <input
              type="text"
              className="form-control form-control-lg border-0"
              placeholder="Your Phone Number"
              maxLength={10}
             {...register("mobile",{ required: true })}
             onChange={handleLead}
            />
            <span className="asterisk_input"></span>
          </div>
          <button
            type="submit"
            className="btn bg-blue text-white hover-saffron btn-lg btn-block rounded"
          >
            Submit to see matiching properties.
          </button>
        </div>
      </div>
    </form>

  )
}

export default LeadForm