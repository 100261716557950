import React, { useEffect, useState } from "react";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import ReactWhatsapp from 'react-whatsapp';
import Tooltip from 'react-bootstrap/Tooltip';
import axios from "axios";
import { toast } from "react-toastify";
import Modal from 'react-bootstrap/Modal';
import PhoneInput from 'react-phone-number-input'
import logo from "../../../src/images/dpf-logo.png";
import 'react-phone-number-input/style.css'
const API_URL = process.env.REACT_APP_API_URL;
const CallActionUser = (props) => {


    const [message, setMessage] = useState('')
    const [queryName, setQueryName] = useState('')
    const [queryPhone, setQueryPhone] = useState('')

    const [queryEmail, setQueryEmail] = useState('')
    const [queryUserId, setQueryUserId] = useState('')
    

    const [show, setShow] = useState(false);
    const { item } = props;

    const handleClose = () => {
        setShow(false);
        setMessage('')
        setQueryUserId('')
        setQueryPhone('')
        setQueryName('')
        setQueryEmail('')
    }
    const handleMessageChange = (event) => {
        setMessage(event.target.value);
    };
    const handleShow = () => {
        setShow(true);
        if(item?.user){
            setQueryUserId(item?.user)
        }else{
            setQueryUserId(item?.id)
        }
        
    }

    /* ***********lead submit ***************/
    const handleLeadSubmit = async () => {

        const data = {}
        data.name = queryName
        data.email = queryEmail
        data.mobile = queryPhone
        data.msg = message
        data.user = queryUserId


       
        try {
            const response = await axios.post(
                `${API_URL}/account/contact/create/`, data
            );


            if (response.status === 201) {
                
                toast.success("Query submitted successfully");
                handleClose();

                // You can handle success here (e.g., show a success message)
            } else {
                
                toast.error("Something went wrong")
                // You can handle errors here
            }
        } catch (error) {
            toast.error(' Please Contact Administrator');
        }
    };


    useEffect(() => {
        setMessage(`Hi, I found your Profile on Dirham Per Foot. Please contact me. Thank you.`)
    }, [])

    return (
        <>
            <div className='mb-1'> 
            {item?.mobile ? (<>
                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" >{item?.mobile}</Tooltip>}>
                    <a
                        href={`tel:${item?.mobile}`}
                        className='btn btn-primary mr-1 py-0 width-50'
                   >
                        <i className="bi bi-telephone margin-native"></i>
                    </a>

                </OverlayTrigger>
                </>):null}
                <button onClick={handleShow} className='border-0 bg-saffron text-white mr-1 px-5 width-50' style={{borderRadius:"3px", marginTop:"2px"}}><i class="bi bi-envelope margin-native"></i></button>
                {item?.whatsapp ? (<>
                    <ReactWhatsapp
                        className='btn btn-success mr-1 py-0 width-50'
                        number={item?.whatsapp}
                       message="Hi">
                        <i class="bi bi-whatsapp margin-native"></i>
                    </ReactWhatsapp>
                    {/* <button className='btn btn-success mr-2 py-0'><i class="bi bi-whatsapp mr-1"></i>WhatApp</button> */}
                </>) : null}
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton >
                    <Modal.Title className='fs-16'>
                        <img
                            src={logo}
                            alt="HomeID"
                            className="normal-logo main-logo-header"
                        /></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4 className='fs-18 mb-5 lh-1.5'></h4>
                    <div className="form-group">

                        <input onChange={(e) => setQueryName(e?.target?.value)}
                            value={queryName}
                            type="text" className="form-control form-control-lg border-1 mb-2" placeholder='Name' />
                        <input onChange={(e) => setQueryEmail(e?.target?.value)}
                            value={queryEmail}
                            type="email" className="form-control form-control-lg border-1 mb-2" placeholder='Email' />

                        <PhoneInput className="form-control form-control-lg border-1 mb-2 d-flex"
                            defaultCountry="AE"
                            value={queryPhone}
                            onChange={setQueryPhone} />
                        <textarea row="10"
                            value={message}
                            onChange={handleMessageChange}
                            name="message" id="message" className="form-control form-control-lg border-1" />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='px-5 py-1 round-5 bg-blue border-0 text-white hover-saffron' onClick={handleLeadSubmit}>
                        Send
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default CallActionUser;