import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import axiosInstance from '../../interceptor/axiosInstance';
import { Link } from 'react-router-dom';
const API_URL = process.env.REACT_APP_API_URL;

const PropertyGraph = (props) => {

  const [AllProperties, setAllProperties] = useState([]);
  useEffect(() => {
    setAllProperties(props?.AllProperties);
}, [props.AllProperties]);

  const fav = AllProperties.filter(item => item?.id === props.selectedProperty?.id);

  let favdata = [1, 9, 6, 3, 8, 5, 0, 7, 1, 5, 7, 3];
  if (fav.length > 0) {
    const myfav = fav[0]?.count_favourite_month;

    if (myfav !== undefined) {
      // If whatsapp is defined, use its data
      favdata = myfav;
    } else {
      // If whatsapp is not defined, use some default data
      favdata = [1, 9, 6, 3, 8, 5, 0, 7, 1, 5, 7, 3];
    }
  }



  const options = {
    series: [
      {
        name: 'Favorites',
        data: favdata,
      },
    ],
    chart: {
      height: 350,
      type: 'line',
      zoom: {
        enabled: true,
      },
    },
    colors: ['#1a63c6'],
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: 'straight',
    },
    title: {
      text: 'Potential Favourite Stats by Month',
      align: 'left',
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'],
        opacity: 0.5,
      },
    },
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ],
    xaxis: {
      type: "Month",
    },
    yaxis: {
      title: {
        text: 'Favorites',
      },
      min: 0,
      max: 10,
    },
  };

  return (
    <div>
      <Link to="/stats">
      <Chart options={options} series={options.series} type="line" height={350} />
      </Link>
    </div>
  );
}

export default PropertyGraph;
