import React from "react";
import axiosInstance from "../interceptor/axiosInstance";

import { toast } from "react-toastify";
import { Link } from "react-router-dom";
const API_URL = process.env.REACT_APP_API_URL;
const FavActionButton = (props) => {
    const itemId = props.itemId
   
    // const setLoading = props.setLoading
    const favproperty = props.favproperty
   
    const setfavproperty = props.setfavproperty
    const favId = favproperty.map((item) => item.properties);
    
    const setShow = props.setShow
    const user_id = localStorage.getItem("currentUser_id");



  

    /* ************ Favourite Property Work ************** */
    const handleHeartClick = () => {


        if (localStorage.getItem("token")) {
            // Check if favproperty contains an item with properties === itemId
            // setLoading(true);
            const existingItemIndex = favproperty.findIndex(
                (item) => item.properties === itemId
            );

            if (existingItemIndex !== -1) {
                // If the item already exists, delete it
                
                axiosInstance
                    .delete(`${API_URL}/properties/favourite/${favproperty[existingItemIndex].id}/`)
                    .then((response) => {
                        
                        // setLoading(false);
                        const updatedFavproperty = [...favproperty];
                        updatedFavproperty.splice(existingItemIndex, 1);
                        setfavproperty(updatedFavproperty);
                        // getfavourite();

                        toast.success("Property is removed from favourite");
                        // Handle the response as needed
                    })
                    .catch((error) => {
                       
                        toast.error("Something Went Wrong");
                        // Handle errors
                    });
            } else {
                // If the item doesn't exist, add it
                const postData = {
                    properties: itemId,
                    user: user_id,
                };
               
                axiosInstance
                    .post(`${API_URL}/properties/favourite/`, postData)
                    .then((response) => {
                        // setLoading(false);
                       
                        toast.success("Property added as Favourite");
                        const updatedFavproperty = [...favproperty, response.data];
                        setfavproperty(updatedFavproperty);
                        // setfavproperty(updatedFavproperty);
                    })
                    .catch((error) => {
                        // setLoading(false);
                        
                        toast.error("Something Went Wrong");
                        // Roll back the state if the API call fails
                        setfavproperty(favproperty);
                        // Handle errors
                    });
            }
        } else {
            
            setShow(true);
        }
    };


    /* ***************end******************* */


    return (<>

        <Link
            data-bs-toggle="tooltip"
            title="Wish list"
            onClick={handleHeartClick}
            className={`w-40px round-5 h-28  d-inline-flex align-items-center justify-content-center `}
        >
            <i
                className={`fas fa-heart ${favId.includes(itemId)
                    ? "text-red"
                    : "text-blue"
                    }`}

            ></i>
        </Link>


    </>)
}
export default FavActionButton;