import React from "react";
import { Link } from "react-router-dom";
import LeadCard from "./LeadCard";

const PropertyLead = () => {
 
  return (
    <>
      <main id="content" className="bg-gray-01 margin-re-top">
        <div className="px-3 px-lg-6 px-xxl-13 py-5 py-lg-10">
          <div className="d-flex flex-wrap flex-md-nowrap mb-6">
            <div className="mr-0 mr-md-auto">
              <h2 className="mt-5 mb-0 text-heading fs-22 lh-15">
                Property Leads
              </h2>
            </div>
            <div>
              <Link
                to="/leads"
                className="btn bg-blue text-white hover-saffron btn-lg"
              >
                <span>Business Leads</span>
                <span className="d-inline-block ml-1 fs-20 lh-1"></span>
              </Link>
            </div>
          </div>
          <div className="card">
            <div className="able  border rounded-lg  bg-blue text-white">
              <div className="row">
                <div className="col-6 border-top-0  px-6 pt-5 pb-4">
                  Property Name
                </div>
                <div className="col-2 border-top-0 pt-5 pb-4">
                  Property Type
                </div>

                <div className="col-2 border-top-0 pt-5 pb-4 header-user-icon">
                  Leads Count
                </div>
              </div>
            </div>

            <LeadCard />
          </div>
        </div>
      </main>
    </>
  );
};
export default PropertyLead;
