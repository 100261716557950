import _ from "lodash";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axiosInstance from "../interceptor/axiosInstance";
import { toast } from "react-toastify";
const API_URL = process.env.REACT_APP_API_URL;

const DashboardLead = () => {
  const [LeadProperties, setLeadProperties] = useState([]);
  
  const getLeadData = () => {

    axiosInstance
      .get(`${API_URL}/properties/user/dashboard/count/lead/`)
      .then((response) => {
        if (response.data) {
          setLeadProperties(response.data);

        } else {


        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {


          toast.error(error.response?.data?.detail);
        } else {

          toast.error(' Please Contact Administrator');
        }
      });
  };
  useEffect(() => {
    getLeadData();
  }, []);


  return (
    <div className="card mb-5">
      <div
        className="card-body  align-items-center px-6 py-3 "
        style={{ overflowX: "auto", overflowY: "auto", maxHeight: "397px" }}
      >
        <div className="row">
          <div className="col-md-6">
            <h2 className="fs-18">
             Latest Property Leads
            </h2>
          </div>
          <div className="col-md-6 text-right">
            <h2 className="fs-18">Count</h2>
          </div>
        </div>

        {/* Add a check for LeadProperties before mapping */}
        {Array.isArray(LeadProperties) && LeadProperties.length > 0 ? (
  LeadProperties
    .filter(item => item?.property_lead_count > 0)
    .map((item, index) => (
      <div
        className="section-content-admin d-flex px-2 py-2 mb-1"
        key={index}
      >
        <div className="property-content px-2 mt-1 w-100">
          <Link to="/leads/property">
            <h2 className="fs-16">{item?.property_name}</h2>
            <span className="text-blue font-weight-bold">
              {item?.prop_id}
            </span>
          </Link>
        </div>
        <span
          className="badge mt-2 badge-orange justify-content-center px-2"
          style={{ height: "fit-content" }}
        >
          {item?.property_lead_count}
        </span>
      </div>
    ))
) : (
  <p>No lead properties available.</p>
)}

      </div>
    </div>
  );
};

export default DashboardLead;
