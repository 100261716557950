import React, { useState } from "react";
import _ from 'lodash';
import { Link } from "react-router-dom";


const CityFilter = (props) => {
    const filterdata = props.filterData
    const grid = props.grid
    const groupedData = _.groupBy(filterdata, 'state');
    

    const setSelectedCity = props.setSelectedCity;
    const setSelectedState = props.setSelectedState;
    const selectedState = props.selectedState;
    const [showStatePart, setShowStatePart] = useState(false);
    // const [selectedState, setSelectedState] = useState(null);
    
    const handleStateClick = (state) => {
        setSelectedState(state);
    };
    const groupedDataByStateAndCity = {};

    for (const state in groupedData) {
        groupedDataByStateAndCity[state] = _.groupBy(groupedData[state], 'city');
    }
    return (<>
        <div className="mb-2 search-border bg-f3f3f3  rounded-lg content-div ">
            <div className="content-inner">
                {showStatePart === false ? (<>
                    <div className="row ">
                        {Object.keys(groupedData).map((state) => (
                            <div className={`px-3 py-2 ${grid === true ? "col-lg-2 " : "col-lg-3"}`}>
                                <Link className=" fs-14 text-blue hover-saffron-text span-class-cursor" key={state}
                                    onClick={() => {
                                        handleStateClick(state); setShowStatePart(true); setSelectedState(state);
                                    }}>
                                    {state}
                                    <span className="ml-1 text-gray">({groupedData[state]?.length})</span>
                                </Link>
                            </div>
                        ))
                        }
                    </div>
                </>) : null}

                {selectedState && (
                    <div className="row">
                        {Object.keys(_.groupBy(groupedData[selectedState], 'city')).map((city) => (
                            <div className={`px-3 py-2 ${grid === true ? "col-lg-2 " : "col-lg-3"}`} key={city}>
                                <Link className="fs-16 text-blue hover-saffron-text span-class-cursor"
                                    onClick={(e) => setSelectedCity(city)}>
                                    {city}
                                    <span className="ml-1 text-gray">
                                        ({groupedDataByStateAndCity[selectedState][city]?.length})
                                    </span>
                                </Link>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    </>)
}
export default CityFilter;