import React from "react";
import { Link } from "react-router-dom";
// import UserReviews from "./UserReviews";
import CompanyReview from "./CompanyReview";
import UserReviews from "./UserReviews";
import PropertyReview from "./PropertyReview";

const MyReviews = () => {
  return (
    <main id="content" className="bg-gray-01 margin-re-top">
      <div className="px-3 px-lg-6 px-xxl-13 py-5 py-lg-10">
        <div className="d-flex flex-wrap flex-md-nowrap mb-6">
          <div className="mr-0 mr-md-auto">
            <h2 className="mt-5 mb-0 text-heading fs-24 lh-15">
              My Reviews
              {/* <span className="badge badge-white badge-pill text-blue fs-18 font-weight-bold ml-2">
                10
              </span> */}
            </h2>
          </div>
        </div>
        <section className="pb-11">
          <div className="container">
            <div className="collapse-tabs mb-10">
              <ul
                className="nav nav-tabs text-uppercase d-md-inline-flex agent-details-tabs"
                role="tablist"
              >
                <li className="nav-item">
                  <a
                    href="#user"
                    className=" text-blue nav-link active  fs-16 padding-r-5"
                    data-bs-toggle="tab"
                    role="tab"
                  >
                    User
                  </a>
                </li>

                <li className="nav-item ml-0">
                  <a
                    href="#properties"
                    className="nav-link text-blue fs-16 padding-r-5"
                    data-bs-toggle="tab"
                    role="tab"
                  >
                    Properties
                  </a>
                </li>
                <li className="nav-item ml-0">
                  <a
                    href="#company"
                    className="nav-link text-blue fs-16 padding-r-5"
                    data-bs-toggle="tab"
                    role="tab"
                  >
                    Company
                  </a>
                </li>
              </ul>
              <div className="tab-content shadow-none py-7 px-6 bg-white">
                <div
                  className="tab-pane tab-pane-parent fade show active"
                  id="user"
                  role="tabpanel"
                >
                  <div className="card border-0 bg-transparent">
                    <div className="card-body p-0">
                      <h2 className="card-title fs-22 lh-15 mb-6 text-dark">
                        User Review
                      </h2>
                      <div className="able  border rounded-lg  bg-blue text-white">
                        <div className="row">
                          <div className="col border-top-0 px-7  pt-5 pb-4">
                            User Name
                          </div>
                          <div className="col border-top-0 px-7 pt-5  pb-4 ">
                            Email
                          </div>

                          <div className="col border-top-0  pt-5 px-7 pb-4 ">
                            Review 
                          </div>
                          <div className="col border-top-0 pt-5 px-7  pb-4 ">
                            Date
                          </div>
                          <div className="col border-top-0 pt-5 px-7  pb-4 ">
                            Status
                          </div>
                        </div>
                      </div>
                      <UserReviews/>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane tab-pane-parent fade"
                  id="properties"
                  role="tabpanel"
                >
                  <div className="card border-0 bg-transparent">
                    <div className="card-body p-0">
                      <h2 className="card-title fs-22 lh-15 mb-6 text-dark">
                        Property Review
                      </h2>
                      <div className="able  border rounded-lg  bg-blue text-white">
                        <div className="row">
                          <div className="col-6 border-top-0  px-6 pt-5 pb-4">
                            Property Name
                          </div>
                          <div className="col-2 border-top-0 pt-5 pb-4">
                            Review
                          </div>

                          <div className="col-2 border-top-0 pt-5 pb-4 header-user-icon">
                            Review Count
                          </div>
                        </div>
                      </div>
                      <PropertyReview/>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane tab-pane-parent fade"
                  id="company"
                  role="tabpanel"
                >
                  <div className="card border-0 bg-transparent">
                    <div className="card-body p-0">
                      <h2 className="card-title fs-22 lh-15 mb-6 text-dark">
                        Company Review
                      </h2>
                      <div className="able  border rounded-lg  bg-blue text-white">
                        <div className="row">
                          <div className="col border-top-0 px-7  pt-5 pb-4">
                            User Name
                          </div>
                          <div className="col border-top-0 px-7 pt-5 pb-4 ">
                            User Mail
                          </div>

                          <div className="col border-top-0 px-7 pt-5 pb-4 ">
                            Review 
                          </div>
                          <div className="col border-top-0 pt-5 px-7 pb-4 ">
                            Date
                          </div>
                          <div className="col border-top-0 pt-5 px-7 pb-4 ">
                            Status
                          </div>
                        </div>
                      </div>
                      <CompanyReview/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
};

export default MyReviews;
