import React, { useEffect, useState } from "react";
import Header from "../../header/Header";
import Footer from "../../footer/Footer";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import BlogSidebar from "./BlogSidebar";
import Loader from "../../Loader";
import { toast } from "react-toastify";
const API_URL = process.env.REACT_APP_API_URL;

const SingleBlog = () => {
  moment.locale('en');
  const [loading, setLoading] = useState();
  const [blogDetails, setBlogDetails] = useState();
  const [blogData,setblogData] = useState([]);
 

  const blogSlug = useParams();



  const GetBlog = async () => {
    setLoading(true);
   try {
     const response = await axios.get(`${API_URL}/blogs/get/`);
     if ((response.status = 200)) {
       const responseData = response.data;
       setblogData(responseData);
        setLoading(false);
     } else {
        setLoading(false);
        toast.error(' Please Contact Administrator');
     }
   } catch (err) {
      setLoading(false);
      toast.error(' Please Contact Administrator');
   }
 };


  const GetBlogDetail = async () => {
     
    try {
      const response = await axios.get(
        `${API_URL}/blogs/get/${blogSlug.slug}/`
      );
      if ((response.status = 200)) {
        const responseData = response.data;
        setBlogDetails(responseData);
        
      } else {
       
        
         toast.error(' Please Contact Administrator');
      }
    } catch (err) {
      
       
       toast.error(' Please Contact Administrator');
    }
  };

  useEffect(() => {
    GetBlogDetail();
    GetBlog();
  }, [blogSlug]);

  return (
    <>
    {loading ? <Loader /> : null}
      <Header />
      <main id="content" className="mt-100">
        <section className="pt-8 pb-8">
          <div className="container-fluid px-20 ">
            <div className="row ">
              
                  <div className="col-lg-9 mb-6 mb-lg-0 pr-xl-6 pl-xl-0  border-right">
                    <h3 className="fs-md-32 text-heading lh-141 mb-5">
                      {blogDetails?.title}
                    </h3>
                    <div className="position-relative ">
                      <img
                        className="rounded-lg d-block"
                        src={blogDetails?.image || "../images/post-11.jpg"}
                        alt={blogDetails?.title}
                       
                      />
                    </div>
                    <ul className="list-inline mt-4 ">
                      <li className="list-inline-item mr-4 fs-20">
                        <i className="far fa-calendar mr-1"></i>{" "}
                        {moment(blogDetails?.create_at).format("DD-MM-YYYY")}
                      </li>
                    </ul>

                    <div className="mb-5 fs-20 font-weight-500">
                        {blogDetails?.short}
                    </div>

                    <div className="lh-214 mb-9 fs-20">
                      <div
                        dangerouslySetInnerHTML={{ __html: blogDetails?.txt }}
                      />
                    </div>
                      </div>
                      <BlogSidebar />
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};
export default SingleBlog;
