import React, { useEffect, useState } from 'react';
import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react';
import axios from 'axios';
import { Card, Offcanvas } from "react-bootstrap";
import { Link, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../../Loader';
import CurrencyFormat from "react-currency-format";
// import { InfoWindow } from 'react-google-maps';
const API_URL = process.env.REACT_APP_API_URL;
const MapView = ({ google }) => {
  const state = useLocation();
  const [loading, setLoading] = useState(false)
  const buildingType = state?.state?.type;

  const [selectedBuildingTypeId, setSelectedBuildingTypeId] = useState("");
  const [fliterData, setFliterData] = useState([]);
  const [allProperties, setAllProperties] = useState([]);

  const [maxiPrice, setMaxiPrice] = useState();
  const [minPrice, setMinPrice] = useState(10000);
  const [maxPrice, setMaxPrice] = useState();
  
  const defaultLatLng = { lat: 25.2048, lng: 55.2708 }; // Default center (New York City)
  const defaultZoom = 8; // Default zoom level
  const [show, setShow] = useState(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [activeMarker, setActiveMarker] = useState(null);
  const [selectedPlace, setSelectedPlace] = useState(null);


  const getAllProperties = async () => {
    setLoading(true)
    try {
      const response = await axios.get(`${API_URL}/properties/advance/filter/`);
      // const response = await axiosInstance.get(`${API_URL}/properties/advance/filter/`);
      // const response = await axiosInstance.get(`${API_URL}/properties/advance/filter/?latitude=${lati || lat}&longitude=${longi || lng}&distance=10000`);
      if (response.status === 200) {
        const responseData = response?.data;
        setAllProperties(responseData)
        let max = 0;

        responseData.forEach((property) => {
          if (property.property_price > max) {
            max = property.property_price;
          }
        });
        setMaxiPrice(max);
        setMaxPrice(max);
        setLoading(false)
      } else {
        
      }
    }

    catch (err) {
      
    }
  }




  const rightTopContent = (
    <div style={{ position: 'absolute', top: '10px', right: '10px', backgroundColor: 'white', padding: '10px', borderRadius: '5px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)' }}>
      <div className=''>
        <h2 className='fs-18'>Current Price Range</h2>
        <hr></hr>

        <div style={{ display: "flex" }}>
          <div className='css-y3ylbw mr-2 mt-1'></div>
          <span className='fs=18 font-weight-500 span-class-cursor'
            onClick={() => {
              setMinPrice(1);
              setMaxPrice(850);
              handleShow();
            }}>Below AED 850</span>
        </div>

        <div style={{ display: "flex" }}>
          <div className='css-18ti2kw mr-2 mt-1'></div>
          <span className='fs=18 font-weight-500 span-class-cursor'
            onClick={() => {
              setMinPrice(850);
              setMaxPrice(2000);
              handleShow();
            }}>AED 850 - AED 2,000</span>
        </div>

        <div style={{ display: "flex" }}>
          <div className='css-1rncvuw mr-2 mt-1'></div>
          <span className='fs=18 font-weight-500 span-class-cursor'
            onClick={() => {
              setMinPrice(2000);
              setMaxPrice(5000);
              handleShow();
            }}>AED 2,000 - AED 5,000</span>
        </div>

        <div style={{ display: "flex" }}>
          <div className='css-18ljdh2 mr-2 mt-1'></div>
          <span className='fs=18 font-weight-500 span-class-cursor'
            onClick={() => {
              setMinPrice(5000);
              setMaxPrice(10000);
              handleShow();
            }}>AED 5,000 - AED 10,000</span>
        </div>

        <div style={{ display: "flex" }}>
          <div className='css-aivvw0 mr-2 mt-1'></div>
          <span className='fs=18 font-weight-500 span-class-cursor'
            onClick={() => {
              setMinPrice(10000);
              setMaxPrice(maxiPrice);
              handleShow();
            }}
          >AED 10,000 - Above</span>
        </div>

      </div>
    </div>
  );
  // const propertyTypeSearch = (
  //   <div style={{ position: 'absolute', top: '10px', right: '220px', backgroundColor: 'white', padding: '10px', borderRadius: '5px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)' }}>
  //     <div style={{ dispaly: 'flex' }}>
  //       <span className='fs-18 mr-2 text-dark'>Select Property type</span>
  //       <select style={{ width: '200px', border: "none", padding: '5px', borderRadius: '3px' }}
  //         onChange={(e) =>
  //           setSelectedBuildingTypeId(e?.target?.value)
  //         }
  //       >
  //         <option></option>
  //         {buildingType?.map((item) => {
  //           return <option value={item?.id}>{item?.name}</option>;
  //         })}
  //       </select>
  //     </div>
  //   </div>
  // );
  const HomePage = (
    <div style={{ position: 'absolute', top: '10px', right: '220px', backgroundColor: '#FF6935', padding: '10px', borderRadius: '5px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)' }}>
      <div style={{ dispaly: 'flex' }}>
        <Link to="/advance-search" className='fs-18 text-white'>Home</Link>
      </div>
    </div>
  );
  useEffect(() => {
    getAllProperties();

  }, [])
 
  useEffect(() => {
    if (minPrice || maxPrice) {
      const newres = allProperties.filter((item) => {
        return (
          // (selectedBuildingTypeId === "" || item.building_type.includes(selectedBuildingTypeId)) ||

          (item.property_price >= minPrice && item.property_price <= maxPrice && item.property_price !== "null") ||
          (item.lease_price_per_mo >= minPrice && item.lease_price_per_mo <= maxPrice && item.lease_price_per_mo !== "null")
        );
      });
      if (newres.length > 0) {
        setFliterData(newres);
      } else {
       
        setFliterData([]);
      }
    }
    // Add an else statement here if you want to handle the case when selectedBuildingTypeId is empty.

  }, [minPrice, maxPrice]);

  const onMarkerClick = (props, marker, e) => {

    setSelectedPlace(props);
    setActiveMarker(marker);

  };

  const onClose = () => {
    if (activeMarker) {
      setActiveMarker(null);
      setSelectedPlace(null);

    }
  };
  return (
    <>
      {loading ? <Loader /> : null}
      <Offcanvas show={show} onHide={handleClose} backdrop="static">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Dirham Per Foot</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="row">
          {fliterData?.length > 0 ?(<>
            {fliterData?.map((item) => (
              <div
                key={item.id}

                data-animate="fadeInUp"
              >
                <Link to={`/property/details/${item?.slug}`}>
                  <div className="" data-animate="fadeInUp">
                    <div style={{ cursor: "pointer" }}>

                      <div className="">
                        <div className="row border-c round-5 mb-5  "
                          style={{
                            margin: "0px",
                            backgroundColor: "#F7F7F7",
                            borderColor: "#D3D3D3",
                          }}
                        >
                          <div
                            className="col-lg-5"
                            style={{ padding: "0px" }}
                          >
                            <div className="">
                              <img
                                style={{
                                  height: "150px",
                                  borderRadius: " 5px 0 0 5px",
                                }}
                                src={item?.upload_featured_image}
                              />
                              <div className="card-img-overlay d-flex flex-column justify-content-between " >
                                <div><span className="badge badge-orange mr-2">{item?.property_label_name}</span></div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-7 px-2" >
                            <p className=" mt-3 fs-16 font-weight-bold text-heading mb-0 " style={{ lineHeight: "0.7" }}>
                              <span className="text-black font-weight-500 fs-12">AED{" "}</span>
                              {item?.property_price ? (<>
                                <CurrencyFormat value={item?.property_price} displayType='text' thousandSeparator={true} prefix="" />
                              </>) : (<>
                                <CurrencyFormat value={item?.lease_price_per_mo} displayType='text' thousandSeparator={true} prefix="" />
                                <span className="text-gray-light font-weight-500 fs-12"> /Mo</span>
                              </>)}
                            </p>
                            <h2 className="mt-1" style={{ lineHeight: "0.5" }}>
                              <a className="fs-14 text-dark">
                                {item?.property_name}
                              </a>
                            </h2>
                            <div className="list-inline-item text-black fs-14  align-items-center  mb-0"
                              data-toggle="tooltip" title="Size">
                              Area:{" "}{item?.property_size}Sq.Ft
                            </div>
                            <p className="text-blue  mb-4 fs-12">
                              {item?.state}{", "}{item.city}
                            </p>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </Link>
              </div>
            ))}</>):(<p className='fs-16 mr-5 text-red'>No matching properties found</p>)}
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      <Map
        google={google}
        zoom={defaultZoom}
        initialCenter={defaultLatLng}

      >
        {rightTopContent}
        {/* {propertyTypeSearch} */}
        {HomePage}

        {
          fliterData.map((item) => {
            return (
              <Marker
                position={{ lat: item?.lat, lng: item?.lng }}
                initialCenter={{ lat: item[0]?.lat, lng: item[0]?.lng }}
                onClick={(props, marker, e) => onMarkerClick(item, marker, e)}
              >
              </Marker>
            )
          })
        }
        <InfoWindow
          marker={activeMarker}
          visible={activeMarker !== null}
          onClose={onClose}
        >
          <a href={`/property/details/${selectedPlace?.slug}`} >
            <div className='section-infowindow-part'  >
              <div className='main-section-marker-infowindow'>
                <img
                  style={{
                    width: "100%",
                    height: "200px",
                    borderRadius: "5px",
                  }}
                  src={selectedPlace?.upload_featured_image}
                />
                <div className="card-img-overlay d-flex flex-column justify-content-between " >
                  <div><span className="badge badge-orange mr-2">{selectedPlace?.property_label_name}</span></div>
                </div>
                <div className="card-body p-0">
                  <h2 className="my-0 mt-1" ><a
                    className="fs-16 text-dark  lh-2">{selectedPlace?.property_name}</a>
                  </h2>
                  <p className="text-gray-light font-weight-500 mb-1">{selectedPlace?.street_address}</p>
                  {selectedPlace?.property_price ?
                    <p className="fs-17 font-weight-bold text-heading mb-0 px-1">AED:{" "}<CurrencyFormat value={selectedPlace?.property_price} displayType='text' thousandSeparator={true} /></p> :
                    selectedPlace?.lease_price_per_mo ?
                      <p className="fs-17 font-weight-bold text-heading mb-0 px-1">AED:{" "}<CurrencyFormat value={selectedPlace?.lease_price_per_mo} displayType='text' thousandSeparator={true} /><span
                        className="text-gray-light font-weight-500 fs-14"> /Mo</span></p> :
                      null
                  }
                </div>



              </div>
            </div>
          </a>
        </InfoWindow>
      </Map>
    </>
  );
};

export default GoogleApiWrapper({
  apiKey: 'AIzaSyDZSiGDJcQdFDZqQU5ONXF7dGkoKQePszo', // Replace with your actual Google Maps API key
})(MapView);