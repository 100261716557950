import React, { useEffect, useState } from "react";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";


const ImageGalleryControl = ({imageData, startIndex}) => {
   
    const GalleryImages = imageData?.map((item) => {
       
        return ({
            original: item,
            thumbnail: item
        })
    })
   
    return (
        <>
            {
                imageData ?
                    <ImageGallery items={GalleryImages} showFullscreenButton={false} startIndex={startIndex} showPlayButton={false} />
                    : null
            }

        </>
    )
}
export default ImageGalleryControl;