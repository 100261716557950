// https://platform.openai.com/docs/guides/text-generation/chat-completions-api
import React, { useEffect, useState } from "react";
import axios from "axios";
  const apiKey = "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"; 

const AnswerSec = () => {
  // const [inputText, setInputText] = useState('');
  // const [conversation, setConversation] = useState([]);

  // const handleInputChange = (event) => {
  //   setInputText(event.target.value);
  // };

  // const sendMessage = async () => {
  //   if (!inputText) return;

  //   try {
  //     const response = await axios.post(
  //       'https://api.openai.com/v1/completions',
  //       {
  //         model: 'gpt-3.5-turbo', // Choose the model you want to use
  //         prompt: inputText,
  //         max_tokens: 150, // Adjust as needed
  //       },
  //       {
  //         headers: {
  //           'Content-Type': 'application/json',
  //           Authorization: `Bearer ${apiKey}`,
  //         },
  //       }
  //     );

  //     const responseData = response.data.choices[0].text.trim();
  //     setConversation([...conversation, { text: inputText, user: true }, { text: responseData, user: false }]);
  //     setInputText('');
  //   } catch (error) {
  //     console.error('Error sending message:', error);
  //   }
  // };

  // return (
  //   <div>
  //     <div style={{ height: '400px', overflowY: 'scroll', border: '1px solid #ccc', padding: '10px' }}>
  //       {conversation.map((message, index) => (
  //         <div key={index} style={{ marginBottom: '10px', textAlign: message.user ? 'right' : 'left' }}>
  //           <div style={{ background: message.user ? '#e0f7fa' : '#b2dfdb', padding: '5px', borderRadius: '10px', display: 'inline-block' }}>
  //             {message.text}
  //           </div>
  //         </div>
  //       ))}
  //     </div>
  //     <div style={{ marginTop: '10px' }}>
  //       <input type="text" value={inputText} onChange={handleInputChange} />
  //       <button onClick={sendMessage}>Send</button>
  //     </div>
  //   </div>
  // );

  // const [description, setDescription] = useState('');
  // const [loading, setLoading] = useState(false);

  // const generateDescription = async () => {
  //   setLoading(true);
  //   try {
  //     const response = await axios.post(
  //       'https://api.openai.com/v1/engines/text-davinci-002/completions',
  //       {
  //         prompt: 'Generate a description for a product:',
  //         max_tokens: 100 // Adjust max_tokens as needed
  //       },
  //       {
  //         headers: {
  //           'Content-Type': 'application/json',
  //           'Authorization': `Bearer ${apiKey}` // Use environment variables for security
  //         }
  //       }
  //     );
  //     setDescription(response.data.choices[0].text);
  //   } catch (error) {
  //     console.error('Error generating description:', error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // return (
  //   <div>
  //     <button onClick={generateDescription} disabled={loading}>
  //       Generate Description
  //     </button>
  //     {loading && <p>Loading...</p>}
  //     {description && <p>{description}</p>}
  //   </div>
  // );




    const sendRequest = async () => {
      const OPENAI_API_KEY = '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'; // Replace with your OpenAI API key
      const requestOptions = {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${OPENAI_API_KEY}`,
          'Content-Type': 'application/json',
          'OpenAI-Beta': 'assistants=v1'
        },
        
      };

      try {
        const response = await fetch('https://api.openai.com/v1/threads/runs', requestOptions);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        console.log(data,"RESPONSE"); // Handle the response data here
      } catch (error) {
        console.error('There was a problem with the request:', error);
      }
    };

  
  

  return (
    <div>
       <button onClick={sendRequest} >
        Generate Description
      </button>
    </div>
  );
};






  
  export default AnswerSec;