import React, { useEffect, useState } from "react";
import axiosInstance from "../interceptor/axiosInstance";
import { toast } from "react-toastify";
import {
  Accordion,
  AccordionDetails,
  Typography,
  AccordionSummary,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Modal, ProgressBar } from "react-bootstrap";
import ContactTable from "./ContactTable";
import { Button } from "react-bootstrap";

import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

const MyContact = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [description, setDescription] = useState("");
  const [contactData, setContactData] = useState([]);
  const [expanded, setExpanded] = useState({});

  const getAllUser = async () => {
    try {
      const response = await axiosInstance.get(`${API_URL}/track/email/data/`);
      if (response.status === 200) {
        const responseData = response.data;
        console.log(responseData, "this is responseData");
        setContactData(responseData);
      } else {
        toast.error(" Please Contact Administrator");
      }
    } catch (err) {
      toast.error(" Please Contact Administrator");
    }
  };

  useEffect(() => {
    getAllUser();
  }, []);

  // Handle Modal
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show, setShow] = useState(false);
  const [progress, setProgress] = useState(0);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleUpload = () => {
    if (!selectedFile) {
      toast.error("Please select a file");
      return;
    }
    if (!description) {
      toast.error("Upload File Name");
      return;
    }

    const formData = new FormData();
    formData.append("csv", selectedFile);
    formData.append("list_name", description);

    axiosInstance
      .post(`${API_URL}/track/upload/email/`, formData)
      .then((response) => {
        console.log(response, "this is response");
        toast.success("File uploaded successfully");
        getAllUser();
        setProgress(0);
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
        toast.error("Error uploading file");
        setProgress(0);
      });
  };



  return (
    <>
      <main id="content" className="bg-gray-01 margin-re-top">
        <div className="px-3 px-lg-6 px-xxl-13 py-5 py-lg-10">
          <div className="d-flex flex-wrap flex-md-nowrap mb-6">
            <div className="mr-0 mr-md-auto">
              <h2 className="mt-5 mb-0 text-heading fs-24 lh-15">
                My Contacts
              </h2>
            </div>
            <div>
              <span
                className="btn bg-blue text-white hover-saffron btn-lg mr-2"
                onClick={handleShow}
              >
                <i class="bi bi-file-earmark-arrow-up-fill fa-lg"></i>Upload CSV
              </span>
              
            </div>
            <div>
              <a
                href="https://dollarperfoot.s3.amazonaws.com/user/video/demo.csv"
                download="demo.csv"
                class="btn bg-blue text-white hover-saffron btn-lg"
              >
                <i class="bi bi-file-earmark-arrow-down-fill fa-lg"></i>{" "}
                Download CSV
              </a>
            </div>
          </div>
          <div className="card">
            <div className="able  border rounded-lg  bg-blue text-white">
              <div className="row">
                <div className="col-6 border-top-0  px-6 pt-5 pb-4">
                  CSV Name
                </div>
              </div>
            </div>
            {contactData.map((item) => (
              <Accordion key={item.id}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>{item?.list_name}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {/* <EnhancedTable /> */}
                  <ContactTable myData={item}/>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        </div>
      </main>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Upload CSV</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="file-input">
            <label htmlFor="file-upload">
              <Button>Choose File</Button>
            </label>
            <input
              id="file-upload"
              type="file"
              name="file"
              className="input-file-profile"
              accept=".csv"
              onChange={handleFileChange}
            />
          </div>
          <p className="fs-12">File must be CSV</p>
          <label htmlFor="description" className="text-heading fs-22">
            List Name
          </label>
          <input
            type="text"
            className="form-control form-control-lg border-0"
            id="description"
            name="description"
            value={description}
            onChange={handleDescriptionChange}
            required
          />
          {progress > 0 && (
            <ProgressBar now={progress} label={`${progress.toFixed(2)}%`} />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="success"
            onClick={() => {
              handleClose();
              handleUpload();
            }}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MyContact;
