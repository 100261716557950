import React, { Fragment } from 'react'
import { Outlet } from 'react-router'
import Sidebar from './Sidebar'
import Header from './Header'

function DashboardLayout() {
    return (



        <Fragment>

            <div className="wrapper dashboard-wrapper">
                <div className="d-flex flex-wrap flex-xl-nowrap">
                    <Sidebar />
                    <div className="page-content">
                        <Header />
                        <Outlet />
                    </div>
                </div>
            </div>
            {/* <AdminHeader /> */}



        </Fragment>
    )
}

export default DashboardLayout