import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AutoComplete from "react-google-autocomplete";
import axios from "axios";
import axiosInstance from "../../interceptor/axiosInstance";
import { useEffect } from "react";
import { toast } from "react-toastify";
const API_URL = process.env.REACT_APP_API_URL
const SearchLocationInput = (props) => {
  const allimages = props?.allimages
  const navigate = useNavigate()
  const [place, setPlace] = useState([])

  const [loading, setLoading] = useState()
  const lati = place?.geometry?.location?.lat();
  const longi = place?.geometry?.location?.lng();
  const [propertyLabel, setPropertyLabel] = useState([])
  const [buildingType, setBuildingType] = useState([]);
  const [byID, setByID] = useState(false);
  const [uniqueId, setUniqueId] = useState();

  const [uID, setUID] = useState([]);


  function searchByID() {
    setByID(true);
  }
  // const filteredArray = allimages.filter(
  //   (item) => item?.id === uniqueId
  // );
  useEffect(() => {
    if (uniqueId !== '' && allimages) {

      const filteredArray = allimages.filter(item => item?.prop_id === uniqueId);

      if (filteredArray.length > 0) {
        setUID(filteredArray[0]?.id);
      }
    }
  }, [uniqueId, allimages]);

  const handleKeyEnter = (e) => {
    if (e.key === 'Enter') {
      passPropertyId();
    }
  };
  const passPropertyId = () => {
    if (uID) {
      navigate(`property/details/${uID}`);
    }
  };

  /* ****************api for property label ************* */

  const getPropertyLabel = async () => {
    setLoading(true)
    try {
      const response = await axios.get(`${API_URL}/properties/property/label/`);
      if (response.status = 200) {
        const responseData = response?.data;
        setPropertyLabel(responseData)
        setLoading(false)
      } else {

        setLoading(false)
        toast.error(' Please Contact Administrator');
      }
    } catch (err) {

      setLoading(false)
      toast.error(' Please Contact Administrator');
    }
  }





  useEffect(() => {
    getPropertyLabel();
  }, [])

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      Search();
    }
  };


  const Search = () => {
    navigate('/advance-search', { state: { lati, longi } })
  };

  return (
    <>
      <div className="mxw-670 top-50-50 position-relative z-index-2">
        <input
          className="search-field"
          type="hidden"
          name="status"
          value="for-sale"
          data-default-value=""
        />
        <ul className="nav nav-pills property-search-status-tab">
          <li className="nav-item" role="presentation">
            <Link
              className="nav-link bg-blue hover-saffron btn shadow-none rounded-bottom-0 fs-13 letter-spacing-087 bg-dark-opacity-05 text-white  text-uppercase  active"
              to="/advance-search"
              state={{ property_label: propertyLabel[0]?.id }}
            >
              sale
            </Link>
          </li>
          <li className="nav-item" role="presentation">
            <Link
              className="padding-816 btn bg-saffron text-white hover-saffron border-0 text-uppercase "
              to="/advance-search"
              state={{ property_label: propertyLabel[1]?.id }}
            >
              Rent
            </Link>
          </li>
          <li className="nav-item" role="presentation">
            <Link
              onClick={searchByID}
              className="padding-816 btn btn-accent text-black border-0 text-uppercase "
            >
              By Property ID
            </Link>
          </li>
        </ul>
        {byID === true ? (
          <>
            <div className="d-flex">
              <div className="position-relative w-100">
                <i className="far fa-search text-dark fs-18 position-absolute pl-4 pos-fixed-left-center"></i>

                <input
                  type="text"
                  onChange={(e) => setUniqueId(e?.target?.value)}
                  value={uniqueId}
                  onKeyPress={handleKeyEnter}
                  className="rounded-bottom-right-lg w-100 pl-8 py-4 bg-gray search-border fs-13 font-weight-500 text-gray-light rounded-0 lh-17"
                  placeholder="Enter Property Unique ID..."
                />
              </div>
              <button
                type="button"
                onClick={passPropertyId}
                className="btn text-white bg-blue hover-saffron fs-18 font-weight-600 rounded-left-0 "
              >
                Search
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="d-flex">
              <div className="position-relative w-100">
                <i className="far fa-search text-dark fs-18 position-absolute pl-4 pos-fixed-left-center"></i>
                <AutoComplete
                  apiKey={"AIzaSyDZSiGDJcQdFDZqQU5ONXF7dGkoKQePszo"}
                  onPlaceSelected={(place) => {
                    setPlace(place);

                  }}
                  onKeyPress={handleKeyPress}
                  className="rounded-bottom-right-lg rounded-top-right-ra w-100 pl-8 py-4   bg-white border-0 fs-13 font-weight-500 text-gray-light rounded-0 lh-17"
                  placeholder="Enter Location..."
                  onKeyDown={(event) => {
                    if (event?.keyCode === 18) {
                      event?.preventDefault();
                    }
                  }}
                  options={{
                    types: ["route"],
                    componentRestrictions: { country: 'ae' },

                  }}

                />
              </div>
              <button
                type="button"
                onClick={Search}
                className="btn text-white bg-blue hover-saffron fs-18 font-weight-600 rounded-left-0 "
              >
                Search
              </button>
            </div>
          </>
        )}
      </div>
    </>

  );
};

export default SearchLocationInput;
