
import React from "react";
// import BannerImage from "../images/top-banner-3.jpg";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { Link } from "react-router-dom";
import SuperContactForm from "./SuperContactForm";
// import logo from "../../images/dpf-logo-new.png";

const SuperLandingPage = () => {
    return (

            <main id="content">
                <section className="d-flex flex-column" style={{ backgroundImage: `url("../images/topBanner-1.png")`, backgroundSize: "cover", backgroundPosition: "center" }} >
                    <div className="container-fluids px-10 mt-5 display-re-none ">
                        <div className="d-flex align-items-center pos-relative">
                            <nav className="navbar navbar-expand-xl bg-transparent px-0 w-100 w-xl-auto wdth-100per">
                                <Link className="navbar-brand mr-7 mr-none"
                                    to="#" data-toggle="dropdown" >
                                    <img src="../images/dpf-logo-new.png" alt="HomeID" className="normal-logo main-logo-header" />
                                </Link>

                            </nav></div>
                    </div>
                    <div className=" container custom-vh-100 margin-re-top-100 margin-re-top-80" style={{ textAlign: 'center' }} >
                        <div className="row">
                            <div className="col-lg-12 col-md-9 col-sm-4">
                                <AnimationOnScroll animateIn="animate__zoomIn">
                                    <h2 className="text-black display-2  fs-42 fw-600 text-center" ><img className="landing-fevicon" src="images/favicon-icon.png" /><span className="text-saffron">Real Estate Elevated !</span> <br /><span className="text-blue">Endorsed by Developers, Tailored for You ! </span> <br />Something Exciting is Launching Soon. Stay Tuned!<br /><span className="text-saffron mb-2">COMING SOON</span></h2>
                                    
                                </AnimationOnScroll>
                            </div>
                            <div className="col-lg-12 col-md-9 padding-x-30" style={{ textAlign: 'center' }}>
                                <AnimationOnScroll className="w-100" animateIn="animate__fadeInLeft "  >
                                    <SuperContactForm />
                                </AnimationOnScroll>
                            </div>
                        </div>
                    </div>
                </section>



            </main>


    )
}
export default SuperLandingPage;