// Components.js
import React from 'react';
import { createChatBotMessage } from 'react-chatbot-kit';

// export const AreaBtn = (props) => {
//     const areas = ['dubai', 'sarjahan']; 

//     const preference = (e) => {
//         if (props.onAreaSelected) {
//             props.onAreaSelected(e?.target?.value); 
//         }
//     };

//     return (
//         <div>
//             {areas.map((area) => (
//                 <button key={area} value={area} className='start-btn' onClick={() => preference(e?.target?.value)}>
//                     {area.charAt(0).toUpperCase() + area.slice(1)} 
//                 </button>
//             ))}
//         </div>
//     );
// }

export const DubaiBtn = (props) => {
    const areas = ['Yes', 'No'];

    const preference = (selectedCountry, event) => {
        if (props.onCountrySelected) {
            props.onCountrySelected(selectedCountry); 
            console.log(selectedCountry, "NANA");
        }
    };

    return (
        <div>
            {areas.map((area) => (
                <button className='start-btn' onClick={(event) => {preference(area, event); console.log(area);}} key={area}>
                    {area}
                </button>
            ))}
        </div>
    );
}

export const AreaBtn = (props) => {
    const areas = ['dubai', 'sarjahan'];

    const preference = (selectedArea, event) => {
        if (props.onAreaSelected) {
            props.onAreaSelected(selectedArea); 
            console.log(selectedArea, "NANA");
        }
    };

    return (
        <div>
            {areas.map((area) => (
                <button className='start-btn' onClick={(event) => {preference(area, event); console.log(area);}} key={area}>
                    {area}
                </button>
            ))}
        </div>
    );
}







export const BuildingBtn = (props) => {
    const buildingType = ['Commercial', 'Residential']; 

    const preference = (selectedBuildingType) => {
        if (props.onBuildingType) {
            props.onBuildingType(selectedBuildingType); 
        }
    };

    return (
        <div>
            {buildingType.map((type) => (
                <button key={type} className='start-btn' onClick={() => preference(type)}>
                    {type.charAt(0).toUpperCase() + type.slice(1)} 
                </button>
            ))}
        </div>
    );
}

export const PropertyBtn = (props) => {
    const propertyType = ['Apartment', 'Office', 'Villa']; 

    const preference = (selectedPropertyType) => {
        if (props.onPropertyType) {
            props.onPropertyType(selectedPropertyType); 
        }
    };

    return (
        <div>
            {propertyType.map((type) => (
                <button key={type} className='start-btn' onClick={() => preference(type)}>
                    {type.charAt(0).toUpperCase() + type.slice(1)} 
                </button>
            ))}
        </div>
    );
}

export const LabelBtn = (props) => {
    const labelType = ['Sale', 'Rent']; 

    const preference = (selectedLabelType) => {
        if (props.onLabelType) {
            props.onLabelType(selectedLabelType); 
        }
    };

    return (
        <div>
            {labelType.map((type) => (
                <button key={type} className='start-btn' onClick={() => preference(type)}>
                    {type.charAt(0).toUpperCase() + type.slice(1)} 
                </button>
            ))}
        </div>
    );
}

export const PriceBtn = (props) => {
    const averagePrice = ['800000', '1000000', '10000000', '15000000', '18000000']; 

    const preference = (selectedAveragePrice) => {
        if (props.onAveragePrice) {
            props.onAveragePrice(selectedAveragePrice); 
        }
    };

    return (
        <div>
            {averagePrice.map((price) => (
                <button key={price} className='start-btn' onClick={() => preference(price)}>
                    {price} 
                </button>
            ))}
        </div>
    );
}

export const PlanBtn = (props) => {
    const planType = ['New Project', 'Off Plan']; 

    const preference = (selectedPlanType) => {
        if (props.onPlanType) {
            props.onPlanType(selectedPlanType); 
        }
    };

    return (
        <div>
            {planType.map((type) => (
                <button key={type} className='start-btn' onClick={() => preference(type)}>
                    {type} 
                </button>
            ))}
        </div>
    );
}

export const ConstructionBtn = (props) => {
    const constructionStatus = ['Completed', 'Under Construction']; 

    const preference = (selectedConstructionStatus) => {
        if (props.onConstructionStatus) {
            props.onConstructionStatus(selectedConstructionStatus); 
        }
    };

    return (
        <div>
            {constructionStatus.map((status) => (
                <button key={status} className='start-btn' onClick={() => preference(status)}>
                    {status} 
                </button>
            ))}
        </div>
    );
}
