import React, { useEffect, useState } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import { Link } from "react-router-dom";
import ReactStoreIndicator from "react-score-indicator";
const PropertyScore = (props) => {
  const [propData, setPropData] = useState("");
  const [propName, setPropName] = useState("");
  const [PropertyWeightage, setPropertyWeightage] = useState({
    Groceries: 15,
    ShoppingMall: 10,
    Hospital: 40,
    Schools: 20,
    MetroStation: 15,
  });
  const nearPlaceCount = props.nearPlaceCount;


  const calculateTotalWeightedValue = () => {
    let totalWeightedValue = 0;
    nearPlaceCount.forEach((location) => {
      const locationName = Object.keys(location)[0]; // Assuming each object has only one property
      const locationCount = location[locationName] || 0;
      totalWeightedValue += PropertyWeightage[locationName] * locationCount;
    });

    return totalWeightedValue;
  };
  const handleCalculatePropertyCount = () => {
    const targetTotalWeightedValue = 10; // Replace with your desired value
    const totalWeightedValue = calculateTotalWeightedValue();
    const propertyCount = totalWeightedValue / targetTotalWeightedValue;

    if (propertyCount > 100) {
      setPropData(99);
    } else {
      setPropData(propertyCount);
    }
    if (propData > 75) {
      setPropName("Premium");
    }
    if (propData < 75 && propData > 45) {
      setPropName("Good");
    }
    if (propData < 45) {
      setPropName("Average");
    }
  };
  useEffect(() => {
    // calculateTotalWeightedValue();
    handleCalculatePropertyCount();
  });

  return (
    <div className="card border-0 widget-request-tour mt-5">
      <div className=" d-block text-center  ">
        <Link className="py-2 bg-blue text-white px-3">NEARBY PLACES </Link>
      </div>
      <div class="card-body pb-5 pt-0  mt-5">
        {/* <ReactStoreIndicator
          value={propData}
          maxValue={100}
          lineWidth="25"
          fadedOpacity="25"
        /> */}
        {/* <div className="fs-18 text-center font-weight-500 mb-2">
          {propName} Location
        </div> */}

        {/* ****1 */}
        {nearPlaceCount.find(
                  (item) => Object.keys(item)[0] === "Hospital"
                )?.Hospital ?(
        <div className="d-flex align-items-center justify-content-center mb-2 bg-blue text-white">
          <div className="font-weight-500 text-uppercase mr-1">Hospitals</div>
          <span className="font-weight-bold fs-16 ">
            {
              nearPlaceCount.find((item) => Object.keys(item)[0] === "Hospital")
                ?.Hospital
            }
          </span>
        </div>):null}
        {/* ****2 */}
        {nearPlaceCount.find(
                (item) => Object.keys(item)[0] === "Groceries"
              )?.Groceries ? (
        <div className="d-flex align-items-center justify-content-center mb-2 bg-green text-white">
          <div className="font-weight-500 text-uppercase mr-2">Groceries</div>
          <div className="font-weight-bold fs-16">
            {
              nearPlaceCount.find(
                (item) => Object.keys(item)[0] === "Groceries"
              )?.Groceries
            }
          </div>
        </div>):null}
        {/* ****3 */}
        {/* {
              nearPlaceCount.find((item) => Object.keys(item)[0] === "Schools")
                ?.Schools ? (
        <div className="d-flex align-items-center justify-content-center mb-2 bg-orange text-white">
          <div className="font-weight-500 text-uppercase mr-2">Schools</div>
          <div className="font-weight-bold fs-16">
            {
              nearPlaceCount.find((item) => Object.keys(item)[0] === "Schools")
                ?.Schools
            }
          </div>
        </div>):null} */}
        {/* ****4 */}
        {
              nearPlaceCount.find((item) => Object.keys(item)[0] === "ShoppingMall")
                ?.ShoppingMall  ? (
        <div className="d-flex align-items-center justify-content-center mb-2 bg-success text-white">
          <div className="font-weight-500 text-uppercase mr-1">Shopping Mall</div>
          <div className="font-weight-bold fs-16">
            {
              nearPlaceCount.find((item) => Object.keys(item)[0] === "ShoppingMall")
                ?.ShoppingMall
            }
          </div>
        </div>):null}
        {/* ****5 */}
        {
              nearPlaceCount.find(
                (item) => Object.keys(item)[0] === "MetroStation"
              )?.MetroStation ? (
        <div className="d-flex align-items-center justify-content-center mb-2 bg-danger text-white">
          <div className="font-weight-500 text-uppercase mr-6">Metro</div>
          <div className="font-weight-bold fs-16">
            {
              nearPlaceCount.find(
                (item) => Object.keys(item)[0] === "MetroStation"
              )?.MetroStation
            }
          </div>
        </div>):null}
      </div>
    </div>
  );
};

export default PropertyScore;