import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import contactImage from "../images/area-score.jpg"
import BackLineImage from "../images/bg_circle.png";
import CurrencyFormat from "react-currency-format";
import axios from "axios";
import { toast } from "react-toastify";

export const Contact = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [value, setValue] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    msg: "",
  });

  const handelSubmit = (event) => {
    event.preventDefault();
    axios
      .post(`${API_URL}/track/contact/`, value)
      .then((response) => {
        toast.success("Your Contact Details Has Been Successfully Sent!");
        // setValue({
        //   first_name: "",
        //   last_name: "",
        //   email: "",
        //   phone: "",
        //   msg: "",
        // });
        this.reset();
      })
      .catch((error) => {
        // toast.error(" Please Contact Administrator");
      });
  };

  return (
    <>
      <Header />
      <main id="content ">
        <section
          class="py-14 py-lg-15 page-title mt-100  marginTopContact"
          style={{
            backgroundImage: `url(${contactImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
                     }}
        >
          <div className="d-flex align-items-center mr-bm-sr text-center" >
        <div className="container-fluid px-20">
          <h1 className="text-white mb-5 lh-2 fs-md-14 fs-lg-32">
          We're always eager to hear from you!
          </h1>

        </div>
      </div>
        </section>
        <section
        style={{ backgroundImage: `url(${BackLineImage})` }}
        >
          <div class="container-fluid px-20 mb-8">
            <div className="row">
              <div className="col-lg-8 pt-10  px-10">
                <div class="card border-1  z-index-3 pb-8 ">
                  <div class="card-body py-2">
                    <h2 class="text-heading mb-2 fs-22 py-2 px-5 lh-16">
                     Feel free to contact us!
                    </h2>

                    <form onSubmit={handelSubmit} class="mxw-751 px-md-5 ">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group position-add-validate-symbol">
                            <input
                              type="text"
                              placeholder="First Name"
                              class="form-control form-control-lg border-0"
                              name="first_name"
                              onChange={(e) =>
                                setValue({ ...value, first_name: e.target.value })
                              }
                              required
                            />
                            <span className="asterisk_input"></span>
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group">
                            <input
                              type="text"
                              placeholder="Last Name"
                              name="last_name"
                              onChange={(e) =>
                                setValue({ ...value, last_name: e.target.value })
                              }
                              required
                              class="form-control form-control-lg border-0"
                            />
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group position-add-validate-symbol">
                            <input
                              placeholder="Your Email"
                              class="form-control form-control-lg border-0"
                              onChange={(e) =>
                                setValue({ ...value, email: e.target.value })
                              }
                              type="email"
                              name="email"
                              required
                            />
                            <span className="asterisk_input"></span>
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group">
                            <CurrencyFormat
                              format="+1 (###) ###-####"
                              mask="_"
                              placeholder="Your Phone"
                              name="phone"
                              onChange={(e) =>
                                setValue({ ...value, phone: e.target.value })
                              }
                              class="form-control form-control-lg border-0"
                              required
                            />
                          </div>
                        </div>
                      </div>

                      <div class="form-group mb-6">
                        <textarea
                          class="form-control border-0"
                          placeholder="Message"
                          name="msg"
                          onChange={(e) =>
                            setValue({ ...value, msg: e.target.value })
                          }
                          required
                          rows="5"
                        ></textarea>
                      </div>
                      <div class="text-center">
                        <button
                          type="submit"
                          class="btn btn-lg bg-blue text-white hover-saffron px-9"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 pt-10">
                <div className="border-1 round p-3 mb-3 bg-white">
                <div class="row ">

                    <div class="col-lg-5">
                      <span className="text-primary fs-40 lh-1 d-inline-block mb-1">
                        <img
                          src="images/uaeflag.jpg"
                          alt="developer"
                        /*   style={{ width: "100px", height: "100px", borderRadius: "50%" }} */
                        />
                      </span>


                    </div>
                    <div class="col-lg-7">
                      <h4 class="fs-22 lh-15 mb-0 text-heading">Dubai</h4>
                      <p class="card-text mb-0">Coming<br /> Soon</p>
                      <a className="text-orange font-weight-600" href="/">Dirham Per Foot<i className="text-blue ml-1 fa fa-external-link" aria-hidden="true" /></a>
                    </div>
                  </div>
                  </div>
                  <div className="border-1 p-3 mb-3 bg-white">
                  <div className="row">
                    <div class="col-lg-5">
                      <span className="text-primary fs-40 lh-1 d-inline-block mb-1">
                      <img src="images/canadaflag.jpg" alt="ob" 
                      // style={{ width: "100px", height: "100px", borderRadius: "50%" }} 
                      />
                      </span>


                    </div>
                    <div class="col-lg-7">
                      <h4 class="fs-22 lh-15 mb-0 text-heading">Canada</h4>
                      <p class="card-text mb-0">
                      7275 Rapistan Court, <br /> Mississauga, Ontario L5N 5Z4
                    </p>
                    <a className="text-orange font-weight-600" target="black" href="https://www.dollarperfoot.com/">Dollar Per Foot <i className="text-blue ml-1 fa fa-external-link" aria-hidden="true" /></a>
                    </div>
                  </div>
                  </div>
                  <div className="border-1 p-3 mb-3 bg-white">
                  <div className="row">
                    <div class="col-lg-5">
                      <span className="text-primary fs-40 lh-1 d-inline-block mb-1">
                      <img src="images/india-flag.jpg" alt="buyers" style={{   }} />
                      </span>


                    </div>
                    <div class="col-lg-7">
                      <h4 class="fs-22 lh-15 mb-0 text-heading">India</h4>
                      <p class="card-text mb-0">
                      Omaxe India Trade Tower Level-11, New Chandigarh Mohali,
                      Punjab 140901
                    </p>
                   {/*  <a className="text-orange font-weight-600" href="/" target="blank">Rupee Per Foot<i className="text-blue ml-1 fa fa-external-link" aria-hidden="true" /></a> */}
                    </div>
                  </div>
                  </div>

              </div>
              {/* <div class="card border-0  py-4">
                  <div class="">
                    <span class="text-primary fs-40 lh-1 d-inline-block">
                      <img src="images/canadaflag.jpg" alt="ob" style={{ width: "100px", height: "100px", borderRadius: "50%" }} />
                    </span>
                    <div class="media-body">
                      <h4 class="fs-22 lh-15 mb-0 text-heading">Canada</h4>
                    </div>
                  </div>
                  <div class="card-body px-0 pt-3 pb-0">
                    <p class="card-text mb-0">
                      7275 Rapistan Court, <br /> Mississauga, Ontario L5N 5Z4
                    </p>
                  </div>
                </div>
                <div class="card border-0  py-4">
                  <div class="">
                    <span class="text-primary fs-40 lh-1 d-inline-block">
                      <img src="images/india-flag.jpg" alt="buyers" style={{ width: "100px", height: "100px", borderRadius: "50%" }} />
                    </span>
                    <div class="media-body">
                      <h4 class="fs-22 lh-15 mb-0 text-heading">India</h4>
                    </div>
                  </div>
                  <div class="card-body px-0 pt-3 pb-0">
                    <p class="card-text mb-0">
                      Omaxe India Trade Tower Level-11, New Chandigarh Mohali,
                      Punjab 140901
                    </p>
                  </div>
                </div> */}
            </div>
          </div>




        </section>
        {/* <section
          className=" position-relative bg-img-cover-center"
          style={{ backgroundImage: `url(${BackLineImage})` }}
        >
          <div className="container ">
            <div class="row text-center">
              <div class="col-md-4 py-8">
                <div class="card border-0 py-4">
                  <div class="">
                    <span className="text-primary fs-40 lh-1 d-inline-block mb-1">
                      <img
                        src="images/uaeflag.jpg"
                        alt="developer"
                        style={{ width: "100px", height: "100px", borderRadius: "50%" }}
                      />
                    </span>

                    <div class="media-body">
                      <h4 class="fs-22 lh-15 mb-0 text-heading">Dubai</h4>
                    </div>
                  </div>
                  <div class="card-body px-0 pt-3 pb-0">
                    <p class="card-text mb-0">Coming<br /> Soon</p>
                  </div>
                </div>
              </div>
              <div class="col-md-4  py-8">
                <div class="card border-0  py-4">
                  <div class="">
                    <span class="text-primary fs-40 lh-1 d-inline-block">
                      <img src="images/canadaflag.jpg" alt="ob" style={{ width: "100px", height: "100px", borderRadius: "50%" }} />
                    </span>
                    <div class="media-body">
                      <h4 class="fs-22 lh-15 mb-0 text-heading">Canada</h4>
                    </div>
                  </div>
                  <div class="card-body px-0 pt-3 pb-0">
                    <p class="card-text mb-0">
                      7275 Rapistan Court, <br /> Mississauga, Ontario L5N 5Z4
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-4  py-8">
                <div class="card border-0  py-4">
                  <div class="">
                    <span class="text-primary fs-40 lh-1 d-inline-block">
                      <img src="images/india-flag.jpg" alt="buyers" style={{ width: "100px", height: "100px", borderRadius: "50%" }} />
                    </span>
                    <div class="media-body">
                      <h4 class="fs-22 lh-15 mb-0 text-heading">India</h4>
                    </div>
                  </div>
                  <div class="card-body px-0 pt-3 pb-0">
                    <p class="card-text mb-0">
                      Omaxe India Trade Tower Level-11, New Chandigarh Mohali,
                      Punjab 140901
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <section className="">
          <div className="">
            <iframe
              className="footer-newsletter"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d115657.97196045142!2d55.347651!3d25.057425!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2sDubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2sin!4v1704277227287!5m2!1sen!2sin"
              height="400px"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};
