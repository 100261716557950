import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ChatBot from 'react-simple-chatbot';
import CurrencyFormat from "react-currency-format";
import Slider from "react-slick";



const  GetProperty = (props) => {

    const propertyData = props.data
console.log(propertyData,"filterfilterfilter....");
//     const fetchData = async (url, setter) => {
//           try {
//             const response = await axios.get(url);
//             if (response.status === 200) {
//               setter(response.data);
//             }
//           } catch (error) {
//             console.error('Error fetching data:', error);
//           }
//         };
//       useEffect(() => {
//         if (filter.selectedCity && filter.selectedBuildingType && filter.selectedPropertyType && filter.selectedProjectType !== '') {
//             fetchData(`${API_URL}/properties/advance/filter/?state=${filter.selectedCity}&building_type=${filter.selectedBuildingType}&property_type=${filter.selectedPropertyType}&is_new_property=${filter.selectedProjectType}`,
//               setPropertyData);
//         }
//         }, [filter]);
        const bannerSlider = {
                pauseOnHover: false,
                infinite: true,
                speed: 1000,
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: true,
                autoplay: false,
            
                autoplaySpeed: 5000,
            
              };
    return(<>
    
                  <div style={{ width: "350px" }}>
        
                    <Slider {...bannerSlider} >
        
                      {propertyData?.map((item) => (
                        <div className="box box pb-7" data-animate="fadeInUp" key={item?.id}>
                          <div className="card shadow-hover-2 rounded-15">
                            <Link to={`/property/details/${item?.slug}`}>
                              <div className="card-img-top">
                                <div className="hover-change-image bg-hover-overlay px-3 pt-3">
                                  <img src={item?.upload_featured_image} alt="Home in Metric Way" className="prelaunch-property-image round-10" />
                                </div>
                                <div className="card-img-overlay p-2 d-flex flex-row justify-content-between">
                                  <div>
                                    <span className="badge ml-3 mt-3 badge-orange">{item?.property_label_name}</span>
                                  </div>
                                  <div className="text-right">
                                    {item?.under_offer && <span className="badge mr-3 mt-3 bg-blue">under offer</span>}
                                  </div>
                                </div>
                              </div>
                              <div className="card-body" style={{ height: "170px" }}>
                                <h2 className="card-title fs-20 fw-600 lh-12 mb-1 "><Link className="text-dark">{item?.property_name}</Link></h2>
                                <p className="card-text font-weight-500 text-gray mb-2 lh-1">
                                  {item?.city && `${item?.city}, `}
                                  {item?.state && `${item?.state}, `}
                                  {item?.country}
                                </p>
                                <ul className="list-inline d-flex mb-0 flex-wrap mr-n5">
                                  <li className="list-inline-item text-gray font-weight-500 fs-13 d-flex align-items-center mr-5" data-toggle="tooltip" title="Size">
                                    <img src="../../images/area.png" className="icon icon-bedroom fs-18 text-heading mr-1 width-32" alt="area" />
                                    {item?.property_size}/sqft
                                  </li>
                                </ul>
                                <h2 className="card-title fs-16 lh-2 fw-600 mb-0">
                                  {item?.property_label_name === "Sale" ? (
                                    <Link className="text-dark">Price: <CurrencyFormat value={item?.property_price} displayType="text" thousandSeparator={true} suffix=".00 AED" /></Link>
                                  ) : (
                                    <Link className="text-dark">Price: <CurrencyFormat value={item?.lease_price_per_mo} displayType="text" thousandSeparator={true} suffix=".00 AED/mo" /></Link>
                                  )}
                                </h2>
                              </div>
                            </Link>
                          </div>
                        </div>
                      ))}
        
                    </Slider>
                  </div>
    </>)}
 
export default GetProperty;
