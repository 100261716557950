import react, { useEffect, useState } from "react"
import money from 'money';
import { Alert } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";




const ConvertCurrency = (props) => {


    const curreny =  "AED"

    const propertyData = props.propertyData
    const [convertedAmount, setConvertedAmount] = useState([]);

    const [exchangeRates, setRate] = useState([]);

    const [fromCurrency, setFromCurrency] = useState([]);



    // Set base currency
    money.base = 'USA';
    money.rates = exchangeRates;

    // Define the API endpoint URL
    useEffect(() => {
        const apiUrl = 'https://openexchangerates.org/api/latest.json?app_id=33fce3070c4748eeb4a548df787fb93b';

        // Make a GET request using the fetch API
        fetch(apiUrl)
            .then(response => response.json())
            .then(data => {
                setRate(data?.rates
                ); // Update the state with fetched data
            })
            .catch(error => {
                
            });
    }, [])

    useEffect(() => {
        if (propertyData) {
            let priceTagValue = "Property Price";
            if (propertyData?.is_property === true) {
                priceTagValue = "Starting Price";
            }
    
            setConvertedAmount({
                "amount": propertyData?.property_price || propertyData?.lease_price_per_mo,
                'sign': curreny,
                'priceTag': priceTagValue,
                "PriceperMonth": "/mo"
            });
            // setPropertyPrice(PropertyData?.pricePerSqft)
        }
    }, [propertyData, curreny]);
    

    return (
        <>

            <div>
                {propertyData?.property_price ? (<>
                    <div className="d-flex"></div>
                    {convertedAmount && <div>

                        <p className="fs-18 mb-0">{convertedAmount['priceTag']}</p>
                        <p className="fs-32 text-heading font-weight-bold  mb-0" > <CurrencyFormat value={convertedAmount['amount']} displayType='text' thousandSeparator={true} suffix={`.00 ${curreny}`} /></p>
                    </div>
                    }
                </>) : (<>
                    <div>
                        <p className="fs-18 mb-0">Price/mo</p>
                        <p className="fs-32 text-heading font-weight-bold  mb-0" > <CurrencyFormat value={convertedAmount['amount']} displayType='text' thousandSeparator={true}



                            suffix={`.00 ${curreny}`}


                        /></p>
                    </div>
                </>)}
                <select className="text-white bg-blue fs-16 fw-600 " style={{padding: "6px 27px 6px 16px", borderRadius:"3px"}}onChange={(e) => {
                    let v_res = e?.target?.value
                   
                    setFromCurrency({
                        "amount": money(convertedAmount['amount']).from(convertedAmount['sign']).to(v_res),
                        'sign': v_res

                    })
                }
                }>
                    {
                        Object.keys(exchangeRates)?.map((key, value) => {
                            const res_value = [key, exchangeRates[key]]

                            return (
                                <>
                                    <option
                                        name={res_value[0]} value={res_value[0]}>{res_value[0]}</option>
                                </>
                            )
                        })
                    }

                </select>

                {fromCurrency ? (
                    <>
                        <p className="fs-32 text-heading font-weight-bold  mb-0"><CurrencyFormat value={fromCurrency['amount']?.toFixed(0)} displayType='text' thousandSeparator={true} suffix=".00"/>&nbsp;{fromCurrency['sign']}</p>

                    </>) : null}
            </div>

        </>
    )


};


export default ConvertCurrency;