import React, { useEffect, useRef, useState } from "react";
// import { PDFViewer } from '@react-pdf/renderer';
import MyDoc from "./MyDoc";
// import { PDFViewer, PDFDownloadLink } from '@react-pdf-viewer/react-to-pdf';
import logo from "../../../images/dpf-logo-new.png";
import { Table } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import axiosInstance from "../../../interceptor/axiosInstance";
import { setupResponseInterceptor } from "../../../interceptor/responseInterceptor";
import Loader from "../../../Loader";

import moment from "moment";
import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import jsPDF from 'jspdf';
import 'jspdf-autotable';


import { Link } from "react-router-dom";
const Invoice = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [loading, setLoading] = useState(false);
  const CheckOut = localStorage.getItem("checkoutId");
  const [paymentData, setPaymentData] = useState([]);


  // const response = axiosInstance.put(`${API_URL}/payment/${CheckOut}/`)
 
  // useEffect(() => {
  //   if (response.status === 200) {
  //     const res = axiosInstance.get(`${API_URL}/payment/user/`)
  //    
  //   }
  // }, [response])

  /* *********Api TO Get Favourite property *********/
  /* *********Api TO change password *********/

  const Checkout = async () => {
    setLoading(true);

    try {
      setupResponseInterceptor(axiosInstance);
      const response = await axiosInstance.put(`/payment/${CheckOut}/`);
      if (response.status === 200) {
        setLoading(false);
        // window.location.reload();
      }
      // window.location.reload();
      // Additional logic for a successful response, if needed
    } catch (error) {
      setLoading(false);
      // window.location.reload();
    }
  };
  const[paymentNewData,setPaymentNewData]=useState([])
  const CheckoutData = async () => {
    setLoading(true);

    try {
      const response = await axiosInstance.get(
        `${API_URL}/payment/history/user/`
      );
      if (response.status === 200) {
       

        setPaymentData(response?.data);
        setLoading(false);
        
        const newPaymentData = response?.data.map(apiEntry => ({
          Date:`${moment(apiEntry.create_at).format("DD-MMM-YY")}`,
          Name:apiEntry.user_name,Email:apiEntry.user_email,Contact:apiEntry.user_phone,
          Invoice: apiEntry.invoice,
          'Price(AED)': `${apiEntry.pricing.toFixed(2)}`, // Format amount as needed
          Plan: apiEntry.sub_title, 
          Expire:`${moment(apiEntry.expire_date).format("DD-MMM-YY")}`,
        }));

        setPaymentNewData(newPaymentData);
      }
      

      // Additional logic for a successful response, if needed
    } catch (error) {
      setLoading(false);
    }
  };
 
  const downloadPDF = () => {
    try {
      const pdf = new jsPDF();

      // Add title to the first page
      pdf.text("Payment History Data", 20, 10);
     
      // Add data to the PDF content
      if (paymentNewData && paymentNewData.length > 0) {
        const columns = Object.keys(paymentNewData[0]);
        const rows = paymentNewData.map(payment => Object.values(payment));
        
        // Auto-generate a table
        pdf.autoTable({
          startX: 10,
          head: [columns],
          body: rows,size: 'a4', orientation: 'landscape' 
        });

        // Save the PDF
        pdf.save("payment_history.pdf");
      } else {
       
      }
    } catch (error) {
      
    }
  };
  

  useEffect(() => {
    moment.locale("en");
    Checkout();
  }, []);
  useEffect(() => {
    if (CheckOut != null || CheckOut != undefined) {
      CheckoutData();
    }
  }, [CheckOut]);

  // const response = axiosInstance.get(`${API_URL}/payment/user/`)
  

  // axiosInstance.get(`${API_URL}/payment/user/`)
  // .then(response => {
 
  // })
  // .catch(error => {

  // });

  return (
    <>
      {loading ? <Loader /> : null}
      <main id="content" className="bg-gray-01">
        <div className="px-3 px-lg-6 px-xxl-13 py-5 py-lg-10">
          {/* <div className="mb-6">
            <h2 className="mb-0 text-heading fs-22 lh-15">Invoice</h2>

          </div> */}

          <div className="px-10 ">
            <div className="border-1 p-5 bg-white">
              {paymentData.length > 0 ? (
                <>
                  {paymentData?.slice(0, 1).map((item) => {
                    return (
                      <>
                        <div className="col-lg-12">
                          <div className="row border-bottom">
                            <div className="col-lg-3">
                              <img
                                src={logo}
                                alt="HomeID"
                                className="normal-logo main-logo-header py-5"
                              />
                            </div>
                            <div className="col-lg-9 d-flex justify-content-end align-items-center">
                              <div className="mr-5">
                                <p className="font-weight-600 fs-16 p-0 m-0 text-dark">
                                  Invoice Details
                                </p>
                                <span className="heading-divider-two"></span>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-12">
                            <div className="py-5">
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="p-3">
                                    <p className="fs-18 text-uppercase font-weight-600 text-uppercase text-dark">
                                      Bill To:
                                    </p>
                                    <p className="m-0 p-0">
                                      <span className="font-weight-500">
                                        Name:
                                      </span>{" "}
                                      {item?.user_name}
                                    </p>
                                    <p className="m-0 p-0">
                                      <span className="font-weight-500">
                                        Email:
                                      </span>{" "}
                                      {item?.user_email}
                                    </p>
                                    {item?.user_phone ? (
                                      <>
                                        <p className="m-0 p-0">
                                          <span className="font-weight-500">
                                            Contact:
                                          </span>{" "}
                                          <CurrencyFormat
                                            displayType={"text"}
                                            value={item?.user_phone}
                                            format=" +971-##-#######"
                                            mask="_"
                                          />
                                        </p>
                                      </>
                                    ) : null}
                                  </div>
                                </div>
                                <div className="col-lg-6 d-flex justify-content-end">
                                  <div className="p-3">
                                    <p className="m-0 p-0 ">
                                      <span className="font-weight-500">
                                        Invoice Date:
                                      </span>{" "}
                                      {moment(item?.create_at).format(
                                        "DD-MMM-YYYY"
                                      )}
                                    </p>
                                    <p className="m-0 p-0">
                                      <span className="font-weight-500">
                                        Invoice No:
                                      </span>{" "}
                                      {item?.invoice}
                                    </p>
                                    <p className="p-0">
                                      <span className="font-weight-500">
                                        Expire Date:
                                      </span>{" "}
                                      {moment(item?.expire_date).format(
                                        "DD-MMM-YYYY"
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              {/* <Table className="fs-16 " bordered hover >
                      <tbody>
                        <tr className="bg-blue fs-18 text-white text-uppercase font-weight-600">
                          <td> Subscription Plan: </td>
                          <td className="text-center">Silver</td>

                        </tr>
                        <tr>
                          <td>Placement in search results </td>
                          <td className="text-center"> <i style={{ color: "#1A63C6" }} className="bi bi-check-circle-fill "></i></td>

                        </tr>
                        <tr>
                          <td>Dashboard for Leads Management</td>
                          <td className="text-center"> <i style={{ color: "#1A63C6" }} className="bi bi-check-circle-fill "></i></td>

                        </tr>
                        <tr>
                          <td>Featured Listing on Homepage</td>
                          <td className="text-center"><i style={{ color: "#FE5900" }} className="bi bi-x-circle-fill"></i></td>

                        </tr>
                        <tr>
                          <td>Featured Agent on Homepage</td>
                          <td className="text-center"><i style={{ color: "#FE5900" }} className="bi bi-x-circle-fill"></i></td>

                        </tr>

                        <tr>
                          <td>Homepage Banner Ad </td>
                          <td className="text-center"><i style={{ color: "#FE5900" }} className="bi bi-x-circle-fill"></i></td>

                        </tr>
                        <tr>
                          <td>Ad Campaign Management </td>
                          <td className="text-center"><i style={{ color: "#FE5900" }} className="bi bi-x-circle-fill"></i></td>

                        </tr>
                        <tr>
                          <td>Property Listings Per Month </td>
                          <td className="text-center">45</td>

                        </tr>
                        <tr>
                          <td>Property Leads Per Month </td>
                          <td className="text-center">200</td>

                        </tr>
                      </tbody>
                    </Table> */}
                            </div>
                          </div>
                          <div className="col-lg-12 ">
                            <div className="bg-gray-01 border-1 px-5 ">
                              <div className="row ">
                                <div className="col-lg-6">
                                  <p className="p-3 mt-3 fs-16">
                                    <span className="font-weight-500 text-uppercase text-dark fs-18">
                                      Subscription Plan:
                                    </span>{" "}
                                    {item?.sub_title}
                                  </p>
                                </div>
                                {/* <div className='col-lg-3'>
                        <p className='p-3 mt-3 fs-18'>
                          <span className='font-weight-500'>Tax:</span> 2%
                        </p>
                      </div> */}
                                <div className="col-lg-6 d-flex justify-content-end">
                                  <p className="p-3 mt-3 fs-18">
                                    <span className="font-weight-500 text-dark">
                                      Total:{" "}
                                    </span>
                                    <CurrencyFormat
                                      value={item?.pricing}
                                      displayType="text"
                                      thousandSeparator={true}
                                      suffix="/-AED"
                                    />
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </>
              ) : (
                <>
                  <h2 className="mb-0 text-heading fs-22 lh-15 mb-5">
                    Please Subscribe Plan
                  </h2>
                  <Link
                    className="rounded bg-blue py-3 px-5 text-white"
                    to="/plan"
                  >
                    Subscribe
                  </Link>
                </>
              )}

              {/* <PDFViewer style={{width: "100%", height :"auto"}}>
                <MyDoc />
              </PDFViewer> */}
            </div>
            {paymentData.length > 0 ? (
              <>
                <div className="mt-5">
                  <h2 className="mb-0 text-heading fs-22 lh-15 mb-5">
                    Invoice History
                    <i
                      class="bi bi-file-earmark-arrow-down-fill ml-3"
                      onClick={downloadPDF}
                    ></i>
                  </h2>
                  {/* <button onClick={downloadPDF}>Download PDF</button> */}
                  <div className="border-1 p-5 bg-white">
                    <div className="col-lg-12">
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <TableHead className="bg-blue ">
                            <TableRow className="">
                              <TableCell className="text-white font-weight-600">
                                Date
                              </TableCell>
                              <TableCell className="text-white font-weight-600 ">
                                Invoice Id
                              </TableCell>
                              <TableCell
                                className="text-white font-weight-600"
                                align="center"
                              >
                                Total Price(AED)
                              </TableCell>
                              <TableCell
                                className="text-white font-weight-600"
                                align="center"
                              >
                                Plan
                              </TableCell>
                              <TableCell
                                className="text-white font-weight-600"
                                align="right"
                              >
                                Expire Date
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {paymentData?.map((row) => (
                              <TableRow key={row.name}>
                                <TableCell>
                                  {moment(row.create_at).format("DD-MM-YYYY")}
                                </TableCell>
                                <TableCell>{row.invoice}</TableCell>
                                <TableCell align="center">
                                  {" "}
                                  <CurrencyFormat
                                    value={row.pricing}
                                    displayType="text"
                                    thousandSeparator={true}
                                  />
                                </TableCell>
                                <TableCell align="center">
                                  {row.sub_title}
                                </TableCell>
                                <TableCell align="right">
                                  {moment(row.expire_date).format("DD-MM-YYYY")}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </main>
    </>
  );
};

export default Invoice;
