import React, { useEffect, useState } from "react";
import AutoComplete from "react-google-autocomplete";
import { useForm, Controller } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import axiosInstance from "../../../interceptor/axiosInstance";
import { toast } from "react-toastify";
import Loader from "../../../Loader";
import PhoneInput from "react-phone-number-input";
const API_URL = process.env.REACT_APP_API_URL;

const AddEditProfile = () => {
  const navigate = useNavigate();
  const ID = useParams();
  const user_id = ID?.id;

  // console.log(user_id);
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  const [place, setPlace] = useState({});
  const [UpdateData, setUpdate] = useState({});
  console.log(UpdateData, "UpdateData");
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [selectComp, setselectedComp] = useState("");
  const [selectedLang, setSelectedLang] = useState([]);
  const [companyInfo, setCompanyInfo] = useState();
  const [imgFile, setImageFile] = useState();
  const [isMyProfile, setIsMyProfile] = useState("");
  const [myProfile, setProfileImage] = useState("");
  const [mynationality, setmynationality] = useState([]);
  const [myNational, setmyNational] = useState([]);
  const [myPhone, setMyphone] = useState('');
  const [myMobile, setMyMobile] = useState('');
  const [myWhatsApp, setMyWhatsApp] = useState('');
  const handleInput = (e) => {
    const name = e?.target?.name;
    const value = e?.target?.value;
    setUpdate({
      ...UpdateData,
      [name]: value,
    });
  };
  const handlephone = (e) => {
    setMyphone(e)
    setUpdate({
      ...UpdateData,
      phone: e,
    });
  }
  const handleMobile = (e) => {
    setMyMobile(e)
    setUpdate({
      ...UpdateData,
      mobile: e,
    });
  }
  const handleWhatsApp = (e) => {
    setMyWhatsApp(e)
    setUpdate({
      ...UpdateData,
      whatsapp: e,
    });
  }
  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    setUpdate({ ...UpdateData, nationality: selectedOption["value"] });
    console.log(selectedOption, "selected option");
    console.log(UpdateData, "selected option");
  };

  const handleSelectCompany = (selectedComp) => {
    setselectedComp(selectedComp);
    setUpdate({ ...UpdateData, company: selectedComp["value"] });
    console.log(selectedComp, "selected option");
    console.log(UpdateData, "selected option");
  };

  const handleSelectChange2 = (selectedLang) => {
    console.log("Selected Languages:", selectedLang);
    setSelectedLang(selectedLang);
    const languageValues = selectedLang.map((item) => item.value);
    console.log("Language Values:", languageValues);
    setUpdate((prevData) => ({
      ...prevData,
      language: languageValues,
    }));
  };
  const languagechoose = [...new Set(selectedLang.map((item) => item.value))];

  const handleInputChange = (e) => {
    setSearchTerm(e);
  };
  const [companydata, setcompanydata] = useState([]);
  console.log(companydata, "companydata");

  const [languageData, setLanguagedata] = useState([]);
  const [nationality, setNationality] = useState([]);

  const getCompanyDetails = () => {
    setLoading(true);
    axiosInstance.get(`${API_URL}/company/get/all/`).then((response) => {
      const companygetdata = response.data;
      setcompanydata(companygetdata);
    });
  };

  /* Get Api for getting nationality */
  const getnationality = () => {
    axiosInstance.get(`${API_URL}/account/national/get/`).then((response) => {
      const nationalitydata = response.data;
      setmynationality(nationalitydata);
    });
  };

  /* get language api */
  const getLanguages = () => {
    axiosInstance
      .get(`${API_URL}/account/language/get/`)
      .then((response) => {
        setLanguagedata(response.data);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Something want wrong");
      });
  };

  /* get api for user details */
  const getUserDetails = () => {
    setLoading(true);
    if (user_id) {
      axiosInstance
        .get(`${API_URL}/account/user/profile/${user_id}/patch/`)
        .then((response) => {
          // Handle successful response
          const data = response.data;
          // Set values for multiple fields
          console.log(data, "data printed !!");
          setmyNational(data?.get_nationlity_name);
          const fieldsToSet = [
            "first_name",
            "last_name",
            "email",
            "street_address",
            "address",
            "country",
            "city",
            "state",
            "business_profile",
            "dld_number",
            "is_business",
            "is_root_user",
            "nationality",
            "get_nationlity_name",
            "mobile",
            "orn_number",
            "phone",
            "post_code",
            "profile",
            "website_link",
            "whatsapp",
            "company",
            "company_name",
            "lat",
            "lng",
            "language",
            "get_language_name",
            "user_role",
            "description"
          ];
          fieldsToSet.forEach((fieldName) => {
            setValue(fieldName, data[fieldName]);
          });
          setCompanyInfo(response.data?.company_name);
          setProfileImage(response.data?.profile);
          setMyphone(response.data?.phone);
          setMyWhatsApp(response.data?.whatsapp);
          setMyMobile(response.data?.mobile);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          toast.error("Something want wrong");
        });
    }
  };
  /* end */

  useEffect(() => {
    getLanguages();
    getnationality();
    getCompanyDetails();
  }, []);
  useEffect(() => {
    if (user_id) {
      getUserDetails();
    }
  }, [user_id]);
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [area, setArea] = useState("");
  const [postCode, setPostCode] = useState("");

  const handleCountryChange = (e) => {
    setCountry(e.target.value);
  };
  // const handleStreetChange = (e) => {
  //   setStreetAddress(e.target.value);
  // };

  const handleStateChange = (e) => {
    setState(e.target.value);
  };

  const handleCityChange = (e) => {
    setCity(e.target.value);
  };
  const handleAreaChange = (e) => {
    setArea(e.target.value);
  };

  useEffect(() => {
    const languageValues = selectedLang.map((item) => item.value);
    setUpdate((prevData) => ({
      ...prevData,
      language: languageValues,
    }));
  }, [selectedLang]);

  useEffect(() => {
    async function propCountry() {
      if (place?.formatted_address) {
        setValue("street_address", place?.formatted_address);
        setUpdate({ street_address: place?.formatted_address });
      }
      if (place) {
        setValue("lat", place?.geometry?.location.lat());
        setValue("lng", place.geometry?.location.lng());
      }

      const res = await place?.address_components?.map((item) => {
        // //console.log(item);
        if (item?.types[0] === "country") {
          // setCounrtyName(item["long_name"]);
          setValue("country", item["long_name"]);
          setCountry(item["long_name"]);
          setUpdate({
            country: item["long_name"],
          });
        }
        if (item?.types[0] === "postal_code") {
          setValue("post_code", item["long_name"]);
          setPostCode(item["long_name"]);
          setUpdate({
            post_code: item["long_name"],
          });
        }
        if (item?.types[0] === "administrative_area_level_1") {
          setValue("state", item["long_name"]);
          setState(item["long_name"]);
          setUpdate({
            state: item["long_name"],
          });
        }
        if (item?.types[0] === "administrative_area_level_3") {
          setValue("city", item["long_name"]);
          setCity(item["long_name"]);
          setUpdate({
            city: item["long_name"],
          });
        }
        if (item?.types[0] === "locality") {
          setValue("area", item["long_name"]);
          setArea(item["long_name"]);
          setUpdate({
            area: item["long_name"],
          });
        }
      });
    }
    propCountry();
  }, [place, setValue]);
  // const newOptions = [{ value: "1", label: "C1" }, { value: "2", label: "C2" }]
  let newOptions = [];
  companydata.map((item) => {
    newOptions.push({ value: item.id, label: item.company_name });
    console.log(newOptions, "newOptions");
  });
  let languageDrop = [];

  languageData.map((item) => {
    languageDrop.push({ value: item.id, label: item.language });
    return null; // Make sure to return null to avoid a warning about a missing return statement
  });
  let nationalityDrop = [];
  mynationality.map((item) => {
    nationalityDrop = [
      ...nationalityDrop,
      { value: item.id, label: item.national },
    ];
    // console.log(nationalityDrop,"thi is natuonality drop")
  });

  //   console.log(languageDrop, "this is language array");
  const onSubmit = async () => {
    // console.log(imgFile);

    if (imgFile) {
      UpdateData["profile"] = imgFile;
    }
    UpdateData["street_address"] = place?.formatted_address;
    UpdateData["country"] = country;
    UpdateData["state"] = state;
    UpdateData["city"] = city;
    UpdateData["post_code"] = postCode;
    UpdateData["area"] = area;
    UpdateData["nationality"] = selectedOption["value"];
    UpdateData["company"] = selectComp["value"];
    // console.log(UpdateData["nationality"]);
    UpdateData["lat"] = place.geometry?.location.lat();
    UpdateData["lng"] = place.geometry?.location.lng();
    UpdateData["language"] = JSON.stringify(languagechoose);

    setLoading(true);
    //
    try {
      axiosInstance
        .put(`/account/user/profile/${user_id}/update/`, UpdateData)
        .then((response) => {
          toast.success("Your profile has been updated");
          console.log(UpdateData, "success");
          toast.success(UpdateData, "success");
          navigate("/profile");
          // navigate("/profile");
          setLoading(false);
        });
    } catch {
      toast.error("Somthinh Went Wrong");
      setLoading(false);
    }
  }; // my profile img uploader preview

  const PorfileImgHandler = (e) => {
    const size = parseFloat(e?.target?.files[0]?.size / (1024 * 1024)).toFixed(
      2
    );
    if (size > 2) {
      toast.error("Please select image size less than 2 MB");
      return;
    }
    setIsMyProfile(URL.createObjectURL(e?.target?.files[0]));
    setImageFile(e?.target?.files[0]);
    setUpdate({ ...UpdateData, profile: e?.target?.files[0] });
  };
  // const compid = companydata.map((item) => {item.id});
  const compid = companydata.map((item) => item.id);

  return (
    <>
      {loading ? <Loader /> : null}
      <main id="content" className="bg-gray-01">
        <div className="px-3 px-lg-6 px-xxl-13 py-5 py-lg-10">
          <div className="mb-6">
            <h2 className="mb-0 text-heading fs-22 lh-15">My Profile</h2>
            {/*  <FileUploadComponent /> */}
          </div>

          <form onSubmit={handleSubmit(onSubmit)} onReset={reset}>
            <div className="row mb-6">
              <div className="col-lg-6 ">
                <div className="card mb-3">
                  <div className="card-body px-3 pt-3 pb-5">
                    <h3 className="card-title mb-0 text-heading fs-22 lh-15">
                      Photo
                    </h3>
                    <p className="card-text">Upload your profile photo.</p>

                    <div className="">
                      {isMyProfile ? (
                        <img
                          src={isMyProfile}
                          alt="image"
                          className="w-100 add-border-other"
                        />
                      ) : (
                        <>
                          <img
                            src={myProfile}
                            alt="image"
                            className="w-100 add-border-other"
                          />
                        </>
                      )}
                      <div className="custom-file mt-4 h-auto">
                        <input
                          type="file"
                          className="custom-file-input add-pstion-abs"
                          id="customFile"
                          name="profile"
                          accept="image/*"
                          onChange={PorfileImgHandler}
                        />
                        <label
                          className="btn bg-blue text-white hover-saffron btn-lg btn-block add-pd-label-uploader"
                          htmlFor="customFile"
                        >
                          <span className="d-inline-block mt-2">
                            <i className="fal fa-cloud-upload"></i>
                          </span>
                          Upload profile image
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <ProfileImageLoader getData={getData} setLoading={setLoading} /> */}

                <div className="card mb-6 mb-lg-0 ">
                  <div className="card-body px-6 pt-6 pb-5 ">
                    <h3 className="card-title mb-0 text-heading fs-22 lh-15">
                      Nationality
                    </h3>

                    <div className="form-group">
                      <label
                        htmlFor="Nationality"
                        className="text-heading margin-b-0"
                      >
                        Select your Nationality{" "}
                        <span className="star-mandatoryfield">*</span>
                      </label>
                      <Select
                        className="form-control border-0 shadow-none form-control-lg selectpicker"
                        //  value={get_nationlity_name}
                        // value={myNational}
                        onChange={handleSelectChange}
                        onInputChange={handleInputChange}
                        options={nationalityDrop}

                        isSearchable
                        placeholder={myNational}
                        classNamePrefix="select-inner"
                      />
                    </div>



                    {/*  <MyMap selectedData={(e) => setAddressMap(e)} addressMap={addressMap} propertyData={userData} /> */}
                  </div>

                </div>


                <div className="card mb-6 mb-lg-0 mt-5 ">
                  <div className="card-body px-6 pt-6 pb-5">
                    <h3 className="card-title mb-0 text-heading fs-22 lh-15">
                      Company Details
                    </h3>
                    {companyInfo ? (
                      <>
                        <div className="form-group">
                          <label
                            htmlFor="companyName"
                            className="text-heading margin-b-0"
                          >
                            Company Name{" "}
                            <span className="star-mandatoryfield">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-lg border-0"
                            id="company"
                            name="company"
                            value={companyInfo}
                            {...register("company_name")}
                          // onChange={handleInput}
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <Select
                          className="form-control border-0 shadow-none form-control-lg selectpicker"
                          value={selectComp}
                          onChange={handleSelectCompany}
                          onInputChange={handleInputChange}
                          options={newOptions}
                          isSearchable
                          placeholder="Search..."
                          classNamePrefix="select-inner"
                        />

                        <Link
                          to={`/company/edit/${compid}`}
                          className="btn btn-lg bg-blue text-white hover-saffron mt-5  mb-3"
                        >
                          Register Company
                        </Link>
                      </>
                    )}
                  </div>
                </div>



                <div className="card mb-6 mt-5">
                  <div className="card-body px-6 pt-6 pb-5">
                    <h3 className="card-title mb-0 text-heading fs-22 lh-15">
                      Address
                    </h3>

                    <div className="form-group">
                      <label htmlFor="twitter" className="text-heading">
                        Search Address
                      </label>
                      <AutoComplete
                        apiKey={"AIzaSyDZSiGDJcQdFDZqQU5ONXF7dGkoKQePszo"}
                        onPlaceSelected={(place) => {
                          //console.log(place, "place");
                          setPlace(place);
                        }}
                        className="mt-fourteen form-control form-control-lg border-0 radius-left-tb shadow-none pr-8 mb-5"
                        placeholder="Search Address..."
                        onKeyDown={(event) => {
                          if (event?.keyCode === 13) {
                            event?.preventDefault();
                          }
                        }}
                        options={{
                          fields: [
                            "address_components",
                            "geometry",
                            "place_id",
                            "formatted_address",
                          ],
                          types: ["establishment"],
                          // componentRestrictions: { country: ["ae"] },
                          libraries: ["places"],
                        }}
                      />
                      <div className="py-2">
                        <label
                          htmlFor="street_address"
                          className="text-heading"
                        >
                          Street Address
                        </label>
                        <input
                          className="form-control form-control-lg border-0"
                          id="street_address"
                          type="text"
                          name="street_address"
                          {...register("street_address")}
                          onChange={(e) => {
                            handleInput(e);
                            // handleStreetChange(e);
                          }}
                        />
                      </div>
                      <div className="form-row mx-n4">
                        <div className="form-group col-md-6 px-4">
                          <label htmlFor="country" className="text-heading">
                            Country
                          </label>
                          <input
                            className="form-control form-control-lg border-0"
                            id="country"
                            type="text"
                            name="country"
                            {...register("country")}
                            onChange={(e) => {
                              handleInput(e);
                              handleCountryChange(e);
                            }}
                          />
                        </div>
                        <div className="form-group col-md-6 px-4">
                          <label htmlFor="state" className="text-heading">
                            State
                          </label>
                          <input
                            className="form-control form-control-lg border-0"
                            id="state"
                            type="text"
                            name="state"
                            {...register("state")}
                            onChange={(e) => {
                              handleInput(e);
                              handleStateChange(e);
                            }}
                          />
                        </div>
                        <div className="form-group col-md-6 px-4">
                          <label htmlFor="city" className="text-heading">
                            City
                          </label>
                          <input
                            className="form-control form-control-lg border-0"
                            id="city"
                            type="text"
                            name="city"
                            {...register("city")}
                            onChange={(e) => {
                              handleInput(e);
                              handleCityChange(e);
                            }}
                          />
                        </div>
                        <div className="form-group col-md-6 px-4">
                          <label htmlFor="postcode" className="text-heading">
                            Post Code
                          </label>
                          <input
                            className="form-control form-control-lg border-0"
                            id="post_code"
                            type="text"
                            name="post_code"
                            {...register("post_code")}
                            onChange={handleInput}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="card mb-6">
                  <div className="card-body px-6 pt-6 pb-5">
                    <div className="form-row mx-n4">
                      <div className="form-group col-md-6 px-4">
                        <label
                          htmlFor="firstName"
                          className="text-heading margin-b-0"
                        >
                          First name{" "}
                          <span className="star-mandatoryfield">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg border-0"
                          id="first_name"
                          name="first_name"
                          {...register("first_name")}
                          onChange={handleInput}
                        />
                      </div>
                      <div className="form-group col-md-6 px-4">
                        <label htmlFor="lastName" className="text-heading">
                          Last name
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg border-0"
                          id="last_name"
                          name="last_name"
                          {...register("last_name")}
                          onChange={handleInput}
                        />
                      </div>
                    </div>
                    <div className="form-row mx-n4">
                      <div className="form-group col-md-6 px-4">
                        <label
                          htmlFor="firstName"
                          className="text-heading margin-b-0"
                        >
                          Role/Designation{" "}
                          <span className="star-mandatoryfield">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg border-0"
                          id="user_role"
                          name="user_role"
                          {...register("user_role")}
                          onChange={handleInput}
                        />
                      </div>

                      <div className="form-group col-md-6 px-4">
                        <label htmlFor="Language" className="text-heading">
                          Language
                        </label>
                        <Controller
                          control={control}
                          name="language"
                          {...register("language")}
                          render={({
                            field: { onChange, onBlur, value, name, ref },
                          }) => (
                            <Select
                              options={languageDrop}
                              onChange={handleSelectChange2}
                              isMulti={true}
                              value={selectedLang}
                              name="language"
                            />
                          )}
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <label
                        htmlFor="email"
                        className="text-heading margin-b-0"
                      >
                        Email <span className="star-mandatoryfield">*</span>
                      </label>
                      <input
                        type="email"
                        className="form-control form-control-lg border-0"
                        id="email"
                        name="email"
                        {...register("email")}
                        onChange={handleInput}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="website" className="text-heading">
                        Website
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg border-0"
                        id="website_link"
                        name="website_link"
                        placeholder="https://google.com"
                        {...register("website_link")}
                        onChange={handleInput}
                      />
                    </div>

                    <div className="form-row mx-n4">
                      <div className="form-group col-md-6 px-4">
                        <label
                          htmlFor="phone"
                          className="text-heading margin-b-0"
                        >
                          Phone <span className="star-mandatoryfield">*</span>
                        </label>

                        {/* <input
                          type="text"
                          className="form-control form-control-lg border-0"
                          id="phone"
                          name="phone"
                          placeholder="Enter 10 Digit Phone Number"
                          format="+971 5# ###-####"

                          {...register("phone")}
                          onChange={handleInput}
                        /> */}
                        <PhoneInput

                          className="form-control form-control-lg border-0 mb-2 d-flex"
                          international

                          defaultCountry="AE"
                          value={myPhone}
                          onChange={handlephone}

                        />
                      </div>
                      <div className="form-group col-md-6 px-4">
                        <label
                          htmlFor="mobile"
                          className="text-heading margin-b-0"
                        >
                          Mobile <span className="star-mandatoryfield">*</span>
                        </label>
                        {/* <input
                          type="text"
                          className="form-control form-control-lg border-0"
                          id="mobile"
                          name="mobile"
                          placeholder="Enter 10 Digit Mobile Number"
                          format="+971 5# ###-####"

                          {...register("mobile")}
                          onChange={handleInput}
                        /> */}
                        <PhoneInput

                          className="form-control form-control-lg border-0 mb-2 d-flex"
                          international

                          defaultCountry="AE"
                          value={myMobile}
                          onChange={handleMobile}

                        />
                      </div>

                      <div className="form-group col-md-6 px-4">
                        <label htmlFor="mobile" className="text-heading">
                          Whatsapp Number
                        </label>
                        <PhoneInput

                          className="form-control form-control-lg border-0 mb-2 d-flex"
                          international

                          defaultCountry="AE"
                          value={myWhatsApp}
                          onChange={handleWhatsApp}

                        />
                        {/* <input
                          type="text"
                          className="form-control form-control-lg border-0"
                          id="whatsapp"
                          name="whatsapp"
                          placeholder="Enter 10 Digit Mobile Number"
                          format="+971 5# ###-####"

                          {...register("whatsapp")}
                          onChange={handleInput}
                        /> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card mb-6">
                  <div className="card-body p-6">
                    <h3 className="card-title mb-0 text-heading fs-22 lh-15">
                      Description
                    </h3>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-group">
                          <label
                            htmlFor="category"
                            className="text-heading margin-b-0"
                          >
                            Describe Details
                            <span className="star-mandatoryfield"></span>
                          </label>
                          <textarea
                            rows="5"
                            name="description"
                            id="description"

                            className="form-control form-control-lg border-0"
                            {...register("description")}
                            onChange={handleInput}
                          // value={multipleInput?.description}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card mb-6 ">
                  <div className="card-body px-6 pt-6 pb-5">
                    <h3 className="card-title mb-0 text-heading fs-22 lh-15">
                      Lisence Number
                    </h3>

                    <div className="form-group">
                      <label htmlFor="twitter" className="text-heading">
                        ORN Number
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg border-0"
                        id="orn_number"
                        name="orn_number"
                        {...register("orn_number")}
                        onChange={handleInput}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="dld_number" className="text-heading">
                        DLD Permit Number
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg border-0"
                        id="dld_number"
                        name="dld_number"
                        {...register("dld_number")}
                        onChange={handleInput}
                      />
                    </div>
                  </div>
                </div>

                <div className="card mb-6 ">
                  <div className="card-body px-6 pt-6 pb-5">
                    <h3 className="card-title mb-0 text-heading fs-22 lh-15">
                      Social information
                    </h3>

                    <div className="form-group">
                      <label htmlFor="twitter" className="text-heading">
                        Twitter Url
                      </label>
                      <input
                        type="url"
                        className="form-control form-control-lg border-0"
                        id="twitterUrl"
                        name="twitterUrl"
                        {...register("twitterUrl")}
                        onChange={handleInput}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="facebook" className="text-heading">
                        Facebook Url
                      </label>
                      <input
                        type="url"
                        className="form-control form-control-lg border-0"
                        id="facebook"
                        name="facebookUrl"
                        {...register("facebookUrl")}
                        onChange={handleInput}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="instagram" className="text-heading">
                        Instagram Url
                      </label>
                      <input
                        type="url"
                        className="form-control form-control-lg border-0"
                        id="instagram"
                        name="instagramUrl"
                        {...register("instagramUrl")}
                        onChange={handleInput}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end flex-wrap">
              {/* <button type='button' className="btn btn-lg bg-hover-white border rounded-lg mb-3" onClick={() => DeleteProfile()}>Delete Profile</button> */}
              <button
                type="submit"
                className="btn btn-lg bg-blue text-white hover-saffron ml-4 mb-3"
              >
                Update Profile
                {/* {userID ? "Update Profile" : "Save Profile"} */}
              </button>
            </div>
          </form>
        </div>
      </main>
    </>
  );
};

export default AddEditProfile;