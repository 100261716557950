import React, { Component } from "react";
import {
  Map,
  Marker,
  Circle,
  GoogleApiWrapper,
  InfoWindow,
  Polyline,
} from "google-maps-react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import CurrencyFormat from "react-currency-format";
import { toast } from "react-toastify";

export class MapContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // for google map places autocomplete
      bounds: "",
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},

      mapCenter: {
        lat: 25.276987,
        lng: 55.296249,
      },
      storeItem: {},
    };
  }

  handleChange = (address) => {
    this.setState({ address });
  };

  handleSelect = (address) => {
    this.setState({ address });
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {


        // update center state
        this.setState({ mapCenter: latLng });
      })
      .catch((error) => toast.error(" Please Contact Administrator"));
  };

  onMarkerClick(props, marker, e, item) {

    this.setState({
      storeItem: item,
      showingInfoWindow: true,
      activeMarker: marker,
    });

    // ..
  }

  ShowProperty = (item) => {

  };
  render() {
 
    const mapCenter = this.props?.searchData?.map((item) => {
      if (item?.lat && item?.lng) {
        return {
          lat: item?.lat,
          lng: item?.lng,
        };
      }
    });


    const lat = this.props.addressGet?.geometry.location.lat();
    const lng = this.props.addressGet?.geometry.location.lng();

    const maplatlong =
      this.props?.searchData?.filter((item) => item?.lat || item?.lng) || [];
    // const coords = {
    //   lat: maplatlong?.[0]?.lat || lat || 25.276987,
    //   lng: maplatlong?.[0]?.lng || lng || 55.296249,
    // };
    const coords = {
      lat: maplatlong && maplatlong.length > 0
        ? maplatlong[0].lat
        : lat || 25.276987,
      lng: maplatlong && maplatlong.length > 0
        ? maplatlong[0].lng
        : lng || 55.296249
    };
    return (
      <div id="googleMaps">
        <Map
          zoom={8}
          google={this.props.google}
          center={coords}
          style={{ width: "100%", height: "100vh", position: "unset" }}
        >
          {this.props?.searchData?.map((item) => {
            if (!item?.lat && !item?.lng) return null;
            return (
              <Marker
                //   icon={{

                //     url: MarkerIcon,
                //     anchor: new this.props.google.maps.Point(50, 50),
                //     scaledSize:new this.props.google.maps.Size(70, 70)

                // }}
                position={{
                  lat: item?.lat,
                  lng: item?.lng,
                }}
                defaultAnimation={2}
                onClick={(props, marker, e) =>
                  this.onMarkerClick(props, marker, e, item)
                }
              ></Marker>
            );
          })}
          {/* <div> */}
          <InfoWindow
            marker={this.state.activeMarker}
            visible={this.state.showingInfoWindow}
            zIndex={9999}
          >
            <div
              className="section-infowindow-part"
              
            >
              {/* {this.state.storeItem?.name} */}
              <a href={`/property/details/${this.state.storeItem?.slug}`}>
                <div className="main-section-marker-infowindow">
                  <img
                    src={this.state.storeItem?.upload_featured_image}
                    
                    style={{ width: "100%", height: "200px", zIndex: 9999 }}
                  />
                  {this.state.storeItem.labels?.map((item) => {
                    return (
                      <span className="badge badge-orange mr-2 labels-infowindow-img">
                        {item?.name}
                      </span>
                    );
                  })}
                  <div className="card-body p-0">
                    <h2 className="my-0 mt-1">
                      <a className="fs-16 text-dark  lh-2">
                        {this.state.storeItem?.property_name}
                      </a>
                    </h2>
                    <p className="text-gray-light font-weight-500 mb-1">
                      {this.state.storeItem?.street_address}
                    </p>


                    {this.state.storeItem?.property_price ? (
                      <p className="fs-17 font-weight-bold text-heading mb-0 px-1">
                        <span className="fs-12">AED</span>{" "}
                        <CurrencyFormat
                          value={this.state.storeItem?.property_price}
                          displayType="text"
                          thousandSeparator={true}
                        />
                        <span className="text-gray-light font-weight-500 fs-14"></span>
                      </p>
                    ) : this.state.storeItem?.lease_price_per_mo ? (
                      <p className="fs-17 font-weight-bold text-heading mb-0 px-1">
                        <span className="fs-12">AED</span>
                        <CurrencyFormat
                          value={this.state.storeItem?.lease_price_per_mo}
                          displayType="text"
                          thousandSeparator={true}
                        />
                        <span className="text-gray-light font-weight-500 fs-14">
                          {" "}
                          / mo
                        </span>
                      </p>
                    ) : null}
                  </div>
                </div>
              </a>
            </div>
          </InfoWindow>


        </Map>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: `AIzaSyDZSiGDJcQdFDZqQU5ONXF7dGkoKQePszo`,
})(MapContainer);
