import React, { useState } from "react";
import { Link ,useHistory, useNavigate} from "react-router-dom";

const PropertyKind = () => {
  const [isEdit, setIsEdit] = useState("false");
  const navigate = useNavigate();
  const handleClick = () => {
    const params = new URLSearchParams();
    params.append('key', 'True');
    navigate(`/property/add?${params.toString()}`,{ state: { isEdit: isEdit } });
  };
  return (<>
    <main id="content" className="bg-gray-01">
      <div className="px-3 px-lg-6 px-xxl-13 py-5 py-lg-10 my-profile">
        <div className="mb-6 margin-re-top">
          <h2 className="mb-0 text-heading fs-22 lh-15">What kind of properties you want to add ?</h2>
        </div>
        <div className="col-lg-12">
          <div className="card mb-6 header-user-icon">
            <div className="card-body p-6">
              <div className="row">
                <div class="col-lg-6">
                  <div class="card mb-6">
                    <div class="card-body p-6">
                      <div class="padding-50 dropzone upload-file text-center ">
                        <div class="dz-default dz-message">
                          <span class="upload-icon lh-1 d-inline-block mb-4">
                            <i class="fas fa-building"></i>
                          </span>
                          <p class="text-heading fs-22 lh-15 mb-4">
                            Add New Residentail or Commerical Properties
                          </p>
                          <a onClick={handleClick}  className="edit-prfle text-white bg-blue hover-saffron">
                            New Project
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="card mb-6">
                    <div class="card-body p-6">
                      <div class="dropzone upload-file text-center padding-50">
                        <div class="dz-default dz-message">
                          <span class="upload-icon lh-1 d-inline-block mb-4">
                            <i class="fas fa-building"></i>
                          </span>
                          <p class="text-heading fs-22 lh-15 mb-4">
                            Add Existing Residentail or Commerical Properties
                          </p>
                          <Link to="/property/add" state={{ isEdit: isEdit }} className="edit-prfle text-white bg-blue hover-saffron">
                            Existing Project
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </>)
}
export default PropertyKind