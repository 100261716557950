import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import AuthService from "../auth/AuthService";
import { isExpired, decodeToken } from "react-jwt";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
const MyModal = ({ show, handleClose }) => {
  const API_URL = process.env.REACT_APP_API_URL;
  const formRef = useRef(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [userData, setUserData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    password_confirmation: "",
    is_business: false,
    is_non_business: false,
    business_profile: "",
    is_root_user: true,
    is_other_option_relation: "",
  });

  // Password Visible
  const [visible, setVisible] = useState(false);
  const [showOtherFields, setShowOtherFields] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };
  // Handle Email Validation

  const handleRadioChange = (e) => {
    const { name, value } = e.target;

    // If "is_business" is set to true, set "is_non_business" to false
    if (name === "is_business" && value === "true") {
      setUserData({ ...userData, is_business: true, is_non_business: false });
    }
    if (name === "is_non_business" && value === "true") {
      setUserData({
        ...userData,
        is_non_business: true,
        is_business: false,
        business_profile: "",
      });
      setShowOtherFields(false);
    }
    if (name === "business_profile" && value === "is_developer") {
      setUserData({ ...userData, is_business: true, is_non_business: false });
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (userData?.password === userData?.password_confirmation) {
      
      try {
        const response = await axios.post(`${API_URL}/auth/users/`, userData);
       
        if (response.data) {
          toast.success(
            "Welcome to Dirham Per Foot, Please check your email to verify"
          );
          navigateToEmailVerification();
        }
        formRef.current.reset();
        setUserData("");
        // You can redirect the user to the login page or take other actions here.
      } catch (error) {
       
        toast.error("User Already Exist", error.response.data.message);
      }
    } else {
      toast.error("Password and Confirm Password Should be same");
    }
  };
  // Otherv User
  const [OtherUserRole, setOtherUserRole] = useState("");
  const getOtheruser = () => {
    axios
      .get(`${API_URL}/account/get/other/user/permission/`)
      .then((response) => {
        // Handle the data
        
        if (response.data) {
          setOtherUserRole(response.data);
        }
      })
      .catch((error) => {
        // Handle any errors
        toast.error(' Please Contact Administrator');
      });
  };
  useEffect(() => {
    getOtheruser();
  }, []);
  // Navigation System
  const navigate = useNavigate();

  const navigateToEmailVerification = () => {
    navigate("/signup/verification/", { state: { Data: userData } }); 
  };
  const handleLogin = async () => {
    try {
      const token = await AuthService.login(email, password);
      
      const myDecodedToken = decodeToken(token?.access);
      const isMyTokenExpired = isExpired(token?.refresh);
     
      const currentUserId = myDecodedToken?.user_id;

      localStorage.setItem('is_authenticated', isMyTokenExpired);
      localStorage.setItem('currentUser_id', currentUserId);
      
      try {
        const response = await axios.post(`${API_URL}/auth/jwt/verify/`, {
          token: token?.access,
        });


        if (response.status === 200) {
          toast.success("Successfully Login");
          handleClose();
          window.location.reload();
        } else {
          
          toast.error("Please Provide Right Cradentials")
        }
      } catch (error) {
        
        toast.error("Please Provide Right Cradentials")
      }
      // Save the token and user data in local storage or a state management solution.
    } catch (error) {
      
     
      toast.error(error?.response?.data.detail);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <div className="modal-content ">
      <div className=" f-right" >
              <button onClick={() => handleClose(true)} type="button" className="close m-0 fs-24 mt-3 mr-5">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
        <div className="modal-header border-0 p-0">
        
          <div
            className="nav nav-tabs row w-100 no-gutters"
            id="myTab"
            role="tablist"
          >
            <a
              className="nav-item col-sm-3 ml-0 nav-link pr-6 py-4 pl-9 active fs-18"
              id="login-tab"
              data-bs-toggle="tab"
              href="#login"
              role="tab"
              aria-controls="login"
              aria-selected="true"
            >
              Login
            </a>
            <a
              className="nav-item col-sm-3 ml-0 nav-link py-4 pr-6  px-6 fs-18"
              id="register-tab"
              data-bs-toggle="tab"
              href="#register"
              role="tab"
              aria-controls="register"
              aria-selected="true"
            >
              Register
            </a>
            <div className="nav-item col-sm-6 ml-0 d-flex align-items-center justify-content-end">
              {/* <button onClick={() => OnhidePop()} type="button" className="close m-0 fs-23">
                  <span aria-hidden="true">&times;</span>
                </button> */}
            </div>
          </div>
        </div>

        <div className="modal-body p-2 py-sm-4 px-sm-5">
          <div className="tab-content shadow-none p-0 ">
            <div
              className="tab-pane  active"
              id="login"
              role="tabpanel"
              aria-labelledby="register-tab"
            >
              <h3 className="ti-fs">
                Secured Login to{" "}
                <a href="/">
                  <span className="pl-name">DirhamPerFoot.</span>
                </a>
              </h3>

              <div className="form-group mb-4">
                {/* username input */}
                <label htmlFor="username" className="sr-only">
                  Username
                </label>
                <div className="input-group input-group-lg">
                  <div className="input-group-prepend ">
                    <span
                      className="input-group-text bg-gray-01 border-0 text-muted fs-18"
                      id="inputGroup-sizing-lg"
                    >
                      <i className="far fa-user"></i>
                    </span>
                  </div>
                  <input
                  type="text"
                  className="form-control border-0 shadow-none fs-13"
                  id="email"
                  name="email"
                  placeholder="Your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                </div>
              </div>
              <div className="form-group mb-4">
                {/* password input */}
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <div className="input-group input-group-lg">
                  <div className="input-group-prepend ">
                    <span className="input-group-text bg-gray-01 border-0 text-muted fs-18">
                      <i className="far fa-lock"></i>
                    </span>
                  </div>
                  <input
                     type={visible ? "text" : "password"}
                    className="form-control border-0 shadow-none fs-13"
                    id="password"
                    name="password"
                    placeholder="Password"
                    value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text bg-gray-01 border-0 text-body fs-18">
                      <i
                        className="far fa-eye-slash"
                        onClick={() => setVisible(!visible)}
                      ></i>
                    </span>
                  </div>
                </div>
              </div>
              <div className="d-flex mb-4">
                <Link
                  to="/forgetpassword"
                  className="d-inline-block ml-auto text-orange fs-15"
                >
                  Forgot Password?
                </Link>
              </div>
              <div className="Submit-button-section-login">
              <button
                onClick={handleLogin}
                className="btn text-white bg-blue hover-saffron btn-lg btn-block"
              >
                Log in
              </button>
            </div>
            </div>

            <div
              className="tab-pane fade"
              id="register"
              role="tabpanel"
              aria-labelledby="register-tab"
            >
              <h3 className="ti-fs t-aling-c">
                <span className="pl-name">Sign Up</span>
              </h3>

              <form ref={formRef} onSubmit={handleSubmit}>
                <div className="form-group mb-4">
                  <label htmlFor="full-name" className="sr-only">
                    First name
                  </label>
                  <div className="input-group input-group-lg">
                    <div className="input-group-prepend ">
                      <span className="input-group-text bg-gray-01 border-0 text-muted fs-18">
                        <i className="far fa-address-card"></i>
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control border-0 shadow-none fs-13"
                      id="first_name"
                      name="first_name"
                      placeholder="First name"
                      value={userData.first_name}
                      onChange={handleInputChange}
                      required
                      title="This is Required Field"
                    />
                  </div>
                </div>
                <div className="form-group mb-4">
                  <label htmlFor="full-name" className="sr-only">
                    Last name
                  </label>
                  <div className="input-group input-group-lg">
                    <div className="input-group-prepend ">
                      <span className="input-group-text bg-gray-01 border-0 text-muted fs-18">
                        <i className="far fa-address-card"></i>
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control border-0 shadow-none fs-13"
                      id="last_name"
                      name="last_name"
                      placeholder="Last name"
                      value={userData.last_name}
                      onChange={handleInputChange}
                      required
                      title="This is Required Field"
                    />
                  </div>
                </div>
                {/* username details */}
                <div className="form-group mb-4">
                  <label htmlFor="username01" className="sr-only">
                    Email
                  </label>
                  <div className="input-group input-group-lg">
                    <div className="input-group-prepend ">
                      <span className="input-group-text bg-gray-01 border-0 text-muted fs-18">
                        <i className="far fa-user"></i>
                      </span>
                    </div>
                    <input
                      type="email"
                      className="form-control border-0 shadow-none fs-13"
                      id="email"
                      name="email"
                      placeholder="Your email"
                      value={userData.email}
                      onChange={handleInputChange}
                      pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                      required
                    />
                  </div>
                </div>

                {/* password fields */}
                <div className="form-group mb-4">
                  <label htmlFor="password01" className="sr-only">
                    Password
                  </label>
                  <div className="input-group input-group-lg">
                    <div className="input-group-prepend ">
                      <span className="input-group-text bg-gray-01 border-0 text-muted fs-18">
                        <i className="far fa-lock"></i>
                      </span>
                    </div>
                    <input
                      type={visible ? "text" : "password"}
                      className="form-control border-0 shadow-none fs-13"
                      id="password"
                      name="password"
                      placeholder="Password"
                      value={userData.password}
                      onChange={handleInputChange}
                      pattern="^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$"
                      required
                    />
                    <div className="input-group-append">
                      <span className="input-group-text bg-gray-01 border-0 text-body fs-18">
                        <i
                          className="far fa-eye-slash"
                          onClick={() => setVisible(!visible)}
                        ></i>
                      </span>
                    </div>
                  </div>
                  <p className="form-text">
                    Password must be at least 8 characters in length
                  </p>
                </div>

                {/* confirm password */}
                <div className="form-group mb-4">
                  <label htmlFor="password02" className="sr-only">
                    Confirm Password
                  </label>
                  <div className="input-group input-group-lg">
                    <div className="input-group-prepend ">
                      <span className="input-group-text bg-gray-01 border-0 text-muted fs-18">
                        <i className="far fa-lock"></i>
                      </span>
                    </div>
                    <input
                      type={visible ? "text" : "password"}
                      className="form-control border-0 shadow-none fs-13"
                      id="confirmPassword"
                      name="password_confirmation"
                      value={userData.password_confirmation}
                      placeholder="Confirm Password"
                      onChange={handleInputChange}
                    />
                    <div className="input-group-append">
                      <span className="input-group-text bg-gray-01 border-0 text-body fs-18">
                        <i
                          className="far fa-eye-slash"
                          // onClick={() =>
                          //   setShowConfirmPassword(!showConfirmPassword)
                          // }
                        ></i>
                      </span>
                    </div>
                  </div>
                  <p className="form-text">
                    it should be same as above password
                  </p>
                </div>

                <div className="d-flex form-group mb-4">
                  <label htmlFor="username01" className="sr-only">
                    Business Profile
                  </label>

                  <div className="input-group input-group-lg">
                    <div className="d-flex">
                      <input
                        type="radio"
                        name="is_business"
                        value={true}
                        checked={userData.is_business === true}
                        onChange={handleRadioChange}
                      />
                      <label className="ml-2 mt-2">Business</label>
                    </div>
                  </div>
                  <div className="input-group input-group-lg">
                    <div className="d-flex">
                      <input
                        type="radio"
                        name="is_non_business"
                        value={true}
                        checked={userData.is_non_business === true}
                        onChange={handleRadioChange}
                      />
                      <label className="ml-2 mt-2">Non Business</label>
                    </div>
                  </div>
                </div>

                {userData.is_business === true ? (
                  <>
                    <p className="form-text mb-2">
                      For Business user, select one of the options below to
                      Signup
                    </p>
                    <div className="d-flex form-group mb-4">
                      <div className="input-group input-group-lg">
                        <div className="d-flex">
                          <input
                            type="radio"
                            name="business_profile"
                            value="is_developer"
                            onChange={() => {
                              handleInputChange({
                                target: {
                                  name: "business_profile",
                                  value: "is_developer",
                                },
                              });
                              setShowOtherFields(false); // Show the additional fields
                            }}
                          />
                          <label className="ml-2 mt-2">Developer</label>
                        </div>
                      </div>
                      <div className="input-group input-group-lg">
                        <div className="d-flex">
                          <input
                            type="radio"
                            name="business_profile"
                            value="is_agent"
                            onChange={() => {
                              handleInputChange({
                                target: {
                                  name: "business_profile",
                                  value: "is_agent",
                                },
                              });
                              setShowOtherFields(false); // Show the additional fields
                            }}
                          />
                          <label className="ml-2 mt-2">Agent</label>
                        </div>
                      </div>
                      <div className="input-group input-group-lg">
                        <div className="d-flex">
                          <input
                            type="radio"
                            name="business_profile"
                            value="is_other"
                            onChange={() => {
                              handleInputChange({
                                target: {
                                  name: "business_profile",
                                  value: "is_other",
                                },
                              });
                              setShowOtherFields(true); // Show the additional fields
                            }}
                          />
                          <label className="ml-2 mt-2">Other</label>
                          <br />
                        </div>
                      </div>
                    </div>
                    {showOtherFields && (
                      <div className="form-group mb-4">
                        <select
                          className="form-control border-0 shadow-none fs-13"
                          onChange={handleInputChange}
                          name="is_other_option_relation"
                          value={userData.is_other_option_relation}
                        >
                          {OtherUserRole.map((item, index) => (
                            <option name="name" value={item.id} key={index}>
                              {item.permission}
                            </option>
                          ))}
                          {/* <option name="md" value="id">
                                  MD
                                </option> */}
                        </select>
                      </div>
                    )}
                  </>
                ) : null}
                <button
                  type="submit"
                  className="btn text-white bg-blue hover-saffron btn-lg btn-block"
                >
                  Sign up
                </button>
              </form>


             
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default MyModal;
