import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

const Signup = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const formRef = useRef(null);

  const [userData, setUserData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    password_confirmation: "",
    is_business: false,
    is_non_business: false,
    business_profile: "",
    is_root_user: true,
    is_other_option_relation: "",
    is_developer: false,
    is_agent: false,
    is_other: false,
  });
  useEffect(() => {
    if (userData?.business_profile === "is_developer") {
      setUserData({ ...userData, is_developer: true, is_agent: false, is_other: false })
    }
    if (userData?.business_profile === "is_agent") {
      setUserData({ ...userData, is_developer: false, is_agent: true, is_other: false })
    }
    if (userData?.business_profile === "is_other") {
      setUserData({ ...userData, is_developer: false, is_agent: false, is_other: true })
    }

  }, [userData?.business_profile])

  console.log(userData, "USERADTA");
  // Password Visible
  const [visible, setVisible] = useState(false);
  const [showOtherFields, setShowOtherFields] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };
  // Handle Email Validation

  const handleRadioChange = (e) => {
    const { name, value } = e.target;

    // If "is_business" is set to true, set "is_non_business" to false
    if (name === "is_business" && value === "true") {
      setUserData({ ...userData, is_business: true, is_non_business: false });
    }
    if (name === "is_non_business" && value === "true") {
      setUserData({
        ...userData,
        is_non_business: true,
        is_business: false,
        business_profile: "",
        is_developer: false,
        is_agent: false,
        is_other: false,
      });
      setShowOtherFields(false);
    }
    if (name === "business_profile" && value === "is_developer") {
      setUserData({ ...userData, is_business: true, is_non_business: false });
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (userData?.password === userData?.password_confirmation) {

      try {
        const response = await axios.post(`${API_URL}/auth/users/`, userData);

        if (response.data) {
          toast.success(
            "Welcome to Dirham Per Foot, Please check your email to verify"
          );
          navigateToEmailVerification();
        }
        formRef.current.reset();
        setUserData("");
        // You can redirect the user to the login page or take other actions here.
      } catch (error) {
        if (error.response.data.password) {
          toast.error(`${error.response.data.password}`)
        } else if (error.response.data.email) {
          toast.error(`${error.response.data.email}`)
        }


      }
    } else {
      toast.error("Password and Confirm Password Should be same");
    }
  };
  // Otherv User
  const [OtherUserRole, setOtherUserRole] = useState('')
  const getOtheruser = () => {
    axios.get(`${API_URL}/account/get/other/user/permission/`)
      .then(response => {
        // Handle the data

        if (response.data) {
          setOtherUserRole(response.data)
        }
      })
      .catch(error => {
        // Handle any errors

      });
  }
  useEffect(() => {
    getOtheruser();

  }, [])
  // Navigation System
  const navigate = useNavigate();

  // const navigateToLoginPage = () => {
  //   navigate('/login'); // Navigates to the '/another-page' route
  // };


  const navigateToEmailVerification = () => {
    navigate('/signup/verification/', { state: { Data: userData } }); // Navigates to the '/another-page' route
  };

  return (
    <>
      <div className="form-body">
        <div className="website-logo">
          <Link to="/">
            <div className="logo">
              <img
                className="logo-size"
                src="../../images/dpf-logo-ae.png"
                style={{ width: "150px" }}
              />
            </div>
          </Link>
        </div>
        <div className="img-holder">
          <div
            className="bg"
            style={{ backgroundImage: "url(images/skyline-2.png)" }}
          ></div>
          <div className="info-holder"></div>
        </div>
        <div className="form-holder">
          <div className="form-content form-reg-content">
            <div className="form-items">
              <h3 className="ti-fs t-aling-c">
                <span className="pl-name">Sign Up</span>
              </h3>
              <form ref={formRef} onSubmit={handleSubmit}>
                <div className="form-group mb-4">
                  <label htmlFor="full-name" className="sr-only">
                    First name
                  </label>
                  <div className="input-group input-group-lg">
                    <div className="input-group-prepend ">
                      <span className="input-group-text bg-gray-01 border-0 text-muted fs-18">
                        <i className="far fa-address-card"></i>
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control border-0 shadow-none fs-13"
                      id="first_name"
                      name="first_name"
                      placeholder="First name"
                      value={userData.first_name}
                      onChange={handleInputChange}
                      required
                      title="This is Required Field"
                    />
                  </div>
                </div>
                <div className="form-group mb-4">
                  <label htmlFor="full-name" className="sr-only">
                    Last name
                  </label>
                  <div className="input-group input-group-lg">
                    <div className="input-group-prepend ">
                      <span className="input-group-text bg-gray-01 border-0 text-muted fs-18">
                        <i className="far fa-address-card"></i>
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control border-0 shadow-none fs-13"
                      id="last_name"
                      name="last_name"
                      placeholder="Last name"
                      value={userData.last_name}
                      onChange={handleInputChange}
                      required
                      title="This is Required Field"
                    />
                  </div>
                </div>
                {/* username details */}
                <div className="form-group mb-4">
                  <label htmlFor="username01" className="sr-only">
                    Email
                  </label>
                  <div className="input-group input-group-lg">
                    <div className="input-group-prepend ">
                      <span className="input-group-text bg-gray-01 border-0 text-muted fs-18">
                        <i className="far fa-user"></i>
                      </span>
                    </div>
                    <input
                      type="email"
                      className="form-control border-0 shadow-none fs-13"
                      id="email"
                      name="email"
                      placeholder="Your email"
                      value={userData.email}
                      onChange={handleInputChange}
                      pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                      required
                    />
                  </div>
                </div>

                {/* password fields */}
                <div className="form-group mb-4">
                  <label htmlFor="password01" className="sr-only">
                    Password
                  </label>
                  <div className="input-group input-group-lg">
                    <div className="input-group-prepend ">
                      <span className="input-group-text bg-gray-01 border-0 text-muted fs-18">
                        <i className="far fa-lock"></i>
                      </span>
                    </div>
                    <input
                      type={visible ? "text" : "password"}
                      className="form-control border-0 shadow-none fs-13"
                      id="password"
                      name="password"
                      placeholder="Password"
                      value={userData.password}
                      onChange={handleInputChange}
                      pattern="^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$"
                      required
                    />
                    <div className="input-group-append">
                      <span className="input-group-text bg-gray-01 border-0 text-body fs-18">
                        <i
                          className="far fa-eye-slash"
                          onClick={() => setVisible(!visible)}
                        ></i>
                      </span>
                    </div>
                  </div>
                  <p className="form-text">
                    Password must be at least 8 characters in length
                  </p>
                </div>

                {/* confirm password */}
                <div className="form-group mb-4">
                  <label htmlFor="password02" className="sr-only">
                    Confirm Password
                  </label>
                  <div className="input-group input-group-lg">
                    <div className="input-group-prepend ">
                      <span className="input-group-text bg-gray-01 border-0 text-muted fs-18">
                        <i className="far fa-lock"></i>
                      </span>
                    </div>
                    <input
                      type={visible ? "text" : "password"}
                      className="form-control border-0 shadow-none fs-13"
                      id="confirmPassword"
                      name="password_confirmation"
                      value={userData.password_confirmation}
                      placeholder="Confirm Password"
                      onChange={handleInputChange}
                    />
                    <div className="input-group-append">
                      <span className="input-group-text bg-gray-01 border-0 text-body fs-18">
                        <i
                          className="far fa-eye-slash"
                        // onClick={() =>
                        //   setShowConfirmPassword(!showConfirmPassword)
                        // }
                        ></i>
                      </span>
                    </div>
                  </div>
                  <p className="form-text">
                    it should be same as above password
                  </p>
                </div>

                <div className="d-flex form-group mb-4">
                  <label htmlFor="username01" className="sr-only">
                    Business Profile
                  </label>

                  <div className="input-group input-group-lg">
                    <div className="d-flex">
                      <input
                        type="radio"
                        name="is_business"
                        value={true}
                        checked={userData.is_business === true}
                        onChange={handleRadioChange}
                      />
                      <label className="ml-2 mt-2">Business</label>
                    </div>
                  </div>
                  <div className="input-group input-group-lg">
                    <div className="d-flex">
                      <input
                        type="radio"
                        name="is_non_business"
                        value={true}
                        checked={userData.is_non_business === true}
                        onChange={handleRadioChange}
                      />
                      <label className="ml-2 mt-2">Non Business</label>
                    </div>
                  </div>
                </div>

                {userData.is_business === true ? (
                  <>
                    <p className="form-text mb-2">
                      For Business user, select one of the options below to
                      Signup
                    </p>
                    <div className="d-flex form-group mb-4">
                      <div className="input-group input-group-lg">
                        <div className="d-flex">
                          <input
                            type="radio"
                            name="business_profile"
                            value="is_developer"
                            onChange={() => {
                              handleInputChange({
                                target: {
                                  name: "business_profile",
                                  value: "is_developer",
                                },
                              });
                              setShowOtherFields(false); // Show the additional fields
                            }}
                          />
                          <label className="ml-2 mt-2">Developer</label>
                        </div>
                      </div>
                      <div className="input-group input-group-lg">
                        <div className="d-flex">
                          <input
                            type="radio"
                            name="business_profile"
                            value="is_agent"
                            onChange={() => {
                              handleInputChange({
                                target: {
                                  name: "business_profile",
                                  value: "is_agent",
                                },
                              });
                              setShowOtherFields(false); // Show the additional fields
                            }}
                          />
                          <label className="ml-2 mt-2">Agent</label>
                        </div>
                      </div>
                      <div className="input-group input-group-lg">
                        <div className="d-flex">
                          <input
                            type="radio"
                            name="business_profile"
                            value="is_other"
                            onChange={() => {
                              handleInputChange({
                                target: {
                                  name: "business_profile",
                                  value: "is_other",
                                },
                              });
                              setShowOtherFields(true); // Show the additional fields
                            }}
                          />
                          <label className="ml-2 mt-2">Other</label>
                          <br />

                        </div>
                      </div>
                    </div>
                    {showOtherFields && (
                      <div className="form-group mb-4">

                        <select className="form-control border-0 shadow-none fs-13"
                          onChange={handleInputChange}
                          name="is_other_option_relation"
                          value={userData.is_other_option_relation}

                        >
                          {OtherUserRole.map((item, index) => (
                            <option name="name" value={item.id} key={index}>
                              {item.permission}
                            </option>
                          ))}
                          {/* <option name="md" value="id">
                                  MD
                                </option> */}
                        </select>


                      </div>
                    )}

                  </>
                ) : null}
                <button
                  type="submit"
                  className="btn text-white bg-blue hover-saffron btn-lg btn-block"

                >
                  Sign up
                </button>
              </form>
              <div className="divider text-center my-2">
                <span className="px-4 bg-white lh-17 text">
                  or continue with
                </span>
              </div>

              {/* <div className="col-12 px-2 mb-4">
            <GoogleLogin
              className="button-google-login"
              clientId={ClientId}
              buttonText="Google"
              onSuccess={responsegoogle}
              onFailure={responsegoogle}
              cookiePolicy={"single_host_origin"}
              isSignedIn={true}

            />
          </div> */}

              <p className="lg-bo">
                Already have an account?{" "}
                <Link to="/login" className="text-blue hover-saffron-text">
                  <u>Log in</u>
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
