import React, { useEffect, useState } from "react";
import logo from "../../images/dpf-logo.png";
import AutoComplete from "react-google-autocomplete";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CurrencyFormat from "react-currency-format";
import SearchMap from "./SearchMap";
import Header from "../../header/Header";
import Footer from "../../footer/Footer";
import Loader from "../../Loader";
import { toast } from "react-toastify";
import CityFilter from "./CityFilter";
import axios from "axios";
import CallActionButton from "../../callActionButton/CallActionButton";
import axiosInstance from "../../interceptor/axiosInstance";
import FavActionButton from "../../favActionButton/FavActionButton";
import MyModal from "../../landingPage/MyModal";
import Searchmodal from "./Searchmodal";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
const API_URL = process.env.REACT_APP_API_URL;

const PropertyList = () => {
  const is_auth = localStorage.getItem("is_authenticated");
  const locatedData = useLocation();
  // const allData = locatedData?.state?.my;
  // useEffect(() => {
  //   if (allData) {
  //     setFliterData(allData)
  //   }
  // }, [allData])
  // console.log(allData, "allData");
  const searchParams = new URLSearchParams(locatedData.search);
  const { searchitem } = locatedData.state || "";
  const searchFoter = locatedData.state?.footersearch || "";
  console.log(searchitem, "this is item");

  const [grid, setGrid] = useState(false);
  const searchstate = searchFoter[0]?.footercity;
  const searchlabel = searchFoter[1]?.footerlabel;

  const save_building_type = searchitem?.building_type || "";
  const save_construction_status = searchitem?.construction_status || "";
  const save_property_label = searchitem?.property_label || "";
  const save_property_type = searchitem?.property_type || "";
  const save_location = searchitem?.street_address || "";
  const save_lat = searchitem?.lat || "";
  const save_long = searchitem?.long || "";
  console.log(save_location, "this is save_location")
  /*** Get Save Search End */
  const building_type_name = locatedData?.state?.building_type;
  const buildingname = locatedData?.state?.buildingname
  const newCity = locatedData?.state?.newCity;
  const allData = locatedData?.state?.allcommerical;
  const property_label_id = locatedData?.state?.property_label;
  console.log(property_label_id,"IDIDIDIIDIDID");
  const [propertyLabelId, setPropertyLabelId] = useState();
  console.log(propertyLabelId,"propertyLabelId");
  // useEffect(() => {
  //   if (property_label_id) {
  //     setPropertyLabelId(property_label_id)
  //   }
  // }, [property_label_id])
  useEffect(() => {
    if (allData) {
      setFliterData(allData)
    }
  }, [allData])
  const is_property = locatedData?.state?.is_property;

  const label_id = locatedData?.state?.property_label ;
  const saleProperty = locatedData?.state?.my;
  // useEffect(() => {
  //   if (label_id) {
  //     setPropertyLabelId(label_id)
  //   }
  // }, [label_id])
  
 

  const navigate = useNavigate();
  const [selectedCity, setSelectedCity] = useState();
  const [selectedState, setSelectedState] = useState();

  const [SearchData, setSearchData] = useState(locatedData?.state);
  const lati = SearchData?.lati;
  const longi = SearchData?.longi;
  const [place, setPlace] = useState([]);

  const [searchText, setSearchText] = useState([]);
  const lat = place?.geometry?.location?.lat();
  const lng = place?.geometry?.location?.lng();

  const [allProperties, setAllProperties] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fliterData, setFliterData] = useState([]);


  const [buildingType, setBuildingType] = useState([]);
  const [selectedBuildingTypeId, setSelectedBuildingTypeId] = useState();
  const [propertyType, setPropertyType] = useState([]);
  const [propertyTypeId, setpropertyTypeId] = useState("");
  const [constructionStatusId, setConstructionStatusId] = useState("");


  const [constructionStatus, setConstructionStatus] = useState([]);
  const [propertyLabel, setPropertyLabel] = useState([]);
  const [favproperty, setfavproperty] = useState([]);
  const [isPopoverOpen, setPopoverOpen] = useState(false);
  const [minRentPrice, setMinRentPrice] = useState("");
  const [maxRentPrice, setMaxRentPrice] = useState("");
  const [minSalePrice, setMinSalePrice] = useState("");
  const [maxSalePrice, setMaxSalePrice] = useState("");

  const [visibleItems, setVisibleItems] = useState(12);
  const itemsToLoad = 12;
  const ResetButton = () => {
    setLoading(true);
    window.location.reload();
    navigate("/advance-search", {});
  };
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const handleButtonClick = () => {
    setPopoverOpen(!isPopoverOpen);
  };

  /* *********Api TO Get Favourite property *********/
  const getfavourite = async () => {
    try {
      const response = await axiosInstance.get(
        `${API_URL}/properties/user/get/favourite/`
      );
      if ((response.status === 200)) {
        const responseData = response.data;
        setfavproperty(responseData);
      } else {
        toast.error(" Please Contact Administrator");
      }
    } catch (err) {
      toast.error(" Please Contact Administrator");
    }
  };
  /* *******************get property data************/

  const getAllProperties = async () => {
    setLoading(true);

    try {
      let locationParams = "/properties/advance/filter?is_new_property=true";

      if (lati !== undefined && longi !== undefined) {
        locationParams += `&latitude=${lati}&longitude=${longi}&distance=5000`;
      }

      else if (lat !== undefined && lng !== undefined) {
        locationParams += `&latitude=${lat}&longitude=${lng}&distance=5000`;
      }
      if (selectedBuildingTypeId !== undefined) {
        locationParams += `&building_type=${selectedBuildingTypeId}`;
      } else if (building_type_name !== undefined) {
        locationParams += `&building_type=${building_type_name}`;
      }
      if ( propertyLabelId !== undefined ) {
        locationParams += `&property_label=${propertyLabelId}`;
        setMinRentPrice("");
        setMaxRentPrice("");
        setMinSalePrice("");
        setMaxSalePrice("");
      } else if (property_label_id !== undefined) {
          locationParams += `&property_label=${property_label_id}`;
          setMinRentPrice("");
          setMaxRentPrice("");
          setMinSalePrice("");
          setMaxSalePrice("");
        }

      if (propertyTypeId !== undefined) {
        locationParams += `&property_type=${propertyTypeId}`;
      }
      if (constructionStatusId !== undefined) {
        locationParams += `&construction_status=${constructionStatusId}`;
      }

      if (newCity !== undefined) {
        locationParams += `&city=${newCity}`;
      }
      if (searchstate !== undefined && searchlabel !== undefined) {
        locationParams += `&property_label_name=${searchlabel}&state=${searchstate}`;
      }
      if (selectedState !== undefined) {
        locationParams += `&state=${selectedState}`;
      }
      if (selectedCity !== undefined) {
        locationParams += `&city=${selectedCity}`;
      }
      // if (newstate !== undefined) {
      //   locationParams += `&state=${newstate}&is_property=${true}`;
      // }
      if (
        save_building_type ||
        save_construction_status ||
        save_property_type || save_long ||
        save_property_label) {
        locationParams += `&longitude=${save_long}&latitude=${save_lat}&construction_status=${save_construction_status}&building_type=${save_building_type}&property_type=${save_property_type}&property_label=${save_property_label}&distance=5000`;

      }
      // if (
      //   lati == undefined &&

      //   propertyLabelId == undefined &&
      //   // property_label_id == undefined &&
      //   save_building_type == undefined &&
      //   newCity == undefined &&
      //   searchlabel == undefined &&
      //   searchstate == undefined &&
      //   constructionStatusId == undefined &&
      //   selectedCity == undefined && selectedBuildingTypeId == undefined && lat == undefined && propertyTypeId == undefined &&
      //   selectedState == undefined

      // ) {
      //   locationParams = "/properties/advance/filter?is_new_property=true";
      // }


      const response = await axios.get(`${API_URL}${locationParams}`);

      if (response.status === 200) {
        const responseData = response?.data;
        setAllProperties(responseData);
        setFliterData(responseData);
        setLoading(false);
      } else {
        toast.error(" Please Contact Administrator");
        setLoading(false);
      }

    } catch (err) {
      toast.error(" Please Contact Administrator");
      setLoading(false);
    }
  };

  

  /* ****************api for building type ************* */

  const getBuildType = async () => {
    try {
      const response = await axios.get(`${API_URL}/properties/building/types/`);
      if ((response.status = 200)) {
        const responseData = response.data;
        setBuildingType(responseData);
      } else {
        toast.error(" Please Contact Administrator");
      }
    } catch (err) {
      toast.error(" Please Contact Administrator");
    }
  };

  /* ****************api for property type ************* */

  const getPropertyType = async () => {
    setLoading(true);
    if (selectedBuildingTypeId !== null) {
      try {
        const response = await axios.get(
          `${API_URL}/properties/property/types/?building_type=${selectedBuildingTypeId}`
        );
        if (response.status === 200) {
          const responseData = response.data;
          setPropertyType(responseData);
          setLoading(false);
        } else {
          setLoading(false);
          toast.error(" Please Contact Administrator");
        }
      } catch (err) {
        setLoading(false);
        toast.error(" Please Contact Administrator");
      }
    }
  };
  /* ****************api for property contruction status ************* */

  const getConstructionstatus = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/properties/construction/status/`
      );
      if ((response.status = 200)) {
        const responseData = response.data;
        setConstructionStatus(responseData);
      } else {
        toast.error(" Please Contact Administrator");
      }
    } catch (err) {
      toast.error(" Please Contact Administrator");
    }
  };

  /* ****************api for property label ************* */

  const getPropertyLabel = async () => {
    try {
      const response = await axios.get(`${API_URL}/properties/property/label/`);
      if ((response.status = 200)) {
        const responseData = response.data;
        setPropertyLabel(responseData);
      } else {
        toast.error(" Please Contact Administrator");
      }
    } catch (err) {
      toast.error(" Please Contact Administrator");
    }
  };

  /* ***************save search************* */

  const [searchCriteria, setSearchCriteria] = useState({
    place: "",
    selectedBuildingTypeId: "",
    propertyTypeId: "",
    constructionStatusId: "",
    propertyLabelId: "",
  });
  console.log(searchCriteria, "this is search Criteria")

  const [saveshow, setSaveshow] = useState(false);
  const handleshowClose = () => setSaveshow(false);
  const handleInputChange = (e, field) => {
    setSearchCriteria((prevSearchCriteria) => ({
      ...prevSearchCriteria,
      [field]: e.target.value,

    }));
  };

  const OnSave = async () => {
    if (localStorage.getItem("token")) {
      setSaveshow(true);
    } else {
      setShow(true);
    }
  };
  /*********** Save Search End */


  // useEffect(() => {
  //   if (building_type_name) {
  //     setSelectedBuildingTypeId(building_type_name)
  //   }
  // }, [building_type_name])

  // useEffect(() => {
  //   if (buildingname) {
  //     setSelectedBuildingTypeId(buildingname)
  //   }
  // }, [locatedData])
  /* ***********filter keyword************* */

  useEffect(() => {
    if (searchText !== null) {
      setLoading(true);
      const res = allProperties.filter((item) => {
        const searchLowerCase = searchText
          ? searchText.toString().toLowerCase()
          : "";
        const searchUpperCase = searchText
          ? searchText.toString().toUpperCase()
          : "";

        const propertyName = item?.property_name || "";
        const streetAddress = item?.street_address || "";

        return (
          searchLowerCase === "" ||
          propertyName.toString().toLowerCase().includes(searchLowerCase) ||
          streetAddress.toString().toLowerCase().includes(searchLowerCase) ||
          propertyName.toString().toUpperCase().includes(searchUpperCase) ||
          streetAddress.toString().toUpperCase().includes(searchUpperCase)
        );
      });

      if (res.length > 0) {
        setFliterData(res);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  }, [searchText]);

  // useEffect(() => {
  //   if (propertyLabelId) {
  //     setLoading(true);
  //     const newres = allProperties.filter((item) => {
  //       return (


  //         (propertyLabelId === "" ||
  //           item.property_label.includes(propertyLabelId))
  //       );
  //     });
  //     if (newres.length) {
  //       setFliterData(newres);
  //       setLoading(false);
  //     } else {
  //       // toast.error("No matching properties found");
  //       setFliterData([]);
  //       // setFliterData(allProperties);
  //       setLoading(false);
  //     }
  //   }
  // }, [propertyLabelId]);

  /* ********* */
  useEffect(() => {
    if (minRentPrice && maxRentPrice) {
      setLoading(true);
      const newres = allProperties.filter((item) => {
        return (
          (minRentPrice === null || item.lease_price_per_mo >= minRentPrice) &&
          (maxRentPrice === null || item.lease_price_per_mo <= maxRentPrice)
        );
      });

      if (newres.length) {
        setFliterData(newres);
        setLoading(false);
      } else {
        setFliterData([]);
        setLoading(false);
      }
    }
  }, [minRentPrice, maxRentPrice]);

  /* **************** */
  /* ********* */
  useEffect(() => {
    if (minSalePrice && maxSalePrice) {
      setLoading(true);
      const newres = allProperties.filter((item) => {
        return (
          (minSalePrice === null || item.property_price >= minSalePrice) &&
          (maxSalePrice === null || item.property_price <= maxSalePrice)
        );
      });

      if (newres.length) {
        setFliterData(newres);
        setLoading(false);
      } else {
        setFliterData([]);
        setLoading(false);
      }
    }
  }, [minSalePrice, maxSalePrice]);

  /* **************** */
  // useEffect(() => {
  //   if (selectedCity) {
  //     setLoading(true);
  //     const newres = allProperties.filter((item) => {
  //       return selectedCity === "" || item.city.includes(selectedCity);
  //     });
  //     if (newres.length) {
  //       setFliterData(newres);
  //       setLoading(false);
  //     } else {
  //       // toast.error("No matching properties found");
  //       setFliterData([]);
  //       // setFliterData(allProperties);
  //       setLoading(false);
  //     }
  //   }
  //   // Add an else statement here if you want to handle the case when selectedBuildingTypeId is empty.
  // }, [selectedCity]);
  /* *************** */
  /* **************** */
  //  useEffect(() => {
  //     if (selectedState) {
  //       setLoading(true);
  //       const newres = allProperties.filter((item) => {
  //         return selectedState === "" || item.state.includes(selectedState);
  //       });
  //       if (newres.length) {
  //         setFliterData(newres);
  //         setLoading(false);
  //       } else {
  //         // toast.error("No matching properties found");
  //         setFliterData([]);
  //         // setFliterData(allProperties);
  //         setLoading(false);
  //       }
  //     }
  //     // Add an else statement here if you want to handle the case when selectedBuildingTypeId is empty.
  //   }, [selectedState]);
  /* ************** */
  useEffect(() => {
    window?.scrollTo(0, 0);
    getBuildType();
    getConstructionstatus();
    getPropertyLabel();
  }, []);
  useEffect(() => {
    window?.scrollTo(0, 0);
    getAllProperties();
  },
    [
      lati,
      propertyLabelId,
      // property_label_id,
      selectedBuildingTypeId,
      save_building_type,
      newCity,
      searchstate,
      searchlabel,
      selectedState, selectedCity, lat, propertyTypeId, constructionStatusId, buildingname
    ]);

  // useEffect(() => {
  //   if (lat) {
  //     getSearchProperties();
  //   }
  // }, [lat, lng]);
  useEffect(() => {
    if (selectedBuildingTypeId) {
      getPropertyType();
      // getPropertiesWithBuildingType();
    }
  }, [selectedBuildingTypeId]);
  useEffect(() => {
    if (saleProperty?.length > 0) {
      setFliterData(saleProperty)
    }
  }, [saleProperty])
  useEffect(() => {
    window?.scrollTo(0, 0);
    if (is_auth === "false") {
      getfavourite();
    }
  }, [is_auth]);


  function handleGrid() {
    setGrid(true);
  }
  function handleList() {
    setGrid(false);
    // getAllProperties();
  }


  const navigateToPage = (propertyId) => {
    navigate(`/property/details/${propertyId}`); // Assuming you have a route like '/page/:id'
  };


  return (
    <>
      {loading ? <Loader /> : null}
      <Header />
      <main id="content">
        {/* *****************search bar*********** */}
        <section className="bg-blue h-lg-80 mt-r-0 h-lg-210 mt-100 list-Section-m">
          <div className="container-fluid">
            <form
              className="property-search d-lg-block width-incenter" /* style={{ paddingTop: '8px' }} */
            >
              <div className="row align-items-lg-center">
                <div className="d-md-flex add-position-input blue-spacing">
                  <div className="form-group mb-0 position-relative flex-md-3 mt-3 mt-md-0 form-align search-in">
                    <AutoComplete
                      apiKey={"AIzaSyDZSiGDJcQdFDZqQU5ONXF7dGkoKQePszo"}
                      onPlaceSelected={(place) => {
                        setPlace(place);
                        setSearchCriteria((prevSearchCriteria) => ({
                          ...prevSearchCriteria,
                          place,
                        }));
                      }}
                      className="mt-fourteen form-control  border-0 radius-left-tb shadow-none pr-8 bg-white placeholder-muted commercial-input"
                      placeholder="Enter Location..."
                      onKeyDown={(event) => {
                        if (event?.keyCode === 18) {
                          event?.preventDefault();
                        }
                      }}
                      // onChange={handleSearch}
                      options={{
                        types: ["route"],
                        componentRestrictions: { country: "ae" },
                        // types: ["(regions)"],
                      }}
                    />
                  </div>

                  <div className="col-md-7 px-md-0 add-inline-flex-prop list-grid-set">
                    <div className="search-ty">
                      <select
                        className="form-control border-0 shadow-none   bg-white"
                        name="Property Type"
                        data-style="btn-lg py-2 h-52 bg-white"
                        id="buildingTypeId"
                        onChange={(e) => {
                          const selectedValue = e?.target?.value;
                          setSelectedBuildingTypeId(selectedValue === "" ? "" : selectedValue);
                          handleInputChange(e, "selectedBuildingTypeId");
                          // setSelectedBuildingTypeId(e?.target?.value);
                          // handleInputChange(e, "selectedBuildingTypeId");
                        }}
                        value={searchCriteria.selectedBuildingTypeId}
                      >
                        <option value="" className="p-holder-color option-set ">
                          Building Type
                        </option>
                        {buildingType?.map((item) => {
                          return <option value={item?.id} key={item?.id}>{item?.name}</option>;
                        })}
                      </select>
                    </div>

                    <div className="search-ty">
                      <select
                        className="form-control border-0 shadow-none   bg-white"
                        name="Property Type"
                        data-style="btn-lg py-2 h-52 bg-white"
                        id="buildingTypeId"
                        // onChange={(e) => setpropertyTypeId(e?.target?.value)}
                        onChange={(e) => {
                          const selectedValue = e?.target?.value;
                          setpropertyTypeId(selectedValue === "Property Type" ? "" : selectedValue);
                          handleInputChange(e, "propertyTypeId");
                        }}
                        value={searchCriteria.selectedpropertyTypeId}
                      >
                        <option className="p-holder-color option-set">
                          Property Type
                        </option>
                        {propertyType?.map((item) => {
                          return <option value={item?.id} key={item?.id}>{item?.name}</option>;
                        })}
                      </select>
                    </div>
                    <div className="search-ty">
                      <select
                        className="form-control border-0 shadow-none   bg-white"
                        name="Construction Status"
                        data-style="btn-lg py-2 h-52 bg-white"
                        // onChange={(e) => setConstructionStatusId(e?.target?.value)} >
                        onChange={(e) => {
                          const selectedValue = e?.target?.value;
                          setConstructionStatusId(selectedValue === "Building Status" ? "" : selectedValue);
                          handleInputChange(e, "constructionStatusId");
                        }}
                        value={searchCriteria.constructionStatusId}
                      >
                        <option className="p-holder-color">
                          Building Status
                        </option>
                        {constructionStatus?.map((item) => {
                          return <option value={item?.id} key={item?.id}>{item?.name}</option>;
                        })}
                      </select>
                    </div>

                    <div className="search-ty">
                      <select
                        className="form-control border-0 shadow-none   bg-white"
                        name="labels"
                        data-style="btn-lg py-2 h-52 bg-white"
                        // onChange={(e) => {
                        //   setPropertyLabelId(e?.target?.value);
                        //   setMinRentPrice('');
                        //   setMaxRentPrice('');
                        //   setMinSalePrice('');
                        //   setMaxSalePrice('');
                        // }}
                        onChange={(e) => {
                          const selectedValue = e?.target?.value;
                          setPropertyLabelId(selectedValue === "For Sale/Rent" ? "" : selectedValue);
                          setMinRentPrice("");
                          setMaxRentPrice("");
                          setMinSalePrice("");
                          setMaxSalePrice("");
                          handleInputChange(e, "propertyLabelId");
                        }}
                        value={searchCriteria.propertyLabelId}
                      >
                        <option className="p-holder-color">
                          For Sale/Rent
                        </option>
                        {propertyLabel.map((item) => {
                          return (
                            <>
                              <option value={item?.id} key={item?.id}>{item?.name}</option>
                            </>
                          );
                        })}
                      </select>
                    </div>
                    <div className="search-ty">
                      <Link
                        onClick={handleButtonClick}
                        className="form-control  border-0 shadow-none   bg-white placeholder-muted"
                      >
                        Price Range
                      </Link>
                      {isPopoverOpen && (
                        <div className="custom-popover">
                          {/* Popover content goes here */}
                          {propertyLabelId === propertyLabel[1]?.id
                             ||  property_label_id === propertyLabel[1]?.id 
                            ? (
                              <>
                                <span className="fs-12">
                                  Price range for rent properties
                                </span>
                                <div className="d-flex">
                                  <div className="price-from">
                                    <input
                                      type="number"
                                      className="form-control border-1 shadow-none form-control-lg  bg-white"
                                      name="labels"
                                      data-style="btn-lg  bg-white"
                                      placeholder="Min Price(AED)"
                                      value={minRentPrice}
                                      onChange={(e) =>
                                        setMinRentPrice(e?.target?.value)
                                      }
                                    />
                                  </div>
                                  <span className="fs-18 mt-2 px-1">To</span>
                                  <div className="price-to">
                                    <input
                                      type="number"
                                      className="form-control border-1 shadow-none form-control-lg  bg-white"
                                      name="labels"
                                      data-style="btn-lg  bg-white"
                                      placeholder="Max Price(AED)"
                                      value={maxRentPrice}
                                      onChange={(e) =>
                                        setMaxRentPrice(e?.target?.value)
                                      }
                                    />
                                  </div>
                                </div>
                              </>
                            ) : propertyLabelId === propertyLabel[0]?.id
                               || property_label_id === propertyLabel[0]?.id 
                              ? (
                                <>
                                  <span className="fs-12">
                                    Price range for sale properties
                                  </span>
                                  <div className="d-flex">
                                    <div className="price-from">
                                      <input
                                        type="number"
                                        className="form-control border-1 shadow-none   bg-white"
                                        name="labels"
                                        data-style="btn-lg  bg-white"
                                        placeholder="Min Price(AED)"
                                        value={minSalePrice}
                                        onChange={(e) =>
                                          setMinSalePrice(e?.target?.value)
                                        }
                                      />
                                    </div>
                                    <span className="fs-18 mt-2 px-1">To</span>
                                    <div className="price-to">
                                      <input
                                        type="number"
                                        className="form-control border-1 shadow-none   bg-white"
                                        name="labels"
                                        data-style="btn-lg  bg-white"
                                        placeholder="Max Price(AED)"
                                        value={maxSalePrice}
                                        onChange={(e) =>
                                          setMaxSalePrice(e?.target?.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <span className="fs-12">
                                    Please select Sale/Rent
                                  </span>
                                </>
                              )}
                        </div>
                      )}
                    </div>
                    <div className="search-ty">
                      <input
                        type="text"
                        className="form-control  border-0 shadow-none bg-white placeholder-muted"
                        placeholder="Keyword"
                        onChange={(e) => setSearchText(e?.target?.value)}
                      />
                    </div>
                  </div>
                  <div className="d-flex search-reset">
                    {/* <button type="button" className="btn property-search-icon bg-white text-dark hover-grey" onClick={() => { refetch() }}>
                      <span className="fs-18 rounded-0"><i
                        className="fal fa-search"></i></span>
                    </button> */}

                    <div className="ad-sr">
                      <button
                        type="button"
                        onClick={() => ResetButton()}
                        className="btn advanced-search btn-accent h-ch-56 h-lg-100 w-100 shadow-none text-secondary radius-left-tb fs-14 fs-sm-16 font-weight-600 text-center d-flex align-items-center button-change"
                      ></button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </section>

        <section className="position-relative">
          <div className="container-fluid px-0 property-margin">
            <div className="row no-gutters">
              {/* **********left side********** */}
              <div
                className={`${grid === true ? "col-lg-12 " : "col-lg-6"
                  } pt-7  order-2 order-xl-1 px-1 px-xxl-8 propertylist-scroll`}
              >

                <div className="display-re-none">
                  <CityFilter
                    filterData={fliterData}
                    setSelectedCity={setSelectedCity}
                    setSelectedState={setSelectedState}
                    selectedState={selectedState}
                    grid={grid}

                  />
                </div>
                <div className="row mb-2 px-2">
                  <div className="col-lg-4">

                    <h2 className="text-heading fs-22 lh-15">
                      {is_property === true ? <>New </> : null}
                      Properties
                      <span className="badge badge-white badge-pill text-blue fs-18 font-weight-bold ml-2 colour-change-in-listpage">
                        {fliterData?.length}
                      </span>
                    </h2>
                  </div>
                  <div className="col-lg-8">
                    <div className="f-right mr-2">
                      <Link
                        className="btn bg-blue text-white hover-saffron fa fa-map py-1  mr-2"
                        to="/map"
                        state={{ type: buildingType }}
                      >
                        Map Views
                      </Link>
                      <div className="display-re-none">
                        <button
                          className="btn bg-blue text-white hover-saffron fa fa-star py-1"
                          onClick={() => OnSave()}
                        >
                          &nbsp;Save Search
                        </button>

                        {grid === false ? (
                          <>
                            <OverlayTrigger
                              overlay={
                                <Tooltip id="tooltip-disabled">
                                  Grid View
                                </Tooltip>
                              }
                            >
                              <button
                                className="ml-2 border-0 px-3 py-1 round-5 bg-blue text-white"
                                onClick={handleGrid}
                              >
                                <i className="bi bi-grid" />
                              </button>
                            </OverlayTrigger>
                          </>
                        ) : (
                          <>
                            <OverlayTrigger
                              overlay={
                                <Tooltip id="tooltip-disabled">
                                  Map View
                                </Tooltip>
                              }
                            >
                              <button
                                className="ml-2 border-0 px-3 py-1 round-5 bg-blue text-white"
                                onClick={handleList}
                              >
                                <i className="bi bi-list" />
                              </button>
                            </OverlayTrigger>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <p className="ml-2 font-weight-600 fs-14">Showing Properties within 5KMs of your search location</p> */}
                {fliterData?.length > 0 ? (
                  <div className={`${grid === true ? "px-2 row  " : "px-2"} `}>
                    {fliterData?.slice(0, visibleItems).map((item, index) => {
                      return (
                        <div
                          className={`${grid === true ? "col-xl-4 col-lg-6  " : ""
                            } `}
                          key={index}
                        >

                          <div className="p-0  border-1 round-5 mb-2 shade-list">
                            <Link to={`/property/details/${item?.slug}`}>
                              <div className="row hover-change-image padding-prop-list" /* onClick={() => navigateToPage(item?.id)} */>

                                <div className="col-lg-4 col-md-6 pr-0">

                                  <div className="bg-overlay-gradient-1 bg-hover-overlay-gradient-3 ">
                                    <img
                                      className="propertylist-img"
                                      style={{ height: "200px" }}
                                      src={item?.upload_featured_image}
                                      alt={item?.property_label_name}
                                    />
                                    <div className="card-img-overlay d-flex flex-column justify-content-between ">
                                      <div>
                                        <span className="badge badge-orange mr-2">
                                          {item?.property_label_name}
                                        </span>
                                      </div>
                                    </div>
                                  </div>

                                </div>

                                <div className="col-lg-8 col-md-6 m-0">

                                  <div className="d-flex justify-content-between">
                                    <p
                                      className="mt-3 fs-20 font-weight-bold text-heading mb-0 "
                                      style={{ lineHeight: "1.3" }}
                                    >
                                      {item?.property_price ? (
                                        <>
                                          <span className="text-black font-weight-500 fs-16">
                                            AED{" "}
                                          </span>
                                          <CurrencyFormat
                                            value={item?.property_price}
                                            displayType="text"
                                            thousandSeparator={true}
                                            suffix=".00"
                                          />
                                          {/* <span className="text-gray-light font-weight-500 fs-14"> /Mo</span> */}
                                        </>
                                      ) : (
                                        <>
                                          <span className="text-black font-weight-500 fs-16">
                                            AED{" "}
                                          </span>
                                          <CurrencyFormat
                                            value={item?.lease_price_per_mo}
                                            displayType="text"
                                            thousandSeparator={true}
                                            suffix=".00"
                                          />
                                          <span className="text-gray-light font-weight-500 fs-14">
                                            {" "}
                                            /Mo
                                          </span>
                                        </>
                                      )}
                                    </p>
                                    <div className="d-flex">
                                      {item?.is_property === true ? (
                                        <div>
                                          <span className="badge badge-orange mt-2 mr-2">
                                            New
                                          </span>
                                        </div>
                                      ) : null}
                                      <div className="mt-2 mr-2">
                                        <FavActionButton
                                          setShow={setShow}
                                          itemId={item?.id}
                                          favproperty={favproperty}
                                          setfavproperty={setfavproperty}
                                          setLoading={setLoading}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="list-inline-item text-black font-weight-500  fs-lg-14  mr-2 mb-0"
                                    data-toggle="tooltip"
                                    title="Size"
                                  >
                                    {item?.building_type_name}
                                    <span className="text-gray-light ml-1 mr-1">
                                      |
                                    </span>
                                    {item?.property_size}{" "}Sq.Ft
                                    {item?.building_type_name ===
                                      "Residential" ? (
                                      <>
                                        {item?.bedroom !== null &&
                                          item?.bathroom !== null ? (
                                          <>
                                            <span className="text-gray-light ml-1 mr-1">
                                              |
                                            </span>
                                            {item?.bedroom}{" "}
                                            <img
                                              src="images/bedd.png"
                                              className="fs-18  width-24 ml-1 mr-1"
                                              alt="Bed"
                                            />
                                            {item?.bathroom}{" "}
                                            <img
                                              src="images/bathtubb.png"
                                              className="fs-18 mr-1 mr-1 width-24"
                                              alt="Bath"
                                            />
                                          </>
                                        ) : null}
                                      </>
                                    ) : null}
                                  </div>
                                  <h2
                                    className=" mr-3 fs-16 text-dark fw-600"
                                    style={{ lineHeight: "1.2" }}
                                  >

                                    {item?.property_name}

                                  </h2>

                                  <p
                                    className="text-blue  mb-2 "
                                    style={{ lineHeight: "1.7" }}
                                  >
                                    <img
                                      src="images/locations.png"
                                      className="icon icon-bedroom fs-18  mr-1 width-32 "
                                      alt="address"
                                    />
                                    {item?.street_address}
                                  </p>
                                  <div className="d-flex justify-content-between">
                                    <CallActionButton key={item?.id} item={item} />
                                    {item?.company_img ? (<>
                                      <div className="mb-1 mr-2 border-1 round-5" >
                                        <img style={{ width: "80px", height: "39px", borderRadius: "5px", objectFit: "fill" }} src={item?.company_img} alt="" />
                                      </div>
                                    </>) : null}
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div>

                        </div>
                      );
                    })}
                    {fliterData?.length > visibleItems && (
                      <div className="d-flex justify-content-center">
                        <button
                          className={`btn bg-blue hover-saffron text-white ${grid === true ? "mb-3" : ""
                            }`}
                          onClick={() =>
                            setVisibleItems((prev) => prev + itemsToLoad)
                          }
                        >
                          Load More
                        </button>
                      </div>
                    )}
                  </div>
                ) : (
                  <h3>No Properties</h3>
                )}
              </div>

              {/* **********right side********** */}

              {/* *************** */}
              {grid === false ? (
                <>
                  <div
                    className="col-lg-6 order-2 order-xl-2 primary-map map-sticky overflow-hidden"
                    id="map-sticky"
                  >
                    <div className="primary-map-inner">
                      <div className="mapbox-gl map-grid-property-01 xl-vh-100">
                        <SearchMap searchData={fliterData} />
                      </div>
                    </div>
                  </div>
                </>
              ) : null}

              {/* ******************* */}
            </div>
          </div>
        </section>
      </main>
      <MyModal show={show} handleClose={handleClose} />
      <Searchmodal
        saveshow={saveshow}
        handleshowClose={handleshowClose}
        searchCriteria={searchCriteria}
        lat={lat || lati}
        lng={lng || longi}
      />
      <Footer />
    </>
  );
};

export default PropertyList;
