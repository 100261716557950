import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Loader from '../Loader';
import { toast } from 'react-toastify';
import { Button } from 'react-bootstrap';

function VerificationEmail() {
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL;
  const { uid, token } = useParams();
  const [loading, setLoading] = useState(true); // Set loading to true by default

  useEffect(() => {
    // Define an async function to perform the API request
    const verifyEmail = async () => {
      try {
        const response = await axios.post(`${API_URL}/auth/users/activation/`, {
          uid: uid,
          token: token,
        });
       
        if (response.status === 204) {
          toast.success('Your Email Has Been Verified ! Redirecting to Login Page...');
          navigate('/login'); // Redirect to the login page
        }
      } catch (error) {
        toast.error("Your Email With Account Already Vefied! Click on Login Page ....");
      } finally {
        setLoading(false); // Set loading to false when the request is complete
      }
    };

    // Call the verification function when the component mounts
    verifyEmail();
  }, [API_URL, uid, token, navigate]);


  return (<>
    {loading ? <Loader /> : null}
    <div className="form-body">
      <div className="website-logo">
        <Link to="/">
          <div className="logo">
            <img
              className="logo-size"
              src="../../images/dpf-logo-ae.png"
              style={{ width: "150px" }}
            />
          </div>
        </Link>
      </div>
      <div className="img-holder">
        <div
          className="bg"
          style={{ backgroundImage: "url(images/skyline-2.png)" }}
        ></div>
      </div>
      <div className="form-holder">
        <div className="form-content fixed-in-mobile">
          <div className="form-items">
            <h3 className="ti-fs">
              Thanks For Email Verification     {" "}
              <a href="/">
                <span className="pl-name">Dirham Per Foot.</span>
              </a>
            </h3>
            <div className="d-flex mb-4">
              
            <div className="Submit-button-section-login">
              <Button
                
                className="btn text-white bg-blue hover-saffron btn-lg btn-block"
              >
                <Link to="/login">Log in</Link>
              </Button>
            </div>
             
            </div>
            <div className="w-100 mt-5">
              <div style={{ textAlign: "-webkit-center" }}>
                {/* <Googlelogin /> */}
              </div>
            </div>
           
          </div>
        </div>
      </div>
    </div>
    </>
  )
}
export default VerificationEmail
