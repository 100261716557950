import React, { useEffect, useState } from "react";
import axios from "axios";
import ReactApexChart from "react-apexcharts";
import { toast } from "react-toastify";
const API_URL = process.env.REACT_APP_API_URL;

const Pricegraph = (props) => {
    const [graphData,setgraphData] = useState([]);
  
    const property_id = props?.parenttochild;

    console.log(property_id,"ftghfgg")
    
    /// Api to get Property price ///////////
    const getPropertyData = async () => {
        try {
          const response = await axios.get(
            `${API_URL}/properties/price/history/?property=${property_id}`
          );
          if ((response.status = 200)) {
            const responseData = response?.data;
            setgraphData(responseData);
            
          } else {
            
          }
        } catch (err) {
          toast.error(' Please Contact Administrator');
        }
      };

useEffect(() => {
  if(property_id){
    getPropertyData();}
},[property_id])


  const options = {
    series: [
      {
        name: 'Price',
        type: 'line',
        data: graphData.map((dataPoint) => dataPoint.price),
      },
    ],
    chart: {
      height: 250,
      type: 'line',
    },
    stroke: {
      width: [5],
      colors: ['#1a63c6'],
    },
    title: {
      text: 'Price Tracking for this Property',
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [0],
    },
    labels: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
    ],
    xaxis: {
      type: 'Month',
    },
    yaxis: [
      {
        opposite: true,
        title: {
          text: 'Price Change',
        },
      },
    ],
  };

  return graphData.length > 0 ? (
    <div className="py-3 border-bottom bg-white p-5 rounded-15">
      <h4 className="fs-22 text-heading mb-4">Price Tracking for this property</h4>
      <ReactApexChart options={options} series={options.series} type="line" height={250} />
    </div>
  ) : null;
  
};

export default Pricegraph;
