import React, { useEffect, useState } from "react";

import moment from "moment";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import axios from "axios";
import ReactStars from "react-rating-stars-component";
import axiosInstance from "../../../interceptor/axiosInstance";
import { toast } from "react-toastify";
import { Button, Modal } from "react-bootstrap";
// import Loader from "../../../Loader";
const API_URL = process.env.REACT_APP_API_URL;

const UserReviews = () => {
  const is_user = localStorage.getItem("currentUser_id");

  const [expanded, setExpanded] = React.useState(false);
  const [reviewData, setreviewData] = useState([]);
  const [reviewId, setreviewId] = useState("");
  console.log(reviewId,"this is reviewId");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show, setShow] = useState(false);
  
  
  const handleInactiveShow = () => setshowinactive(true);
  const handleInactiveClose = () => setshowinactive(false);
  const [showinactive,setshowinactive] = useState(false);
  

  /* ******************* Get Review Data ****************** */
  const getReviewData = async () => {
    try {
      const reviewresponse = await axiosInstance.get(
        `${API_URL}/account/review/get/`
      );
      if (reviewresponse.status === 200) {
   
        setreviewData(reviewresponse.data);
      } else {
        
      }
    } catch (err) {
      toast.error(' Please Contact Administrator');
    }
  };

  useEffect(() => {
    getReviewData();
  }, []);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };


  const handleActive = async () => {
    try {
     
      const response = await axiosInstance.put(
        `${API_URL}/account/review/${reviewId}/update/`,{is_active:true}
      );
      
console.log(response,"this is resposne")
      if (response.status === 200) {
        toast.success("Review  Successfully Activated");
        setreviewId("");
        getReviewData();

        
      } else {
        // Handle other status codes or errors as needed
       
      }
    } catch (error) {
      // Handle network errors or exceptions
      toast.error(' Please Contact Administrator');
    }
    
    
 
    
  };
  const handleInactive = async () => {
    try {
     
      const response = await axiosInstance.put(
        `${API_URL}/account/review/${reviewId}/update/`,{is_active:false}
      );
      
console.log(response,"this is resposne")
      if (response.status === 200) {
        toast.success("Review  Successfully InActivated");
        setreviewId("");
    getReviewData();

        
      } else {
        // Handle other status codes or errors as needed
       
      }
    } catch (error) {
      // Handle network errors or exceptions
      toast.error(' Please Contact Administrator');
    }
    
 
    
    
  };

  return (
    // {reviewData.length > 0 ? (
    <>
      <div className="card border-0 mb-4 header-r-logout">
        {reviewData.map((item, key) => {
          return (
            <Accordion
              expanded={expanded === `panel${key}`}
              onChange={handleChange(`panel${key}`)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
              
                <Typography
                  sx={{
                    marginTop: "15px",
                    width: "75%",
                    color: "text.secondary",
                  }}
                >
                  {item?.user_review_user}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  className="
                            "
                >
                  <span className="fw-bold fs-18">Published Date:</span>
                  {moment(item?.date_published).format("DD-MMM-YYYY")}
                  <br />
                  <span className="fw-bold fs-18">Message: </span>
                  {item?.description}
                  <br />
                  <span className="fw-bold fs-18">Email: </span>
                  <a
                    href={`mailto:${item?.user_review_email}`}
                    className="mb-0 text-heading border-dark hover-saffron-text lh-1 cl-text-hover"
                  >
                    {item?.user_review_email}
                  </a>
                  <br />
                  <span className="fw-bold fs-18">Rating:</span>
                  {item?.review}
                </Typography>
                <Typography
                    sx={{
                      marginTop: "15px",
                      width: "10%",
                      color: "text.secondary",
                    }}
                  >
                    <i class="bi bi-clipboard-check-fill fs-lg"></i>
                  </Typography>
                 
              </AccordionDetails>
            </Accordion>
          );
        })}
      </div>
      <div className="card border-0 mb-4 header-user-icon">
        <div className="card-body p-0">
          {reviewData.map((item, key) => {
            return (
              <Accordion
                expanded={expanded === `panel${key}`}
                onChange={handleChange(`panel${key}`)}
                key={item?.id}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                
                  <Typography
                    sx={{
                      marginTop: "15px",
                      width: "20%",
                      color: "text.secondary",
                    }}
                  >
                    {item?.user_review_user}
                  </Typography>
                  <Typography
                    sx={{
                      marginTop: "15px",
                      width: "23%",
                      color: "text.secondary",
                    }}
                  >
                    <a
                      href={`mailto:${item?.user_review_email}`}
                      className="mb-0 text-heading border-dark hover-saffron-text lh-1 cl-text-hover"
                    >
                      {item?.user_review_email}
                    </a>
                  </Typography>
                  <Typography
                    sx={{
                      marginTop: "15px",
                      width: "20%",
                      color: "text.secondary",
                    }}
                  >
                    <ReactStars
                      value={item.review}
                      count={5}
                      edit={false}
                      size={26}
                    />
                  </Typography>
                  <Typography
                    sx={{
                      marginTop: "15px",
                      width: "25%",
                      color: "text.secondary",
                    }}
                  >
                    {moment(item?.create_at).format("DD-MM-YYYY")}
                  </Typography>
                 
                  { item?.is_active==false ? (<Typography
                          sx={{
                            marginTop: "15px",
                            width: "5%",
                            color: "text.secondary",
                          }}
                        >
                          <a
                              data-bs-toggle="tooltip"
                              title="Active"
                              className="d-inline-block fs-18  text-blue"
                              onClick={() => {
                                
                                 setreviewId(item?.id);
                                  handleShow();
                              }}
                            >
                    <i class="bi bi-clipboard-check-fill fa-lg"></i></a>
                        </Typography>):
                        <Typography
                        sx={{
                          marginTop: "15px",
                          width: "5%",
                          color: "text.secondary",
                        }}
                      >
                        <a
                            data-bs-toggle="tooltip"
                            title="InActive"
                            className="d-inline-block fs-18  text-orange"
                            onClick={() => {
                              
                               setreviewId(item?.id);
                                handleInactiveShow();
                            }}
                          >
                  <i class="bi bi-clipboard-check-fill fa-lg"></i></a>
                      </Typography> }
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="mx-10">{item?.description}</Typography>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Review Active</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Do You Want to Activate This Review
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button
                variant="success"
                onClick={() => {
                   handleClose();
                   handleActive();
                }}
              >
                Active
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal show={showinactive} onHide={handleInactiveClose}>
            <Modal.Header closeButton>
              <Modal.Title>Review Active</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Do You Want to Deactivate This Review
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button
                variant="danger"
                onClick={() => {
                   handleInactiveClose();
                   handleInactive();
                }}
              >
                InActive
              </Button>
            </Modal.Footer>
          </Modal>
    </>
    //   ) : null}
  );
};

export default UserReviews;
