import React, { useEffect, useRef, useState, createRef } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "../../../Loader";
import moment from "moment";
import ReactStars from "react-rating-stars-component";
import {
  EmailShareButton,
  FacebookShareButton,
  InstapaperShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,

} from "react-share";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import {
  EmailIcon,
  FacebookIcon,
  InstapaperIcon,
  LinkedinIcon,
  MailruIcon,
  TumblrIcon,
  TwitterIcon,
  WhatsappIcon,
  TelegramIcon,
  XIcon,
} from "react-share";
import Header from "../../../header/Header";
import Footer from "../../../footer/Footer";
import axiosInstance from "../../../interceptor/axiosInstance";
import { toast } from "react-toastify";
import { Toast } from "react-bootstrap";
const PreviewProperty = () => {
  const API_URL = process.env.REACT_APP_API_URL
  const [isOpen, setIsOpen] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const propData = location.state?.inputData;
  console.log(propData, "propData");
  // const isProperty =location.state?.isProperty;
  const lat = location.state?.lat;
  const lng = location.state?.lng;
  const [loading, setLoading] = useState(false);


  const [confirmImages, setConfirmImages] = useState([])
  const [noFloor, setNoFloor] = useState([])
  const [propVideo, setPropVideo] = useState('')
   const [bhkCount, setBhkCount] = useState('')
  const [floorData, setFloorData] = useState([])
  const [bhkData, setBHKData] = useState([])
  console.log(bhkData," BHK DAta");
  const [confirmAmenities, setConfirmAmenities] = useState([])
  const [streetAddress, setStreetAddress] = useState('')
  const [propertyName, setPropertyName] = useState('')
  const [dldNumber, setDLDNumber] = useState('')
  const [buildingType, setBuildingType] = useState('')
  const [propertyType, setPropertyType] = useState('')
  const [propertySize, setPropertySize] = useState('')
  const [projectWebsite, setProjectWebsite] = useState('')
  const [country, setCountry] = useState('')
  const [state, setState] = useState('')
  const [city, setCity] = useState('')
  const [area, setArea] = useState('')
  const [description, setDescription] = useState('')
  const [bathroom, setBathroom] = useState('')
  const [bedroom, setBedroom] = useState('')
  const [constructionStatus, setConstructionStatus] = useState('')
  const [launchDate, setLaunchDate] = useState('')
  const [constructionStartDate, setConstructionStartDate] = useState('')
  const [estimatedCompletion, setEstimatedCompletion] = useState('')
  const [propertyLabel, setPropertyLabel] = useState('')
  const [leasePrice, setLeasePrice] = useState('')
  const [propertyPrice, setPropertyPrice] = useState('')
  // const [ownerShip, setOwnerShip] = useState('')
  const [propertyStatus, setPropertyStatus] = useState('')
  const [inspectionFrom, setInspectionFrom] = useState('')
  const [inspectionTo, setInspectionTo] = useState('')
  const [uploadFeaturedImage, setUploadFeaturedImage] = useState('')
  const [uploadAvailabilityPlan, setUploadAvailabilityPlan] = useState('')
  const [uploadProjectBrochure, setUploadProjectBrochure] = useState('')
  const [isNewProperty, setIsNewProperty] = useState("");
  const [isProperty, setIsProperty] = useState("False");
  const [newSalePrice, setNewSalePrice] = useState('');
  const [newLeasePrice, setNewLeasePrice] = useState('');
  const [confirmation, setConfirmation] = useState(false);
  console.log(confirmation, "confirmation");
  const [isAccordionOpen, setIsAccordionOpen] = useState(true);
  const [paymentPlan, setPaymentPlan] = useState();
  const [paymentPlanUrl, setPaymentPlanUrl] = useState();
  const [featurePreImg, setFeaturePreImg] = useState();
  const [multipleImage, setMultipleImage] = useState([]);
  const [videoPreImg, setVideoPreImg] = useState('');
  const [brochure, setBrochure] = useState('');
  const [avaPlan, setAvaPlan] = useState('');

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };
  const handleAccordionToggle = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };
  const iframeRef = useRef(null);
  const iframeReff = useRef(null);
  const iframeRefs = useRef([]);
  const iframeBHK = useRef([]);
  const toggleFull = () => {
    const iframe = iframeReff.current;
    if (iframe) {
      if (iframe.requestFullscreen) {
        iframe.requestFullscreen();
      } else if (iframe.mozRequestFullScreen) { /* Firefox */
        iframe.mozRequestFullScreen();
      } else if (iframe.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
        iframe.webkitRequestFullscreen();
      } else if (iframe.msRequestFullscreen) { /* IE/Edge */
        iframe.msRequestFullscreen();
      }
    }
  };
  const toggleBHKFull = () => {
    const iframe = iframeBHK.current;
    if (iframe) {
      if (iframe.requestFullscreen) {
        iframe.requestFullscreen();
      } else if (iframe.mozRequestFullScreen) { /* Firefox */
        iframe.mozRequestFullScreen();
      } else if (iframe.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
        iframe.webkitRequestFullscreen();
      } else if (iframe.msRequestFullscreen) { /* IE/Edge */
        iframe.msRequestFullscreen();
      }
    }
  };
  const toggleFullScreen = () => {
    const iframe = iframeRef.current;
    if (iframe) {
      if (iframe.requestFullscreen) {
        iframe.requestFullscreen();
      } else if (iframe.mozRequestFullScreen) { /* Firefox */
        iframe.mozRequestFullScreen();
      } else if (iframe.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
        iframe.webkitRequestFullscreen();
      } else if (iframe.msRequestFullscreen) { /* IE/Edge */
        iframe.msRequestFullscreen();
      }
    }
  };
  const toggleFloorFull = (index) => {
    const iframe = iframeRefs.current[index]?.current;
    if (iframe) {
      if (iframe.requestFullscreen) {
        iframe.requestFullscreen();
      } else if (iframe.mozRequestFullScreen) { /* Firefox */
        iframe.mozRequestFullScreen();
      } else if (iframe.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
        iframe.webkitRequestFullscreen();
      } else if (iframe.msRequestFullscreen) { /* IE/Edge */
        iframe.msRequestFullscreen();
      }
    }
  };
  const handleImageChange = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFeaturePreImg(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const brochureHandle = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setBrochure(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const avaHandle = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setAvaPlan(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleVideo = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setVideoPreImg(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleMultipleImage = (files) => {
    if (files && files.length > 0) {
      const imageResults = []; // Array to store the results of reading each image
      files.forEach(file => {
        const reader = new FileReader();
        reader.onloadend = () => {
          imageResults.push(reader.result); // Store the result of reading the image
          if (imageResults.length === files.length) {
            // If all images have been processed, set the state with the array of results
            setMultipleImage(imageResults);
          }
        };
        reader.readAsDataURL(file);
      });
    }
  };
  // const floorData = propData?.inputList
  // const [floorPlans, setFloorPlans] = useState([]);
  // console.log(floorPlans, "floorPlans");
  // const handleFloorPdf = (files) => {
  //   if (files && files.length > 0) {
  //     const imageResults = []; // Array to store the results of reading each image
  //     files.forEach(file => {
  //       const reader = new FileReader();
  //       reader.onloadend = () => {
  //         imageResults.push(reader.result); // Store the result of reading the image
  //         if (imageResults.length === files.length) {
  //           // If all images have been processed, set the state with the array of results
  //           setFloorPlans(imageResults);
  //         }
  //       };
  //       reader.readAsDataURL(file);
  //     });
  //   }
  // };

  // const floorHandle = (floorData) => {
  //   const floorFiles = inputList.map(item => item.floor_pdf).filter(file => file instanceof File);
  //   handleFloorPdf(floorFiles);
  // };







  useEffect(() => {
    if (propData?.imgFile) {
      handleImageChange(propData?.imgFile);
    }
    if (propData?.videoData) {
      handleVideo(propData?.videoData);
      setPropVideo(propData?.videoData)
    }
    if (propData?.imageData) {
      handleMultipleImage(propData?.imageData);
    }
    if (propData?.brochureFile) {
      brochureHandle(propData?.brochureFile);
    }
    if (propData?.avaFile) {
      avaHandle(propData?.avaFile);
    }
    if (propData?.inputList) {
      setFloorData(propData?.inputList);
    }
    if (propData?.bhkDetails) {
      setBHKData(propData?.bhkDetails);
    }
  }, [propData]);

  useEffect(() => {
    setConfirmImages(propData?.imageData)
    setBhkCount(propData?.bhkCount)
    setConfirmAmenities(propData?.amenities)
    setStreetAddress(propData?.streetAddress)
    setPropertyName(propData?.propertyName)
    setDLDNumber(propData?.dldNumber)
    setBuildingType(propData?.propertyBuildingType)
    setPropertyType(propData?.propertyPropType)
    setPropertySize(propData?.propertySize)
    setProjectWebsite(propData?.propertyWebsite)
    setCountry(propData?.country)
    setPaymentPlan(propData?.paymentPlan)
    setPaymentPlanUrl(propData?.paymentPlanUrl)
    setState(propData?.state)
    setCity(propData?.city)
    setArea(propData?.area)
    setDescription(propData?.description)
    setBathroom(propData?.bathroomNumber)
    setBedroom(propData?.bedroomNumber)
    setConstructionStatus(propData?.propertyConstStatus)
    setLaunchDate(propData?.launchDate)
    setIsNewProperty(propData?.isNewProperty);
    setIsProperty(propData?.isProperty);
    setConstructionStartDate(propData?.constructionStartDate)
    setEstimatedCompletion(propData?.estimateDate)
    setPropertyLabel(propData?.propertyPropLabel)
    setLeasePrice(propData?.leasePrice)
    setPropertyPrice(propData?.salePrice)
    // setOwnerShip(propData?.propertyOwnership)
    setPropertyStatus(propData?.status)
    // setInspectionFrom(propData?.inspection_from)
    // setInspectionTo(propData?.inspection_to)
    setNoFloor(propData?.numberBuilding)
    setUploadFeaturedImage(propData?.imgFile)
    setUploadAvailabilityPlan(propData?.avaFile)
    setUploadProjectBrochure(propData?.brochureFile)

  }, [propData])




  useEffect(() => {
    if (typeof propertyPrice === 'string' && propertyPrice.includes(',')) {
      setNewSalePrice(propertyPrice.replace(/,/g, ""));
    } else {
      // handle other types or cases where leasePrice doesn't contain commas
      // for example, setting newLeasePrice to leasePrice if it's not a string
      setNewSalePrice(propertyPrice);
    }
  }, [propertyPrice]);
  useEffect(() => {
    if (typeof leasePrice === 'string' && leasePrice.includes(',')) {
      setNewLeasePrice(leasePrice.replace(/,/g, ""));
    }
    else if (leasePrice === '') {
      setNewLeasePrice(null);
    }
    else {
      setNewLeasePrice(leasePrice);
    }
  }, [leasePrice]);

  /* ****************add property api POST************* */

  const submitProperty = async () => {
    try {
      if (confirmation ===false) {
        toast.error("Accept our terms and policies to submit your property");
        return; // Return early if confirmation is false
      }
      setLoading(true);
      const formData = new FormData();
      console.log(formData, "formData");
      confirmImages.forEach((image) => {
        formData.append('property_images', image);
      });
      floorData.forEach(({ name, no_of_unit, floor_pdf }) => {
        formData.append('floor', JSON.stringify({ name, no_of_unit }));
        formData.append('floor_pdf', floor_pdf);
      });
      bhkData.forEach(({ name, size, file }) => {
        formData.append('bhk', JSON.stringify({ name, size }));
        formData.append('bhk_pdf', file);
      });
      formData.append("amenities", JSON.stringify(confirmAmenities) || "");
      formData.append("bhk_count", bhkCount || "");
      formData.append("street_address", streetAddress || "");
      formData.append("property_name", propertyName || "");
      formData.append("dld_number", dldNumber || "");
      formData.append("lat", lat || "");
      formData.append("lng", lng || "");
      formData.append("building_type", buildingType || "");
      formData.append("bedroom", bedroom || "");
      formData.append("bathroom", bathroom || "");
      formData.append("property_type", propertyType || "");
      formData.append("property_size", propertySize || "");
      formData.append("project_website", projectWebsite || "");
      formData.append("country", country || "");
      formData.append("state", state || "");
      formData.append("city", city || "");
      formData.append("no_of_floor", noFloor || "");
      formData.append("area", area || "");
      formData.append("is_property", isProperty);
      formData.append("is_new_property", isNewProperty);
      formData.append("description", description || "");
      formData.append("construction_status", constructionStatus || "");
      formData.append("launch_date", launchDate || "");
      formData.append("construction_start_date", constructionStartDate || "");
      formData.append("estimated_completion", estimatedCompletion || "");
      formData.append("property_label", propertyLabel || "");
      formData.append("lease_price_per_mo", newLeasePrice || 0);
      formData.append("property_price", newSalePrice || 0);
      // formData.append("ownership", ownerShip || "");
      formData.append("property_status", propertyStatus || "");
      // formData.append("inspection_from", inspectionFrom || "");
      // formData.append("inspection_to", inspectionTo || "");
      formData.append("upload_featured_image", uploadFeaturedImage || "");
      formData.append("payment_plan", paymentPlan || "");
      formData.append("property_video", propVideo || "");
      formData.append("upload_availability_plan", uploadAvailabilityPlan || "");
      formData.append("upload_project_brochure", uploadProjectBrochure || "");
      console.log(formData, "this is form Dat")
      const response = await axiosInstance.post(`${API_URL}/properties/post/`, formData);
      if (response.status === 201) {
        const responseData = response.data;
        toast.success("Property added Successfully")
        setLoading(false)
        navigate('/property')
      } else {
        setLoading(false)
        toast.error("Something went wrong")
      }

    } catch (error) {
      setLoading(false)
    }
  };






  const editProperty = () => {
    navigate('/property/add/', { state: { propData: propData, lat: lat, lng: lng, isProperty: isProperty } });
  }
  // const editProperty = () =>{
  //   navigate('/property/kind/' , {});
  // }
  useEffect(() => {
    window?.scrollTo(0, 0);
  }, [])



  return (
    <>
      {loading ? <Loader /> : null}
      <Header />
      <div style={{
        display: 'block',

        position: 'fixed',
        top: '50%',
        left: '50%',
        width: "30%",
        height: "25%",
        transform: 'translate(-50%, -50%)',
        background: "transparent",
        backgroundColor: "rgba(51, 51, 51, 0.8)", /* Use RGBA for semi-transparent background */
        padding: '20px',
        borderRadius: '5px',
        zIndex: "1111"
      }}> <h2 className="fw-600 text-uppercase  text-center text-white">Preview Property</h2>
        <div className="d-flex justify-content-center ">
          <button onClick={editProperty} className="btn text-white bg-blue hover-saffron" style={{ marginRight: '10px', marginLeft: "15px" }}>Edit Property</button>
          <button onClick={submitProperty} className="btn text-white bg-blue hover-saffron" style={{ width: "135px" }} >Submit</button>
        </div>
        <div className="d-flex">
          <div className="mt-3">
            <input
              type="checkbox"
              name="confirm"
              checked={confirmation}
              onChange={(e) => setConfirmation(e.target.checked)}
            /></div>
          <p className="text-white mt-3 fw-600 ml-2"> I have read and agree to abide by the terms and policies outlined for property submission.</p>
        </div>
      </div>
      <main id="content-details" style={{ marginTop: "100px" }}>
        <section>
          { }

          <div className="container-fluid">
            <div className="position-relative" data-bs-animate="zoomIn">
              <div className="row galleries m-n1">
                <div className="col-lg-6 p-1">
                  <div className="item item-size-4-3">
                    <div
                      className="card p-0 hover-zoom-in "

                    >
                      <Link
                        className="card-img"
                        data-gtf-mfp="true"
                        data-gallery-id="01"
                      >
                        <img
                          className="height-705"
                          src={featurePreImg} alt="Featured Image"

                        />
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 p-1">
                  <div className="row m-n1">
                    {propData?.videoData ? (<>

                      {multipleImage.slice(0, 3).map((dataUrl, index) => (
                        <div className="col-md-6 p-1" key={index}>
                          <div className="item item-size-4-3">
                            <div className="card p-0 hover-zoom-in">
                              <Link
                                className="card-img"
                                data-gtf-mfp="true"
                                data-gallery-id="01"
                              >
                                <img
                                  className="height-350"
                                  src={dataUrl}
                                  alt={`Image ${index}`}
                                />
                              </Link>
                            </div>
                          </div>
                        </div>
                      ))}
                      <div className="col-md-6 p-1">
                        <div className="item item-size-4-3">
                          <div
                            className="card p-0 hover-zoom-in"
                          >
                            {/* <div className="overlay-2" >

                              <img
                                src="../../images/play-button.png"
                                alt="Play"
                                style={{
                                  width: '50px', // adjust size as needed
                                  height: '50px',
                                  position: 'absolute',
                                  top: '43%',
                                  left: '43%',
                                  // transform: 'translate(-50%, -50%)',
                                }}
                              />


                            </div> */}
                            {videoPreImg &&
                              <video controls className="add-border-other-2">
                                <source src={videoPreImg} type="video/mp4" />
                                Your browser does not support the video tag.
                              </video>
                            }
                          </div>
                        </div>
                      </div>

                    </>) : (<>

                      {multipleImage.slice(0, 4).map((dataUrl, index) => (
                        <div className="col-md-6 p-1" key={index}>
                          <div className="item item-size-4-3">
                            <div className="card p-0 hover-zoom-in">
                              <Link
                                className="card-img"
                                data-gtf-mfp="true"
                                data-gallery-id="01"
                              >
                                <img
                                  className="height-350"
                                  src={dataUrl}
                                  alt={`Image ${index}`}
                                />
                              </Link>
                            </div>
                          </div>
                        </div>
                      ))}


                    </>)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="primary-content bg-f3f3f3">
          <div className="container-fluid px-15">
            <div className="row pt-5">
              <article className="col-lg-9 col-md-7 ">
                <section className=" border-bottom ">
                  <div className="d-flex justify-content-between">
                    <div className="">

                      {propData?.propertyPropLabel === "9c9cd2a0-ef4f-4151-b7ab-17496bd3d6c0" ? (<>
                        <p className="list-inline-item badge badge-orange mr-3 py-2 px-2">
                          For Sale
                        </p>
                      </>) : (<>
                        <p className="list-inline-item badge badge-orange mr-3 py-2 px-2">
                          For Rent
                        </p>
                      </>)}

                    </div>

                    <div className="nav-item dropdown mine-tab">
                      <button className=" nav-link dropdown-toggle btn bg-blue text-white hover-saffron"
                        data-bs-toggle="dropdown"
                        aria-expanded="false">
                        Share
                        <i className=" ml-1 fas fa-share"></i>
                      </button>
                      <div className="dropdown-menu ">
                        <WhatsappShareButton >
                          <WhatsappIcon size={32} round={true} className="dropdown-item" />
                        </WhatsappShareButton>
                        <FacebookShareButton >
                          <FacebookIcon size={32} round={true} className="dropdown-item" />
                        </FacebookShareButton>
                        <EmailShareButton >
                          <EmailIcon size={32} round={true} className="dropdown-item" />
                        </EmailShareButton>
                        <TelegramShareButton >
                          <TelegramIcon size={32} round={true} className="mdropdown-item" />
                        </TelegramShareButton>
                        <TwitterShareButton >
                          <XIcon size={32} round={true} className="dropdown-item" />
                        </TwitterShareButton>
                        <LinkedinShareButton >
                          <LinkedinIcon size={32} round={true} className="dropdown-item" />
                        </LinkedinShareButton>
                      </div>
                    </div>
                  </div>
                  <div className="d-sm-flex justify-content-sm-between ">
                    <div style={{ width: "60%" }}>
                      <h1 className="fs-35 font-weight-600 lh-15 text-heading">
                        {propData?.propertyName}
                      </h1>




                      <p className="mb-0 fs-18 ">
                        <i className="fal fa-map-marker-alt mr-2"></i>
                        {propData?.streetAddress}
                      </p>
                    </div>

                    <div className="mt-2 text-lg-right">
                      {propData?.salePrice ? (<>
                        <h2>Price: AED {" "}{propData?.salePrice}</h2>
                      </>) : (<>
                        <h2>Price: AED {" "}{propData?.leasePrice}/<span className="fs=16">month</span></h2>
                      </>)}

                      {/* {propertyData?.building_type_name === "00eb5d1b-1d34-4ebb-84d8-aa6d0a843419" ? (
                        <>
                          <div className="d-flex ">
                            <div className="d-block">
                              <p className="fs-18 mb-0">
                                {propertyData?.bedroom}
                              </p>
                              <img
                                className="sz-32"
                                src="../../images/bed.png"
                                alt="property-type"
                              />
                              <p className="ml-5">Bedroom</p>
                            </div>
                            <div className="d-block px-5">
                              <p className="fs-18 mb-0">
                                {propertyData?.bathroom}
                              </p>
                              <img
                                className="sz-32"
                                src="../../images/bathtub.png"
                                alt="property-type"
                              />
                              <p className="ml-5">Bathroom</p>
                            </div>
                          </div>
                        </>
                      ) : null} */}
                    </div>
                  </div>
                </section>

                <section className="pb-2 pt-5">
                  <div className=" ">
                    <div className="collapse-tabs mb-5 rounded-15">
                      <ul
                        className="nav nav-tabs text-uppercase d-md-inline-flex agent-details-tabs"
                        role="tablist"
                      >
                        <li className="nav-item">
                          <Link
                            to="#description"
                            className=" text-blue nav-link active  fs-16 padding-r-5"
                            data-bs-toggle="tab"
                            role="tab"
                          >
                            Description
                          </Link>
                        </li>
                        <>
                          <li className="nav-item ml-0">
                            <Link
                              to="#neighbourhood"
                              className="nav-link text-blue fs-16 padding-r-5"
                              data-bs-toggle="tab"
                              role="tab"
                            >
                              Nearby Places
                            </Link>
                          </li>

                          <li className="nav-item ml-0">
                            <Link
                              to="#calculator"
                              className="nav-link text-blue fs-16 padding-r-5"
                              data-bs-toggle="tab"
                              role="tab"
                            >
                              Calculator
                            </Link>
                          </li>
                        </>
                      </ul>
                      <div className="tab-content shadow-none  bg-white">
                        <div
                          className="tab-pane tab-pane-parent fade show active"
                          id="description"
                          role="tabpanel"
                        >
                          <div className="card border-0 bg-transparent">
                            <div className="card-body p-0">
                              <h2 className="card-title fs-22 lh-15 mb-1 text-dark">
                                About property
                              </h2>
                              {propData?.description ? (<>
                                <p className="card-text lh-214 mb-3 fs-18 ws-3">
                                  {propData?.description}
                                </p>
                              </>) : null}


                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane tab-pane-parent fade"
                          id="neighbourhood"
                          role="tabpanel"
                        >
                          <div className="card ">
                            <div className="">
                              <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d30237.28615539878!2d55.16698374778278!3d25.11814187408713!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1709205104695!5m2!1sen!2sin"
                                width="100%" height="450"
                                allowfullscreen=""
                                loading="lazy" referrerpolicy="no-referrer-when-downgrade">

                              </iframe>
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane tab-pane-parent fade"
                          id="calculator"
                          role="tabpanel"
                        >
                          <div className="card border-0 bg-transparent">
                            <div className="card-body p-0">
                              <h2 className="fs-22">Mortgage Calculator</h2>
                              <p className="fs-16">You can try below:</p>
                              <div className="row">
                                <div className="col-lg-3">
                                  <Link
                                    className="fs-16 text-gray hover-saffron-text"
                                    to="https://www.bankfab.com/en-ae/personal/mortgages/mortgage-calculator"
                                    target="black"
                                  >
                                    <img
                                      src="../../images/fab.png"
                                      alt="image"
                                    />
                                  </Link>
                                </div>
                                <div className="col-lg-3">
                                  <Link
                                    className="fs-16 text-gray hover-saffron-text"
                                    to="https://www.cbd.ae/mortgage-loan-calculator"
                                    target="black"
                                  >
                                    <img
                                      src="../../images/cbd.png"
                                      alt="image"
                                    />
                                  </Link>
                                </div>

                                <div className="col-lg-3">
                                  <Link
                                    className="fs-16 text-gray hover-saffron-text"
                                    to="https://www.mymoneysouq.com/calculators/mortgage-loan-calculator"
                                    target="black"
                                  >
                                    <img
                                      src="../../images/mymoneysouq.png"
                                      alt="image"
                                    />
                                  </Link>
                                </div>

                                <div className="col-lg-3">
                                  <Link
                                    className="fs-16 text-gray hover-saffron-text"
                                    to="https://www.emiratesnbd.com/en/loans/home-loans"
                                    target="black"
                                  >
                                    <img
                                      src="../../images/emiratesnbd.png"
                                      alt="image"
                                    />
                                  </Link>
                                </div>
                              </div>
                              <h2>Disclaimer</h2>
                              <p>
                                {" "}
                                Information Dirhamperfoot.ae is not responsible
                                for any offers, prices or terms listed on listed
                                mortgage/loan providers. The end-user is
                                self-responsible for any loans or mortgages
                                offered by listed providers. The information
                                provided on this website is for general
                                education purposes only and is not intended to
                                constitute specialist or personal advice. This
                                website has been prepared without taking into
                                account your objectives, financial situation or
                                needs. Dirhamperfoot can connect you with our
                                referral who can talk to you about home loans
                                from a range of lenders. * Note: the home loan
                                with the lowest current interest rate is not
                                necessarily the most suitable for your
                                circumstances; you may not qualify for that
                                particular product, the product may not include
                                all the relevant features, and not all products
                                are available in all states and territories.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section className="border-bottom">
                  <h4 className="fs-22 text-heading mb-6">
                    Facts and Features
                  </h4>
                  <div className="row">

                    <div className="col-lg-3 col-sm-4 mb-6">
                      <div className="media">
                        <div className="p-2 shadow-xxs-1 rounded-lg mr-2">
                          <div className="icon icon-family fs-20 text-primary">
                            <img
                              className="sz-32"
                              src="../../images/d-1.png"
                              alt="property-type"
                            />
                          </div>
                        </div>
                        <div className="media-body">
                          <h5 className="my-1 fs-18 text-uppercase font-weight-bold letter-spacing-093 font-weight-normal">
                            Days On Market
                          </h5>
                          <p className="mb-0 fs-16  text-heading">
                            2 Days
                          </p>
                        </div>
                      </div>
                    </div>

 <div className="col-lg-3 col-sm-4 mb-6">
                      <div className="media">
                        <div className="p-2 shadow-xxs-1 rounded-lg mr-2">
                          <div className="icon icon-family fs-20 text-primary">
                            <img
                              className="sz-32"
                              src="../../images/dubai_land.jpg"
                              alt="property-type"
                            />
                          </div>
                        </div>
                        <div className="media-body">
                          <h5 className="my-1 fs-18 text-uppercase font-weight-bold letter-spacing-093 font-weight-normal">
                            DLD Permit Number
                          </h5>
                          <p className="mb-0 fs-16  text-heading">
                          {propData?.dldNumber}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-4 mb-6">
                      <div className="media">
                        <div className="p-2 shadow-xxs-1 rounded-lg mr-2">
                          <div className="icon icon-family fs-20 text-primary">
                            <img
                              className="sz-32"
                              src="../../images/b-10.png"
                              alt="property-type"
                            />
                          </div>
                        </div>
                        <div className="media-body">
                          <h5 className="my-1 fs-18 text-uppercase font-weight-bold letter-spacing-093 font-weight-normal">
                            Project Website
                          </h5>
                          <Link

                            target="blank"
                            className="mb-0 fs-16  text-heading"
                          >
                            Click Link
                          </Link>
                        </div>
                      </div>
                    </div>


                    {/* <div className="col-lg-3 col-sm-4 mb-6">
                      <div className="media">
                        <div className="p-2 shadow-xxs-1 rounded-lg mr-2">
                          <div className="icon icon-family fs-20 text-primary">
                            <img
                              className="sz-32"
                              src="../../images/b-1.png"
                              alt="property-type"
                            />
                          </div>
                        </div>
                        <div className="media-body">
                          <h5 className="my-1 fs-18 text-uppercase font-weight-bold letter-spacing-093 font-weight-normal">
                            Property Type
                          </h5>
                          <p className="mb-0 fs-16  text-heading">
                            propertyData type name
                          </p>
                        </div>
                      </div>
                    </div>
 */}

                    <div className="col-lg-3 col-sm-4 mb-6">
                      <div className="media">
                        <div className="p-2 shadow-xxs-1 rounded-lg mr-2">
                          <div className="icon icon-family fs-20 text-primary">
                            <img
                              className="sz-32"
                              src="../../images/b-4.png"
                              alt="property-type"
                            />
                          </div>
                        </div>
                        <div className="media-body">
                          <h5 className="my-1 fs-18 font-weight-bold text-uppercase letter-spacing-093 font-weight-normal">
                            SQFT
                          </h5>
                          <p className="mb-0 fs-16 text-heading">
                            {propData?.propertySize}
                          </p>
                        </div>
                      </div>
                    </div>

                    {propData?.bedroomNumber ? (<>
                      <div className="col-lg-3 col-sm-4 mb-6">
                        <div className="media">
                          <div className="p-2 shadow-xxs-1 rounded-lg mr-2">
                            <div className="icon icon-family fs-20 text-primary">
                              <img
                                className="sz-32"
                                src="../../images/hotel.png"
                                alt="property-type"
                              />
                            </div>
                          </div>

                          <div className="media-body">
                            <h5 className="my-1 fs-18 font-weight-bold text-uppercase letter-spacing-093 font-weight-normal">
                              Bedroom
                            </h5>
                            <p className="mb-0 fs-16  text-heading">
                              {propData?.bedroomNumber}
                            </p>
                          </div>

                        </div>
                      </div>
                    </>) : null}
                    {propData?.bathroomNumber ? (<>
                      <div className="col-lg-3 col-sm-4 mb-6">
                        <div className="media">
                          <div className="p-2 shadow-xxs-1 rounded-lg mr-2">
                            <img
                              className="sz-32"
                              src="../../images/bathtub.png"
                              alt="property-type"
                            />
                          </div>
                          <div className="media-body">
                            <h5 className="my-1 fs-18 font-weight-bold text-uppercase letter-spacing-093 font-weight-normal">
                              Bathroom
                            </h5>
                            <p className="mb-0 fs-16  text-heading">
                              {propData?.bathroomNumber}
                            </p>
                          </div>
                        </div>
                      </div>

                    </>) : null}
                    {/* {propData?.ownership ? (<>
                      <div className="col-lg-3 col-sm-4 mb-6">
                        <div className="media">
                          <div className="p-2 shadow-xxs-1 rounded-lg mr-2">
                            <img
                              className="sz-32"
                              src="../../images/key.png"
                              alt="property-type"
                            />
                          </div>
                          <div className="media-body">
                            <h5 className="my-1 fs-18 font-weight-bold text-uppercase letter-spacing-093 font-weight-normal">
                              Ownership
                            </h5>
                            <p className="mb-0 fs-16  text-heading">
                              self
                            </p>
                          </div>
                        </div>
                      </div>
                    </>) : null} */}

                    {/* {propData?.construction_status ? (<>
                      <div className="col-lg-3 col-sm-4 mb-6">
                        <div className="media">
                          <div className="p-2 shadow-xxs-1 rounded-lg mr-2">
                            <img
                              className="sz-32"
                              src="../../images/b-11.png"
                              alt="property-type"
                            />
                          </div>
                          <div className="media-body">
                            <h5 className="my-1 fs-18 font-weight-bold text-uppercase letter-spacing-093 font-weight-normal">
                              Const.Status
                            </h5>
                            <p className="mb-0 fs-16  text-heading">
                              {propData?.construction_status}
                            </p>
                          </div>
                        </div>
                      </div>
                    </>) : null} */}

                    {propData?.constructionStartDate ? (<>
                      <div className="col-lg-3 col-sm-4 mb-6">
                        <div className="media">
                          <div className="p-2 shadow-xxs-1 rounded-lg mr-2">
                            <div className="icon icon-family fs-20 text-primary">
                              <img
                                className="sz-32"
                                src="../../images/hook.png"
                                alt="property-type"
                              />
                            </div>
                          </div>
                          <div className="media-body">
                            <h5 className="my-1 fs-18 font-weight-bold text-uppercase letter-spacing-093 font-weight-normal">
                              const.Start Date
                            </h5>
                            <p class="mb-0 fs-16  text-heading">
                              {moment(propData?.constructionStartDate).format(
                                "DD-MM-YYYY"
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </>) : null}
                    {propData?.estimateDate ? (<>
                      <div className="col-lg-3 col-sm-4 mb-6">
                        <div className="media">
                          <div className="p-2 shadow-xxs-1 rounded-lg mr-2">
                            <div className="icon icon-family fs-20 text-primary">
                              <img
                                className="sz-32"
                                src="../../images/completion_date.png"
                                alt="property-type"
                              />
                            </div>
                          </div>
                          <div className="media-body">
                            <h5 className="my-1 fs-18 font-weight-bold text-uppercase letter-spacing-093 font-weight-normal">
                              Completion Date
                            </h5>
                            <p class="mb-0 fs-16  text-heading">
                              {moment(propData?.estimateDate).format(
                                "DD-MM-YYYY"
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </>) : null}
                    {propData?.launchDate ? (<>
                      <div className="col-lg-3 col-sm-4 mb-6">
                        <div className="media">
                          <div className="p-2 shadow-xxs-1 rounded-lg mr-2">
                            <div className="icon icon-family fs-20 text-primary">
                              <img
                                className="sz-32"
                                src="../../images/mortgage.png"
                                alt="property-type"
                              />
                            </div>
                          </div>
                          <div className="media-body">
                            <h5 className="my-1 fs-18 font-weight-bold text-uppercase letter-spacing-093 font-weight-normal">
                              Delivery Date
                            </h5>
                            <p class="mb-0 fs-16  text-heading">
                              {moment(propData?.launchDate).format(
                                "DD-MM-YYYY"
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </>) : null}
                  </div>
                </section>



                {paymentPlan ? (
                            <div className="card-body py-4 py-md-0 px-0">
                                <div className="card mb-6">
                                    <div className="card-body p-6">
                                        <h3 className="card-title mb-0 text-heading fs-22 lh-15">
                                            Payment Plan
                                        </h3>
                                        <div className="col-lg-12">

                                            <div className="py-3 border-bottom">

                                                <iframe
                                                    // ref={iframeRefs.current[index]}
                                                    src={paymentPlanUrl}
                                                    style={{ width: "100%", height: "500px" }}

                                                ></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}




                {propData?.brochureFile ? (
                  <section className="">
                    <>
                      <div className="py-3 border-bottom">
                        <Accordion allowOneExpanded preExpanded={['item1']}>
                          <AccordionItem uuid="item1">
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                <div
                                  className="floor-title"
                                  onClick={toggleAccordion}
                                >
                                  <h4>

                                    Project Brochure
                                    {isOpen ? (

                                      <i class="bi bi-chevron-up"></i>
                                    ) : (
                                      <i class="bi bi-chevron-down"></i>
                                    )}
                                  </h4>
                                </div>
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <div className="floor-plan-sr">
                                <div className="card border-0 shadow-xxs-2">
                                  <div
                                    id="collapse-01"
                                    className={`collapse ${isOpen ? "show" : ""}`}
                                    aria-labelledby="floor-plans-01"
                                    data-parent="#accordion-01"

                                  >
                                    <div className="card-body  mb-3 ">
                                      <div className="">
                                        <iframe
                                          ref={iframeReff}
                                          src={brochure}
                                          style={{ width: "100%", height: "500px" }}
                                          title="Project Brochure"
                                        ></iframe>
                                      </div>
                                      <div className="">
                                        <button className="btn bg-blue text-white hover-saffron" onClick={toggleFull}>Full Screen</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </AccordionItemPanel>
                          </AccordionItem>
                        </Accordion>
                      </div>
                    </>
                  </section>
                ) : null}

                {propData?.avaFile ? (
                  <section className="">
                    <>
                      <div className="py-3 border-bottom">
                        <Accordion allowOneExpanded preExpanded={['item2']}>
                          <AccordionItem uuid="item2">
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                <div
                                  className="floor-title"
                                  onClick={handleAccordionToggle}
                                >
                                  <h4>

                                    Availability Plan
                                    {isAccordionOpen ? (
                                      <i className="bi bi-chevron-up margin-auto"></i>
                                    ) : (
                                      <i className="bi bi-chevron-down margin-auto"></i>
                                    )}</h4>
                                </div>
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <div className="floor-plan-sr">
                                <div className="card border-0 shadow-xxs-2">
                                  <div
                                    id="collapse-02"
                                    className={`collapse ${isAccordionOpen ? "show" : ""
                                      }`}
                                    aria-labelledby="floor-plans-01"
                                    data-parent="#accordion-02"
                                  >
                                    <div className="card-body mb-3 ">
                                      <div className="">
                                        <iframe
                                          ref={iframeRef}
                                          src={avaPlan}
                                          style={{ width: "100%", height: "500px" }}
                                          title="Project Brochure"
                                        ></iframe>
                                      </div>
                                      <div className="">
                                        <button className="btn bg-blue text-white hover-saffron" onClick={toggleFullScreen}>Full Screen</button>
                                      </div>

                                    </div>
                                  </div>
                                </div>
                              </div>
                            </AccordionItemPanel>
                          </AccordionItem>
                        </Accordion>
                      </div>
                    </>
                  </section>
                ) : null}

{propData && propData?.bhkDetails?.length > 0 && (
                  <section className="">
                    <div className="py-3 border-bottom">

                      <h4 className="fs-22 text-heading mb-4">Unit Plans</h4>
                      <Accordion allowOneExpanded preExpanded={['item-0']}>
                        {propData?.bhkDetails?.map((floors, index) => (
                          <AccordionItem key={index} uuid={`item-${index}`}>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                <div className="floor-title" >
                                  <h4>
                                    {floors?.name}{" "} with {" "}{floors?.size} Size.
                                  </h4>
                                </div>
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <div className="floor-plan-sr">
                                <div className="card border-0 shadow-xxs-2">
                                  <div>
                                    <div className="card-body mb-3">
                                      <div className="">
                                        <iframe
                                          ref={iframeBHK.current[index]}
                                          src={floors?.file_url}
                                          style={{ width: "100%", height: "500px" }}
                                          title="Floor Plan"
                                        ></iframe>
                                      </div>
                                      <div className="">
                                        <button className="btn bg-blue text-white hover-saffron" onClick={() => toggleBHKFull(index)}>Full Screen</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </AccordionItemPanel>
                          </AccordionItem>
                        ))}
                      </Accordion>
                    </div>
                  </section>
                )}
                {propData && propData?.inputList?.length > 0 && (
                  <section className="">
                    <div className="py-3 border-bottom">

                      <h4 className="fs-22 text-heading mb-4">Floor Plans</h4>
                      <Accordion allowOneExpanded preExpanded={['item-0']}>
                        {propData?.inputList?.map((floors, index) => (
                          <AccordionItem key={index} uuid={`item-${index}`}>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                <div className="floor-title" >
                                  <h4>
                                    {floors?.name}{" "} with {" "}{floors?.no_of_unit} units.
                                  </h4>
                                </div>
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <div className="floor-plan-sr">
                                <div className="card border-0 shadow-xxs-2">
                                  <div>
                                    <div className="card-body mb-3">
                                      <div className="">
                                        <iframe
                                          ref={iframeRefs.current[index]}
                                          src={floors?.pdf_url}
                                          style={{ width: "100%", height: "500px" }}
                                          title="Floor Plan"
                                        ></iframe>
                                      </div>
                                      <div className="">
                                        <button className="btn bg-blue text-white hover-saffron" onClick={() => toggleFloorFull(index)}>Full Screen</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </AccordionItemPanel>
                          </AccordionItem>
                        ))}
                      </Accordion>
                    </div>
                  </section>
                )}


                <section className="py-6 border-bottom">
                  <div className="card border-0">
                    <div className="card-body p-5">
                      <div className="row mb-6">
                        <div className="col-sm-4 mb-6 mb-sm-0">
                          <h2 className="fs-22 lh-2 text-heading mb-6">
                            Avarage Property Rating
                          </h2>

                          <div>
                            <p className="fs-40 text-heading font-weight-bold mb-6 lh-1">
                              4
                              <span className="fs-18 text-gray-light font-weight-normal">
                                /5
                              </span>
                            </p>

                            <ul className="list-inline">
                              <ReactStars
                                value="4"
                                edit={false}
                                size={40}
                                isHalf={true}
                                a11y={true}
                              />
                            </ul>
                          </div>

                        </div>

                      </div>

                      <h3 className="mt-5 border-top fs-16 lh-2 text-heading mb-2">
                        Write A Review
                      </h3>
                      <div className=" mb-5">
                        <div className="form-group mb-4 d-flex justify-content-start ">
                          <ReactStars
                            count={5}
                            size={36}
                            activeColor="#F6B500"
                            name="review"

                          />
                        </div>
                        <div className="form-group mb-6">
                          <textarea
                            className="form-control form-control-lg border-0"
                            placeholder="Your Review"
                            name="description"
                            rows="5"


                          ></textarea>
                        </div>
                        <button
                          type="button"
                          className="btn btn-lg bg-blue text-white hover-saffron px-10 mb-2"

                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* <div className="d-flex justify-content-between mt-3">
                    <div>
                      <a onClick={editProperty} className="btn bg-blue text-white hover-saffron">Edit Property</a>
                    </div>
                    <div>
                      <a onClick={submitProperty} className="btn bg-blue text-white hover-saffron">Confirm</a>
                    </div>
                  </div> */}
                </section>

              </article>
              <aside
                className="col-lg-3 col-md-5 primary-sidebar sidebar-sticky"
                id="sidebar"
              >
                <div className="primary-sidebar-inner">
                  {/* <div className="card border-0 widget-request-tour pt-5">
                    <div className="card-body  pb-5 pt-0">
                      <div className="pt-1 pb-0 px-0 d-block">
                        <div className="pt-5">
                          <div className="w-100 text-align-center  py-2">
                            <Link
                              className="navbar-brand"
                              to={`/profile/details/${propertyData?.user}`}
                            >
                              <img
                                className="profile-img border-rd-50 px-2 py-2"
                                src={userData?.profile}
                                alt="HomeID"
                                style={{ width: "150px" }}
                              />
                            </Link>
                            <h2 className="text-333 fs-22 lh-15">
                              {userData?.first_name} {userData?.last_name}
                            </h2>
                            {propertyData?.company ? (
                              <>
                                <Link
                                  to={`/company/details/${propertyData?.company}`}
                                  className=" fs-18 text-blue text-uppercase hover-saffron-text"
                                >
                                  {propertyData?.company_name}
                                </Link>
                              </>
                            ) : null}

                            {userData.get_language_name ? (
                              <p className="text-dark mb-2 fs-18">
                                Language:{" "}
                                {userData.get_language_name.join(", ")}
                              </p>
                            ) : null}
                            <OverlayTrigger
                              overlay={
                                <Tooltip id="tooltip-disabled">
                                  {userData?.mobile}
                                </Tooltip>
                              }
                            >
                              <Link
                                to={`tel:${userData?.mobile}`}
                                className="text-white bg-saffron mr-2  round-5"
                                style={{ padding: "7px 25px 9px 25px" }}
                              // onClick={() => handleCallAction({ ref: item?.prop_id, phone: item?.get_user_mobile, id: item?.id })}
                              >
                                <i className="bi bi-telephone"></i>
                              </Link>
                            </OverlayTrigger>
                            {userData?.whatsapp ? (
                              <ReactWhatsapp
                                className="btn btn-success mr-2 py-1"
                                number={userData?.whatsapp}
                                // onClick={() => handleWhatAppAction({ ref: item?.prop_id, phone: item?.get_user_whatsapp, id: item?.id })}
                                message="Hi"
                              >
                                <i class="bi bi-whatsapp"></i>
                              </ReactWhatsapp>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {/* <PropertyScore nearPlaceCount={nearPlaceCount} /> */}
                  {/* <PropertyScore lat={propertyData?.lat} lng={propertyData?.lng}/> */}
                  <div className="card border-0 widget-request-tour mt-5">
                    <div className=" d-block text-center  ">
                      <Link className="py-2 bg-blue text-white px-3">
                        I am Interested
                      </Link>
                    </div>
                    <div className="card-body pb-5 pt-0">
                      <form >
                        <div className="pt-1 pb-0 px-0 d-block">
                          <div className="pt-5">
                            <div className="form-group mb-2 position-add-validate-symbol">
                              <input
                                type="text"
                                className="form-control form-control-lg border-0"
                                placeholder="Your Name"

                              />
                              <span className="asterisk_input"></span>
                            </div>
                            <div className="form-group mb-2 position-add-validate-symbol">
                              <input
                                type="email"
                                className="form-control form-control-lg border-0"
                                placeholder="Your Email"

                              />
                              <span className="asterisk_input"></span>
                            </div>
                            <div className="form-group mb-2">
                              <input
                                type="text"
                                className="form-control form-control-lg border-0"
                                placeholder="Your Phone Number"
                                maxLength={10}

                              />
                            </div>
                            <div className="form-group mb-4">
                              <textarea
                                className="form-control border-0"
                                rows="4"
                                placeholder="Your Message?"

                              ></textarea>
                            </div>
                            <button
                              type="submit"
                              className="btn bg-blue text-white hover-saffron btn-lg btn-block rounded"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  {/*  {companydata ? (
                    <div className="card border-0 widget-request-tour mt-5 mb-5">
                      <div className="card-body pb-5 ">
                        <img
                          src={companydata.img}

                          alt={companydata?.company_name}
                        />
                        <Link
                          to={`/company/details/${companydata?.id}`}
                          className="d-block text-blue hover-saffron-text mt-2 fs-20"
                        >
                          {companydata?.company_name}
                        </Link>
                        <p className=" d-block fs-16 py-1">
                          <span className="fas fa-map-marker-alt mr-2"></span>
                          {companydata?.street_address}
                        </p>
                        <div className="mt-2">
                          <div className="fa fa-phone d-block fs-16 py-1">
                            <span className="ml-2 fs-16">
                              <CurrencyFormat
                                displayType={"text"}
                                value={companydata?.office_number}
                                format=" +###-##-#####"
                                mask="_"
                              />
                            </span>
                          </div>
                          <div className="fa fa-phone d-block fs-16 py-1">
                            <span className="ml-2 fs-16">
                              <CurrencyFormat
                                displayType={"text"}
                                value={companydata?.mobile_number}
                                format=" +###-##-#####"
                                mask="_"
                              />
                            </span>
                          </div>
                          <Link
                            to={companydata?.company_website}
                            target="blank"
                            className="fa fa-globe d-block fs-16 py-1 text-blue hover-saffron-text"
                          >
                            <span className="ml-2">Website</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  ) : null} */}
                </div>
              </aside>
            </div>
          </div>
        </div>
        {/*  <MyModal show={show} handleClose={handleClose} />
        <Modal show={isPlaying} onHide={() => setIsPlaying(false)} size="lg" >
          <div className="modal-content ">
            <div className=" f-right" >
              <button onClick={() => setIsPlaying(false)} type="button" className="close m-0 fs-24 mt-3 mr-5">
                <span aria-hidden="true">&times;</span>
              </button>
            </div> */}
        {/* <div className="modal-header border-0 p-0">
            </div> */}
        {/*  <iframe src={propertyData?.upload_video} alt="image" className="add-border-other-2" allow="autoplay; encrypted-media"
              allowFullScreen /> */}
        {/*  </div>
        </Modal> */}
      </main>
      <Footer />
    </>
  );
};

export default PreviewProperty;