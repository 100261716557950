import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import bgimage from "../images/skyline-2.png"
import homeimage from "../images/dpf-logo.png"
import axiosInstance from "../interceptor/axiosInstance";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Loader from "../Loader";
import { setupResponseInterceptor } from "../interceptor/responseInterceptor";
const ChangePassword = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [visibleOne, setVisibleOne] = useState(false);
  const [visibleTwo, setVisibleTwo] = useState(false);
  const [visibleThree, setVisibleThree] = useState(false);
  const [UpdateData, setUpdate] = useState([]);
  const [loading, setLoading] = useState(false)
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
    reset,
    setValue,
  } = useForm();
  const handleInput = (e) => {
    const name = e?.target?.name;
    const value = e?.target?.value;
    setUpdate({
      ...UpdateData,
      [name]: value,
    });
  };
  /* *********Api TO change password *********/

  const onSubmit = async () => {
    setLoading(true);

    try {
      setupResponseInterceptor(axiosInstance);
      const response = await axiosInstance.post(`/auth/users/set_password/`, UpdateData);
      if (response.status === 204) {
        toast.success("Your Password has been updated");
        setLoading(false);
        localStorage.removeItem('token');
        localStorage.removeItem('currentUser_id');
        localStorage.setItem('is_authenticated', true)
        window.location.href = '/login';
      }
     

      // Additional logic for a successful response, if needed
    } catch (error) {
      if (error.response.status === 400) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if (error.response.data?.current_password) {
          toast.error("Incorrect current password");
          setLoading(false);
        }
        if (error.response.data?.new_password) {
          toast.error(error.response.data?.new_password[0]);
          setLoading(false);
        }
        if (error.response.data?.non_field_errors) {
          toast.error("New password and confirm password should be same");
          setLoading(false);
        }
        
      } else if (error.request) {
        // The request was made but no response was received
        toast.error("No response received from the server. Please try again later.");
        
        setLoading(false);
      } else {
        // Something happened in setting up the request that triggered an Error
        toast.error("An unexpected error occurred. Please try again later.");
        
        setLoading(false);
      }
    }
  };
  return (
    <>
      {loading ? <Loader /> : null}
      <div class="form-body pl-10">
        <div className="form-content form-ch-content">
          <div className="form-items mt-20">
            <h3 className="card-title mb-0 text-heading lh-15 mb-5">
              Change password
            </h3>
            <form onSubmit={handleSubmit(onSubmit)} onReset={reset}>
              <div className="form-group mb-4">
              {/* password input */}
              <label htmlFor="password" className="sr-only">
              Current Password
              </label>
              <div className="input-group input-group-lg">
                <div className="input-group-prepend ">
                  <span className="input-group-text bg-gray-01 border-0 text-muted fs-18">
                    <i className="far fa-lock"></i>
                  </span>
                </div>
                <input
                  type={visibleThree ? "text" : "password"}
                  className="form-control border-0 shadow-none fs-13"
                  id="current_password"
                  name="current_password"
                  placeholder="Current Password"
                  {...register("current_password")}
                  onChange={handleInput}
                 
                  
                />
                <div className="input-group-append">
                  <span className="input-group-text bg-gray-01 border-0 text-body fs-18">
                    <i
                      className="far fa-eye-slash"
                      onClick={() => setVisibleThree(!visibleThree)}
                    ></i>
                  </span>
                </div>
              </div>
            </div>

 {/* <div className="form-row mx-n4"> */}
              <div className="form-group mb-4">
              {/* password input */}
              <label htmlFor="password" className="sr-only">
               New Password
              </label>
              <div className="input-group input-group-lg">
                <div className="input-group-prepend ">
                  <span className="input-group-text bg-gray-01 border-0 text-muted fs-18">
                    <i className="far fa-lock"></i>
                  </span>
                </div>
                <input
                  type={visibleTwo ? "text" : "password"}
                  className="form-control border-0 shadow-none fs-13"
                  id="new_password"
                  name="new_password"
                  placeholder="New Password"
                  {...register("new_password")}
                  onChange={handleInput}
                 
                  
                />
                <div className="input-group-append">
                  <span className="input-group-text bg-gray-01 border-0 text-body fs-18">
                    <i
                      className="far fa-eye-slash"
                      onClick={() => setVisibleTwo(!visibleTwo)}
                    ></i>
                  </span>
                </div>
              </div>
            </div>
             

            <div className="form-group mb-4">
              {/* password input */}
              <label htmlFor="password" className="sr-only">
              Confirm New Password
              </label>
              <div className="input-group input-group-lg">
                <div className="input-group-prepend ">
                  <span className="input-group-text bg-gray-01 border-0 text-muted fs-18">
                    <i className="far fa-lock"></i>
                  </span>
                </div>
                <input
                  type={visibleOne ? "text" : "password"}
                  className="form-control border-0 shadow-none fs-13"
                  id="re_new_password"
                  name="re_new_password"
                  placeholder="Confirm New Password"
                  {...register("re_new_password")}
                  onChange={handleInput}
                 
                  
                />
                <div className="input-group-append">
                  <span className="input-group-text bg-gray-01 border-0 text-body fs-18">
                    <i
                      className="far fa-eye-slash"
                      onClick={() => setVisibleOne(!visibleOne)}
                    ></i>
                  </span>
                </div>
              </div>
            </div>
              

              <div className="form-group col-md-6 col-lg-12 col-xxl-6 px-4">
                <button
                  type="submit"
                  className="btn text-white bg-blue hover-saffron"

                >
                  Update Password
                </button>
              </div>
            </form>
          </div>
        </div>

      </div>
      {/* </div> */}
    </>
  );
};

export default ChangePassword;
