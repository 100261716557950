import React, { useEffect, useRef, useState, createRef } from "react";
import axiosInstance from "../interceptor/axiosInstance";
import { Link, useNavigate, useParams } from "react-router-dom";
import Loader from "../Loader";
import CurrencyFormat from "react-currency-format";
import moment from "moment";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import Header from "../header/Header";
import Slider from "react-slick";
import { AnimationOnScroll } from "react-animation-on-scroll";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import ReactStars from "react-rating-stars-component";
import {Helmet} from "react-helmet"

import {
  EmailShareButton,
  FacebookShareButton,
  InstapaperShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import {
  EmailIcon,
  FacebookIcon,
  InstapaperIcon,
  LinkedinIcon,
  MailruIcon,
  TumblrIcon,
  TwitterIcon,
  WhatsappIcon,
  TelegramIcon,
  XIcon,
} from "react-share";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { Modal } from "react-bootstrap";
import ImageGalleryControl from "../imagesGallery/ImagesGallery";
import ConvertCurrency from "../currencyConverter/CurrencyConverter";
import axios from "axios";
import NearbyPropertiesMap from "./nearByProperty/NearbyPropertiesMap";
import NearbyPlacesMap from "./nearByProperty/NearbyPropertiesMap";
import Footer from "../footer/Footer";
import Pricegraph from "./nearByProperty/Pricegraph";
import ReactWhatsapp from "react-whatsapp";
import CallActionButton from "../callActionButton/CallActionButton";
import FavActionButton from "../favActionButton/FavActionButton";
import MyModal from "../landingPage/MyModal";
import Avatar from "react-avatar";
import PropertyScore from "./nearByProperty/PropertyScore";
import Chartgraph from "../landingPage/intellegence/Chartgraph";
import { tsPropertySignature } from "@babel/types";
import PhoneInput from "react-phone-number-input";

const API_URL = process.env.REACT_APP_API_URL;

const PropertyDetails = () => {
  moment.locale("en");
  const is_auth = localStorage.getItem("is_authenticated");
  const [nearPlaceCount, setNearPlaceCount] = useState([]);
  const navigate = useNavigate();
  const currentUrl = window.location.href;
  var settings = {
    dots: true,
    speed: 500,
    initialSlide: 0,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };
  const [favproperty, setfavproperty] = useState([]);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [propertyData, setPropertyData] = useState([]);

  const [propertytypedata, setpropertytypedata] = useState("");
  const [buildinglabelData, setbuildinglabelData] = useState("");
  const [buildingArea, setbuildingArea] = useState("");
  const [imageData, setImageData] = useState([]);

  const [loading, setLoading] = useState(false);
  const [user_id, setUserId] = useState("");

  const [userData, setUserData] = useState([]);
  const [reviewDescription, setReviewDescription] = useState();

  const [companydata, setcompanydata] = useState();
  const [relatingProperty, SetRelatingProperty] = useState([]);
  const [newPropertyId, setPropertyId] = useState();
  const [companySlug, setCompanySlug] = useState();
  const propertySlug = useParams();

  const [leadInput, setLeadInput] = useState([]);
  const [floorPlans, setFloorPlans] = useState([]);
  const [bhkDetails, setbhkDetails] = useState([]);
  console.log(bhkDetails, "bhkDetails");

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [index, setIndex] = useState(0);
  const [allProperties, setAllProperties] = useState([]);
  const [rateInput, setRateInput] = useState([]);
  const [reviewData, setreviewData] = useState([]);
  const [isOpen, setIsOpen] = useState(true);
  const [myphone, setmyphone] = useState();
  const [isAccordionOpen, setIsAccordionOpen] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);

  const handleOverlayClick = () => {
    setIsPlaying(!isPlaying);
  };
  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const handleAccordionToggle = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };
  const { register, handleSubmit, reset } = useForm();
  /* *********Api TO Get Favourite property *********/
  const getfavourite = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        `${API_URL}/properties/user/get/favourite/`
      );
      if ((response.status = 200)) {
        const responseData = response.data;
        setfavproperty(responseData);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      toast.error(" Please Contact Administrator");
    }
  };
  /* *********Api TO Get Favourite property *********/
  const getFloorPlans = async () => {
    try {
      const response = await axiosInstance.get(
        `${API_URL}/properties/floor/${newPropertyId}/`
      );
      if ((response.status = 200)) {
        const responseData = response.data;
        setFloorPlans(responseData);
      }
    } catch (err) {

    }
  };
  /* *********Api TO Get Favourite property *********/
  const getBHKDetails = async () => {
    try {
      const response = await axiosInstance.get(
        `${API_URL}/properties/bhk/${newPropertyId}/get/`
      )
      if ((response.status = 200)) {
        const responseData = response.data;
        setbhkDetails(responseData);
      }
    } catch (err) {

    }
  };
  // const toggleReplyForm = (itemId) => {
  //   setShowReplyForms((prev) => ({
  //     ...prev,
  //     [itemId]: !prev[itemId], // Toggle the state for the clicked item
  //   }));
  // };

  /* ****************lead handler ************* */

  const handleLead = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setLeadInput({ ...leadInput, [name]: value, properties: newPropertyId, mobile: myphone });
  };
  const handlephone = (e) => {
    setmyphone(e)
  }
  const onSubmit = async () => {
    try {
      const response = await axios.post(
        `${API_URL}/properties/user/contact/post/`,
        leadInput
      );
      const myresponse = response.data;

      toast.success("Lead Send successfully");
      reset();
      setmyphone();
    } catch (error) {
      toast.error("Something Went Wrong");
    }
  };

  /********Review Handler */
  const [reviewNumber, setReviewNumber] = useState(0);

  const ratingChanged = (newRating) => {
    setReviewNumber(newRating);
  };

  const handleRating = (e) => {
    // e.preventDefault();
    setRateInput({
      ...rateInput,
      description: reviewDescription,
      review: reviewNumber, // Use the value from ratingChanged directly
      properties: newPropertyId,
      user: user_id,
      is_active: false,
    });
  };
  useEffect(() => {
    handleRating();
  }, [reviewDescription]);
  const submitrating = async (event) => {
    if (localStorage.getItem("token")) {
      if (reviewNumber !== 0) {
        try {
          const response = await axiosInstance.post(
            `${API_URL}/properties/user/review/post/`,
            rateInput
          );
          const myresponse = response.data;

          toast.success("Review sent successfully");
          setReviewDescription("");
          setReviewNumber("");

          getReviewData();
        } catch (error) {
          toast.error("Review allready submitted");
        }
      } else {
        toast.error("Please Rate First");
      }
    } else {
      setShow(true);
    }
  };

  /* *******************get property data************/
  const getAllProperties = async () => {
    // setLoading(true);
    try {
      const response = await axios.get(
        `${API_URL}/properties/advance/filter/?is_new_property=true`
      );

      if (response.status === 200) {
        const responseData = response?.data;
        setAllProperties(responseData);

        // setLoading(false);
      } else {
      }
    } catch (err) {
      toast.error(" Please Contact Administrator");
    }
  };

  /* ****************api for property Data ************* */

  const getPropertyData = async () => {
    // setLoading(true);
    try {
      const response = await axios.get(
        `${API_URL}/properties/${propertySlug?.slug}/patch/`
      );
      if ((response.status = 200)) {
        const responseData = response?.data;
        setPropertyData(responseData);
        setPropertyId(responseData?.id);
        setbuildinglabelData(responseData?.property_label_name);
        setbuildingArea(responseData?.area);
        setpropertytypedata(responseData?.building_type_name);
        setImageData(responseData?.get_property_image);
        setUserId(responseData?.user);
        setCompanySlug(responseData?.company_slug)
        await getUserData(responseData?.user);
        // setLoading(false);
      } else {
      }
    } catch (err) {
      // toast.error(" Please Contact Administrator");
      navigate("/advance-search", {});
    }
  };

  /* ****************get user Data ************* */

  const getUserData = async (user_id) => {
    // setLoading(true);

    try {
      const response = await axios.get(
        `${API_URL}/account/user/profile/${user_id}/patch/`
      );
      if (response.status === 200) {
        const responseData = response.data;
        setUserData(responseData);
        await getCompanyData(responseData?.company);

        // setLoading(false);
      } else {
      }
    } catch (err) {
      toast.error(" Please Contact Administrator");
    }
  };

  /* ****************************Get Company Data *********  */

  const getCompanyData = async (companyid) => {
    try {
      const companyresponse = await axios.get(
        `${API_URL}/company/${companyid}/patch/`
      );
      if ((companyresponse.status = 200)) {
        setcompanydata(companyresponse.data);
      } else {
      }
    } catch (err) {
      toast.error(" Please Contact Administrator");
    }
  };

  /* ******************* Get Review Data ****************** */
  const getReviewData = async () => {
    try {
      const reviewresponse = await axios.get(
        `${API_URL}/properties/user/review/filter/?properties=${newPropertyId}`
      );
      if ((reviewresponse.status = 200)) {
        setreviewData(
          reviewresponse.data.filter((item) => item?.is_active === true)
        );
      } else {
      }
    } catch (err) {
      toast.error(" Please Contact Administrator");
    }
  };
  const iframeRef = useRef(null);
  const iframeReff = useRef(null);
  const iframePaymentRefs = useRef(null);
  const iframeRefs = useRef([]);
  const iframeBHKRefs = useRef([]);
  const toggleFullScreen = () => {
    const iframe = iframeRef.current;
    if (iframe) {
      if (iframe.requestFullscreen) {
        iframe.requestFullscreen();
      } else if (iframe.mozRequestFullScreen) {
        /* Firefox */
        iframe.mozRequestFullScreen();
      } else if (iframe.webkitRequestFullscreen) {
        /* Chrome, Safari & Opera */
        iframe.webkitRequestFullscreen();
      } else if (iframe.msRequestFullscreen) {
        /* IE/Edge */
        iframe.msRequestFullscreen();
      }
    }
  };
  const togglePaymentScreen = () => {
    const iframe = iframePaymentRefs?.current;
    if (iframe) {
      if (iframe.requestFullscreen) {
        iframe.requestFullscreen();
      } else if (iframe.mozRequestFullScreen) {
        /* Firefox */
        iframe.mozRequestFullScreen();
      } else if (iframe.webkitRequestFullscreen) {
        /* Chrome, Safari & Opera */
        iframe.webkitRequestFullscreen();
      } else if (iframe.msRequestFullscreen) {
        /* IE/Edge */
        iframe.msRequestFullscreen();
      }
    }
  };

  const toggleFull = () => {
    const iframe = iframeReff.current;
    if (iframe) {
      if (iframe.requestFullscreen) {
        iframe.requestFullscreen();
      } else if (iframe.mozRequestFullScreen) {
        /* Firefox */
        iframe.mozRequestFullScreen();
      } else if (iframe.webkitRequestFullscreen) {
        /* Chrome, Safari & Opera */
        iframe.webkitRequestFullscreen();
      } else if (iframe.msRequestFullscreen) {
        /* IE/Edge */
        iframe.msRequestFullscreen();
      }
    }
  };

  const toggleFloorFull = (index) => {
    const iframe = iframeRefs.current[index]?.current;
    if (iframe) {
      if (iframe.requestFullscreen) {
        iframe.requestFullscreen();
      } else if (iframe.mozRequestFullScreen) {
        /* Firefox */
        iframe.mozRequestFullScreen();
      } else if (iframe.webkitRequestFullscreen) {
        /* Chrome, Safari & Opera */
        iframe.webkitRequestFullscreen();
      } else if (iframe.msRequestFullscreen) {
        /* IE/Edge */
        iframe.msRequestFullscreen();
      }
    }
  };
  const toggleBHKScreen = (index) => {
    const iframe = iframeBHKRefs.current[index]?.current;
    if (iframe) {
      if (iframe.requestFullscreen) {
        iframe.requestFullscreen();
      } else if (iframe.mozRequestFullScreen) {
        /* Firefox */
        iframe.mozRequestFullScreen();
      } else if (iframe.webkitRequestFullscreen) {
        /* Chrome, Safari & Opera */
        iframe.webkitRequestFullscreen();
      } else if (iframe.msRequestFullscreen) {
        /* IE/Edge */
        iframe.msRequestFullscreen();
      }
    }
  };

  useEffect(() => {
    // Ensure refs array matches the length of floorPlans
    iframeRefs.current = Array(floorPlans.length)
      .fill(null)
      .map((_, i) => iframeRefs.current[i] || React.createRef());
  }, [floorPlans]);
  useEffect(() => {
    // Ensure refs array matches the length of floorPlans
    iframeBHKRefs.current = Array(bhkDetails.length)
      .fill(null)
      .map((_, i) => iframeBHKRefs.current[i] || React.createRef());
  }, [bhkDetails]);

  useEffect(() => {
    if (propertyData !== null) {
      const propertyCity = propertyData?.city;
      const propertystate = propertyData?.state;

      const propertyId = propertyData?.id;
      const propertyBuildingType = propertyData?.building_type_name;
      const propertyCategory = propertyData?.property_type;
      const propertyLabels = propertyData?.property_label_name;
      const filteredProperty = allProperties?.filter(
        (item) => item?.state === propertystate
      );

      const similarProperty = allProperties?.filter(
        (item) =>
          item?.building_type_name === propertyBuildingType &&
          item?.state === propertystate
      );

      const finalFilterProperty = similarProperty?.filter(
        (item) => item?.property_type === propertyCategory
      );
      const finalProperty = finalFilterProperty?.filter(
        (item) => item?.property_label_name === propertyLabels
      );
      const resultProperty = finalProperty?.filter(
        (item) => item?.id !== propertyId
      );

      if (resultProperty) {
        SetRelatingProperty(resultProperty);
      }
    }
  }, [propertyData, allProperties]);
  /* remove html tag from decription */

  const regex = /(<([^>]+)>)/gi;
  const propDesc = propertyData?.description?.replace(regex, " ");

  useEffect(() => {
    window?.scrollTo(0, 0);
    getAllProperties();
    getPropertyData();
    // getReplyData();
  }, []);
  useEffect(() => {
    window?.scrollTo(0, 0);
    if (is_auth === "false") {
      getfavourite();
    }
  }, [is_auth]);
  useEffect(() => {
    if(newPropertyId){
    getFloorPlans();
    getReviewData();
    getBHKDetails();}
  }, [newPropertyId]);
  return (
    <>
      {loading ? <Loader /> : null}
      <Helmet>
        <title>My Awesome Website</title>
        <meta name="description" content="This is an awesome website built with React." />
        <meta name="keywords" content="React, SEO, Awesome" />
        
        {/* Open Graph tags */}
        <meta property="og:title" content="My Awesome Website" />
        <meta property="og:description" content="This is an awesome website built with React." />
        <meta property="og:image" content="https://example.com/image.jpg" />
        <meta property="og:url" content="https://example.com" />
        <meta property="og:type" content="website" />
        
        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="My Awesome Website" />
        <meta name="twitter:description" content="This is an awesome website built with React." />
        <meta name="twitter:image" content="https://example.com/image.jpg" />
      </Helmet>
      
      <Header />
      <main id="content-details" style={{ marginTop: "100px" }}>
        <section>
          { }

          <div className="container-fluid">
            <div className="position-relative" data-bs-animate="zoomIn">
              <div className="row galleries m-n1">
                <div className="col-lg-6 p-1">
                  <div className="item item-size-4-3">
                    <div
                      className="card p-0 hover-zoom-in "
                      onClick={() => {
                        setIndex(index + 1);
                        setIsModalVisible(true);
                      }}
                    >
                      <Link
                        className="card-img"
                        data-gtf-mfp="true"
                        data-gallery-id="01"
                      >
                        <img
                          className="height-705"
                          src={propertyData?.upload_featured_image}
                          alt="user-profile"
                        />
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 p-1">
                  <div className="row m-n1">
                    {propertyData?.upload_video !== null ? (
                      <>
                        {propertyData?.get_property_image
                          ?.slice(0, 3)
                          ?.map((item) => {
                            return (
                              <>
                                <div className="col-md-6 p-1">
                                  <div className="item item-size-4-3">
                                    <div
                                      className="card p-0 hover-zoom-in"
                                      onClick={() => {
                                        setIndex(index + 1);
                                        setIsModalVisible(true);
                                      }}
                                    >
                                      <Link
                                        className="card-img"
                                        data-gtf-mfp="true"
                                        data-gallery-id="01"
                                      >
                                        <img
                                          className="height-350"
                                          src={item}
                                          alt="user-profile"
                                        />
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        <div className="col-md-6 p-1">
                          <div className="item item-size-4-3">
                            <div className="card p-0 hover-zoom-in">
                              <div
                                className="overlay-2"
                                onClick={handleOverlayClick}
                              >
                                {!isPlaying && (
                                  <img
                                    src="../../images/play-button.png"
                                    alt="Play"
                                    style={{
                                      width: "50px", // adjust size as needed
                                      height: "50px",
                                      position: "absolute",
                                      top: "43%",
                                      left: "43%",
                                      // transform: 'translate(-50%, -50%)',
                                    }}
                                  />
                                )}
                              </div>
                              <iframe
                                src={propertyData?.upload_video}
                                alt="image"
                                className="add-border-other-2"
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        {propertyData?.get_property_image
                          ?.slice(0, 4)
                          ?.map((item) => {
                            return (
                              <>
                                <div className="col-md-6 p-1">
                                  <div className="item item-size-4-3">
                                    <div
                                      className="card p-0 hover-zoom-in"
                                      onClick={() => {
                                        setIndex(index + 1);
                                        setIsModalVisible(true);
                                      }}
                                    >
                                      <Link
                                        className="card-img"
                                        data-gtf-mfp="true"
                                        data-gallery-id="01"
                                      >
                                        <img
                                          className="height-350"
                                          src={item}
                                          alt="user-profile"
                                        />
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {isModalVisible ? (
          <Modal
            className="modal-add-contact sliderFullWrap"
            size={"lg"}
            show={isModalVisible}
            onHide={() => setIsModalVisible(false)}
            onBackdropClick={() => setIsModalVisible(false)}
          >
            <Modal.Body className="modal-body-property-page-slider">
              <ImageGalleryControl
                imageData={imageData}
                startIndex={index}
                featureImage={propertyData?.upload_featured_image}
              />
            </Modal.Body>
          </Modal>
        ) : null}
        <div className="primary-content bg-f3f3f3">
          <div className="container-fluid px-15">
            <div className="row pt-5">
              <article className="col-lg-9 col-md-7 ">
                <section className=" border-bottom ">
                  <div className="d-flex justify-content-between">
                    <div className="">
                      {propertyData?.property_label_name ? (
                        <>
                          {propertyData?.property_label_name === "Sale" ? (
                            <>
                              <p className="list-inline-item badge badge-orange mr-3 py-2 px-2">
                                For Sale
                              </p>
                            </>
                          ) : (
                            <>
                              <p className="list-inline-item badge badge-orange mr-3 py-2 px-2">
                                For Rent
                              </p>
                            </>
                          )}
                        </>
                      ) : null}
                      {propertyData?.is_property === true ? (
                        <>
                          <p className="list-inline-item badge bg-blue mr-3 p-2">
                            New
                          </p>
                        </>
                      ) : null}
                    </div>

                    <div className="nav-item dropdown mine-tab">
                      <button
                        className=" nav-link dropdown-toggle btn bg-blue text-white hover-saffron"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Share
                        <i className=" ml-1 fas fa-share"></i>
                      </button>
                      <div className="dropdown-menu ">
                        <WhatsappShareButton
                          url={currentUrl}
                          title={propertyData?.property_name}
                        >
                          <WhatsappIcon
                            size={32}
                            round={true}
                            className="dropdown-item"
                          />
                        </WhatsappShareButton>
                        <FacebookShareButton
                          url={currentUrl}
                          title={propertyData?.property_name}
                        >
                          <FacebookIcon
                            size={32}
                            round={true}
                            className="dropdown-item"
                          />
                        </FacebookShareButton>
                        <EmailShareButton
                          url={currentUrl}
                          title={propertyData?.property_name}
                        >
                          <EmailIcon
                            size={32}
                            round={true}
                            className="dropdown-item"
                          />
                        </EmailShareButton>
                        <TelegramShareButton
                          url={currentUrl}
                          title={propertyData?.property_name}
                        >
                          <TelegramIcon
                            size={32}
                            round={true}
                            className="mdropdown-item"
                          />
                        </TelegramShareButton>
                        <TwitterShareButton
                          url={currentUrl}
                          title={propertyData?.property_name}
                        >
                          <XIcon
                            size={32}
                            round={true}
                            className="dropdown-item"
                          />
                        </TwitterShareButton>
                        <LinkedinShareButton
                          url={currentUrl}
                          title={propertyData?.property_name}
                        >
                          <LinkedinIcon
                            size={32}
                            round={true}
                            className="dropdown-item"
                          />
                        </LinkedinShareButton>
                      </div>
                    </div>
                  </div>
                  <div className="d-sm-flex justify-content-sm-between ">
                    <div style={{ width: "60%" }}>
                      <h1 className="fs-35 font-weight-600 lh-15 text-heading">
                        {propertyData?.property_name}
                      </h1>
                      {propertyData?.prop_id ? (
                        <h4 className="fs-16 text-orange">
                          Property ID:{" "}
                          <span className="text-blue">
                            {propertyData?.prop_id}
                          </span>
                        </h4>
                      ) : null}

                      <p className="mb-0 fs-18 ">
                        <i className="fal fa-map-marker-alt mr-2"></i>
                        {propertyData?.street_address}
                      </p>
                    </div>

                    <div className="mt-2 text-lg-right">
                      <ConvertCurrency
                        // curreny={curreny}
                        propertyData={propertyData}
                      />
                      {/* {propertyData?.building_type_name === "Residential" ? (
                        <>
                          <div className="d-flex ">
                            <div className="d-block">
                              <p className="fs-18 mb-0">
                                {propertyData?.bedroom}
                              </p>
                              <img
                                className="sz-32"
                                src="../../images/bed.png"
                                alt="property-type"
                              />
                              <p className="ml-5">Bedroom</p>
                            </div>
                            <div className="d-block px-5">
                              <p className="fs-18 mb-0">
                                {propertyData?.bathroom}
                              </p>
                              <img
                                className="sz-32"
                                src="../../images/bathtub.png"
                                alt="property-type"
                              />
                              <p className="ml-5">Bathroom</p>
                            </div>
                          </div>
                        </>
                      ) : null} */}
                    </div>
                  </div>
                </section>

                <section className="pb-2 pt-5">
                  <div className=" ">
                    <div className="collapse-tabs mb-5 rounded-15">
                      <ul
                        className="nav nav-tabs text-uppercase d-md-inline-flex agent-details-tabs"
                        role="tablist"
                      >
                        <li className="nav-item">
                          <Link
                            to="#description"
                            className=" text-blue nav-link active  fs-16 padding-r-5"
                            data-bs-toggle="tab"
                            role="tab"
                          >
                            Description
                          </Link>
                        </li>
                        <>
                          <li className="nav-item ml-0">
                            <Link
                              to="#neighbourhood"
                              className="nav-link text-blue fs-16 padding-r-5"
                              data-bs-toggle="tab"
                              role="tab"
                            >
                              Nearby Places
                            </Link>
                          </li>

                          <li className="nav-item ml-0">
                            <Link
                              to="#calculator"
                              className="nav-link text-blue fs-16 padding-r-5"
                              data-bs-toggle="tab"
                              role="tab"
                            >
                              Calculator
                            </Link>
                          </li>
                        </>
                      </ul>
                      <div className="tab-content shadow-none  bg-white">
                        <div
                          className="tab-pane tab-pane-parent fade show active"
                          id="description"
                          role="tabpanel"
                        >
                          <div className="card border-0 bg-transparent">
                            <div className="card-body p-0">
                              <h2 className="card-title fs-22 lh-15 mb-1 text-dark">
                                About property
                              </h2>

                              {propDesc ? (
                                <>
                                  <p className="card-text lh-214 mb-3 fs-18 ws-3">
                                    {propDesc}
                                  </p>
                                </>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane tab-pane-parent fade"
                          id="neighbourhood"
                          role="tabpanel"
                        >
                          <div className="card ">
                            <div className="">
                              {/* { propertyData?.lat} */}
                              <NearbyPlacesMap
                                lat={propertyData?.lat}
                                lng={propertyData?.lng}
                                setNearPlaceCount={setNearPlaceCount}
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane tab-pane-parent fade"
                          id="calculator"
                          role="tabpanel"
                        >
                          <div className="card border-0 bg-transparent">
                            <div className="card-body p-0">
                              <h2 className="fs-22">Mortgage Calculator</h2>
                              <p className="fs-16">You can try below:</p>
                              <div className="row">
                                <div className="col-lg-3">
                                  <Link
                                    className="fs-16 text-gray hover-saffron-text"
                                    to="https://www.bankfab.com/en-ae/personal/mortgages/mortgage-calculator"
                                    target="black"
                                  >
                                    <img
                                      src="../../images/fab.png"
                                      alt="image"
                                    />
                                  </Link>
                                </div>
                                <div className="col-lg-3">
                                  <Link
                                    className="fs-16 text-gray hover-saffron-text"
                                    to="https://www.cbd.ae/mortgage-loan-calculator"
                                    target="black"
                                  >
                                    <img
                                      src="../../images/cbd.png"
                                      alt="image"
                                    />
                                  </Link>
                                </div>

                                <div className="col-lg-3">
                                  <Link
                                    className="fs-16 text-gray hover-saffron-text"
                                    to="https://www.mymoneysouq.com/calculators/mortgage-loan-calculator"
                                    target="black"
                                  >
                                    <img
                                      src="../../images/mymoneysouq.png"
                                      alt="image"
                                    />
                                  </Link>
                                </div>

                                <div className="col-lg-3">
                                  <Link
                                    className="fs-16 text-gray hover-saffron-text"
                                    to="https://www.emiratesnbd.com/en/loans/home-loans"
                                    target="black"
                                  >
                                    <img
                                      src="../../images/emiratesnbd.png"
                                      alt="image"
                                    />
                                  </Link>
                                </div>
                              </div>
                              <h2>Disclaimer</h2>
                              <p>
                                {" "}
                                Information Dirhamperfoot.ae is not responsible
                                for any offers, prices or terms listed on listed
                                mortgage/loan providers. The end-user is
                                self-responsible for any loans or mortgages
                                offered by listed providers. The information
                                provided on this website is for general
                                education purposes only and is not intended to
                                constitute specialist or personal advice. This
                                website has been prepared without taking into
                                account your objectives, financial situation or
                                needs. Dirhamperfoot can connect you with our
                                referral who can talk to you about home loans
                                from a range of lenders. * Note: the home loan
                                with the lowest current interest rate is not
                                necessarily the most suitable for your
                                circumstances; you may not qualify for that
                                particular product, the product may not include
                                all the relevant features, and not all products
                                are available in all states and territories.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section className="border-bottom">
                  <h4 className="fs-22 text-heading mb-6">
                    Facts and Features
                  </h4>
                  <div className="row">
                    {propertyData?.days_on_market ? (
                      <>
                        <div className="col-lg-3 col-sm-4 mb-6">
                          <div className="media">
                            <div className="p-2 shadow-xxs-1 rounded-lg mr-2">
                              <div className="icon icon-family fs-20 text-primary">
                                <img
                                  className="sz-32"
                                  src="../../images/d-1.png"
                                  alt="property-type"
                                />
                              </div>
                            </div>
                            <div className="media-body">
                              <h5 className="my-1 fs-18 text-uppercase font-weight-bold letter-spacing-093 font-weight-normal">
                                Days On Market
                              </h5>
                              <p className="mb-0 fs-16  text-heading">
                                {propertyData?.days_on_market}
                              </p>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : null}{" "}
                    {propertyData?.project_website ? (
                      <>
                        <div className="col-lg-3 col-sm-4 mb-6">
                          <div className="media">
                            <div className="p-2 shadow-xxs-1 rounded-lg mr-2">
                              <div className="icon icon-family fs-20 text-primary">
                                <img
                                  className="sz-32"
                                  src="../../images/b-10.png"
                                  alt="property-type"
                                />
                              </div>
                            </div>
                            <div className="media-body">
                              <h5 className="my-1 fs-18 text-uppercase font-weight-bold letter-spacing-093 font-weight-normal">
                                Project Website
                              </h5>
                              <Link
                                to={`${propertyData?.project_website}`}
                                target="blank"
                                className="mb-0 fs-16  text-heading"
                              >
                                Click Link
                              </Link>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : null}
                    {propertyData?.property_type_name ? (
                      <>
                        <div className="col-lg-3 col-sm-4 mb-6">
                          <div className="media">
                            <div className="p-2 shadow-xxs-1 rounded-lg mr-2">
                              <div className="icon icon-family fs-20 text-primary">
                                <img
                                  className="sz-32"
                                  src="../../images/b-1.png"
                                  alt="property-type"
                                />
                              </div>
                            </div>
                            <div className="media-body">
                              <h5 className="my-1 fs-18 text-uppercase font-weight-bold letter-spacing-093 font-weight-normal">
                                Property Type
                              </h5>
                              <p className="mb-0 fs-16  text-heading">
                                {propertyData?.property_type_name}
                              </p>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : null}
                    {propertyData?.property_size ? (
                      <>
                        <div className="col-lg-3 col-sm-4 mb-6">
                          <div className="media">
                            <div className="p-2 shadow-xxs-1 rounded-lg mr-2">
                              <div className="icon icon-family fs-20 text-primary">
                                <img
                                  className="sz-32"
                                  src="../../images/b-4.png"
                                  alt="property-type"
                                />
                              </div>
                            </div>
                            <div className="media-body">
                              <h5 className="my-1 fs-18 font-weight-bold text-uppercase letter-spacing-093 font-weight-normal">
                                SQFT
                              </h5>
                              <p className="mb-0 fs-16 text-heading">
                                <CurrencyFormat
                                  value={propertyData?.property_size}
                                  displayType="text"
                                  thousandSeparator={true}
                                />
                              </p>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : null}
                    {propertyData?.bedroom ? (
                      <>
                        <div className="col-lg-3 col-sm-4 mb-6">
                          <div className="media">
                            <div className="p-2 shadow-xxs-1 rounded-lg mr-2">
                              <div className="icon icon-family fs-20 text-primary">
                                <img
                                  className="sz-32"
                                  src="../../images/hotel.png"
                                  alt="property-type"
                                />
                              </div>
                            </div>
                            <div className="media-body">
                              <h5 className="my-1 fs-18 font-weight-bold text-uppercase letter-spacing-093 font-weight-normal">
                                Bedroom
                              </h5>
                              <p className="mb-0 fs-16  text-heading">
                                {propertyData?.bedroom}
                              </p>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : null}
                    {propertyData?.bathroom ? (
                      <>
                        <div className="col-lg-3 col-sm-4 mb-6">
                          <div className="media">
                            <div className="p-2 shadow-xxs-1 rounded-lg mr-2">
                              <img
                                className="sz-32"
                                src="../../images/bathtub.png"
                                alt="property-type"
                              />
                            </div>
                            <div className="media-body">
                              <h5 className="my-1 fs-18 font-weight-bold text-uppercase letter-spacing-093 font-weight-normal">
                                Bathroom
                              </h5>
                              <p className="mb-0 fs-16  text-heading">
                                {propertyData?.bathroom}
                              </p>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : null}
                    {propertyData?.ownership_name ? (
                      <>
                        <div className="col-lg-3 col-sm-4 mb-6">
                          <div className="media">
                            <div className="p-2 shadow-xxs-1 rounded-lg mr-2">
                              <img
                                className="sz-32"
                                src="../../images/key.png"
                                alt="property-type"
                              />
                            </div>
                            <div className="media-body">
                              <h5 className="my-1 fs-18 font-weight-bold text-uppercase letter-spacing-093 font-weight-normal">
                                Ownership
                              </h5>
                              <p className="mb-0 fs-16  text-heading">
                                {propertyData?.ownership_name}
                              </p>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : null}
                    {propertyData?.construction_status_name ? (
                      <>
                        <div className="col-lg-3 col-sm-4 mb-6">
                          <div className="media">
                            <div className="p-2 shadow-xxs-1 rounded-lg mr-2">
                              <img
                                className="sz-32"
                                src="../../images/b-11.png"
                                alt="property-type"
                              />
                            </div>
                            <div className="media-body">
                              <h5 className="my-1 fs-18 font-weight-bold text-uppercase letter-spacing-093 font-weight-normal">
                                Const.Status
                              </h5>
                              <p className="mb-0 fs-16  text-heading">
                                {propertyData?.construction_status_name}
                              </p>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : null}
                    {/* {propertyData?.propertyDob ? (
                          <>
                            <div className="col-lg-3 col-sm-4 mb-6">
                              <div className="media">
                                <div className="p-2 shadow-xxs-1 rounded-lg mr-2">
                                  <img
                                    className="sz-32"
                                    src="../../images/b-11.png"
                                    alt="property-type"
                                  />
                                </div>
                                <div className="media-body">
                                  <h5 className="my-1 fs-18 text-uppercase letter-spacing-093 font-weight-normal">
                                    Built Year{" "}
                                  </h5>
                                  <p className="mb-0 fs-16 font-weight-bold text-heading">
                                    {PropertyData?.propertyDob}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : null} */}
                    {propertyData?.construction_start_date ? (
                      <>
                        <div className="col-lg-3 col-sm-4 mb-6">
                          <div className="media">
                            <div className="p-2 shadow-xxs-1 rounded-lg mr-2">
                              <div className="icon icon-family fs-20 text-primary">
                                <img
                                  className="sz-32"
                                  src="../../images/hook.png"
                                  alt="property-type"
                                />
                              </div>
                            </div>
                            <div className="media-body">
                              <h5 className="my-1 fs-18 font-weight-bold text-uppercase letter-spacing-093 font-weight-normal">
                                const.Start Date
                              </h5>
                              <p class="mb-0 fs-16  text-heading">
                                {moment(
                                  propertyData?.construction_start_date
                                ).format("DD-MM-YYYY")}
                              </p>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : null}
                    {propertyData?.estimated_completion ? (
                      <>
                        <div className="col-lg-3 col-sm-4 mb-6">
                          <div className="media">
                            <div className="p-2 shadow-xxs-1 rounded-lg mr-2">
                              <div className="icon icon-family fs-20 text-primary">
                                <img
                                  className="sz-32"
                                  src="../../images/completion_date.png"
                                  alt="property-type"
                                />
                              </div>
                            </div>
                            <div className="media-body">
                              <h5 className="my-1 fs-18 font-weight-bold text-uppercase letter-spacing-093 font-weight-normal">
                                Completion Date
                              </h5>
                              <p class="mb-0 fs-16  text-heading">
                                {moment(
                                  propertyData?.estimated_completion
                                ).format("DD-MM-YYYY")}
                              </p>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : null}
                    {propertyData?.launch_date ? (
                      <>
                        <div className="col-lg-3 col-sm-4 mb-6">
                          <div className="media">
                            <div className="p-2 shadow-xxs-1 rounded-lg mr-2">
                              <div className="icon icon-family fs-20 text-primary">
                                <img
                                  className="sz-32"
                                  src="../../images/mortgage.png"
                                  alt="property-type"
                                />
                              </div>
                            </div>
                            <div className="media-body">
                              <h5 className="my-1 fs-18 font-weight-bold text-uppercase letter-spacing-093 font-weight-normal">
                                Delivery Date
                              </h5>
                              <p class="mb-0 fs-16  text-heading">
                                {moment(propertyData?.launch_date).format(
                                  "DD-MM-YYYY"
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : null}
                  </div>
                </section>

                {propertyData?.get_property_amenities_name?.length > 0 ? (
                  <section className="">
                    <>
                      <div className="pt-6 border-bottom pb-4">
                        <h4 className="fs-22 text-heading mb-4">Amenities</h4>
                        <ul className="list-unstyled mb-0 row no-gutters">
                          {propertyData?.get_property_amenities_name?.map(
                            (item) => {
                              return (
                                <li className="col-sm-3 col-6 mb-2 fs-18">
                                  <i className="far fa-check mr-2 text-blue"></i>
                                  {item}
                                </li>
                              );
                            }
                          )}
                        </ul>
                      </div>
                    </>
                  </section>
                ) : null}

                <Chartgraph
                  propertytypedata={propertytypedata}
                  buildinglabelData={buildinglabelData}
                  buildingArea={buildingArea}
                />

                <section>
                  <Pricegraph parenttochild={newPropertyId} />
                </section>
                {propertyData?.upload_project_brochure !== null ? (
                  <section className="">
                    <>
                      <div className="py-3 border-bottom">
                        <Accordion allowOneExpanded preExpanded={["item1"]}>
                          <AccordionItem uuid="item1">
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                <div
                                  className="floor-title"
                                  onClick={toggleAccordion}
                                >
                                  <h4>
                                    Project Brochure
                                    {isOpen ? (
                                      <i class="bi bi-chevron-up"></i>
                                    ) : (
                                      <i class="bi bi-chevron-down"></i>
                                    )}
                                  </h4>
                                </div>
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <div className="floor-plan-sr">
                                <div className="card border-0 shadow-xxs-2">
                                  <div
                                    id="collapse-01"
                                    className={`collapse ${isOpen ? "show" : ""
                                      }`}
                                    aria-labelledby="floor-plans-01"
                                    data-parent="#accordion-01"
                                  >
                                    <div className="card-body  mb-3 ">
                                      <div className="">
                                        <iframe
                                          ref={iframeReff}
                                          src={
                                            propertyData?.upload_project_brochure
                                          }
                                          style={{
                                            width: "100%",
                                            height: "500px",
                                          }}
                                          title="Project Brochure"
                                        ></iframe>
                                      </div>
                                      <div className="">
                                        <button
                                          className="btn bg-blue text-white hover-saffron"
                                          onClick={toggleFull}
                                        >
                                          Full Screen
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </AccordionItemPanel>
                          </AccordionItem>
                        </Accordion>
                      </div>
                    </>
                  </section>
                ) : null}

                {propertyData?.upload_availability_plan !== null ? (
                  <section className="">
                    <>
                      <div className="py-3 border-bottom">
                        <Accordion allowOneExpanded preExpanded={["item2"]}>
                          <AccordionItem uuid="item2">
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                <div
                                  className="floor-title"
                                  onClick={handleAccordionToggle}
                                >
                                  <h4>
                                    Availability Plan
                                    {isAccordionOpen ? (
                                      <i className="bi bi-chevron-up margin-auto"></i>
                                    ) : (
                                      <i className="bi bi-chevron-down margin-auto"></i>
                                    )}
                                  </h4>
                                </div>
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <div className="floor-plan-sr">
                                <div className="card border-0 shadow-xxs-2">
                                  <div
                                    id="collapse-02"
                                    className={`collapse ${isAccordionOpen ? "show" : ""
                                      }`}
                                    aria-labelledby="floor-plans-01"
                                    data-parent="#accordion-02"
                                  >
                                    <div className="card-body mb-3 ">
                                      <div className="">
                                        <iframe
                                          ref={iframeRef}
                                          src={
                                            propertyData?.upload_availability_plan
                                          }
                                          style={{
                                            width: "100%",
                                            height: "500px",
                                          }}
                                          title="Project Brochure"
                                        ></iframe>
                                      </div>
                                      <div className="">
                                        <button
                                          className="btn bg-blue text-white hover-saffron"
                                          onClick={toggleFullScreen}
                                        >
                                          Full Screen
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </AccordionItemPanel>
                          </AccordionItem>
                        </Accordion>
                      </div>
                    </>
                  </section>
                ) : null}

                {/* {floorPlans?.length > 0  ? (
                  <section className="">
                    <>
                      <div className="py-3 border-bottom">
                        <Accordion allowOneExpanded preExpanded={['item1']}>
                          <AccordionItem uuid="item1">
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                <div
                                  className="floor-title"
                                  onClick={togglePlanAccordion}
                                >
                                  <h4>

                                    Floor Plan
                                    {isOpen ? (

                                      <i class="bi bi-chevron-up"></i>
                                    ) : (
                                      <i class="bi bi-chevron-down"></i>
                                    )}
                                  </h4>
                                </div>
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <div className="floor-plan-sr">
                                <div className="card border-0 shadow-xxs-2">
                                  <div
                                    id="collapse-01"
                                    className={`collapse ${isPlan ? "show" : ""}`}
                                    aria-labelledby="floor-plans-01"
                                    data-parent="#accordion-01"

                                  >
                                    <div className="card-body  mb-3 ">
                                      <div className="">
                                        <iframe
                                          ref={iframeReff}
                                          src={floorPlans?.floor_pdf}
                                          style={{ width: "100%", height: "500px" }}
                                          title="Project Brochure"
                                        ></iframe>
                                      </div>
                                      <div className="">
                                        <button className="btn bg-blue text-white hover-saffron" onClick={toggleFull}>Full Screen</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </AccordionItemPanel>
                          </AccordionItem>
                        </Accordion>
                      </div>
                    </>
                  </section>
                ) : null} */}
                {propertyData?.payment_plan ? (
                  <div className="card-body py-4 py-md-0 px-0">
                    <div className="card mb-6">
                      <div className="card-body p-6">
                        <h3 className="card-title mb-0 text-heading fs-22 lh-15">
                          Payment Plan
                        </h3>
                        <div className="col-lg-12">

                          <div className="py-3 border-bottom">

                            <iframe
                              ref={iframePaymentRefs}
                              src={propertyData?.payment_plan}
                              style={{ width: "100%", height: "500px" }}

                            ></iframe>
                          </div>
                          <div className="">
                            <button
                              className="btn bg-blue text-white hover-saffron"
                              onClick={() => togglePaymentScreen()}
                            >
                              Full Screen
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                {floorPlans && floorPlans.length > 0 && (
                  <section className="">
                    <div className="py-3 border-bottom">
                      <h4 className="fs-22 text-heading mb-4">Floor Plans</h4>
                      <Accordion allowOneExpanded preExpanded={["item-0"]}>
                        {floorPlans?.map((floors, index) => (
                          <AccordionItem key={index} uuid={`item-${index}`}>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                <div className="floor-title">
                                  <h4>
                                    {floors?.name} with {floors?.no_of_unit}{" "}
                                    units.
                                  </h4>
                                </div>
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <div className="floor-plan-sr">
                                <div className="card border-0 shadow-xxs-2">
                                  <div>
                                    <div className="card-body mb-3">
                                      <div className="">
                                        <iframe
                                          ref={iframeRefs?.current[index]}
                                          src={floors?.floor_pdf}
                                          style={{
                                            width: "100%",
                                            height: "500px",
                                          }}
                                          title="Floor Plan"
                                        ></iframe>
                                      </div>
                                      <div className="">
                                        <button
                                          className="btn bg-blue text-white hover-saffron"
                                          onClick={() => toggleFloorFull(index)}
                                        >
                                          Full Screen
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </AccordionItemPanel>
                          </AccordionItem>
                        ))}
                      </Accordion>
                    </div>
                  </section>
                )}
                {bhkDetails && bhkDetails.length > 0 && (
                  <section className="">
                    <div className="py-3 border-bottom">
                      <h4 className="fs-22 text-heading mb-4">Unit Plans</h4>
                      <Accordion allowOneExpanded preExpanded={["item-0"]}>
                        {bhkDetails?.map((floors, index) => (
                          <AccordionItem key={index} uuid={`item-${index}`}>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                <div className="floor-title">
                                  <h4>
                                    {floors?.name} with {floors?.size}{" "}
                                    size.
                                  </h4>
                                </div>
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <div className="floor-plan-sr">
                                <div className="card border-0 shadow-xxs-2">
                                  <div>
                                    <div className="card-body mb-3">
                                      <div className="">
                                        <iframe
                                          ref={iframeBHKRefs?.current[index]}
                                          src={floors?.bhk_pdf}
                                          style={{
                                            width: "100%",
                                            height: "500px",
                                          }}
                                          title="Floor Plan"
                                        ></iframe>
                                      </div>
                                      <div className="">
                                        <button
                                          className="btn bg-blue text-white hover-saffron"
                                          onClick={() => toggleBHKScreen(index)}
                                        >
                                          Full Screen
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </AccordionItemPanel>
                          </AccordionItem>
                        ))}
                      </Accordion>
                    </div>
                  </section>
                )}
                {relatingProperty?.length > 0 ? (
                  <>
                    <section className="py-6 border-bottom ">
                      <div className="card border-0 rounded-15">
                        <div className="card-body p-5">
                          <h4 className="fs-22">
                            More Properties in this Area
                          </h4>
                          {relatingProperty?.length > 2 ? (
                            <Slider {...settings}>
                              {relatingProperty
                                ?.slice(0, 10)
                                .map((item, index) => {
                                  return (
                                    <div>
                                      <AnimationOnScroll animateIn="animate__zoomIn">
                                        <div /* onClick={() => RedirectPropertyPage(item)} */
                                        >
                                          <div
                                            className="box box pb-7 pt-2 "
                                            data-animate="fadeInUp"
                                          >
                                            <div className="card shadow-hover-2">
                                              <a
                                                href={`/property/details/${item?.slug}`}
                                              >
                                                <div className="">
                                                  <div className="hover-change-image bg-hover-overlay rounded-lg card-img-top">
                                                    <img
                                                      src={
                                                        item?.upload_featured_image
                                                      }
                                                      alt="Home in Metric Way"
                                                      className="prelaunch-property-image"
                                                    />
                                                    <div className="card-img-overlay p-2 d-flex flex-column">
                                                      <div>
                                                        <span className="badge mr-2 badge-orange">
                                                          {
                                                            item?.property_label_name
                                                          }
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div
                                                    className="card-body pt-3"
                                                    style={{ height: "250px" }}
                                                  >
                                                    <h2 className="card-title fs-16 lh-2 mb-0">
                                                      <div className="text-dark">
                                                        {item?.property_name}
                                                      </div>
                                                    </h2>
                                                    <p className="card-text font-weight-500 text-gray-light mb-2">
                                                      {item?.street_address}
                                                    </p>
                                                    <ul className="list-inline d-flex mb-0 flex-wrap mr-n5">
                                                      <li
                                                        className="list-inline-item text-gray font-weight-500 fs-13 d-flex align-items-center mr-5"
                                                        data-toggle="tooltip"
                                                        title="Size"
                                                      >
                                                        <img
                                                          src="../../images/area.png"
                                                          className="icon icon-bedroom fs-18 text-primary mr-1 width-32"
                                                          alt="Area"
                                                        />
                                                        {item?.property_size}{" "}
                                                        Sq.Ft
                                                      </li>
                                                    </ul>
                                                    {item?.property_price ? (
                                                      <p className="fs-17 font-weight-bold text-heading mb-0 px-1">
                                                        AED: {""}
                                                        <CurrencyFormat
                                                          value={
                                                            item?.property_price
                                                          }
                                                          displayType="text"
                                                          thousandSeparator={
                                                            true
                                                          }
                                                          suffix=".00"
                                                        />
                                                        <span className="text-gray-light font-weight-500 fs-14"></span>
                                                      </p>
                                                    ) : item?.lease_price_per_mo ? (
                                                      <p className="fs-17 font-weight-bold text-heading mb-0 px-1">
                                                        AED: {""}
                                                        <CurrencyFormat
                                                          value={
                                                            item?.lease_price_per_mo
                                                          }
                                                          displayType="text"
                                                          thousandSeparator={
                                                            true
                                                          }
                                                          suffix=".00"
                                                        />
                                                        /Mo
                                                        <span className="text-gray-light font-weight-500 fs-14"></span>
                                                      </p>
                                                    ) : null}
                                                  </div>
                                                </div>
                                              </a>
                                              <div className="card-footer bg-transparent d-flex justify-content-between align-items-center py-3">
                                                <div className="mr-auto">
                                                  <CallActionButton
                                                    key={item?.id}
                                                    item={item}
                                                  />
                                                </div>
                                                <ul className="list-inline mb-0">
                                                  <li className="list-inline-item">
                                                    <FavActionButton
                                                      setShow={setShow}
                                                      itemId={item?.id}
                                                      favproperty={favproperty}
                                                      setfavproperty={
                                                        setfavproperty
                                                      }
                                                      setLoading={setLoading}
                                                    />
                                                  </li>
                                                </ul>
                                                {/* <FavCard proId={item?.id} IndexValue={index} favData={favProp} /> */}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </AnimationOnScroll>
                                    </div>
                                  );
                                })}
                            </Slider>
                          ) : (
                            <>
                              <div className="row">
                                {relatingProperty?.map((item, index) => {
                                  return (
                                    <div className="col-lg-4">
                                      <div className=" card shadow-hover-2">
                                        <a
                                          href={`/property/details/${item?.slug}`}
                                        >
                                          <div className="">
                                            <div className="hover-change-image bg-hover-overlay rounded-lg card-img-top">
                                              <img
                                                src={
                                                  item?.upload_featured_image
                                                }
                                                alt="Home in Metric Way"
                                                className="prelaunch-property-image"
                                              />
                                              <div className="card-img-overlay p-2 d-flex flex-column">
                                                <div>
                                                  <span className="badge mr-2 badge-orange">
                                                    {item?.property_label_name}
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                            <div
                                              className="card-body pt-3"
                                              style={{ height: "250px" }}
                                            >
                                              <h2 className="card-title fs-16 lh-2 mb-0">
                                                <div className="text-dark">
                                                  {item?.property_name}
                                                </div>
                                              </h2>
                                              <p className="card-text font-weight-500 text-gray-light mb-2">
                                                {item?.street_address}
                                              </p>
                                              <ul className="list-inline d-flex mb-0 flex-wrap mr-n5">
                                                <li
                                                  className="list-inline-item text-gray font-weight-500 fs-13 d-flex align-items-center mr-5"
                                                  data-toggle="tooltip"
                                                  title="Size"
                                                >
                                                  <img
                                                    src="../../images/area.png"
                                                    className="icon icon-bedroom fs-18 text-primary mr-1 width-32"
                                                    alt="area"
                                                  />
                                                  {item?.property_size} Sq.Ft
                                                </li>
                                              </ul>
                                              {item?.property_price ? (
                                                <p className="fs-17 font-weight-bold text-heading mb-0 px-1">
                                                  AED: {""}
                                                  <CurrencyFormat
                                                    value={item?.property_price}
                                                    displayType="text"
                                                    thousandSeparator={true}
                                                    suffix=".00"
                                                  />
                                                  <span className="text-gray-light font-weight-500 fs-14"></span>
                                                </p>
                                              ) : item?.lease_price_per_mo ? (
                                                <p className="fs-17 font-weight-bold text-heading mb-0 px-1">
                                                  AED: {""}
                                                  <CurrencyFormat
                                                    value={
                                                      item?.lease_price_per_mo
                                                    }
                                                    displayType="text"
                                                    thousandSeparator={true}
                                                    suffix=".00"
                                                  />
                                                  /Mo
                                                  <span className="text-gray-light font-weight-500 fs-14"></span>
                                                </p>
                                              ) : null}
                                            </div>
                                          </div>
                                        </a>
                                        <div className="card-footer bg-transparent d-flex justify-content-between align-items-center py-3">
                                          <div className="mr-auto">
                                            <CallActionButton
                                              key={item?.id}
                                              item={item}
                                            />
                                          </div>
                                          <ul className="list-inline mb-0">
                                            <li className="list-inline-item">
                                              <FavActionButton
                                                setShow={setShow}
                                                itemId={item?.id}
                                                favproperty={favproperty}
                                                setfavproperty={setfavproperty}
                                                setLoading={setLoading}
                                              />
                                            </li>
                                          </ul>
                                          {/* <FavCard proId={item?.id} IndexValue={index} favData={favProp} /> */}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </section>
                  </>
                ) : null}
                <section className="py-6 border-bottom">
                  <div className="card border-0">
                    <div className="card-body p-5">
                      <div className="row mb-6">
                        <div className="col-sm-4 mb-6 mb-sm-0">
                          <h2 className="fs-22 lh-2 text-heading mb-6">
                            Avarage Property Rating
                          </h2>
                          {propertyData?.average_review ? (
                            <div>
                              <p className="fs-40 text-heading font-weight-bold mb-6 lh-1">
                                {propertyData?.average_review.toFixed(1)}
                                <span className="fs-18 text-gray-light font-weight-normal">
                                  /5
                                </span>
                              </p>

                              <ul className="list-inline">
                                <ReactStars
                                  value={propertyData?.average_review}
                                  // count={5}
                                  edit={false}
                                  size={40}
                                  isHalf={true}
                                  a11y={true}
                                />
                              </ul>
                            </div>
                          ) : null}
                        </div>
                        <div className="col-sm-8 pt-3">
                          {/* <h5 className="fs-16 lh-2 text-heading mb-5 mb-0">
                            Rating Breakdown
                          </h5> */}
                          {/* first rating */}
                          {/* <div className="d-flex align-items-center ">
                            <ul className="list-inline d-flex px-1 mb-0">
                              <ReactStars
                                value={5}
                                edit={false}
                                size={20}
                                classNames="average-rating-stars-property"
                              ></ReactStars>
                            </ul>

                            <div className="d-block w-100 px-1">
                              <Slider min={0} max={100} value={4}></Slider>
                            </div>
                            <div className="text-muted px-1">75%</div>
                          </div> */}

                          {/* second rating */}
                          {/* <div className="d-flex align-items-center ">
                            <ul className="list-inline d-flex px-1 mb-0">
                              <ReactStars
                                value={4}
                                edit={false}
                                size={20}
                                classNames="average-rating-stars-property"
                              ></ReactStars>
                            </ul>

                            <div className="d-block w-100 px-1">
                              <Slider min={0} max={100} value={4}></Slider>
                            </div>
                            <div className="text-muted px-1">65%</div>
                          </div> */}

                          {/* third rating */}
                          {/* <div className="d-flex align-items-center ">
                            <ul className="list-inline d-flex px-1 mb-0">
                              <ReactStars
                                value={3}
                                edit={false}
                                size={20}
                                classNames="average-rating-stars-property"
                              ></ReactStars>
                            </ul>

                            <div className="d-block w-100 px-1">
                              <Slider min={0} max={100} value={4}></Slider>
                            </div>
                            <div className="text-muted px-1">75%</div>
                          </div> */}

                          {/* fourth rating */}
                          {/* <div className="d-flex align-items-center ">
                            <ul className="list-inline d-flex px-1 mb-0">
                              <ReactStars
                                value={2}
                                edit={false}
                                size={20}
                                classNames="average-rating-stars-property"
                              ></ReactStars>
                            </ul>

                            <div className="d-block w-100 px-1">
                              <Slider min={0} max={100} value={4}></Slider>
                            </div>
                            <div className="text-muted px-1">75%</div>
                          </div> */}

                          {/* fifth rating */}
                          {/* <div className="d-flex align-items-center ">
                            <ul className="list-inline d-flex px-1 mb-0">
                            <ReactStars
                                value={2}
                                edit={false}
                                size={20}
                                classNames="average-rating-stars-property"
                              ></ReactStars>
                            </ul>

                            <div className="d-block w-100 px-1">
                              <Slider min={0} max={100} value={4}></Slider>
                            </div>
                            <div className="text-muted px-1">75%</div>
                          </div> */}
                        </div>
                      </div>
                      {reviewData
                        ? reviewData.map((item) => (
                          <div
                            className="row border-top mt-3 pt-6"
                            key={item.id}
                          >
                            <div className="col-md-3 mb-4 mb-md-0">
                              <div className="media">
                                <div className="h-70 mr-2">
                                  <Avatar
                                    name={item?.user_name}
                                    round={true}
                                    size="50"
                                    color="#529BE8"
                                  />
                                </div>
                                <div className="media-body mt-2">
                                  <p className="fs-16 font-weight-500 text-heading mb-0 lh-15 add-mleft-for-avatar">
                                    {item?.user_name}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-9">
                              <div className="d-flex mb-1">
                                <ul className="list-inline mb-2 mb-lg-0">
                                  <li className="list-inline-item fs-13 text-heading font-weight-500 mr-1">
                                    <ReactStars
                                      value={item?.review}
                                      count={5}
                                      edit={false}
                                      size={26}
                                    />
                                  </li>
                                </ul>
                                <p className="ml-auto mb-0 text-muted">
                                  {moment(item?.create_at)
                                    .format("DD-MM-YY")
                                    .toUpperCase()}
                                </p>
                              </div>
                              <p className="mb-1">{item?.description}</p>
                              {/* <ReviewDetails id={item?.id} /> */}

                              {/* <p
                                  className="font-weight-500"
                                  onClick={() => toggleReplyForm(item.id)}
                                >
                                  Reply
                                </p>

                                {showReplyForms[item.id] && (
                                  <div className="form-group mb-6">
                                    <textarea
                                      className="form-control form-control-lg border-0"
                                      placeholder="Your Reply"
                                      name="reply"
                                      rows="2"
                                      value={reply}
                                      onChange={handleReplyChange}
                                    ></textarea>

                                    <button
                                      type="button"
                                      className="btn btn-lg bg-blue text-white hover-saffron px-10 mb-2 mt-2"
                                      onClick={() => submitReply(item.id)}
                                    >
                                      Submit
                                    </button>
                                  </div>
                                )} */}
                            </div>
                          </div>
                        ))
                        : null}

                      <h3 className="mt-5 border-top fs-16 lh-2 text-heading mb-2">
                        Write A Review
                      </h3>
                      <div className=" mb-5">
                        <div className="form-group mb-4 d-flex justify-content-start ">
                          <ReactStars
                            count={5}
                            size={36}
                            activeColor="#F6B500"
                            name="review"
                            onChange={ratingChanged}
                          />
                        </div>
                        <div className="form-group mb-6">
                          <textarea
                            className="form-control form-control-lg border-0"
                            placeholder="Your Review"
                            name="description"
                            rows="5"
                            onChange={(e) =>
                              setReviewDescription(e?.target?.value)
                            }
                            value={reviewDescription}
                          // {...register("description", { required: true })}
                          // onChange={handleRating}
                          ></textarea>
                        </div>
                        <button
                          type="button"
                          className="btn btn-lg bg-blue text-white hover-saffron px-10 mb-2"
                          onClick={submitrating}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </section>
              </article>
              <aside
                className="col-lg-3 col-md-5 primary-sidebar sidebar-sticky"
                id="sidebar"
              >
                <div className="primary-sidebar-inner">
                  <div className="card border-0 widget-request-tour pt-5">
                    <div className="card-body  pb-5 pt-0">
                      <div className="pt-1 pb-0 px-0 d-block">
                        <div className="pt-5">
                          <div className="w-100 text-align-center  py-2">
                            <Link
                              className="navbar-brand"
                              to={`/profile/details/${propertyData?.user}`}
                            >
                              <img
                                className="profile-img border-rd-50 px-2 py-2"
                                src={userData?.profile}
                                alt="HomeID"
                                style={{ width: "150px" }}
                              />
                            </Link>
                            <h2 className="text-333 fs-22 lh-15">
                              {userData?.first_name} {userData?.last_name}
                            </h2>
                            {propertyData?.company ? (
                              <>
                                <Link
                                  to={`/company/details/${companySlug}`}
                                  className=" fs-18 text-blue text-uppercase hover-saffron-text"
                                >
                                  {propertyData?.company_name}
                                </Link>
                              </>
                            ) : null}

                            {userData.get_language_name ? (
                              <p className="text-dark mb-2 fs-18">
                                Language:{" "}
                                {userData.get_language_name.join(", ")}
                              </p>
                            ) : null}
                            <OverlayTrigger
                              overlay={
                                <Tooltip id="tooltip-disabled">
                                  {userData?.mobile}
                                </Tooltip>
                              }
                            >
                              <Link
                                to={`tel:${userData?.mobile}`}
                                className="text-white bg-saffron mr-2  round-5"
                                style={{ padding: "7px 25px 9px 25px" }}
                              // onClick={() => handleCallAction({ ref: item?.prop_id, phone: item?.get_user_mobile, id: item?.id })}
                              >
                                <i className="bi bi-telephone"></i>
                              </Link>
                            </OverlayTrigger>
                            {userData?.whatsapp ? (
                              <ReactWhatsapp
                                className="btn btn-success mr-2 py-1"
                                number={userData?.whatsapp}
                                // onClick={() => handleWhatAppAction({ ref: item?.prop_id, phone: item?.get_user_whatsapp, id: item?.id })}
                                message="Hi"
                              >
                                <i class="bi bi-whatsapp"></i>
                              </ReactWhatsapp>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <PropertyScore nearPlaceCount={nearPlaceCount} />
                  {/* <PropertyScore lat={propertyData?.lat} lng={propertyData?.lng}/> */}
                  <div className="card border-0 widget-request-tour mt-5">
                    <div className=" d-block text-center  ">
                      <Link className="py-2 bg-blue text-white px-3">
                        I am Interested
                      </Link>
                    </div>
                    <div className="card-body pb-5 pt-0">
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="pt-1 pb-0 px-0 d-block">
                          <div className="pt-5">
                            <div className="form-group mb-2 position-add-validate-symbol">
                              <input
                                type="text"
                                className="form-control form-control-lg border-0"
                                placeholder="Your Name"
                                {...register("name", { required: true })}
                                onChange={handleLead}
                              />
                              <span className="asterisk_input"></span>
                            </div>
                            <div className="form-group mb-2 position-add-validate-symbol">
                              <input
                                type="email"
                                className="form-control form-control-lg border-0"
                                placeholder="Your Email"
                                {...register("email", { required: true })}
                                onChange={handleLead}
                              />
                              <span className="asterisk_input"></span>
                            </div>
                            <div className="form-group mb-2">
                              {/* <input
                                type="text"
                                className="form-control form-control-lg border-0"
                                placeholder="Your Phone Number"
                                maxLength={10}
                                {...register("mobile")}
                                onChange={handleLead}
                              /> */}
                              <PhoneInput

                                className="form-control form-control-lg border-1 mb-2 d-flex"

                                defaultCountry="AE"
                                value={myphone}
                                onChange={handlephone}
                              />
                            </div>
                            <div className="form-group mb-4">
                              <textarea
                                className="form-control border-0"
                                rows="4"
                                placeholder="Your Message?"
                                {...register("message")}
                                onChange={handleLead}
                              ></textarea>
                            </div>
                            <button
                              type="submit"
                              className="btn bg-blue text-white hover-saffron btn-lg btn-block rounded"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  {companydata ? (
                    <div className="card border-0 widget-request-tour mt-5 mb-5">
                      <div className="card-body pb-5 ">
                        <img
                          src={companydata?.img}
                          // style={{ width: "150px" }}
                          alt={companydata?.company_name}
                        />
                        <Link
                          to={`/company/details/${companySlug}`}
                          className="d-block text-blue hover-saffron-text mt-2 fs-20"
                        >
                          {companydata?.company_name}
                        </Link>
                        <p className=" d-block fs-16 py-1">
                          <span className="fas fa-map-marker-alt mr-2"></span>
                          {companydata?.street_address}
                        </p>
                        <div className="mt-2">
                          <div className="fa fa-phone d-block fs-16 py-1">
                            <span className="ml-2 fs-16">
                              <CurrencyFormat
                                displayType={"text"}
                                value={companydata?.office_number}
                                format=" +###-##-#####"
                                mask="_"
                              />
                            </span>
                          </div>
                          <div className="fa fa-phone d-block fs-16 py-1">
                            <span className="ml-2 fs-16">
                              <CurrencyFormat
                                displayType={"text"}
                                value={companydata?.mobile_number}
                                format=" +###-##-#####"
                                mask="_"
                              />
                            </span>
                          </div>
                          <Link
                            to={companydata?.company_website}
                            target="blank"
                            className="fa fa-globe d-block fs-16 py-1 text-blue hover-saffron-text"
                          >
                            <span className="ml-2">Website</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </aside>
            </div>
          </div>
        </div>
        <MyModal show={show} handleClose={handleClose} />
        <Modal show={isPlaying} onHide={() => setIsPlaying(false)} size="lg">
          <div className="modal-content ">
            <div className=" f-right">
              <button
                onClick={() => setIsPlaying(false)}
                type="button"
                className="close m-0 fs-24 mt-3 mr-5"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            {/* <div className="modal-header border-0 p-0">
            </div> */}
            <iframe
              src={propertyData?.upload_video}
              alt="image"
              className="add-border-other-2"
              allow="autoplay; encrypted-media"
              allowFullScreen
            />
          </div>
        </Modal>
      </main>
      <Footer />
    </>
  );
};

export default PropertyDetails;
