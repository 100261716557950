import React, { useEffect, useState } from "react";

import SearchBox from "./SearchBox";

import Header from "../../header/Header";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Footer from "../../footer/Footer";
import moment from "moment";
import Loader from "../../Loader";
import axios from "axios";
import CurrencyFormat from "react-currency-format";
import axiosInstance from "../../interceptor/axiosInstance";
import { Link } from "react-router-dom";
import FavActionButton from "../../favActionButton/FavActionButton";
import CallActionButton from "../../callActionButton/CallActionButton";
import MyModal from "../MyModal";
import { toast } from "react-toastify";


const DataIntellegence = () => {
  const [selectedCity, setSelectedCity] = useState();
  const [allProperties, setAllProperties] = useState([]);
 

  const [tData, setTdata] = useState([]);
  const [thirdStartDate, setThirdStartDate] = useState()
  const [endDate, setEndDate] = useState();

  const [fliterData, setFliterData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [buildingType, setBuildingType] = useState([]);
  const [propertyType, setPropertyType] = useState([]);
  const mycity = selectedCity;
  const [propertyLabel, setPropertyLabel] = useState([]);
  const [selectedBuildingTypeId, setSelectedBuildingTypeId] = useState("");
  const [transactionData, setTransactionData] = useState([]);
 



  // Filter properties for the last 3 months
  // const lastthree = () =>{
  //   const last3MonthsProperties = transactionData?.results?.data.filter((item) => {
  //     const createdAtDate = new Date(item.transaction_date);
  //     const threeMonthsAgo = new Date();
  //     threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);

  //     return createdAtDate >= threeMonthsAgo;
  //   });
  //   setTdata(last3MonthsProperties)
  // }




  


  const [propertyTypeId, setpropertyTypeId] = useState("");
  const [propertyLabelId, setPropertyLabelId] = useState("");
  const buildingTypeName = buildingType.find(
    (type) => type.id === selectedBuildingTypeId
  );
  const labelTypeName = propertyLabel.find(
    (type) => type.id === propertyLabelId
  );
  const [labelName, setLabelName] = useState(undefined);
  const [propertyArea, setPropertyArea] = useState([]);
  const trueValue = transactionData?.results?.predicted_prices;
  const [roomType, setRoomType] = useState();

  const typeName = buildingTypeName?.name;
  // const avg_price_per_sqmt =
  //   transactionData?.results?.single_square_meter_average_price;
  // const avg_price = transactionData?.results?.average_amount;
  // const avg_size_per_sqmt = transactionData?.results?.average_property_size;
  // const actualPrice = (avg_price_per_sqmt * avg_size_per_sqmt) / 150
  // const avgPercentageSqmt = (avg_price_per_sqmt / avg_price) * 100;
  // const percentageChange =
  //   ((trueValue - avg_price_per_sqmt) / Math.abs(avg_price_per_sqmt)) * 100;
  // const percentageColor = percentageChange > 0 ? 'green' : 'red';
  const is_auth = localStorage.getItem("is_authenticated");
  /* ****************api for building type ************* */
  const API_URL = process.env.REACT_APP_API_URL;
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [visibleItems, setVisibleItems] = useState(12);
  const itemsToLoad = 12;
  const [favproperty, setfavproperty] = useState([]);
  /* *********Api TO Get Favourite property *********/
  const getfavourite = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        `${API_URL}/properties/user/get/favourite/`
      );
      if ((response.status = 200)) {
        const responseData = response.data;
        setfavproperty(responseData);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(" Please Contact Administrator");
      }
    } catch (err) {
      setLoading(false);
      toast.error(" Please Contact Administrator");
    }
  };

  useEffect(() => {
    window?.scrollTo(0, 0);
    if (is_auth === "false") {
      getfavourite();
    }
  }, [is_auth]);

  useEffect(() => {
    if (labelTypeName?.name === "Sale") {
      setLabelName("Sales");
    } else if (labelTypeName?.name === "Rent") {
      setLabelName("Rents");
    } else {
      setLabelName(undefined);
    }
  }, [labelTypeName]);
  useEffect(() => {
    if (typeName === "Commercial") {
      setRoomType(undefined);
    }
  }, [typeName]);

  /**************Api For Building Type */
  const GetBuildType = async () => {
    try {
      const response = await axios.get(`${API_URL}/properties/building/types/`);
      if ((response.status = 200)) {
        const responseData = response.data;
        setBuildingType(responseData);
      } else {
        toast.error(" Something went wrong");
      }
    } catch (err) {
      toast.error(" Something went wrong");
    }
  };
  /* ****************api for property type ************* */

  const GetPropertyType = async () => {
    // setLoading(true);
    if (selectedBuildingTypeId !== null) {
      try {
        const response = await axios.get(
          `${API_URL}/properties/property/types/?building_type=${selectedBuildingTypeId}`
        );
        if (response.status === 200) {
          const responseData = response.data;
          setPropertyType(responseData);
          // setLoading(false);
        } else {
          toast.error("Something went wrong");
        }
      } catch (err) {
        toast.error("Something went wrong");
      }
    }
  };
  /* ****************api for property label ************* */

  const GetPropertyLabel = async () => {
    try {
      const response = await axios.get(`${API_URL}/properties/property/label/`);
      if ((response.status = 200)) {
        const responseData = response.data;
        setPropertyLabel(responseData);
      } else {
        toast.error("  Something went wrong");
      }
    } catch (err) {
      toast.error(" Something went wrong");
    }
  };
  /* ****************api for property Area ************* */

  const GetPropertyArea = async () => {
    try {
      const response = await axios.get(`${API_URL}/dubailand/unique/area/`);
      if ((response.status = 200)) {
        const responseData = response.data;
        setPropertyArea(responseData);
      } else {
        toast.error("No transaction data available from DLD for this area");
      }
    } catch (err) {
      toast.error("No transaction data available from DLD for this area");
    }
  };

  /* ****************api for Table Data************* */
  const getData = async (currentPage) => {
    setLoading(true);
    try {
      let locationParams;

      if (labelName === "Rents") {
        locationParams = `/dubailand/rent/transactions/get-data/?page=${currentPage}`;
      } else {
        locationParams = `/dubailand/average/price/?page=${currentPage}`;
      }

      if (thirdStartDate !== undefined && endDate !== undefined) {
        locationParams += `&start_date=${thirdStartDate}&end_date=${endDate}`;
      }
      if (mycity !== undefined) {
        locationParams += `&area=${mycity}`;
      }
      if (typeName !== undefined) {
        locationParams += `&usage=${typeName}`;
      }
      if (labelName !== undefined) {
        locationParams += `&transaction_type=${labelName}`;
      }
      if (roomType !== undefined) {
        locationParams += `&room=${roomType}`;
      }

      if (
        mycity == undefined &&
        typeName == undefined &&
        labelName == undefined &&
        thirdStartDate == undefined &&
        endDate == undefined &&
        roomType == undefined
      ) {
        locationParams = `/dubailand/average/price/?page=${currentPage}`;
      }

      const response = await axios.get(`${API_URL}${locationParams}`);
      if (response.status === 200) {
        const responseData = response.data;
        setTransactionData(responseData);
        setTdata(responseData?.results?.data);
      } else {
        // Handle the case when the response status is not 200
        // You might want to add additional error handling logic here
      }
    } catch (err) {
      // Handle the error
      toast.error("No transaction data available from DLD for this area");
    } finally {
      // Make sure to set loading to false in the finally block
      setLoading(false);
    }
  };


  const handleNextClick = () => {
    const nextPage = currentPage + 1;
    setCurrentPage(nextPage);
    getData(nextPage);
  };

  const handlePrevClick = () => {
    if (currentPage > 1) {
      const prevPage = currentPage - 1;
      setCurrentPage(prevPage);
      getData(prevPage);
    }
  };

  useEffect(() => {
    if (currentPage > 0) {
      getData(currentPage);
    }
  }, [currentPage, mycity, typeName, labelName, roomType, endDate, thirdStartDate]);





  /* *******************get property data************/

  const getAllProperties = async () => {
    try {
      let locationParams = "/properties/advance/filter/?is_new_property=true";

      if (selectedBuildingTypeId !== undefined) {
        locationParams += `&building_type=${selectedBuildingTypeId}`;
      }
      if (propertyTypeId !== undefined) {
        locationParams += `&property_type=${propertyTypeId}`;
      }
      if (propertyLabelId !== undefined) {
        locationParams += `&property_label=${propertyLabelId}`;
      }
      if (selectedCity !== undefined) {
        locationParams += `&city=${selectedCity}`;
      }
      // Reset locationParams if all parameters are undefined or null
      if (
        selectedBuildingTypeId == undefined &&
        propertyTypeId == undefined &&
        propertyLabelId == undefined &&
        selectedCity == undefined
      ) {
        locationParams = "/properties/advance/filter/?is_new_property=true";
      }

      const response = await axios.get(`${API_URL}${locationParams}`);

      if (response.status === 200) {
        const responseData = response?.data;
        setAllProperties(responseData);
        // setFliterData(responseData);
        // setSearchData({ ...locatedData, state: { ...locatedData.state, lati: null, longi: null } });
      } else {
      }
    } catch (err) {
      toast.error("No transaction data available from DLD for this area");
    }
  };


  useEffect(() => {
    GetBuildType();
    GetPropertyLabel();
    GetPropertyArea();
    moment.locale("en");
  }, []);
  useEffect(() => {
    getAllProperties();
  }, [selectedBuildingTypeId, propertyTypeId, propertyLabelId, selectedCity]);
  useEffect(() => {
    if (selectedBuildingTypeId) {
      GetPropertyType();
    }
  }, [selectedBuildingTypeId]);

  /* *******************filter by month********** */

  const LastThreeMonth = () => {
    const currentDate = new Date();
    const lastMonth = new Date(currentDate);
    lastMonth.setMonth(currentDate.getMonth() - 3); // Subtract 2 to get the last month

    const formattedDate = `${lastMonth.getFullYear()}-${(lastMonth.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${lastMonth.getDate().toString().padStart(2, '0')}`;

    setThirdStartDate(formattedDate);
    const formattedCurrentDate = currentDate.toISOString().slice(0, 10);
    setEndDate(formattedCurrentDate);
  };
  const LastSixMonth = () => {
    const currentDate = new Date();
    const lastMonth = new Date(currentDate);
    lastMonth.setMonth(currentDate.getMonth() - 6); // Subtract 2 to get the last month

    const formattedDate = `${lastMonth.getFullYear()}-${(lastMonth.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${lastMonth.getDate().toString().padStart(2, '0')}`;

    setThirdStartDate(formattedDate);
    const formattedCurrentDate = currentDate.toISOString().slice(0, 10);
    setEndDate(formattedCurrentDate);
  };
  const LastMonth = () => {
    const currentDate = new Date();
    const lastMonth = new Date(currentDate);
    lastMonth.setMonth(currentDate.getMonth() - 2); // Subtract 2 to get the last month

    const formattedDate = `${lastMonth.getFullYear()}-${(lastMonth.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${lastMonth.getDate().toString().padStart(2, '0')}`;

    setThirdStartDate(formattedDate);
    const formattedCurrentDate = currentDate.toISOString().slice(0, 10);
    setEndDate(formattedCurrentDate);
  };
  const LastTwelveMonth = () => {
    const currentDate = new Date();
    const lastMonth = new Date(currentDate);
    lastMonth.setMonth(currentDate.getMonth() - 12); // Subtract 2 to get the last month

    const formattedDate = `${lastMonth.getFullYear()}-${(lastMonth.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${lastMonth.getDate().toString().padStart(2, '0')}`;

    setThirdStartDate(formattedDate);
    const formattedCurrentDate = currentDate.toISOString().slice(0, 10);
    setEndDate(formattedCurrentDate);
  };



  return (
    <>
      {loading ? <Loader /> : null}
      <Header />

      <main id="intelligence">
        <SearchBox
          buildingType={buildingType}
          setSelectedCity={setSelectedCity}
          setSelectedBuildingTypeId={setSelectedBuildingTypeId}
          selectedBuildingTypeId={selectedBuildingTypeId}
          propertyType={propertyType}
          setpropertyTypeId={setpropertyTypeId}
          setPropertyLabelId={setPropertyLabelId}
          propertyLabel={propertyLabel}
          propertyArea={propertyArea}
          setRoomType={setRoomType}
          labelName={labelName}
        />
        <section className="d-flex align-items-center text-center container-fluid px-20">
          <div className="mt-5  mx-auto justify-content-center">
            <p className="fs-24 text-dark font-weight-600">
              Real Estate Transactions Data as Per Dubai Land Department{" "}
            </p>
            <button className="btn bg-blue text-white border-c mb-2 hover-blue round-10 ml-5 blog-category fs-16" onClick={LastMonth}>
              Last Month
            </button>
            <button className="btn bg-blue text-white border-c mb-2 hover-blue round-10 ml-3 blog-category fs-16" onClick={LastThreeMonth}>
              Last 3 Month
            </button>
            <button className="btn bg-blue text-white border-c mb-2 hover-blue round-10 ml-3 blog-category fs-16" onClick={LastSixMonth}>
              Last 6 Month
            </button>
            <button className="btn bg-blue text-white border-c mb-2 hover-blue round-10 ml-3 blog-category fs-16" onClick={LastTwelveMonth}>
              Last 12 Month
            </button>

          </div>
        </section>
        <div className="container  mb-5">
          <div className="row mt-5 mb-5">
            <div className="col-lg-4 ">
              <div className="text-center border-1 p-5 shade bg-f3f3f3">
                <p className="text-dark font-weight-600" style={{ fontSize: "28px" }}>
                  <CurrencyFormat
                    value={Math.floor(
                      transactionData?.results
                        ?.single_square_meter_average_price
                    )}
                    displayType="text"
                    thousandSeparator={true}
                    suffix=".00"
                  />{" "}
                  AED<span className="fs-12 font-weight-600">/sqmt</span>
                </p>
                {labelName === "Rents" ? (<>
                  <p className="fs-18 text-uppercase text-dark font-weight-600 ">Average Rent price per sqmt</p>
                </>) : (<>
                  <p className="fs-18 text-uppercase text-dark font-weight-600">Average Sale price per sqmt</p>
                </>)}


              </div>
            </div>
            <div className="col-lg-4 ">
              <div className="text-center border-1 p-5 shade bg-f3f3f3">
                <p className=" text-dark font-weight-600" style={{ fontSize: "28px" }}>
                  <CurrencyFormat
                    value={Math.floor(transactionData?.results?.average_amount)}
                    displayType="text"
                    thousandSeparator={true}
                    suffix=".00"
                  />{" "}
                  AED
                </p>
                {labelName === "Rents" ? (<>
                  <p className="fs-18 text-uppercase text-dark font-weight-600">Average Rent price</p>
                </>) : (<>
                  <p className="fs-18 text-uppercase text-dark font-weight-600">Average Sale price</p>
                </>)}
              </div>
            </div>
            <div className="col-lg-4 ">
              <div className="text-center border-1 p-5  bg-f3f3f3 shade">
                <p className="text-dark font-weight-600" style={{ fontSize: "28px" }}>
                  <CurrencyFormat
                    value={Math.floor(
                      transactionData?.results?.predicted_prices
                    )}
                    displayType="text"
                    thousandSeparator={true}
                    allowNegative="false"
                    suffix=".00"
                  />{" "}
                  AED<span className="fs-12 font-weight-600">/sqmt</span>
                </p>
                {labelName === "Rents" ? (<>
                  <p className="fs-18 text-uppercase text-blue font-weight-600">Predicted Rent Price</p>
                </>) : (<>
                  <p className="fs-18 text-uppercase text-blue font-weight-600">Predicted Sale Price</p>
                </>)}




              </div>
            </div>
          </div>
          
          {labelName === "Rents" ? (<>
            <p className="fs-18 text-dark font-weight-600 ">Property rent transaction data</p>
          </>) : (<>
            <p className="fs-18 text-dark font-weight-600 ">Property sale transaction data</p>
          </>)}
          
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead className="bg-blue ">
                <TableRow className="">
                  <TableCell className="text-white font-weight-600">
                    Date
                  </TableCell>
                  <TableCell className="text-white font-weight-600 ">
                    Location
                  </TableCell>
                  <TableCell
                    className="text-white font-weight-600"
                    align="right"
                  >
                    Price(AED)
                  </TableCell>
                  <TableCell
                    className="text-white font-weight-600"
                    align="right"
                  >
                    Size(SQMT)
                  </TableCell>
                  <TableCell
                    className="text-white font-weight-600"
                    align="right"
                  >
                    Type
                  </TableCell>
                  <TableCell
                    className="text-white font-weight-600"
                    align="right"
                  >
                    Sub Type
                  </TableCell>
                  {typeName === "Residential" && labelName === "Sales" ? (<>
                    <TableCell
                      className="text-white font-weight-600"
                      align="right"
                    >
                      Bed
                    </TableCell>
                  </>) : null}
                </TableRow>
              </TableHead>
              <TableBody>
                {tData.map((row) => (
                  <TableRow /* key={row.name} */>
                    <TableCell>
                      {moment(row.transaction_date || row.registration_date).format("DD-MM-YYYY")}
                    </TableCell>
                    <TableCell>{row.area}</TableCell>
                    <TableCell align="right">
                      {" "}
                      <CurrencyFormat
                        value={row.amount || row.contract_amount}
                        displayType="text"
                        thousandSeparator={true}
                      />
                    </TableCell>
                    <TableCell align="right">{row.property_size}</TableCell>
                    <TableCell align="right">{row.property_sub_type}</TableCell>
                    <TableCell align="right">{row.property_type}</TableCell>
                    {typeName === "Residential" && labelName === "Sales" ? (<>
                      <TableCell align="right">{row.room || row.number_of_room}</TableCell> </>) : null}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="text-center mt-3 mb-3">
            <div className="">
              <button
                className="border-0 px-2 bg-blue text-white hover-saffron"
                disabled={currentPage === 1}
                onClick={handlePrevClick}
              >
                <i class="bi bi-caret-left-fill"></i>
              </button>

              <span className="px-2">
                {currentPage} of {transactionData?.count}
              </span>

              <button
                className="border-0 px-2 bg-blue text-white hover-saffron"
                disabled={currentPage === transactionData?.count}
                onClick={handleNextClick}
              >
                <i class="bi bi-caret-right-fill"></i>
              </button>
            </div>
          </div>

          <div className="col-lg-12 pt-7 p-0 order-2">
            {allProperties?.length > 0 ? (
              <div className="">
                <p className="fs-18 text-dark font-weight-600 ">
                  Related Properties in same area
                </p>
                {allProperties?.slice(0, visibleItems).map((item, index) => {
                  return (
                    <div className="p-0  border-1 round-5 mb-2">
                      <div className="row hover-change-image">
                        <div className="col-lg-4 col-md-6">
                          <Link to={`/property/details/${item?.slug}`}>
                            <div className="bg-overlay-gradient-1 bg-hover-overlay-gradient-3 rounded-lg card-img">
                              <img
                                className="propertylist-img"
                                style={{ height: "200px" }}
                                src={item?.upload_featured_image}
                              />
                              <div className="card-img-overlay d-flex flex-column justify-content-between ">
                                <div>
                                  <span className="badge badge-orange mr-2">
                                    {item?.property_label_name}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>

                        <div className="col-lg-8 col-md-6 px-5">
                          <div className="d-flex justify-content-between">
                            <p
                              className="mt-3 fs-20 font-weight-bold text-heading mb-0 "
                              style={{ lineHeight: "1.3" }}
                            >
                              {item?.property_price ? (
                                <>
                                  <span className="text-black font-weight-500 fs-16">
                                    AED{" "}
                                  </span>
                                  <CurrencyFormat
                                    value={item?.property_price}
                                    displayType="text"
                                    thousandSeparator={true}
                                    suffix=".00"
                                  />
                                </>
                              ) : (
                                <>
                                  <span className="text-black font-weight-500 fs-16">
                                    AED{" "}
                                  </span>
                                  <CurrencyFormat
                                    value={item?.lease_price_per_mo}
                                    displayType="text"
                                    thousandSeparator={true}
                                    suffix=".00"
                                  />
                                  <span className="text-gray-light font-weight-500 fs-14">
                                    {" "}
                                    /Mo
                                  </span>
                                </>
                              )}
                            </p>
                            <div className="d-flex">
                              {item?.is_property === true ? (
                                <div>
                                  <span className="badge badge-orange mt-2 mr-2">
                                    New
                                  </span>
                                </div>
                              ) : null}
                              <div className="mt-2 mr-2">
                                <FavActionButton
                                  setShow={setShow}
                                  itemId={item?.id}
                                  favproperty={favproperty}
                                  setfavproperty={setfavproperty}
                                  setLoading={setLoading}
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            className="list-inline-item text-black font-weight-500 fs-15 d-flex align-items-center mr-5 mb-0"
                            data-toggle="tooltip"
                            title="Size"
                          >
                            {item?.building_type_name}
                            <span className="text-gray-light ml-2 mr-2">|</span>
                            Area: {item?.property_size}Sq.Ft
                            {item?.building_type_name === "Residential" ? (
                              <>
                                {item?.bedroom !== null &&
                                  item?.bathroom !== null ? (
                                  <>
                                    <span className="text-gray-light ml-2 mr-2">
                                      |
                                    </span>
                                    {item?.bedroom}{" "}
                                    <img
                                      src="images/bedd.png"
                                      className="icon icon-bedroom fs-18  mr-1 width-32 ml-1 mr-2"
                                    />
                                    {item?.bathroom}{" "}
                                    <img
                                      src="images/bathtubb.png"
                                      className="icon icon-bedroom fs-18 mr-1 mr-1 width-32"
                                    />
                                  </>
                                ) : null}
                              </>
                            ) : null}
                          </div>
                          <h2 className="mt-1" style={{ lineHeight: "0.5" }}>
                            <a className="fs-16 text-dark">
                              {item?.property_name}
                            </a>
                          </h2>

                          <p
                            className="text-blue  mb-2 "
                            style={{ lineHeight: "1.7" }}
                          >
                            <img
                              src="images/locations.png"
                              className="icon icon-bedroom fs-18  mr-1 width-32 "
                            />
                            {item?.street_address}
                          </p>

                          <CallActionButton key={item?.id} item={item} />
                        </div>
                      </div>
                    </div>
                  );
                })}
                {allProperties?.length > visibleItems && (
                  <div className="d-flex justify-content-center">
                    <button
                      className="btn bg-blue hover-saffron text-white "
                      onClick={() =>
                        setVisibleItems((prev) => prev + itemsToLoad)
                      }
                    >
                      Load More
                    </button>
                  </div>
                )}
              </div>
            ) : null}
          </div>
        </div>

        <MyModal show={show} handleClose={handleClose} />
      </main>

      <Footer />
    </>
  );
};

export default DataIntellegence;
