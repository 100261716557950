import React, { useEffect, useState } from "react";
import moment from "moment";
import Header from "../../header/Header";
import Footer from "../../footer/Footer";
import BlogSidebar from "./BlogSidebar";
import { Link } from "react-router-dom";
import Loader from "../../Loader";

import axios from "axios";
import { toast } from "react-toastify";
const API_URL = process.env.REACT_APP_API_URL;

const BlogPage = () => {
  moment.locale('en');
  const [loading, setLoading] = useState(false);
  const [blogData, setblogData] = useState([]);
  const [category,setCategory]= useState([]);
  const [ShowData, setShowData] = useState("");
  const GetBLog = async () => {
     setLoading(true);
    try {
      const response = await axios.get(`${API_URL}/blogs/get/`);
      if ((response.status === 200)) {
        const responseData = response.data;
        setblogData(responseData);
        
         setLoading(false);
      } else {
        
         setLoading(false);
         toast.error(' Please Contact Administrator');
      }
    } catch (err) {
     
       setLoading(false);
       toast.error(' Please Contact Administrator');
    }
  };
  const GetCategory = async () => {
    
    try {
      const response = await axios.get(`${API_URL}/blogs/category/`);
      if ((response.status === 200)) {
        const responseData = response.data;
        setCategory(responseData);
        
      } else {
        setLoading(false);
        toast.error(' Please Contact Administrator');
         
      }
    } catch (err) {
      
     
       toast.error(' Please Contact Administrator');
    }
  };

  useEffect(() => {
    GetBLog();
    GetCategory();
  }, []);

  return (
    <>
    {loading ? <Loader /> : null}
      <Header />
      <main id="content" className="mt-100">

        <section className="pt-8 pb-8">
          <div className="container-fluid px-20">
            <div className="row  ">
              <p className="fs-18 text-dark font-weight-600  ">
                {category.map((item) => (
                   <Link
                   to={`/blog/${item?.slug}`}
                   key={item.id}
                   state={{ name: item?.name }}
                   className={`btn-link-container ml-2`}
                 >
                   <span className="btn blog-category border-c bg-blue text-white hover-saffron round-10 fs-16">
                     {item?.name}
                   </span>
                 </Link>
                ))}

              
              </p>

              <div className="col-lg-9 mt-3 border-right">
                <div className="row">
                  {blogData.map((item,id) => (
                    <div className="col-lg-4 mb-6" key={id}>
                      <div className="card border-0 shadow-xxs-3">
                        <div className="position-relative d-flex align-items-end card-img-top hover-shine">
                          <Link
                            to={`/blog/${item?.category_slug}/${item?.slug}`}
                            state={{ blogData: blogData }}
                            className="text-heading "
                          >
                            <img
                              src={item?.image || "images/favicon-icon.png"}
                              alt={item?.title}
                            />
                            <div className="card-img-overlay p-2 d-flex flex-column">
                                <div>
                                  <span className="badge mr-2 badge-orange">
                                    {item?.category_name}
                                  </span>
                                </div>
                              </div>
                          </Link>
                        </div>
                        <div className="card-body px-5 pt-3 pb-5">
                          <p className="mb-1 fs-13">
                            {moment(item?.create_at).format("DD-MM-YYYY")}
                          </p>
                          <h3 className="fs-18 text-heading lh-194 mb-1">
                            <Link
                              to={`/blog/${item?.category_slug}/${item?.slug}`}
                              state={{ blogData: blogData }}
                              className="text-heading "
                            >
                              {item?.title}
                            </Link>
                          </h3>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item?.txt.substring(0, 250),
                            }}
                          />

                          <Link
                            to={`/blog/${item?.category_slug}/${item?.slug}`}
                            state={{ blogData: blogData }}
                            className="text-heading "
                          >
                            Learn more
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <BlogSidebar />
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};
export default BlogPage;