import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import PrivateRoute from "./route/PrivateRoute";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LandingPage from "./landingPage/LandingPage";
import Login from "./auth/Login";
import Signup from "./auth/Signup";
import Dashboard from "./dashboard/Dashboard";
import DashboardLayout from "./dashboard/DashboardLayout";
import AddEditProfile from "./dashboard/pages/profile/AddEditProfile";
import AddEditCompany from "./company/AddEditCompany";
import Profile from "./dashboard/pages/profile/Profile";
import PropertyKind from "./dashboard/pages/property/PropertyKind";
import AddEditProperty from "./dashboard/pages/property/AddEditProperty";
import AllProperties from "./dashboard/pages/property/AllProperties";
import MyCompany from "./company/MyCompany";
import BusinessLead from "./dashboard/pages/leads/BusinessLead";
import PropertyDetails from "./pages/PropertyDetails";
import Team from "./Team/Team";
import TeamAdd from "./Team/TeamAdd";
import DataIntellegence from "./landingPage/intellegence/DataIntellegence";
import { Contact } from "./contact/Contact";
import PropertyList from "./pages/advancedSearch/PropertyList";
import MapView from "./pages/advancedSearch/MapComponent";
import MapViewNewProject from "./pages/advancedSearch/MapComponentNewProject";
import PropertyLead from "./dashboard/pages/leads/PropertyLead";
import Businessfinder from "./landingPage/Bussiness-Finder/Businessfinder";
import ProfileDetails from "./pages/ProfileDetails";
import CompanyDetails from "./pages/advancedSearch/CompanyDetails";
import Plan from "./landingPage/Plans/Plan";
import EmailVerification from "./auth/SignupEmailVerification.js";
import VerificationEmail from "./auth/VerificationEmail.js";
import ForgetPassword from "./auth/ForgetPassword.js";
import ForgetNewPassword from "./auth/ForgetNewPassword.js";
import Portfolio from "./dashboard/portfolio/Portfolio.js";
import FavouriteProperty from "./dashboard/pages/property/FavouriteProperty.js";
import Success from "./landingPage/Plans/Success.js";
import Savesearch from "./dashboard/search/Savesearch.js";
import MyStats from "./dashboard/MyStats.js";
import StatLead from "./dashboard/StatLead.js";
import MyReviews from "./dashboard/pages/reviews/MyReviews.js";
import BlogPage from "./pages/blog/BlogPage.js";
import { Provider } from "react-redux";
import store from "./redux/store.js";
import Policy from './pages/Policy.js';
import ChangePassword from "./auth/ChangePassword.js";
import Invoice from "./dashboard/pages/invoice/Invoice.js";

import SingleBlog from "./pages/blog/SingleBlog.js";
import BlogCategory from "./pages/blog/BlogCategory.js";
import Areascore from "./landingPage/intellegence/Areascore.js";
import ArchiveProperty from "./dashboard/pages/property/ArchiveProperty.js";
import Terms from "./pages/term.js";
import AdvancedNewProject from "./pages/advancedSearch/advancedNewProject.js";
import MyContact from "./dashboard/MyContact.js";
import MultiImageUploader from "./dashboard/pages/property/MultiImgUploader.js";
import PreviewProperty from "./dashboard/pages/property/PreviewProperty.js";
import AddProperty from "./dashboard/pages/property/AddProperty.js";
import SuperLandingPage from "./SuperLandingPage.js";

const App = () => {
  return (
    <>
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<SuperLandingPage />} />
            <Route path="/home-page" element={<LandingPage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/signup/verification/" element={<EmailVerification />} />
            <Route
              path="/activation/:uid/:token/"
              element={<VerificationEmail />}
            />
            <Route path="/forgetpassword/" element={<ForgetPassword />} />
            <Route
              path="/forget/password/confirm/:uid/:token/"
              element={<ForgetNewPassword />}
            />
            <Route path="*" element={<Navigate to="/" />} />
            <Route path="/property/details/:slug" element={<PropertyDetails />} />
            <Route path="/profile/details/:id" element={<ProfileDetails />} />
            <Route path="/company/details/:slug" element={<CompanyDetails />} />
            <Route path="/intellegence" element={<DataIntellegence />} />
            <Route path="/propertyscore" element={<Areascore />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/businessfinder" element={<Businessfinder />} />
            <Route path="/advance-search" element={<PropertyList />} />
            <Route path="/advance-search/new-project" element={<AdvancedNewProject />} />
            <Route path="/map" element={<MapView />} />
            <Route path="/map/new-project" element={<MapViewNewProject />} />
            <Route path='/termanduse' element={<Terms />} />
            <Route path='/policy' element={<Policy />} />
            <Route path="/blog">
              <Route path="" element={<BlogPage />} />
              <Route path=":slug" element={<BlogCategory />} />
              <Route path=":name/:slug" element={<SingleBlog />} />


            </Route>

            <Route path="/plan" element={<><PrivateRoute /> <Plan /></>} />
            <Route path="/preview" element={<PreviewProperty />} />
            <Route path="" element={
              <>
                <PrivateRoute />

                <DashboardLayout />
              </>
            }
            >

              <Route path="/dashboard">
                <Route path="" element={<Dashboard />} />
              </Route>
              <Route path="property">
                <Route path="" element={<AllProperties />} />
                <Route path="kind" element={<PropertyKind />} />
                <Route path="add" element={<AddProperty />} />
                <Route path="edit/:id" element={<AddProperty />} />
                <Route path="edit/media/:id" element={<MultiImageUploader />} />
                <Route path="favourite" element={<FavouriteProperty />} />

              </Route>
              <Route path="team">
                <Route path="" element={<Team />} />
                <Route path="add" element={<TeamAdd />} />
              </Route>
              <Route path="profile">
                <Route path="" element={<Profile />} />
                <Route path="edit/:id" element={<AddEditProfile />} />
                <Route path="change-password" element={<ChangePassword />} />
              </Route>
              <Route path="leads">
                <Route path="" element={<BusinessLead />} />
                {/* <Route path="edit/:id" element={<AddEditProfile />} /> */}
                <Route path="property" element={<PropertyLead />} />
              </Route>
              <Route path="mycontact" element={<MyContact />} />
              <Route path="archive" element={<ArchiveProperty />} />
              <Route path="company/create" element={<AddEditCompany />} />
              <Route path="company/edit/:id" element={<AddEditCompany />} />
              <Route path="company/create" element={<AddEditCompany />} />
              <Route path="mycompany" element={<MyCompany />} />
              <Route path="portfolio" element={<Portfolio />} />
              <Route path="save-search" element={<Savesearch />} />
              <Route path="stats" element={<MyStats />} />
              <Route path="reviews" element={<MyReviews />} />
              <Route path="/contactlead" element={<StatLead />} />
              <Route path="/invoice" element={<Invoice />} />
            </Route>
            <Route path="/success" element={<Success />} />
          </Routes>
        </BrowserRouter>
        <ToastContainer />
      </Provider>
    </>
  );
};
export default App;