import React, { useEffect, useState } from "react";
import { ImCross } from "react-icons/im";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../../interceptor/axiosInstance";
import { Modal } from "react-bootstrap";

const API_URL = process.env.REACT_APP_API_URL;

const Portfolio = () => {
  const [show, setShow] = useState(false);
  const togglePopup = () => {
    setShow(!show);
  };

  const [projectBrochure, setProjectBrochure] = useState([]);
  const [projectBrochureSubmit, setProjectBrochureSubmit] = useState([]);
  const { state } = useLocation();
  const PropertyId = state?.item?.id;
  const [selectedImage, setSelectedImage] = useState([]);
  const [imageData, setImageData] = useState([]);
  const [selectedVideo, SetSelectedVideo] = useState([]);
  const [videoPreview, setVideoPreview] = useState([]);
 
  const [description, setDescription] = useState("");
  const [desc_video, setDescription_video] = useState("");
  const [desc_pdf, setDescription_pdf] = useState("");

  const ImageHandler = (e) => {
    const size = parseFloat(e?.target?.files[0]?.size / (1024 * 1024)).toFixed(
      2
    );

    if (size > 2) {
      toast.error("Please select an image size less than 2 MB");
      return;
    }

    if (e?.target?.files) {
      const fileArray = Array.from(e?.target?.files).map((file) =>
        URL.createObjectURL(file)
      );
      const fileImage = Array.from(e?.target?.files);

      if (fileArray?.length > 100) {
        toast.error("You can upload a maximum of 100 images");
        return;
      }

      setSelectedImage((prevImages) => [...prevImages, ...fileArray]);
      setImageData((prevImages) => [...prevImages, ...fileImage]);

      Array.from(e?.target?.files).map((file) => URL.revokeObjectURL(file));
    }
  };

  const VideoHandler = (e) => {
    const videoSize = parseFloat(
      e?.target?.files[0]?.size / (1024 * 1024)
    ).toFixed(20);

    if (videoSize > 100) {
      toast.error("Please select Video size less than 100 MB");
      return;
    }

    if (e?.target?.files) {
      const fileArray = Array.from(e?.target?.files).map((file) =>
        URL.createObjectURL(file)
      );
      const fileVideo = Array.from(e?.target?.files);

      SetSelectedVideo((prevVideo) => [...prevVideo, ...fileArray]);
      setVideoPreview((prevVideos) => [...prevVideos, ...fileVideo]);

      Array.from(e?.target?.files).map((file) => URL.revokeObjectURL(file));
    }
  };

  const ProjectBrochure = (e) => {
    if (e?.target?.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const fileURL = URL.createObjectURL(file);
  
      // Replace the existing project brochure with the new one
      setProjectBrochure([fileURL]);
      setProjectBrochureSubmit([file]);
    }
  };
  

  const renderMedia = (source, type) => {
    if (source.length === 0) {
      return null;
    }

    return source.map((media, index) => (
      <div className="previewimgmuploader" key={index}>
        {type === "photo" && <img src={media} alt="photo" />}
        {type === "video" && (
          <iframe
            src={media}
            className="w-100 add-border-other"
            style={{ width: "300px", height: "200px" }}
            title={`video-${index}`}
          ></iframe>
        )}
        {type === "brochure" && (
          <iframe
            src={media}
            alt="brochure"
            className="w-100 add-border-other"
            style={{ width: "250px", height: "200px" }}
            title={`brochure-${index}`}
          ></iframe>
        )}
        <span
          className="services-add-preview"
          onClick={() => onRemove(index, type)}
        >
          <ImCross className="cross-icon-service" />
        </span>
      </div>
    ));
  };

  const onRemove = (index, type) => {
    if (type === "photo") {
      let arr = [...selectedImage];
      arr.splice(index, 1);
      setSelectedImage(arr);
  
     
    } else if (type === "video") {
      let arr = [...selectedVideo];
      arr.splice(index, 1);
      SetSelectedVideo(arr);
  
      
      setVideoPreview([]);
    } else if (type === "brochure") {
      let arr = [...projectBrochure];
      arr.splice(index, 1);
      setProjectBrochure(arr);
      setProjectBrochureSubmit(arr);
    }
  };
  

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  const ImageSubmitPortfolio = async () => {

    if (!description || description.trim() === "") {
      toast.error("Please Enter  Description");
      return;
    }
    const formData = new FormData();
    imageData.forEach((image) => {
      formData.append("image", image);
      formData.append("txt", description);
    });

    try {
      const response = await axiosInstance.post(
        `${API_URL}/account/user/image/post/`,
        formData,
        config
      );

      if (response.status === 201) {
        toast.success("Images are uploaded");
        setDescription("");
        setSelectedImage([]);
        window.location.reload();
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      toast.error("Please Contact Administrator");
    }
  };

  const VideoSubmit = async () => {
    if (!videoPreview.length) {
      toast.error("Please Select Video");
      return;
    }

    if (!desc_video || desc_video.trim() === "") {
      toast.error("Please Enter Video Description");
      return;
    }

    setShow(true);

    const formData = new FormData();
    videoPreview.forEach((video) => {
      formData.append("video", video);
      formData.append("txt", desc_video);
    });

    try {
      const response = await axiosInstance.post(
        `${API_URL}/account/user/video/post/`,
        formData,
        config
      );

      if (response.status === 201 || response.status === 200) {
        toast.success("Successfully uploaded");
        setDescription_video("");
        SetSelectedVideo([]);
        window.location.reload();
        
      } else {
        toast.error("Something went wrong");
      }
      setShow(false);
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  const PdfSubmit = async () => {
    if (!desc_pdf || desc_pdf.trim() === "") {
      toast.error("Please Enter Description");
      return;
    }
  
    setShow(true);
  
    // const formData = new FormData();
    // // Take only the first brochure for submission
    // formData.append("pdf", projectBrochure[0]); 
    // formData.append("txt", desc_pdf);
    const formData = new FormData();
    projectBrochureSubmit.forEach((video) => {
      formData.append("pdf", projectBrochureSubmit[0]);
      formData.append("txt", desc_pdf);
    });
  
    try {
      const response = await axiosInstance.post(
        `${API_URL}/account/user/boucher/post/`,
        formData,
        config
      );
  
      if (response.status === 201 || response.status === 200) {
        toast.success("Successfully uploaded");
        setDescription_pdf("");
        // Reset the projectBrochure state to empty array after successful upload
        setProjectBrochure([]);
        setProjectBrochureSubmit([]);
        
        window.location.reload();
      } else {
        toast.error("Something went wrong");
      }
      setShow(false);
    } catch (error) {
      toast.error("Something went wrong");
      setShow(false);
    }
  };


  return (
    <>
      <div className="overflow-hidden">
        <div className="agent-row margin-re-top">
          <div className="container">
            <h1
              className="fs-32"
              style={{
                width: "100%",
                margin: "0 auto",
                textAlign: "center",
                marginBottom: "20px",
              }}
            >
              Update Your Portfolio
            </h1>
            {/* responsive content */}
            <div className="header-r-logout ">
              <div className="row">
                <div className="col-sm-4 col-lg-3 margin-r-bottom-20">
                  <div className="card mb-2">
                    <div className="card-body ">
                      <p className="text-heading fs-22 lh-15 mb-4">
                        Browse Gallery Images
                      </p>
                      <div className="edit-prfl btn-sub">
                        Upload Images
                        <input
                          type="file"
                          className="input-file-profile"
                          multiple
                          accept="image/*"
                          onChange={(e) => ImageHandler(e)}
                        ></input>
                      </div>
                      <p className="fs-12">
                        Photos must be JPEG or PNG and Max Size 2MB
                      </p>
                      <label
                        htmlFor="description"
                        className="text-heading fs-22"
                      >
                        Description
                      </label>
                      <textarea
                        rows="3"
                        cols="50"
                        className="form-control form-control-lg border-0"
                        id="description"
                        name="description"
                        onChange={(e) => setDescription(e?.target?.value)}
                        value={description}
                      />
                    </div>
                  </div>
                  <div className="finalresult">
                 { selectedImage && renderMedia(selectedImage, 'photo')}
                  </div>
                  <button
                    type="button"
                    className="btn bg-blue text-white hover-saffron btn-sub"
                    onClick={() => ImageSubmitPortfolio()}
                    style={{
                      width: "110px",
                      marginLeft: "5px",
                      marginTop: "10px",
                    }}
                  >
                    Submit
                  </button>
                </div>

                <div className="col-sm-4 col-lg-3 margin-r-bottom-20">
                  <div className="card mb-2">
                    <div className="card-body ">
                      <p className="text-heading fs-22 lh-15 mb-4">
                        Browse Portfolio Video
                      </p>
                      <div className="edit-prfl btn-sub">
                        Upload Video
                        <input
                          type="file"
                          name="name"
                          className="input-file-profile"
                          accept="video/*"
                          onClick={(e) => VideoHandler(e)}
                        ></input>
                      </div>

                      <p className="fs-12">Video must be Max Size 20MB</p>
                      <label
                        htmlFor="description"
                        className="fs-22 text-heading"
                      >
                        Description
                      </label>
                      <textarea
                        rows="3"
                        cols="50"
                        className="form-control form-control-lg border-0"
                        id="description"
                        name="desc_video"
                        onChange={(e) => setDescription_video(e?.target?.value)}
                        value={desc_video}
                      />
                    </div>
                  </div>
                  <div className="finalresult">
                  {selectedVideo && renderMedia(selectedVideo, 'video')}
                  </div>
                  <button
                    type="button"
                    className="btn bg-blue text-white hover-saffron btn-sub"
                    onClick={() => VideoSubmit()}
                    style={{
                      width: "110px",
                      marginLeft: "5px",
                      marginTop: "10px",
                    }}
                  >
                    Submit
                  </button>
                </div>
                <div className="col-sm-4 col-lg-3 margin-r-bottom-20">
                  <div className="card mb-2">
                    <div className="card-body ">
                      <p className="text-heading fs-22 lh-15 mb-4">
                        Browse Your Brochure PDF
                      </p>
                      <div className="edit-prfl btn-sub">
                        Upload Brochure
                        <input
                          type="file"
                          name="file"
                          className="input-file-profile"
                          accept="application/pdf"
                          onClick={(e) => ProjectBrochure(e)}
                        ></input>
                      </div>

                      <p className="fs-12">
                        Brochure must be PDF and in good quality
                      </p>
                      <label
                        htmlFor="description"
                        className="text-heading fs-22"
                      >
                        Description
                      </label>
                      <textarea
                        rows="3"
                        cols="50"
                        className="form-control form-control-lg border-0"
                        id="description"
                        name="desc_pdf"
                        onChange={(e) => setDescription_pdf(e?.target?.value)}
                        value={desc_pdf}
                      />
                    </div>
                  </div>
                  <div className="finalresult">
                  { projectBrochure && renderMedia(projectBrochure, 'brochure')}
                  </div>
                  <button
                    type="button"
                    className="btn bg-blue text-white hover-saffron btn-sub"
                    onClick={() => PdfSubmit()}
                    style={{
                      width: "110px",
                      marginLeft: "5px",
                      marginTop: "10px",
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>

            {/* end */}

            <div className="col-lg-12 header-user-icon">
              <div className="card mb-6">
                <div className="card-body ">
                  <div className="collapse-tabs mb-10">
                    <ul
                      className="nav nav-tabs text-uppercase  d-md-inline-flex agent-details-tabs"
                      role="tablist"
                    >
                      <li className="nav-item">
                        <a
                          href="#uploadimages"
                          className="nav-link active  fs-13"
                          data-bs-toggle="tab"
                          role="tab"
                        >
                          Upload Images
                        </a>
                      </li>
                      <li className="nav-item ml-0">
                        <a
                          href="#uploadvideo"
                          className="nav-link  fs-13"
                          data-bs-toggle="tab"
                          role="tab"
                        >
                          Upload Video
                        </a>
                      </li>
                      <li className="nav-item ml-0">
                        <a
                          href="#uploadpdf"
                          className="nav-link  fs-13"
                          data-bs-toggle="tab"
                          role="tab"
                        >
                          Upload Brochure
                        </a>
                      </li>
                    </ul>

                    <div className="row">
                      <div className="tab-content shadow-none py-7 px-6 bg-white">
                        {/* images section */}
                        <div
                          className="tab-pane tab-pane-parent fade show active"
                          id="uploadimages"
                          role="tabpanel"
                        >
                          <div className="col-md-4">
                            <div className="dropzone upload-file text-center py-5">
                              <p className="text-heading fs-22 lh-15 mb-4">
                                Browse Gallery Images
                              </p>
                              <div className="edit-prfl btn-sub">
                                Upload Images
                                <input
                                  type="file"
                                  className="input-file-profile"
                                  multiple
                                  accept="image/*"
                                  onChange={(e) => ImageHandler(e)}
                                ></input>
                              </div>
                              <p className="fs-12">
                                Photos must be JPEG or PNG and Max Size 2MB
                              </p>
                              <label
                                htmlFor="description"
                                className="text-heading fs-22"
                              >
                                Description
                              </label>
                              <textarea
                                rows="3"
                                cols="50"
                                className="form-control form-control-lg border-0"
                                id="description"
                                name="description"
                                onChange={(e) =>
                                  setDescription(e?.target?.value)
                                }
                                value={description}
                              />
                            </div>
                          </div>
                          <div className="col-md-8">
                            <div className="finalresult">
                            { selectedImage && renderMedia(selectedImage, 'photo')}
                            </div>
                          </div>

                          <button
                            type="button"
                            className="btn bg-blue text-white hover-saffron btn-sub"
                            onClick={() => ImageSubmitPortfolio()}
                            style={{
                              width: "110px",
                              marginLeft: "15px",
                              marginTop: "20px",
                            }}
                          >
                            Submit
                          </button>
                        </div>
                        {/* video section */}

                        <div
                          className="tab-pane tab-pane-parent fade show "
                          id="uploadvideo"
                          role="tabpanel"
                        >
                          <div className="col-lg-4">
                            <div className="dropzone upload-file text-center py-5">
                              <p className="text-heading fs-22 lh-15 mb-4">
                                Browse Portfolio Video
                              </p>
                              <div className="edit-prfl btn-sub">
                                Upload Video
                                <input
                                  type="file"
                                  name="name"
                                  className="input-file-profile"
                                  accept="video/*"
                                  onChange={(e) => VideoHandler(e)}
                                ></input>
                              </div>

                              <p className="fs-12">
                                Video must be Max Size 20MB
                              </p>
                              <label
                                htmlFor="description"
                                className="fs-22 text-heading"
                              >
                                Description
                              </label>
                              <textarea
                                rows="3"
                                cols="50"
                                className="form-control form-control-lg border-0"
                                id="description"
                                name="desc_video"
                                onChange={(e) =>
                                  setDescription_video(e?.target?.value)
                                }
                                value={desc_video}
                              />
                            </div>
                          </div>
                          <div className="col-md-8">
                            <div className="finalresult">
                            { selectedVideo && renderMedia(selectedVideo, 'video')}
                            </div>
                          </div>

                          <button
                            type="button"
                            className="btn bg-blue text-white hover-saffron btn-sub"
                            onClick={() => VideoSubmit()}
                            style={{
                              width: "110px",
                              marginLeft: "15px",
                              marginTop: "20px",
                            }}
                          >
                            Submit
                          </button>
                        </div>
                        {/* pdf */}

                        <div
                          className="tab-pane tab-pane-parent fade show "
                          id="uploadpdf"
                          role="tabpanel"
                        >
                          <div className="col-lg-4">
                            <div className="dropzone upload-file text-center py-5">
                              <p className="text-heading fs-22 lh-15 mb-4">
                                Browse Your Brochure PDF
                              </p>
                              <div className="edit-prfl btn-sub">
                                Upload Brochure
                                <input
                                  type="file"
                                  name="file"
                                  className="input-file-profile"
                                  accept="application/pdf"
                                  onChange={(e) => ProjectBrochure(e)}
                                ></input>
                              </div>

                              <p className="fs-12">
                                Brochure must be PDF and in good quality
                              </p>
                              <label
                                htmlFor="description"
                                className="text-heading fs-22"
                              >
                                Description
                              </label>
                              <textarea
                                rows="3"
                                cols="50"
                                className="form-control form-control-lg border-0"
                                id="description"
                                name="desc_pdf"
                                onChange={(e) =>
                                  setDescription_pdf(e?.target?.value)
                                }
                                value={desc_pdf}
                              />
                            </div>
                          </div>
                          <div className="col-md-8">
                            <div className="finalresult">
                            { projectBrochure && renderMedia(projectBrochure, 'brochure')}
                            </div>
                          </div>

                          <button
                            type="button"
                            className="btn bg-blue text-white hover-saffron btn-sub"
                             onClick={() => PdfSubmit()}
                            style={{
                              width: "110px",
                              marginLeft: "15px",
                              marginTop: "20px",
                            }}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal className="modal-add-contact" show={show}>
        <Modal.Body>
          <h3 className="submit-property-modal-heading">Media Uploading…</h3>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default Portfolio;
