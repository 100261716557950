import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import Slider from "react-slick";
import PropertyGraph from "./graph/PropertyGraph";
import ContactGraph from "./graph/ContactGraph";
import { Dropdown, InputGroup, FormControl } from "react-bootstrap";
import whatimage from "../images/whatsapp.png";
import axiosInstance from "../interceptor/axiosInstance";
import DashboardFavourite from "./DashboardFavourite";
import DashboardCount from "./DashboardCount";
import Loader from "../Loader";
import LeadGraph from "./graph/LeadGraph";
import DashboardLead from "./DashboardLead";
import { toast } from "react-toastify";
const API_URL = process.env.REACT_APP_API_URL;
const Dashboard = () => {
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [AllProperties, setAllProperties] = useState([]);
  console.log(AllProperties,"All");
  const [selectedProperty, setSelectedProperty] = useState("");

  const [filter, setFilter] = useState("");

  // Filter properties based on the entered name
  const filteredProperties = AllProperties.filter((item) =>
    item.property_name.toLowerCase()?.includes(filter.toLowerCase())
  );


  const [profiledata, setprofiledata] = useState([]);
  const phonecall = profiledata?.count_phone_call;
  const whatsappcall = profiledata?.count_whatsapp_call;

  const [loading, setLoading] = useState(false);
  const user_Id = localStorage.getItem("currentUser_id");

  const [showAllItems, setShowAllItems] = useState(false); // New state to track search status


  const visibleProperties = showAllItems
    ? filteredProperties
    : filteredProperties.slice(0, 5);

  const getprofiledata = async () => {
    try {
      
      
      if (user_Id) {
        const response = await axiosInstance.get(`${API_URL}/account/user/profile/${user_Id}/patch/`);
        const profileinfo = response?.data;
        
       
        setprofiledata(profileinfo);
      }
    } catch (error) {
      toast.error(' Please Contact Administrator');
    } finally {
          }
  };
  

  const handleMouseEnter = () => {
    setDropdownVisible(true);
  };

  const handleMouseLeave = () => {
    setDropdownVisible(false);
  };

  /* ****************api for property list ************* */

  const getAllProperties = async () => {
    //  setLoading(true);
    try {
      const response = await axiosInstance.get(
        `${API_URL}/properties/dashboard/get/`
      );
      if ((response.status === 200)) {
        const responseData = response.data;
        setAllProperties(responseData);
        // setLoading(false)
      } else {
        
        // setLoading(false)
      }
    } catch (err) {
      // setLoading(false)
      toast.error(' Please Contact Administrator');
    }
  };


  const handleItemSelect = (selectedItem) => {
   
    setSelectedProperty(selectedItem);
   
  };

  useEffect(() => {
    getprofiledata();
    getAllProperties();
  }, [user_Id]);

  return (
    <>
    {loading ? <Loader /> : null}
    <main id="content" className="bg-gray-01 margin-re-top-80">
      <div className="px-3 px-lg-5 px-xxl-13 py-5">
        <div className="row">
          <div className="col-sm-3 col-xxl-3 mb-6">
            <Link to="#" className="text-title">
              <Dropdown
                show={isDropdownVisible}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <Dropdown.Toggle
                  variant="light"
                  style={{ background: "transparent", border: "none",padding: "0px", width:"290px"}}
                >
                  <div className="card">
                    <div className="card-body row align-items-center px-6 display-inline-block">
                      <div className="col-4">
                        <span className="w-83px h-83 fs-36 badge badge-orange badge-circle">
                          <img
                            style={{
                              marginTop: "5px",
                              width: "56px",
                              height: "56px",
                            }}
                            src="./images/property-icon.png"
                            alt="property-icon"
                          />
                        </span>
                      </div>
                      <div className="col-8 text-center max-width-100">
                        <p
                          className="fs-42 lh-12 mb-0 counterup text-gray"
                          data-start="0"
                          data-end="29"
                          data-decimals="0"
                          data-duration="0"
                          data-separator=""
                        >
                          {AllProperties.length}
                        </p>
                        <p className="text-gray ">My Properties</p>
                      </div>
                    </div>
                  </div>
                </Dropdown.Toggle>

                <Dropdown.Menu className="custom-dropdown-menu">
                  {/* Input field for entering property name */}
                  <InputGroup className="mb-3">
                      <FormControl
                        placeholder="Enter property name"
                        value={filter}
                        onChange={(e) => {
                          setFilter(e.target.value);
                          setShowAllItems(false); // Reset to display only 10 items when starting a new search
                        }}
                      />
                    </InputGroup>
                  {visibleProperties.map((item, index) => (
                      <Dropdown.Item
                        key={index}
                        onClick={() => handleItemSelect(item)}
                      >
                        {item?.property_name}
                      </Dropdown.Item>
                    ))}

                    {/* Display a button to show all items when there are more than 10 items */}
                    {/* {!showAllItems && filteredProperties.length > 5 && (
                      <Dropdown.Item onClick={() => setShowAllItems(true)}>
                        Show all results
                      </Dropdown.Item>
                    )} */}
                  </Dropdown.Menu>
                </Dropdown>
            </Link>
          </div>
          <div className="col-sm-3 col-xxl-3 mb-6">
            <Link to="/stats" className="text-title">
              <div className="card">
                <div className="card-body row align-items-center px-6 display-inline-block">
                  <div className="col-5 ">
                    <span className="w-83px h-83 d-flex align-items-center justify-content-center fs-36 badge badge-blue badge-circle">
                      <img
                        src="./images/property-team.png"
                        alt="property-rating"
                        style={{ width: "56px", height: "56px" }}
                      />
                    </span>
                  </div>
                  <div className="col-7 text-center max-width-100">
                    <p
                      className="fs-42 lh-12 mb-0 counterup"
                      data-start="0"
                      data-end="1730"
                      data-decimals="0"
                      data-duration="0"
                      data-separator=""
                    >
                      {selectedProperty?.count_phone_call > 0
                        ? selectedProperty.count_phone_call
                        : phonecall}{" "}
                    </p>
                    <p>Call Clicks</p>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-sm-3 col-xxl-3 mb-6">
            <Link to="/stats" className="text-title">
              <div className="card">
                <div className="card-body row align-items-center px-6  display-inline-block">
                  <div className="col-4 py-1">
                    <span className="w-83px h-83 d-flex align-items-center justify-content-center fs-36 badge bg-green badge-circle ">
                      {/* <img
                        src={whatimage}
                        alt="property-rating"
                        style={{ width: "56x", height: "56px" }}
                      /> */}
                      <i className="bi bi-whatsapp"></i>
                    </span>
                  </div>
                  <div className="col-7 text-center max-width-100">
                    <p
                      className="fs-42 lh-12 mb-0 counterup"
                      data-start="0"
                      data-end="329"
                      data-decimals="0"
                      data-duration="0"
                      data-separator=""
                    >
                      {selectedProperty?.count_whatsapp_call > 0
                        ? selectedProperty.count_whatsapp_call
                        : whatsappcall}
                    </p>
                    <p className="fs-14 lh-1">Whatsapp Clicks</p>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-sm-3 col-xxl-3 mb-6">
            <Link className="text-title" to="/leads">
              <div className="card">
                <div className="card-body row align-items-center px-6 display-inline-block">
                  <div className="col-5">
                    <span className="w-83px h-83 d-flex align-items-center justify-content-center fs-36 badge badge-blue badge-circle">
                      <img
                        src="./images/leadership.png"
                        alt="property-leads"
                        style={{ width: "56px", height: "56px" }}
                      />
                    </span>
                  </div>
                  <div className="col-7 text-center max-width-100">
                    <p
                      className="fs-42 lh-12 mb-0 counterup"
                      data-start="0"
                      data-end="914"
                      data-decimals="0"
                      data-duration="0"
                      data-separator=""
                    >
                      {/* //property_lead_count
                      {profiledata?.total_lead} */}
                      {selectedProperty?.property_lead_count > 0
                        ? selectedProperty.property_lead_count
                        : profiledata?.total_lead}
                    </p>
                    <p>My Leads</p>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>

        {/* Raghav Graph Div */}

        <div className="row mb-5">
          <div className="col-lg-6">
            <div className="card px-6 py-3 ">
              {/* <LeadGra selectedProperty={selectedProperty} AllProperties={AllProperties} /> */}
              <LeadGraph  selectedProperty={selectedProperty} AllProperties={AllProperties} />
            </div>
          </div>
          <div className="col-lg-6">
            
            <DashboardLead/>
          
          </div>
        </div>
<div className="row mb-5">
          <div className="col-lg-6">
            <div className="card px-6 py-3 ">
              <PropertyGraph selectedProperty={selectedProperty} AllProperties={AllProperties} />
            </div>
          </div>
          <div className="col-lg-6">
           
            <DashboardFavourite/>
         
          </div>
        </div>
        <div className="row mb-5">
          <div className=" col-lg-6">
            <div className="card px-6 py-3 ">
            
            <ContactGraph selectedProperty={selectedProperty} AllProperties={AllProperties}/>
            </div>
          </div>
          <div className=" col-lg-6">
            
            
            <DashboardCount AllProperties={AllProperties}/>
            
          </div>
        </div>
        {/* <div className="row mb-5">
          <div className="col-lg-12">
            <div className="card round-30">
              <div className="card-body align-items-center px-7 ">
                <div className="row">
                  <div className="section-content-admin d-flex px-2 py-3">
                    <div className="col-lg-1 col-sm-3">
                      <span className="w-83px h-83 d-flex align-items-center justify-content-center fs-36 badge badge-pink badge-circle">
                        <img
                          src="images/subscribe.png"
                          alt="property-leads"
                          style={{ width: "56px", height: "56px" }}
                        />
                      </span>
                    </div>
                    <div className="col-lg-10 col-sm-6">
                      <p className="mb-0">Subscribed Plan</p>
                      <h2 className="fs-16 ">Palne Name</h2>
                      <p className="text-blue">Valid till: Date</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        
      </div>
    </main>
    </>
  );
};

export default Dashboard;
