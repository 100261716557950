import React, { useEffect } from "react";
import { useState } from "react";

import BannerImage from "../../images/dataintel.jpg"



const SearchBox = (props) => {
  const API_URL = process.env.REACT_APP_API_URL;

  const buildingType = props.buildingType

  const selectedBuildingTypeId = props.selectedBuildingTypeId
  const setSelectedBuildingTypeId = props.setSelectedBuildingTypeId
  const propertyType = props.propertyType
  const setRoomType = props.setRoomType
  const setpropertyTypeId = props.setpropertyTypeId
  const setPropertyLabelId = props.setPropertyLabelId

  const propertyArea = props.propertyArea
  const propertyLabel = props.propertyLabel
  const labelName = props.labelName
  
  const buildingTypeName = buildingType.find(type => type.id === selectedBuildingTypeId)
  // const propertyLabelName = propertyLabel.find(type => type.id === selectedBuildingTypeId)
  // const handleButtonClick = (duration) => {
  //   setSelectedDuration(duration);
  //   setIsPopoverOpen(false)

  // };


  const options = [
    { value: "", label: "Select Your Area" },
    { value: "Downtown Dubai", label: "Downtown Dubai" },
    { value: "Deira", label: "Deira" },
    { value: "Dubai Marina", label: "Dubai Marina" },
    { value: "Business Bay", label: "Business Bay" },
    { value: "Bur Dubai", label: "Bur Dubai" },
    { value: "Al Fahidi Historical Neighbourhood", label: "Al Fahidi Historical Neighbourhood" },
    { value: "Al Barsha", label: "Al Barsha" },
    { value: "Palm Jumeirah", label: "Palm Jumeirah" },
    { value: "Al Karama", label: "Al Karama" },
    { value: "Mirdif", label: "Mirdif" },
    { value: "aJumeirah Village Circle", label: "Jumeirah Village Circle" },
    { value: "Al Satwa", label: "Al Satwa" },
    { value: "Al Sufouh", label: "Al Sufouh" },
    { value: "Al Quoz", label: "Al Quoz" },
    { value: "Al Wasl", label: "Al Wasl" },
    { value: "Dubai Land Department", label: "Dubai Land Department" },
  ];
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
  ];
  const [firstMonth, setFirstMonth] = useState("Jan");
  const [secondMonth, setSecondMonth] = useState(null);
  const setcity = props?.setSelectedCity;

  const handleFirstMonthChange = (e) => {
    const selectedMonth = e.target.value;
    setFirstMonth(selectedMonth);


    const filteredOptions = months.slice(months.indexOf(selectedMonth) + 1);
    setSecondMonth(null);
  };

return (<>

    <section
      className="h-100vh d-flex flex-column"
      style={{
        backgroundImage: `url(${BannerImage})`,
        backgroundSize: "cover",
        // backgroundColor: "#000",
        // opacity: "0.8",
      }}
    >
      <div className="d-flex align-items-center mr-bm-sr text-center" style={{ height: "470px" }}>
        <div className="container-fluid px-20">
          <h1 className="text-white mb-5 lh-2 fs-md-14 fs-lg-32 display-re-none">
            Property Prices in Dubai<br /> Price Trends & Top Selling Projects
          </h1>
          <div className="row mx-auto justify-content-center">
            <div class=" col-lg-3 bg-white  mr-2 round-10 border-mine mb-1">
              <div class="row align-items-center">

                <div class="col-lg-3  search-l  ">
                  <span class=" text-bule  shadow-none fs-16 font-weight-600">
                    Dubai
                  </span>
                </div>

                <div class="col-lg-9  ">
                  <select class="dropdown bootstrap-select search-select  bg-transparent  rounded-0 "
                    title="Select"
                    data-style="p-0 lh-17 text-dark" name="type"
                    onChange={(event) => setcity(event.target.value)}
                    placeholder="Select Dubai city"
                  >
                    {propertyArea.map((item, index) => (
                      <option className="dropdown-item" key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>

              </div>
            </div>
            <div class="col-lg-2 mr-2 search-r border-mine mb-1">
              <select class="search-select bg-transparent fs-16 font-weight-600"
                title="Select"
                name="type"
                id="buildingTypeId"
                onChange={(e) => {
                  setSelectedBuildingTypeId(e?.target?.value);
                }}
              >
                <option className="dropdown-item" >Building Type </option>
                {buildingType?.map((item) => {
                  return <option value={item?.id}>{item?.name}</option>;
                })}
              </select>
            </div>
            {/* <div class="col-lg-2 mr-2 search-r border-mine mb-1">
              <select class="search-select bg-transparent fs-16 font-weight-600"
                title="Select"
                name="type"
                onChange={(e) => {
                  setpropertyTypeId(e?.target?.value);

                }}
              >
                <option className="dropdown-item" >Property Type </option>
                {propertyType?.map((item) => {
                  return <option value={item?.id}>{item?.name}</option>;
                })}
              </select>
            </div> */}
            <div class="col-lg-2 mr-2 search-r border-mine mb-1">
              <select class="search-select bg-transparent fs-16 font-weight-600"
                title="Select"
                name="type"
                onChange={(e) => {
                  setPropertyLabelId(e?.target?.value);
                }}
              >
                <option className="dropdown-item" >For Sale/Rent </option>
                {
                  propertyLabel.map((item) => {
                    return (
                      <>
                        <option value={item?.id}>{item?.name}</option>
                      </>
                    )
                  })
                }
              </select>
            </div>
            {buildingTypeName?.name === "Residential"  && labelName ==="Sales"? (<>
              <div class="col-lg-1 mr-2 search-r border-mine mb-1">
                <select class="search-select bg-transparent fs-16 font-weight-600"
                  title="Select"
                  name="type"
                  onChange={(e) => {
                    setRoomType(e?.target?.value);

                  }}
                >
                  <option className="dropdown-item" >Bed </option>
                  <option className="dropdown-item" value="1 B/R">1 Bed </option>
                  <option className="dropdown-item" value="2 B/R">2 Bed </option>
                  <option className="dropdown-item" value="3 B/R">3 Bed </option>
                  <option className="dropdown-item" value="4 B/R">4 Bed </option>
                  <option className="dropdown-item" value="5 B/R">5 Bed </option>
                  <option className="dropdown-item" value="6 B/R">6 Bed </option>
                  <option className="dropdown-item" value="7 B/R">7 Bed </option>
                  <option className="dropdown-item" value="8 B/R">8 Bed </option>
                  <option className="dropdown-item" value="Studio">Studio </option>
                </select>
              </div>
            </>) : null}


          </div>
        </div>
      </div>
    </section>
    {/* <section className="container-fluid px-20">
      <div className="mt-5">
        <p className="fs-18">
          Real Estate Transactions Data in Dubai* {' '}
          <button className="btn bg-blue text-white hover-saffron" onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
            {selectedDuration}
          </button>
        </p>
        {isPopoverOpen && (
          <div className="date-popover">
            <button
              className="btn fs-16 border-mine round-10 px-3 py-1 bg-white text-gray"
              onClick={() => handleButtonClick('last 3 months')}
            >
              last 3 months
            </button>
            <button
              className="btn fs-16 border-mine round-10 px-3 py-1 bg-white text-gray"
              onClick={() => handleButtonClick('last 6 months')}
            >
              last 6 months
            </button><br />
            <button
              className="btn fs-16 border-mine round-10 px-3 py-1 bg-white text-gray"
              onClick={() => handleButtonClick('last 12 months')}
            >
              last 12 months
            </button>
            <button
              className="btn fs-16 border-mine round-10 px-3 py-1 bg-white text-gray"
              onClick={() => handleButtonClick('last 2 years')}
            >
              last 2 years
            </button>
            <button
              className="btn fs-16 border-mine round-10 px-3 py-1 bg-white text-gray"
              onClick={() => handleButtonClick('last 3 years')}
            >
              last 3 years
            </button>
          </div>
        )}
      </div>
    </section> */}
  </>

    // <div className="mxw-670 position-relative z-index-2 border-1">
    //   <input
    //     className="search-field"
    //     type="hidden"
    //     name="status"
    //     value="for-sale"
    //     data-default-value=""
    //   />
    //   <div className="d-flex">
    //     <span className="btn text-white  bg-blue hover-blue fs-18  rounded-left-0">
    //       Dubai
    //     </span>


    //     <select
    //       name="select"
    //       className="fs-18 rounded-left-0 border-0"
    //       value={firstMonth}
    //       onChange={handleFirstMonthChange}
    //     >
    //       {months.map((month) => (
    //         <option key={month} value={month}>
    //           {month}
    //         </option>
    //       ))}
    //     </select>
    //     <select
    //       name="select"
    //       className="fs-18 rounded-left-0 border-0"
    //       value={secondMonth}
    //       onChange={(e) => setSecondMonth(e.target.value)}
    //     >
    //       <option > {"---"} </option>
    //       {months
    //         .slice(months.indexOf(firstMonth) + 1)
    //         .map((month) => (
    //           <>

    //             <option key={month} value={month}>
    //               {month}
    //             </option>
    //           </>
    //         ))}
    //     </select>

    //     <div className="position-relative w-100">
    //       <Select
    //         className="fs-18 rounded-left-0 "
    //         options={options}
    //         placeholder="Select Your Area"
    //         isSearchable
    //         onChange={(selectedOption) => setcity(selectedOption ? selectedOption.value : '')}
    //       />



    //       <i className="far fa-search text-dark fs-30 position-absolute pr-4 pos-fixed-right-center"></i>

    //     </div>
    //   </div>
    // </div>
  );
};

export default SearchBox;