// import { render } from "@testing-library/react";
import React, { useEffect, useState, useRef } from "react";
import { ImCross } from 'react-icons/im';
import { toast } from "react-toastify";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
// import Loader from "../../Components/Loader";
import axios from "axios";
import axiosInstance from "../../../interceptor/axiosInstance";
import Loader from "../../../Loader";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from "react-accessible-accordion";
import Modal from "react-bootstrap/Modal";
import { Button, Dropdown, DropdownButton } from "react-bootstrap";
const API_URL = process.env.REACT_APP_API_URL;

const MultiImageUploader = () => {

    const togglePopup = () => {
        setIsOpen(!isOpen);
    }
    const { state } = useLocation()
    const propertyId = useParams();
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [selectedImage, setSelectedImage] = useState([]);
    console.log(selectedImage, 'selectedImage');
    const [imageData, setImageData] = useState([])
    console.log(imageData, "this is image Data");
    const [lastImageData, setLastImageData] = useState([])
    console.log(lastImageData, "lastImageData");
    const [floorPlans, setFloorPlans] = useState([]);
    const [formData, setFormData] = useState({
        properties: propertyId?.id,
        image: []
    });
    const iframeRefs = useRef([]);

    /* *********Api TO Get Favourite property *********/
    const getFloorPlans = async () => {
        setLoading(true);
        try {
            const response = await axiosInstance.get(
                `${API_URL}/properties/floor/${propertyId?.id}/`
            );
            if ((response.status = 200)) {
                const responseData = response.data;
                setFloorPlans(responseData);
                setLoading(false);
            }
        } catch (err) {
            setLoading(false);
            // toast.error("Floor Plan Please Contact Administrator");
        }
    };
    const ImageSubmit = async () => {
        setLoading(true);
        try {

            const formData = new FormData();
            formData.append('properties', propertyId?.id);
            imageData.forEach((file) => {
                formData.append(`image`, file);
            });
            const response = await axiosInstance.post(
                `${API_URL}/properties/image/add/`,
                formData);
            const myresponse = response.data;
            setLoading(false);
            // getPropertyImage();
            toast.success("Image Added Successfully");
        } catch (error) {
            setLoading(false);
            toast.error("Something Went Wrong");
        }
    };

    // Update formData whenever imageData changes
    useEffect(() => {
        setFormData({
            properties: propertyId?.id,
            image: JSON.stringify(imageData),
        });
    }, [imageData, propertyId?.id]);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [show, setShow] = useState(false);



    const [selectedVideo, SetSelectedVideo] = useState('');

    const [videoPreview, setVideoPreview] = useState()
    console.log(videoPreview, "videoPreview");
    //const sVideo = selectedVideo;
    //const str_arr = sVideo.split("/");
    //const rootScope = str_arr[str_arr.length - 1];
    //console.log(rootScope, 'rootScope');
    const [propertyData, setPropertyData] = useState();
    const [featuredImage, setFeaturedImage] = useState();
    const [avaPlan, setAvailablePlan] = useState();
    const [projectBrouchure, setProjectBrochure] = useState();
    console.log(featuredImage, "featuredImage")
    console.log(avaPlan, "avaPlan")
    console.log(projectBrouchure, "projectBrouchure")
    const [mediaType, setMediaType] = useState();
    const [getDropwonData, setGetDropdownData] = useState();

    const ImageHandler = (e) => {
        const size = parseFloat(e?.target?.files[0]?.size / (1024 * 1024)).toFixed(2);
        if (size > 2) {
            toast.error("Please select image size less than 2 MB")
            return;
        }
        if (e?.target?.files) {
            const fileArray = Array.from(e?.target?.files).map((file) => URL.createObjectURL(file))
            const fileImage = Array.from(e?.target?.files).map((file) => file)


            if (fileArray?.length > 20) {
                toast.error("You Can Maximum 20 Images Send")
                return;
            }

            setSelectedImage((prevImages) => prevImages.concat(fileArray))
            setImageData((prevImages) => prevImages.concat(fileImage))
            Array.from(e?.target?.files).map(
                (file) => URL.revokeObjectURL(file)

            )
        }
    }
    const [selectedFeatureImageUrl, SetSelectedFeatureImageUrl] = useState()
    const [selectedFeatureImage, setSelectedFeatureImage] = useState()
    const [featureImagePreview, setFeatureImagePreview] = useState()
    console.log(selectedFeatureImage, "selectedFeatureImage");
    const FeaturedImageHandler = (e) => {
        const size = parseFloat(e?.target?.files[0]?.size / (1024 * 1024)).toFixed(2);
        if (size > 10) {
            toast.error("Please select image size less than 10 MB")
            return;
        }
        if (e?.target?.files) {

            SetSelectedFeatureImageUrl(URL.createObjectURL(e?.target?.files[0]));
            setSelectedFeatureImage(e?.target?.files[0]);
            setFeatureImagePreview(e?.target?.files[0]);
        }
    }
    const [avaUrl, setAVAurl] = useState("");
    const [avaFile, setAVAFile] = useState("");
    const AVAHandler = (e) => {
        const size = parseFloat(e?.target?.files[0]?.size / (1024 * 1024)).toFixed(
            2
        );
        if (size > 50) {
            toast.error("Please select image size less than 50 MB");
            return;
        }
        setAVAurl(URL.createObjectURL(e?.target?.files[0]));
        setAVAFile(e?.target?.files[0]);

    };
    const [brochureUrl, setBrochureUrl] = useState("");
    const [brochureFile, setBrochureFile] = useState("");
    const brochureHandler = (e) => {
        const size = parseFloat(e?.target?.files[0]?.size / (1024 * 1024)).toFixed(
            2
        );
        if (size > 50) {
            toast.error("Please select image size less than 50 MB");
            return;
        }
        setBrochureUrl(URL.createObjectURL(e?.target?.files[0]));
        setBrochureFile(e?.target?.files[0]);

    };
    const VideoHandler = (e) => {
        const videoSize = parseFloat(e?.target?.files[0]?.size / (1024 * 1024)).toFixed(20);
        if (videoSize > 50) {
            toast.error("Please select Video size less than 50 MB")
            return;
        }
        SetSelectedVideo(URL.createObjectURL(e?.target?.files[0]))
        setVideoPreview(e?.target?.files[0])
    }
    const [floorName, setFloorName] = useState();
    console.log(floorName,"floorName");
    const [unitNumber, setUnitNumber] = useState();
    console.log(unitNumber,"unitNumber");
    const [floorPlanUrl, setFloorPlanUrl] = useState("");
    const [floorPlanFile, setFloorPlanFile] = useState("");
    console.log(floorPlanFile,"floorPlanFile");
    const floorPlanHandler = (e) => {
        const floorPlanSize = parseFloat(e?.target?.files[0]?.size / (1024 * 1024)).toFixed(20);
        if (floorPlanSize > 50) {
            toast.error("Please select Pdf size less than 50 MB")
            return;
        }
        setFloorPlanUrl(URL.createObjectURL(e?.target?.files[0]))
        setFloorPlanFile(e?.target?.files[0])
    }
    const renderPhotos = (source) => {

        return source.map((photo) => {
            return (
                <>
                    <div className="previewimgmuploader">
                        <img src={photo} key={photo} />

                        <span className="services-add-preview"
                            onClick={() => onRemove(photo)}><ImCross className="cross-icon-service" /></span>
                    </div>
                </>
            )


        })
    }
    const renderLastFeatured = (photo) => {

        return (
            <>
                <div className="previewimgmuploader">
                    <img src={photo} key={photo} />
                </div>
            </>
        )
    }
    const renderLastVedio = (photo) => {

        return (
            <>
                <div className=" img-uploader-cnt-inf">
                    <iframe src={photo} alt="image" className="add-border-other" />
                </div>
            </>
        )
    }
    const renderLastAva = (photo) => {

        return (
            <>
                <div className=" img-uploader-cnt-inf">
                    <iframe src={photo} alt="image" className="add-border-other" />
                </div>
            </>
        )
    }
    const renderFloorUrl = (photo) => {

        return (
            <>
                <div className=" img-uploader-cnt-inf">
                    <iframe src={photo} alt="image" className="add-border-other" />
                </div>
            </>
        )
    }
    const renderLastBro = (photo) => {

        return (
            <>
                <div className=" img-uploader-cnt-inf">
                    <iframe src={photo} alt="image" className="add-border-other" />
                </div>
            </>
        )
    }
    const renderNewBro = (photo) => {

        return (
            <>
                <div className=" img-uploader-cnt-inf">
                    <iframe src={photo} alt="image" className="add-border-other" />
                </div>
            </>
        )
    }
    const renderNewVideo = (photo) => {

        return (
            <>
                <div className=" img-uploader-cnt-inf">
                    <iframe src={photo} alt="image" className="add-border-other" />
                </div>
            </>
        )
    }
    const renderNewAva = (photo) => {

        return (
            <>
                <div className=" img-uploader-cnt-inf">
                    <iframe src={photo} alt="image" className="add-border-other" />
                </div>
            </>
        )
    }
    const renderNewFeatured = (photo) => {


        return (
            <>
                <div className="previewimgmuploader">
                    <img src={photo} key={photo} />

                    <span className="services-add-preview"
                        onClick={onRemoveFeatureImage}><ImCross className="cross-icon-service" /></span>
                </div>
            </>
        )



    }
    const renderPhotosLast = (lastImageData) => {

        return lastImageData.map((photo) => {
            return (
                <>
                    <div className="previewimgmuploader">
                        <img src={photo?.image} key={photo?.image} />
                        <span className="services-add-preview"
                            onClick={() => onRemoveLast(photo.id)} ><ImCross className="cross-icon-service" /></span>
                    </div>
                </>
            )


        })
    }


    const onRemove = (val) => {
        let arr = [...selectedImage]

        const Index = arr.indexOf(val);
        if (Index > -1) {
            arr.splice(Index, 1);
        }
        setSelectedImage(arr)
    }
    const onRemoveFeatureImage = () => {
        SetSelectedFeatureImageUrl("")
        setSelectedFeatureImage("")
        setFeatureImagePreview("")
        getPropertyData()
        // setSelectedImage(arr)
    }
    const onRemoveLast = async (id) => {

        // alert(id);
        try {
            setLoading(true);
            const response = await axiosInstance.delete(
                `${API_URL}/properties/image/destroy/${id}/`);



            if (response.status === 204) {
                toast.success("Image Removed Successfully");
            } else {


            }
            getPropertyImage();
        } catch (error) {
            // Handle network errors or exceptions
            toast.error(' Please Contact Administrator');
        }
    }

    const OnVideoRemove = (val) => {
        SetSelectedVideo()
    }
    /* ****************api for property image ************* */


    console.log(propertyData, "DATA");
    const getPropertyImage = async () => {
        setLoading(true);
        try {
            const response = await axiosInstance.get(
                `${API_URL}/properties/image/get/${propertyId?.id}/`
            );
            if ((response.status = 200)) {
                console.log(response?.data, "this is data")
                const responseData = response?.data;

                setLastImageData(responseData);
                setLoading(false);
            } else {
            }
        } catch (err) {
            setLoading(false);
            // toast.error(" Please Contact Administrator");
        }
    };
    /* ****************api for property image ************* */


    const getPropertyData = async () => {
        setLoading(true);
        try {
            const response = await axiosInstance.get(
                `${API_URL}/properties/${propertyId?.id}/patch/`
            );
            if ((response.status = 200)) {
                console.log(response?.data, "this is data")
                const responseData = response?.data;
                setPropertyData(responseData);
                setFeaturedImage(responseData?.upload_featured_image);
                setAvailablePlan(responseData?.upload_availability_plan);
                setProjectBrochure(responseData?.upload_project_brochure);
                setLoading(false);
            } else {
            }
        } catch (err) {
            setLoading(false);
            // toast.error(" Please Contact Administrator");
        }
    };
    /* ****************api for property image ************* */
    const [propertyVedio, setPropertyVideo] = useState("")
    console.log(propertyVedio, "propertyVedio");
    const getPropertyVideo = async () => {
        setLoading(true);
        try {
            const response = await axiosInstance.get(
                `${API_URL}/properties/video/${propertyId?.id}/`
            );
            if ((response.status = 200)) {
                console.log(response?.data, "this is data propertyVedio")
                const responseData = response?.data;


                setPropertyVideo(responseData?.upload_video);
                setLoading(false);
            } else {
            }
        } catch (err) {
            setLoading(false);
        }
    };
    useEffect(() => {
        getPropertyImage();
        getPropertyData();
        getPropertyVideo();
        getFloorPlans();
    }, [])


    const updateFeatureImage = async () => {
        setLoading(true);
        try {
            const response = await axiosInstance.put(
                `${API_URL}/properties/update/feature/${propertyId?.id}/`,
                { upload_featured_image: selectedFeatureImage } // Assuming you have a variable called newName with the updated property name
            );
            if (response.status === 200) {
                const responseData = response.data;
                toast.success("Property Featured Image updated successfully");
                // Optionally, you may want to update the property data in your state or wherever you're storing it
                // setPropertyData(responseData);
                SetSelectedFeatureImageUrl("")
                setSelectedFeatureImage("")
                setFeatureImagePreview("")
                getPropertyData();
            } else {
                setLoading(false);
                toast.error("Something went wrong while updating Featured Image");
            }
        } catch (error) {
            setLoading(false);
            toast.error("An error occurred while updating Featured Image. Please contact the administrator.");
        }
    };
    const updateAVAPlan = async () => {
        setLoading(true);
        try {
            const response = await axiosInstance.put(
                `${API_URL}/properties/update/availability/${propertyId?.id}/`,
                { upload_availability_plan: avaFile } // Assuming you have a variable called newName with the updated property name
            );
            if (response.status === 200) {
                const responseData = response.data;
                toast.success("Availability Plan updated successfully");
                // Optionally, you may want to update the property data in your state or wherever you're storing it
                // setPropertyData(responseData);
                setAVAurl("");
                setAVAFile("");
                getPropertyData();
            } else {
                setLoading(false);
                toast.error("Something went wrong while updating Availability Plan");
            }
        } catch (error) {
            setLoading(false);
            toast.error("An error occurred while updating Availability Plan. Please contact the administrator.");
        }
    };
    const updateBro = async () => {
        setLoading(true);
        try {
            const response = await axiosInstance.put(
                `${API_URL}/properties/update/brochure/${propertyId?.id}/`,
                { upload_project_brochure: brochureFile } // Assuming you have a variable called newName with the updated property name
            );
            if (response.status === 200) {
                const responseData = response.data;
                toast.success("Project Brochure updated successfully");
                // Optionally, you may want to update the property data in your state or wherever you're storing it
                // setPropertyData(responseData);
                setBrochureUrl("");
                setBrochureFile("");
                getPropertyData();
            } else {
                setLoading(false);
                toast.error("Something went wrong while updating Project Brochure");
            }
        } catch (error) {
            setLoading(false);
            toast.error("An error occurred while updating Project Brochure. Please contact the administrator.");
        }
    };
    const updateVedio = async () => {
        setLoading(true);
        try {
            const response = await axiosInstance.put(
                `${API_URL}/properties/video/${propertyId?.id}/`,
                { upload_video: videoPreview } // Assuming you have a variable called newName with the updated property name
            );

            if (response.status === 200) {
                const responseData = response.data;
                console.log(responseData);
                toast.success("Project video updated successfully");
                // Optionally, you may want to update the property data in your state or wherever you're storing it
                // setPropertyData(responseData);
                SetSelectedVideo("");
                setVideoPreview("")
                getPropertyVideo();
            } else {
                setLoading(false);
                toast.error("Something went wrong while updating Project video");
            }
        } catch (error) {
            setLoading(false);
            toast.error("An error occurred while updating Project video. Please contact the administrator.");
        }
    };
    const toggleFloorFull = (index) => {
        const iframe = iframeRefs.current[index]?.current;
        if (iframe) {
            if (iframe.requestFullscreen) {
                iframe.requestFullscreen();
            } else if (iframe.mozRequestFullScreen) { /* Firefox */
                iframe.mozRequestFullScreen();
            } else if (iframe.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
                iframe.webkitRequestFullscreen();
            } else if (iframe.msRequestFullscreen) { /* IE/Edge */
                iframe.msRequestFullscreen();
            }
        }
    };

    useEffect(() => {
        iframeRefs.current = Array(floorPlans.length)
            .fill(null)
            .map((_, i) => iframeRefs.current[i] || React.createRef());
    }, [floorPlans]);


    const deleteFloor = async (id) => {
        setLoading(true);
        try {
            const response = await axiosInstance.delete(
                `${API_URL}/properties/floor/${id}/delete/`
            );
            if (response.status === 204) {

                toast.success("Floor Plan deleted successfully");
                getFloorPlans();
            } else {
                setLoading(false);
                toast.error("Something went wrong while deleting Floor Plan");
            }
        } catch (error) {
            setLoading(false);

        }
    };
    /* ****************api for property status ************* */

    const addNewFloorPlan = async () => {

        try {
            setLoading(true)
            const formData = new FormData();

            formData.append("name", floorName || "");
            formData.append("no_of_unit", unitNumber || "");
            formData.append("properties", propertyId?.id || "");
            formData.append("floor_pdf", floorPlanFile || "");
            for (let entry of formData.entries()) {
                console.log(entry);
            }
    
            const response = await axiosInstance.post(`${API_URL}/properties/floor/add/`, formData);
            if (response.status = 201) {
                const responseData = response.data;
                toast.success("Floor Plan Created successfully");
                setFloorName('')
                setUnitNumber('')
                setFloorPlanFile("")
                setFloorPlanUrl("")
                getFloorPlans();
                setLoading(false)

            }
        } catch (err) {
            setLoading(false)
            toast.error(' Please Contact Administrator');

        }
    }


    // images post API
    // const ImageSubmit = (PropertyId) => {
    //     imageData?.map((item) => {
    //         const data = {}
    //         const formData = new FormData()
    //         formData.append('media_type', 1)

    //         // formData.append('media_path', fileArray)
    //         formData.append('property_id', PropertyId);
    //         if (selectedImage) {
    //             formData.append('media_path', item)
    //         }
    //         const option = {
    //             method: 'POST', headers: {
    //                 'access-control-allow-origin': '*',
    //                 'content-type': 'multipart/form-data',
    //                 'Authorization': `Bearer ${getToken()}`
    //             },
    //             data: formData,
    //             url: `${ApiUrl()}/api/property/property-media/${state?.item?.id}`
    //         };
    //         axios(option)
    //             .then(e => {
    //                 toast.success("Images Added Sucessfully")
    //             })
    //             .catch(err => {
    //                 if (err?.request?.status === 401) {
    //                     removeUserSession();
    //                     navigate('/')
    //                 }
    //             })
    //         // navigate('/developersingleproperty', { state: `${state?.item?.id}` })
    //         navigate('/devmyproperties')
    //     })
    // }

    // const VideoSubmit = () => {
    //     const formData = new FormData();
    //     formData.append('media_type', 2);
    //     formData.append('property_id', PropertyId);
    //     if (selectedVideo) {
    //         formData.append('media_path', videoPreview);
    //     }

    //     const option = {
    //         method: 'POST', headers: {
    //             'access-control-allow-origin': '*',
    //             'content-type': 'multipart/form-data',
    //             'Authorization': `Bearer ${getToken()}`
    //         },
    //         data: formData,
    //         url: `${ApiUrl()}/api/property/property-media/${state?.item?.id}`
    //     };
    //     axios(option)
    //         .then(e => {
    //             toast.success("Video Added Succesfully")
    //         })
    //         .catch(err => {
    //             if (err?.request?.status === 401) {
    //                 removeUserSession();
    //                 navigate('/')
    //             }
    //         })
    //     // navigate('/developersingleproperty', { state: `${state?.item?.id}` })
    //     navigate('/devmyproperties')
    // }
    return (
        <>
            {loading ? <Loader /> : null}
            <main id="content" className="bg-gray-01">
                <div className="px-3 px-lg-6 px-xxl-13 py-5 py-lg-10">
                    <div className="d-flex flex-wrap flex-md-nowrap mb-6">

                        <div className="container">

                            <section className="pb-2 pt-5">
                                <div className=" ">
                                    <div className="collapse-tabs mb-5 rounded-15">
                                        <ul
                                            className="nav nav-tabs text-uppercase d-md-inline-flex agent-details-tabs"
                                            role="tablist"
                                        >
                                            <li className="nav-item">
                                                <Link
                                                    to="#featuredimage"
                                                    className=" text-blue nav-link active  fs-16 padding-r-5"
                                                    data-bs-toggle="tab"
                                                    role="tab"
                                                >
                                                    Featured Image
                                                </Link>
                                            </li>
                                            <>
                                                <li className="nav-item ml-0">
                                                    <Link
                                                        to="#gallery"
                                                        className="nav-link text-blue fs-16 padding-r-5"
                                                        data-bs-toggle="tab"
                                                        role="tab"
                                                    >
                                                        Gallery
                                                    </Link>
                                                </li>

                                                <li className="nav-item ml-0">
                                                    <Link
                                                        to="#avaplan"
                                                        className="nav-link text-blue fs-16 padding-r-5"
                                                        data-bs-toggle="tab"
                                                        role="tab"
                                                    >
                                                        Availability Plan
                                                    </Link>
                                                </li>
                                                <li className="nav-item ml-0">
                                                    <Link
                                                        to="#brochure"
                                                        className="nav-link text-blue fs-16 padding-r-5"
                                                        data-bs-toggle="tab"
                                                        role="tab"
                                                    >
                                                        Project Brouchure
                                                    </Link>
                                                </li>
                                                <li className="nav-item ml-0">
                                                    <Link
                                                        to="#video"
                                                        className="nav-link text-blue fs-16 padding-r-5"
                                                        data-bs-toggle="tab"
                                                        role="tab"
                                                    >
                                                        Project Video
                                                    </Link>
                                                </li>
                                                <li className="nav-item ml-0">
                                                    <Link
                                                        to="#floorplan"
                                                        className="nav-link text-blue fs-16 padding-r-5"
                                                        data-bs-toggle="tab"
                                                        role="tab"
                                                    >
                                                        Floor Plans
                                                    </Link>
                                                </li>
                                            </>
                                        </ul>
                                        <div className="tab-content shadow-none  bg-white">
                                            <div
                                                className="tab-pane tab-pane-parent fade show active"
                                                id="featuredimage"
                                                role="tabpanel"
                                            >
                                                <div className="card border-0 bg-transparent">
                                                    <div className="card-body p-0">
                                                        <div className="d-flex justify-content-between">
                                                            <div className="">
                                                                {

                                                                    featuredImage?.length > 0 ? (<>
                                                                        <h2 className="card-title fs-22 lh-15 mb-1 text-dark">
                                                                            Featured Image
                                                                        </h2></>) : (<>
                                                                            <h2 className="card-title fs-22 lh-15 mb-1 text-dark">
                                                                                No Featured Image
                                                                            </h2>
                                                                        </>)
                                                                }

                                                            </div>
                                                            <div className="">
                                                                <div className="edit-prfl-uploader btn-sub" style={{ cursor: "pointer" }}>Upload Images
                                                                    <input type="file" className="input-file-profile" multiple accept="image/*" onChange={FeaturedImageHandler}></input></div>
                                                            </div>
                                                        </div>
                                                        <div className="finalresult ">
                                                            {
                                                                featuredImage ? renderLastFeatured(featuredImage) : null
                                                            }
                                                        </div>
                                                        <div className="finalresult ">
                                                            {
                                                                selectedFeatureImageUrl ? renderNewFeatured(selectedFeatureImageUrl) : null
                                                            }
                                                        </div>
                                                        <button type="button" className="btn bg-blue text-white hover-saffron btn-sub"
                                                            onClick={() => {
                                                                if (selectedFeatureImage) {
                                                                    // ImageSubmit();
                                                                    updateFeatureImage()

                                                                } else {
                                                                    toast.error("Please Select Media")
                                                                }
                                                            }}
                                                        >Submit</button>

                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="tab-pane tab-pane-parent fade"
                                                id="gallery"
                                                role="tabpanel"
                                            >
                                                <div className="card border-0 bg-transparent">
                                                    <div className="card-body p-0">
                                                        <div className="d-flex justify-content-between mb-5">
                                                            <div className="">
                                                                {
                                                                    lastImageData?.length > 0 ? (<>
                                                                        <h2 className="card-title fs-22 lh-15 mb-1 text-dark">
                                                                            Gallery
                                                                        </h2></>) : (<>
                                                                            <h2 className="card-title fs-22 lh-15 mb-1 text-dark">
                                                                                No Gallery Image
                                                                            </h2>
                                                                        </>)
                                                                }
                                                            </div>
                                                            <div className="">
                                                                <div className="edit-prfl-uploader btn-sub" style={{ cursor: "pointer" }}>Upload Images
                                                                    <input type="file" className="input-file-profile" multiple accept="image/*" onChange={ImageHandler}></input></div>
                                                            </div>
                                                        </div>

                                                        <div className="finalresult ">
                                                            {
                                                                lastImageData ? renderPhotosLast(lastImageData) : null
                                                            }
                                                        </div>
                                                        <div className="finalresult">
                                                            {
                                                                selectedImage ? renderPhotos(selectedImage) : null
                                                            }
                                                        </div>
                                                        <button type="button" className="btn bg-blue text-white hover-saffron btn-sub"
                                                            onClick={() => {
                                                                if (selectedImage?.length) {
                                                                    ImageSubmit();
                                                                    // VideoSubmit();

                                                                } else {
                                                                    toast.error("Please Select Media")
                                                                }
                                                            }}
                                                        >Submit</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="tab-pane tab-pane-parent fade"
                                                id="avaplan"
                                                role="tabpanel"
                                            >
                                                <div className="card border-0 bg-transparent">
                                                    <div className="card-body p-0">
                                                        <div className="d-flex justify-content-between">
                                                            <div className="">
                                                                {

                                                                    avaPlan?.length > 0 ? (<>
                                                                        <h2 className="card-title fs-22 lh-15 mb-1 text-dark">
                                                                            Availability Plan
                                                                        </h2></>) : (<>
                                                                            <h2 className="card-title fs-22 lh-15 mb-1 text-dark">
                                                                                No Availability Plan
                                                                            </h2>
                                                                        </>)
                                                                }

                                                            </div>
                                                            <div className="">
                                                                <div className="edit-prfl-uploader btn-sub" style={{ cursor: "pointer" }}>Upload Plan
                                                                    <input type="file" className="input-file-profile" accept="application/pdf" onChange={AVAHandler}></input></div>
                                                            </div>
                                                        </div>
                                                        <div className="finalresult ">
                                                            {
                                                                avaPlan ? renderLastAva(avaPlan) : null
                                                            }
                                                        </div>
                                                        <div className="finalresult ">
                                                            {
                                                                avaUrl ? renderNewAva(avaUrl) : null
                                                            }
                                                        </div>

                                                        <button type="button" className="btn bg-blue text-white hover-saffron btn-sub"
                                                            onClick={() => {
                                                                if (avaUrl) {
                                                                    // ImageSubmit();
                                                                    updateAVAPlan()

                                                                } else {
                                                                    toast.error("Please Select Media")
                                                                }
                                                            }}
                                                        >Submit</button>

                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="tab-pane tab-pane-parent fade"
                                                id="brochure"
                                                role="tabpanel"
                                            >
                                                <div className="card border-0 bg-transparent">
                                                    <div className="card-body p-0">
                                                        <div className="d-flex justify-content-between">
                                                            <div className="">
                                                                {

                                                                    projectBrouchure?.length > 0 ? (<>
                                                                        <h2 className="card-title fs-22 lh-15 mb-1 text-dark">
                                                                            Project Brouchure
                                                                        </h2></>) : (<>
                                                                            <h2 className="card-title fs-22 lh-15 mb-1 text-dark">
                                                                                No Project Brouchure
                                                                            </h2>
                                                                        </>)
                                                                }

                                                            </div>
                                                            <div className="">
                                                                <div className="edit-prfl-uploader btn-sub" style={{ cursor: "pointer" }}>Upload Plan
                                                                    <input type="file" className="input-file-profile" accept="application/pdf" onChange={brochureHandler}></input></div>
                                                            </div>
                                                        </div>
                                                        <div className="finalresult ">
                                                            {
                                                                projectBrouchure ? renderLastBro(projectBrouchure) : null
                                                            }
                                                        </div>
                                                        <div className="finalresult ">
                                                            {
                                                                brochureUrl ? renderNewBro(brochureUrl) : null
                                                            }
                                                        </div>

                                                        <button type="button" className="btn bg-blue text-white hover-saffron btn-sub"
                                                            onClick={() => {
                                                                if (brochureUrl) {
                                                                    // ImageSubmit();
                                                                    updateBro()

                                                                } else {
                                                                    toast.error("Please Select Media")
                                                                }
                                                            }}
                                                        >Submit</button>

                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="tab-pane tab-pane-parent fade"
                                                id="video"
                                                role="tabpanel"
                                            >
                                                <div className="card border-0 bg-transparent">
                                                    <div className="card-body p-0">
                                                        <div className="d-flex justify-content-between">
                                                            <div className="">
                                                                {

                                                                    propertyVedio ? (<>
                                                                        <h2 className="card-title fs-22 lh-15 mb-1 text-dark">
                                                                            Video
                                                                        </h2></>) : (<>
                                                                            <h2 className="card-title fs-22 lh-15 mb-1 text-dark">
                                                                                No property Video
                                                                            </h2>
                                                                        </>)
                                                                }

                                                            </div>
                                                            <div className="">
                                                                <div className="edit-prfl-uploader btn-sub" style={{ cursor: "pointer" }}>Upload Video
                                                                    <input type="file" className="input-file-profile" accept="video/*" onChange={VideoHandler}></input></div>
                                                            </div>
                                                        </div>
                                                        <div className="finalresult ">
                                                            {
                                                                propertyVedio ? renderLastVedio(propertyVedio) : null
                                                            }
                                                        </div>
                                                        <div className="finalresult ">
                                                            {
                                                                selectedVideo ? renderNewVideo(selectedVideo) : null
                                                            }
                                                        </div>
                                                        <button type="button" className="btn bg-blue text-white hover-saffron btn-sub"
                                                            onClick={() => {
                                                                if (selectedVideo) {
                                                                    // ImageSubmit();
                                                                    updateVedio()

                                                                } else {
                                                                    toast.error("Please Select Media")
                                                                }
                                                            }}
                                                        >Submit</button>

                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="tab-pane tab-pane-parent fade"
                                                id="floorplan"
                                                role="tabpanel"
                                            >
                                                <div className="card border-0 bg-transparent">
                                                    <div className="card-body p-0">

                                                       
                                                            <section className="">
                                                                <div className="d-flex justify-content-between">
                                                                    <div className="">
                                                                        <h2 className="card-title fs-22 lh-15 mb-1 text-dark">
                                                                            Floor Plan
                                                                        </h2>
                                                                    </div>
                                                                    <div className="">
                                                                        <div className="edit-prfl-uploader btn-sub" onClick={()=>{handleShow();
                                                                        setFloorName("")
                                                                        setUnitNumber("")
                                                                        setFloorPlanFile("")
                                                                        setFloorPlanUrl("")
                                                                        
                                                                        }} style={{ cursor: "pointer" }}>
                                                                            Add Floor Plan
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {floorPlans?.length > 0 ? (
                                                                <div className="py-3 border-bottom">
                                                                    <Accordion allowOneExpanded preExpanded={['item-0']}>
                                                                        {floorPlans.map((floors, index) => (
                                                                            <AccordionItem key={index} uuid={`item-${index}`}>
                                                                                <AccordionItemHeading>
                                                                                    <AccordionItemButton>
                                                                                        <div className="floor-title" >
                                                                                            <div className="d-flex justify-content-between">
                                                                                                <div className="">
                                                                                                    <h4 className="card-title fs-16  mb-1 ">
                                                                                                        {floors.name}{" "} with {" "}{floors.no_of_unit} units.
                                                                                                    </h4>
                                                                                                </div>
                                                                                                <div className="">
                                                                                                    <div className="px-5" style={{ cursor: "pointer" }}
                                                                                                        onClick={() => deleteFloor(floors?.id)}
                                                                                                    >
                                                                                                        <i class="fa fa-trash" aria-hidden="true" title="Delete"></i>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <h4>

                                                                                            </h4>
                                                                                        </div>
                                                                                    </AccordionItemButton>
                                                                                </AccordionItemHeading>
                                                                                <AccordionItemPanel>
                                                                                    <div className="floor-plan-sr">
                                                                                        <div className="card border-0 shadow-xxs-2">
                                                                                            <div>
                                                                                                <div className="card-body mb-3">
                                                                                                    <div className="">
                                                                                                        <iframe
                                                                                                            ref={iframeRefs.current[index]}
                                                                                                            src={floors.floor_pdf}
                                                                                                            style={{ width: "100%", height: "500px" }}
                                                                                                            title="Floor Plan"
                                                                                                        ></iframe>
                                                                                                    </div>
                                                                                                    <div className="">
                                                                                                        <button className="btn bg-blue text-white hover-saffron" onClick={() => toggleFloorFull(index)}>Full Screen</button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </AccordionItemPanel>
                                                                            </AccordionItem>
                                                                        ))}
                                                                    </Accordion>
                                                                </div>
                                                                 ) : (<>No Floor Plan for this property</>)}
                                                            </section>
                                                       </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>

                        </div>
                    </div>
                </div>
            </main>
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Add Floor Plan</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">

                        <div className="col-lg-4">
                            <div className="form-group">
                                <input type="text" name="name"
                                    value={floorName}
                                    className="form-control form-control-lg border-0"
                                    placeholder="Enter Floor Name"
                                    onChange={(e) => setFloorName(e?.target?.value)}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="form-group">
                                <input type="number" name="no_of_unit"
                                    className="form-control form-control-lg border-0"
                                    placeholder="Enter units"
                                    value={unitNumber}
                                    onChange={(e) => setUnitNumber(e?.target?.value)}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 ">
                            <div className="form-group">

                                <input
                                    type="file"
                                    className="custom-file-input add-pstion-abs"
                                    id="customFile"
                                    name="profile"
                                    accept="application/pdf"
                                    onChange={floorPlanHandler}
                                // onChange={(e) => floorPdfHandler(e, i)} 
                                />

                                <label
                                    className="btn bg-blue text-white hover-saffron "
                                    htmlFor="customFile" style={{ padding: "12px 32px 12px 32px" }}
                                >
                                    <i className="fal fa-cloud-upload"></i>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="">
                        <div className="finalresult ">
                            {
                                floorPlanUrl ? renderFloorUrl(floorPlanUrl) : null
                            }
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>

                    <Button
                        variant="success"
                        onClick={() => {
                            handleClose();
                           
                            if (floorName && unitNumber && floorPlanFile) {
                                // ImageSubmit();
                                addNewFloorPlan();

                            } else {
                                toast.error("Please Fill All Inputs and Try Again")
                            }
                        }}
                       
                    >
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default MultiImageUploader;