import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom';
import AuthService from './AuthService';
import { isExpired, decodeToken } from "react-jwt";
import axios from 'axios';
import Loader from '../Loader';
import { toast } from "react-toastify";
import axiosInstance from '../interceptor/axiosInstance';

const ForgetNewPassword = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const { uid, token } = useParams();
  const [value , setValue] = useState({
    password:"",
    uid:uid,
    token:token,
  })
 

  const navigate = useNavigate()
  // setValue(response.data)
  const handleSubmit = () => {
    axios.post(`${API_URL}/auth/users/reset_password_confirm/`, value)
      .then(response => {
        if (response.status === 204) {
          // Display a custom success message for status 204
          toast.success("Password Update succeeded.");
          navigate('/login')

        } else {
          // Display the response data in a success message
          toast.success("User", response.data.message);

        }
      })
      .catch(error => {
       
  
        // Display an error message
        toast.error("Your Token Has Been Expired !!",error.response.data.token);

      });
  }
  return (
    <section className="py-13 add-cstm-mt modal-opacity-none">
      <div className="container">
        <div className="row">
          <div className="col-lg-7 mx-auto">
            <div className="card border-0 shadow-xxs-2 login-register">
              <div className="card-body p-6">
                <h2 className="card-title fs-30 font-weight-600 text-dark lh-16 mb-2">
                  Update New Password !
                </h2>

                <form className="form">
                  <div className="form-group">
                    <label htmlFor="password" className="text-heading">
                      Enter your New Password
                    </label>
                    <input
                      type="password"
                      name="new_password"
                      className="form-control form-control-lg border-0"
                      placeholder="Enter your New Password"
                      onChange={e => setValue({...value, new_password:e.target.value})}
                    />
                  </div>
                  <button
                    type="button" onClick={handleSubmit} 
                    className="btn bg-blue text-white hover-saffron btn-lg rounded"
                    // onClick={EmailSubmit}
                  >
                   Update New Password
                  </button>
                </form>
                <br />

                <button
                    className="btn bg-blue text-white hover-saffron btn-lg rounded"
                    // onClick={EmailSubmit}
                  >
                   <Link to="/login">Login</Link>
                  </button>

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForgetNewPassword;
