// config.js

import React from 'react';
import { createChatBotMessage } from 'react-chatbot-kit';
import Avatar from './components/Avatar';
import StartBtn from './components/StartBtn';
import { AreaBtn, PropertyBtn, BuildingBtn, LabelBtn, PriceBtn, PlanBtn, ConstructionBtn, DubaiBtn } from './components/Components';
import data from './data';

const config = {
    botName: "AI Property Search Assistant!",
    initialMessages: [createChatBotMessage(`Welcome to Dirhram PerFoot!`, {
        widget: "startBtn"
    })],
    
    customComponents: {
        botAvatar: (props) => <Avatar {...props} />,
    },
    state: {
        checker: null,
        data,
        userData: {
            country:'',
            name: "",
            email: "",
            mobile: "",
            area: "",
            propertyType: "",
            buildingType: "",
            propertyLabel: "",
            averagePrice: "",
            propertyPlan: "",
            constructionStatus: "",
        }
    },
    widgets: [
        {
            widgetName: "startBtn",
            widgetFunc: (props) => <StartBtn {...props} />,
        },
        {
            widgetName: "DubaiBtn",
            widgetFunc: (props) => <DubaiBtn {...props} />,
        },
        {
            widgetName: "AreaBtn",
            widgetFunc: (props) => <AreaBtn {...props} />,
        },
        {
            widgetName: "BuildingBtn",
            widgetFunc: (props) => <BuildingBtn {...props} />,
        },
        {
            widgetName: "PropertyBtn",
            widgetFunc: (props) => <PropertyBtn {...props} />,
        },
        {
            widgetName: "LabelBtn",
            widgetFunc: (props) => <LabelBtn {...props} />,
        },
        {
            widgetName: "PriceBtn",
            widgetFunc: (props) => <PriceBtn {...props} />,
        },
        {
            widgetName: "PlanBtn",
            widgetFunc: (props) => <PlanBtn {...props} />,
        },
        {
            widgetName: "ConstructionBtn",
            widgetFunc: (props) => <ConstructionBtn {...props} />,
        },
    ]
};

export default config;



    // initialArea: [createChatBotMessage(`Choose Your Relocated Area !`, {
    //     widget: "AreaBtn"
    // })],

    // initialPropertyType: [createChatBotMessage(`Choose Your Property Types !`, {
    //     widget: "PropertyBtn"
    // })],

    // initialBuildingType: [createChatBotMessage(`Choose Your Building Types !`, {
    //     widget: "BuildingBtn"
    // })],

    // initialLabelType: [createChatBotMessage(`Choose Your Property Label !`, {
    //     widget: "LabelBtn"
    // })],

    // initialPriceAverage: [createChatBotMessage(`Choose Your average Price !`, {
    //     widget: "PriceBtn"
    // })],

    // initialPropertyPlan: [createChatBotMessage(`Choose Your Plan Details !`, {
    //     widget: "PlanBtn"
    // })],

    // initialConstructionType: [createChatBotMessage(`Choose Your Construction !`, {
    //     widget: "ConstructionBtn"
    // })],
    